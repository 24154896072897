import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { Plans } from './plans/plans';
import React from 'react';
import {
	Loading,
	LoadingButton,
	PageError,
	ScreenContainer,
} from '../../components';
import { RequestState } from '../../store-tools';
import { useCurrentAccount } from '../../contexts';
import { SubscriptionIntent, useBilling } from '../../hooks/useBilling';
import { UpsellInfo } from './upsellInfo';
import { ensureExists } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			display: 'grid',
			gridTemplateAreas: `"information actions"`,
			gridTemplateColumns: 'minmax(0, 35%) minmax(0, 65%)',
			gap: theme.spacing(2),
		},
	},
	information: {
		display: 'none',

		[theme.breakpoints.up('md')]: {
			display: 'block',
			gridArea: 'information',
		},
	},
	actions: {
		gridArea: 'actions',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));

type ValidState = {
	hasTrialled: boolean;
	isOnProPlan: boolean;
	navigateToSubscriptionPortal: () => void;
	navigateToSubscriptionPortalPending: boolean;
	navigateToBillingPortal: () => void;
	navigateToBillingPortalPending: boolean;
	onCancel: () => void;
	intent: SubscriptionIntent;
};

type State = RequestState<ValidState>;

const useState = (): State => {
	const { currentAccount } = useCurrentAccount();
	const {
		error,
		navigateToSubscriptionPortal,
		navigateToBillingPortal,
		isPending,
		onCancel,
		intent,
	} = useBilling();

	const result = React.useMemo<State>(() => {
		if (!error) {
			return {
				status: 'success',
				data: {
					hasTrialled: Boolean(currentAccount?.hasTrialled),
					isOnProPlan: ensureExists(currentAccount).tier === 'pro',
					navigateToSubscriptionPortal,
					navigateToSubscriptionPortalPending: isPending,
					navigateToBillingPortal,
					navigateToBillingPortalPending: isPending,
					onCancel,
					intent,
				},
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return { status: 'pending' as const };
		}
	}, [
		error,
		currentAccount,
		navigateToSubscriptionPortal,
		isPending,
		navigateToBillingPortal,
		onCancel,
		intent,
	]);

	return result;
};

export const AccountOwnerPaywallContent: React.ComponentType = () => {
	const styles = useStyles();
	const state = useState();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status !== 'success') {
		return <Loading showRandomLabels />;
	}

	const {
		hasTrialled,
		isOnProPlan,
		onCancel,
		navigateToSubscriptionPortal,
		navigateToSubscriptionPortalPending,
		navigateToBillingPortal,
		navigateToBillingPortalPending,
		intent,
	} = state.data;

	return (
		<ScreenContainer
			variant="two-column"
			containerClassName={styles.container}
		>
			<div className={styles.information}>
				<UpsellInfo />
			</div>
			<div className={styles.actions}>
				<Box mb={3}>
					<Typography variant="h3" align="center">
						{hasTrialled
							? 'Renew Pro today'
							: 'Try Pro free of charge for 30 days'}
					</Typography>
				</Box>

				<Plans />

				<Box mt={2}>
					{isOnProPlan ? (
						<LoadingButton
							loading={navigateToBillingPortalPending}
							variant="contained"
							color="primary"
							onClick={navigateToBillingPortal}
							fullWidth
						>
							Manage Subscription
						</LoadingButton>
					) : (
						<>
							<LoadingButton
								loading={navigateToSubscriptionPortalPending}
								variant="contained"
								color="primary"
								onClick={navigateToSubscriptionPortal}
								size="large"
								fullWidth
							>
								{hasTrialled ? 'Renew' : 'Trial'} Pro
							</LoadingButton>
							<Box mt={2}>
								<Button
									fullWidth
									variant="outlined"
									color="primary"
									onClick={onCancel}
								>
									{intent === 'paywall'
										? 'Back'
										: 'Stay on the free plan'}
								</Button>
							</Box>
						</>
					)}
				</Box>
			</div>
		</ScreenContainer>
	);
};
