import { DateTime } from 'luxon';
import LuxonAdapter from '@date-io/luxon';
const luxon = new LuxonAdapter();

const buildRecurrenceOptions = (date = luxon.date()) => {
	const {
		weekdayLong: weekday,
		day,
		monthLong: monthName,
		month,
	} = DateTime.fromISO(date);

	const weekdayICSCode = weekday.toUpperCase().slice(0, 2);

	return [
		{
			label: `Weekly on ${weekday}`,
			value: `FREQ=WEEKLY;BYDAY=${weekdayICSCode};INTERVAL=1`,
		},
		{
			label: `Monthly on the second ${weekday}`,
			value: `FREQ=MONTHLY;BYSTEP=2;BYDAY=${weekdayICSCode};INTERVAL=1`,
		},
		{
			label: `Annually on ${day}th ${monthName}`,
			value: `FREQ=YEARLY;BYMONTHDAY=${day};BYMONTH=${month};`,
		},
	];
};

export default buildRecurrenceOptions;
