import React from 'react';
import { FACEBOOK, GOOGLE, useAuth } from '../hooks';
import { ErrorType, registerError } from '../errors';
import { UserMarketing } from '@contracts/platform';
import { ButtonProps } from '@material-ui/core';
import { LoadingButton } from './loadingButton';
import { capitalize } from '@common/utils/capitalize';

type Props = ButtonProps & {
	providerID: typeof GOOGLE | typeof FACEBOOK;
	setError: React.Dispatch<React.SetStateAction<ErrorType | null>>;
	onComplete?: () => void;
	mode?: 'login' | 'link';
	className?: string;
	userAcquisitionData?: UserMarketing;
};

/**
 * @deprecated please implement logic directly
 * e.g., @common/login-settings/components/updateProviders
 * */
export const SocialLoginButton = ({
	setError,
	onComplete,
	mode = 'login',
	providerID,
	className,
	userAcquisitionData,
	...props
}: Props): React.ReactElement<Props> => {
	const { linkWithPopup, loginOrCreateUserWithPopup } = useAuth();

	const [isPending, setIsPending] = React.useState(false);

	const existingProvider = [GOOGLE, FACEBOOK].includes(providerID);

	const loginWithSocial = React.useCallback(
		async (providerID) => {
			setIsPending(true);

			try {
				setError(null);

				switch (mode) {
					case 'link':
						await linkWithPopup(providerID);
						break;
					case 'login':
						await loginOrCreateUserWithPopup({
							providerKey: providerID,
							userAcquisitionData,
						});
						break;
					default:
						throw new Error('Invalid mode specified');
				}

				onComplete?.();
			} catch (error) {
				registerError(error);
				setError(error as ErrorType);
			} finally {
				setIsPending(false);
			}
		},
		[
			linkWithPopup,
			loginOrCreateUserWithPopup,
			mode,
			onComplete,
			setError,
			userAcquisitionData,
		],
	);

	return (
		<LoadingButton
			onClick={() => {
				loginWithSocial(providerID);
			}}
			loading={isPending}
			className={className}
			disabled={!existingProvider || isPending}
			fullWidth
			{...props}
		>
			{capitalize(providerID)}
		</LoadingButton>
	);
};
