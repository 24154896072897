import React from 'react';
import {
	Box,
	CircularProgress,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { Duration } from 'luxon';

const sizeXs = 60;
const sizeLg = 127;
const STEP = 100; // ms

const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'relative',
	},
	track: {
		borderWidth: '6px',
		borderStyle: 'solid',
		borderColor: '#BDBDBD',
		width: 'calc(100%)',
		height: 'calc(100%)',
		borderRadius: '100%',
		position: 'absolute',
	},
	seconds: {
		fontSize: 'clamp(16px, 1vw, 3vw)',
		fontWeight: 'normal',
	},
	team: {
		position: 'relative',
	},
}));

type UseCountdownIndicatorProps = {
	durationMs: number;
	onUpdate: (timeLeft: number) => void;
	onComplete?: () => void;
};

const useCountdownIndicator = (props: UseCountdownIndicatorProps) => {
	const { durationMs, onUpdate, onComplete } = props;

	// @ts-ignore
	React.useEffect(timer, [durationMs, onUpdate, onComplete]);

	function timer() {
		const startTime = Date.now();
		let relevant = true;
		let timeLeft = durationMs;
		let lastUpdate = Infinity;

		requestAnimationFrame(function onFrame() {
			if (!relevant) {
				return;
			}

			timeLeft = Math.max(0, durationMs - (Date.now() - startTime));

			if (lastUpdate - timeLeft > STEP) {
				onUpdate(timeLeft);
				lastUpdate = timeLeft;
			}

			if (timeLeft <= 0) {
				onComplete?.();
			} else {
				requestAnimationFrame(onFrame);
			}
		});

		return () => (relevant = false);
	}
};

type CountdownIndicatorProps = {
	durationMs: number;
	onComplete?: () => void;
	size?: number;
};

export const CountdownIndicator: React.ComponentType<CountdownIndicatorProps> =
	(props: CountdownIndicatorProps) => {
		const isDesktop = useMediaQuery<Theme>((theme) =>
			theme.breakpoints.up('md'),
		);
		const {
			durationMs,
			onComplete,
			size = isDesktop ? sizeLg : sizeXs,
		} = props;
		const [timeLeft, setTimeLeft] = React.useState(durationMs);
		const classes = useStyles();

		const borderWidth = size / 12;

		useCountdownIndicator({
			durationMs,
			onUpdate: setTimeLeft,
			onComplete,
		});

		const seconds = React.useCallback(() => {
			return Duration.fromMillis(timeLeft).toFormat('m:ss');
		}, [timeLeft]);

		return (
			<Box
				position="relative"
				display="inline-flex"
				className={classes.wrapper}
				style={{ width: size }}
			>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<div className={classes.track} style={{ borderWidth }} />
					<Typography
						className={classes.seconds}
						variant="h3"
						component="div"
						color="textPrimary"
					>
						{seconds()}
					</Typography>
				</Box>
				<CircularProgress
					color="primary"
					variant="determinate"
					value={(timeLeft / durationMs) * 100}
					size={size}
					className={classes.team}
				/>
			</Box>
		);
	};
