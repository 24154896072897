export const contentAnimation = {
	from: { opacity: '0', transform: 'translateY(1%)' },
	to: async (next, cancel) => {
		next({ opacity: '1', transform: 'translateY(0px)' });
	},
	config: { duration: 500 },
};

export const gifAnimation = {
	from: { transform: 'translateX(-40%)', opacity: '0' },
	to: { transform: 'translateX(0px)', opacity: '1' },
	config: { duration: 500 },
};
