import React from 'react';
import { useField } from 'formik';
import { ChipTextField } from '@remote-social/common';
import * as Yup from 'yup';
import { useValidatedChipTextFieldState } from '../chipTextFieldHooks';

type ChipTextFieldProps = Omit<
	React.ComponentProps<typeof ChipTextField>,
	'chipsValue' | 'inputValue' | 'onChange'
>;

type Props = {
	name: string;
	chipValidationSchema?: Yup.StringSchema;
} & ChipTextFieldProps;

const ChipFormField: React.ComponentType<Props> = ({
	name,
	delimiters,
	chipValidationSchema,
	helperText,
	...rest
}) => {
	const [, meta, helpers] = useField(name);

	const [input, setInput] = React.useState('');
	// we maintain additional error state
	const [localError, setError] = React.useState('');

	const { value, error } = meta;

	if (typeof value === 'undefined') {
		throw new Error(
			'No initial value provided for field with name ' + name,
		);
	}
	if (!Array.isArray(value)) {
		throw new Error(
			'ChipFormField only accepts string arrays as value types',
		);
	}

	const { onChange } = useValidatedChipTextFieldState({
		validationSchema: chipValidationSchema,
		setInput,
		setChips: helpers.setValue,
		setError: setError,
		delimiters,
	});

	const hasError = Boolean(error || localError);
	const helperTextToUse = error || localError || helperText;

	return (
		<ChipTextField
			chipsValue={value}
			inputValue={input}
			error={hasError}
			helperText={helperTextToUse}
			onChange={onChange}
			{...rest}
		/>
	);
};

export { ChipFormField };
