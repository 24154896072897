import React, { useState, useCallback } from 'react';

export const AnimatedBackgroundContext = React.createContext(null);

export const AnimatedBackgroundSpeed = ({ children, defaultSpeed = 0.2 }) => {
	const [backgroundSpeed, setBackgroundSpeed] = useState(defaultSpeed);

	const resetBackgroundSpeed = useCallback(() => {
		setBackgroundSpeed(defaultSpeed);
	}, [defaultSpeed, setBackgroundSpeed]);

	return (
		<AnimatedBackgroundContext.Provider
			value={{
				backgroundSpeed,
				setBackgroundSpeed,
				resetBackgroundSpeed,
			}}
		>
			{children}
		</AnimatedBackgroundContext.Provider>
	);
};
