import imageCompression from 'browser-image-compression';

/**
 * Compression option props
 * -------------------------
 * maxSizeMB - Number.POSITIVE_INFINITY
 * maxWidthOrHeight - default undefined
 * useWebWorker - default false
 * maxIteration - default 10
 * exifOrientation - Default to be the exif orientation from the image file
 * onProgress - A function takes one progress argument (progress from 0 to 100)
 * fileType - Default to be the original mime type from the image file
 * initialQuality - default 1.0
 * */

const ImageCompressor = async (image, options) => {
	const defaultOptions = {
		maxSizeMB: 1,
		useWebWorker: true,
	};

	// if image is already of proper size, no need to resize it
	if (image.size / 1024 / 1024 <= defaultOptions.maxSizeMB) {
		return image;
	}

	// warn if options ask for max size over default max size
	if (options.maxSizeMB > defaultOptions.maxSizeMB) {
		console.warn(
			'Larger images will cause slowdown and un-necessary bandwidth usage.',
		);
	}

	return await imageCompression(image, {
		...defaultOptions,
		...options,
	});
};

export default ImageCompressor;
