const envHost = (appName = 'hub') => {
	let hostname = window.location.hostname;
	let origin = window.location.origin;

	// check for local
	if (hostname === 'localhost' || hostname === '127.0.0.1') {
		return origin;
	}

	let link = '';

	// this case for staging/production apps
	const start = origin.indexOf('//') + 2;
	const end = origin.indexOf('.');

	// e.g., link = "http://" + "X" + ".remotesocial.io"
	link = `${origin.slice(0, start)}${appName}${origin.slice(
		end,
		origin.length,
	)}`;

	return link;
};

const envURL = (link, app = 'hub') => {
	if (!link) return '';

	// we are separating the host and resource link
	const snipPoint = link.indexOf('/', link.includes('http') ? 8 : 0);

	// now we generate host url for current env
	const host = envHost(app);
	const resourceURL = link.slice(snipPoint);

	// create new game link
	return `${host}${resourceURL}`;
};

exports.envHost = envHost;
exports.envURL = envURL;
