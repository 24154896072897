import type { ValueTypesOf } from '../../shared';
import { mapOf } from '../../shared';

const gameStates = ['lobby', 'game'] as const;

export type GameState = ValueTypesOf<typeof gameStates>;

// TODO: Remove when all is TypeScript
// NOTE: do not copy-paste this constant, we don't really need it
// for string literal types, we could just use strings as is
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameState = mapOf(gameStates);
