import React, { useCallback } from 'react';
import { useField } from 'formik';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const SelectField = ({ name, options, formControlProps, ...rest }) => {
	const [, meta, helpers] = useField(name);
	const { value } = meta;

	const handleChange = useCallback(
		(event) => {
			helpers.setValue(event.target.value, true);
		},
		[helpers],
	);

	return (
		<FormControl
			variant="outlined"
			style={{ width: '100%' }}
			{...formControlProps}
		>
			<InputLabel>{rest.label}</InputLabel>
			<Select value={value} onChange={handleChange} {...rest}>
				{options.map((option, idx) => (
					<MenuItem key={idx} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectField;
