import { UnreachableError } from '../errors';
import { AppNames, EnvName } from './types';

export const localPortByApp: Record<AppNames, number> = {
	hub: 3000,
	connect: 3001,
	trivia: 3002,
	bingo: 3003,
	physical: 3004,
	poker: 3005,
	checkin: 3006,
} as const;

type AppUrlsConfig = Record<`${AppNames}Url`, string>;

export type UrlConfig = {
	// Remote Social company page
	webUrl: string;
	// Ziago hosted games
	ziagoUrl: string;
	// Hosted experiences
	experiencesUrl: string;
} & AppUrlsConfig;

function appUrl(appName: AppNames, envName: EnvName, port: number): string {
	switch (envName) {
		case 'local': {
			return `${window.location.protocol}//localhost:${port}`;
		}
		case 'prod': {
			return `https://${appName}.remotesocial.io`;
		}
		case 'staging': {
			return `https://${appName}.remotesocial.app`;
		}
		default: {
			throw new UnreachableError(envName);
		}
	}
}

export const urlsByEnv = (envName: EnvName): UrlConfig => {
	const baseUrls = {
		webUrl: 'https://remotesocial.io',
		ziagoUrl:
			envName === 'prod'
				? 'https://games.remotesocial.io'
				: 'https://rs---dev.web.app',
		experiencesUrl:
			envName === 'prod'
				? 'https://experiences.remotesocial.io'
				: 'https://remotesocial-sharetribe-dev.herokuapp.com',
	};

	const appUrls: Partial<Record<`${AppNames}Url`, string>> = {};

	for (const [appName, port] of Object.entries(localPortByApp) as Array<
		[AppNames, number]
	>) {
		const key: `${AppNames}Url` = `${appName}Url`;
		appUrls[key] = appUrl(appName, envName, port);
	}

	return {
		...baseUrls,
		...(appUrls as Record<`${AppNames}Url`, string>),
	};
};
