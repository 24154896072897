/**
 * Whether current application is build for development purposes or for PROD deployment
 *
 * This is not same as `env().name === 'local'` - we can have a production build that runs
 * locally using `local` flavor of the environment configuration
 *
 * HINT:
 *   yarn start -> isDevBuild() returns true
 *   yarn build -> npx serve ./build -> isDevBuild() returns false
 *
 * @returns `true` if development build, `false` if not
 */
export function isDevBuild() {
	return (
		process.env.NODE_ENV === 'development' ||
		// treat unit tests as dev build
		process.env.NODE_ENV === 'test'
	);
}
