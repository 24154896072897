import React from 'react';
import {
	Checkbox,
	createStyles,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	makeStyles,
	Typography,
	useMediaQuery,
	Theme,
	Grid,
} from '@material-ui/core';
import {
	DayOfWeek,
	daysOfWeek,
	parseTimeOfDay,
	TimeOfDay,
} from '@contracts/schedules';
import {
	ConnectScheduleTiming,
	connectScheduleTimingSchemaMap,
} from '@contracts/connect';
import clsx from 'clsx';
import { TimeSelect } from './timeSelect';
import { DateTime } from 'luxon';
import { currentTimezone, TimeZoneSelect } from './timeZoneSelect';
import { Joi, objectSchemaOf } from '@contracts/shared';
import { HtmlMessage } from '@common/components';

const useStyles = makeStyles((theme) => {
	return createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2, 0),
		},
		helperText: {
			flexBasis: `100%`,
		},
	});
});

const dayOfWeekLabelsLong: Record<DayOfWeek, string> = {
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',
};

const dayOfWeekLabelsShort: Record<DayOfWeek, string> = {
	monday: 'Mon',
	tuesday: 'Tue',
	wednesday: 'Wed',
	thursday: 'Thu',
	friday: 'Fri',
	saturday: 'Sat',
	sunday: 'Sun',
};

const defaultSchedule: ConnectScheduleTiming = {
	daysOfWeek: ['monday', 'wednesday', 'friday'],
	timesOfDay: ['10:00'],
	timeZone: currentTimezone(),
};

const schema = objectSchemaOf<ConnectScheduleTiming>({
	...connectScheduleTimingSchemaMap,
	// add messages for things that can fail on this UI
	daysOfWeek: connectScheduleTimingSchemaMap.daysOfWeek.messages({
		'array.min': 'At least one day of week must be selected',
	}),
});

const errorForPath = (
	path: keyof ConnectScheduleTiming,
	result: Joi.CustomValidationResult<ConnectScheduleTiming>,
) => {
	if (!result.error) {
		return;
	}
	const details = result.error.details.find(
		(detail) => detail.path[0] === path,
	);
	if (!details) {
		return;
	}
	return details.message;
};

const useSchedulePanelState = (
	partialDefaultValue?: Partial<ConnectScheduleTiming>,
) => {
	const defaultValue = {
		...defaultSchedule,
		...partialDefaultValue,
	};
	const [dayOfWeekState, setDayOfWeekState] = React.useState<
		Record<DayOfWeek, boolean>
	>(
		daysOfWeek.reduce(
			(acc, day) => ({
				...acc,
				[day]: defaultValue.daysOfWeek?.includes(day) ?? false,
			}),
			{} as Record<DayOfWeek, boolean>,
		),
	);

	const onDayOfWeekSelected: React.ChangeEventHandler<HTMLInputElement> =
		React.useCallback(
			(e) => {
				const isChecked = Boolean(e.currentTarget.checked);
				const name = e.currentTarget.name;
				setDayOfWeekState((state) => ({
					...state,
					[name]: isChecked,
				}));
			},
			[setDayOfWeekState],
		);

	const dayOfWeekOptions = daysOfWeek.map((day) => ({
		name: day,
		checked: dayOfWeekState[day],
		onSelected: onDayOfWeekSelected,
	}));

	const [dateTime, setDateTime] = React.useState(
		defaultValue.timesOfDay && defaultValue.timesOfDay[0]
			? DateTime.now().set(parseTimeOfDay(defaultValue.timesOfDay[0]))
			: DateTime.now(),
	);

	const [timeZoneValue, setTimeZone] = React.useState(defaultValue.timeZone);

	const daysOfWeekValue = dayOfWeekOptions
		.filter((option) => option.checked)
		.map((option) => option.name);
	const timeOfDayValue = `${dateTime
		.get('hour')
		.toFixed(0)
		.padStart(2, '0')}:${dateTime
		.get('minute')
		.toFixed(0)
		.padStart(2, '0')}` as TimeOfDay;

	const schedule = React.useMemo(
		() => {
			const schedule = {
				daysOfWeek: daysOfWeekValue,
				timesOfDay: [timeOfDayValue],
				timeZone: timeZoneValue,
			} as unknown as ConnectScheduleTiming;
			const validationResult = schema.validate(schedule, {
				abortEarly: false,
			});
			return {
				isValid: !validationResult.error,
				validationResult,
				schedule,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[daysOfWeekValue.join(''), timeOfDayValue, timeZoneValue],
	);

	const dayOfWeek = {
		options: dayOfWeekOptions,
		error: errorForPath('daysOfWeek', schedule.validationResult),
	};

	const timeOfDay = {
		dateTime,
		setDateTime,
	};

	const timeZone = {
		value: timeZoneValue,
		setTimeZone,
	};

	return {
		dayOfWeek,
		timeOfDay,
		timeZone,
		schedule,
	};
};

type Props = {
	className?: string;
	defaultValue?: Partial<ConnectScheduleTiming>;
	onChange?: (state: {
		isValid: boolean;
		schedule: ConnectScheduleTiming;
	}) => void;
	daysOfWeekRecommendation?: string;
};

export const useSchedulePanel = (opts?: {
	defaultValue?: Partial<ConnectScheduleTiming>;
}) => {
	const [schedule, setSchedule] = React.useState<
		ConnectScheduleTiming | 'invalid'
	>({
		...defaultSchedule,
		...opts?.defaultValue,
	});

	const onChange = React.useCallback(
		(state: { isValid: boolean; schedule: ConnectScheduleTiming }) => {
			if (state.isValid) {
				setSchedule(state.schedule);
			} else {
				setSchedule('invalid');
			}
		},
		[setSchedule],
	);

	return {
		schedule,
		schedulePanelProps: {
			defaultValue: opts?.defaultValue,
			onChange,
		},
	};
};

export const SchedulePanel: React.ComponentType<Props> = React.memo(
	({ onChange, defaultValue, className, daysOfWeekRecommendation }) => {
		const styles = useStyles();
		const { dayOfWeek, timeOfDay, timeZone, schedule } =
			useSchedulePanelState(defaultValue);

		const isXs = useMediaQuery<Theme>((theme) =>
			theme.breakpoints.down('xs'),
		);
		const shouldUseNarrowLayout = isXs;
		const dayOfWeekLabelPlacement = shouldUseNarrowLayout
			? 'bottom'
			: 'end';
		const dayOfWeekLabels = shouldUseNarrowLayout
			? dayOfWeekLabelsShort
			: dayOfWeekLabelsLong;

		React.useEffect(() => {
			if (!onChange) {
				return;
			}
			onChange({
				isValid: schedule.isValid,
				schedule: schedule.schedule,
			});
		}, [onChange, schedule]);

		return (
			<div className={clsx(styles.container, className)}>
				<div>
					<Typography variant="h4" gutterBottom>
						Day of week
					</Typography>
					{daysOfWeekRecommendation && (
						<Typography variant="body1">
							<HtmlMessage message={daysOfWeekRecommendation} />
						</Typography>
					)}
					<FormControl
						component="fieldset"
						error={Boolean(dayOfWeek.error)}
					>
						<FormGroup row>
							{dayOfWeek.options.map((day) => (
								<FormControlLabel
									key={day.name}
									control={
										<Checkbox
											checked={day.checked}
											onChange={day.onSelected}
											name={day.name}
										/>
									}
									label={dayOfWeekLabels[day.name]}
									labelPlacement={dayOfWeekLabelPlacement}
								/>
							))}
							<FormHelperText className={styles.helperText}>
								{dayOfWeek.error ??
									'You can select one or more days to run your activity'}
							</FormHelperText>
						</FormGroup>
					</FormControl>
				</div>

				<div>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Typography variant="h4" gutterBottom>
								Time of day
							</Typography>
							<TimeSelect
								minuteStep={15}
								value={timeOfDay.dateTime}
								onChange={timeOfDay.setDateTime}
								helperText="Time to run your activity at"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography variant="h4" gutterBottom>
								Time zone
							</Typography>
							<TimeZoneSelect
								value={timeZone.value}
								onChange={timeZone.setTimeZone}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	},
);
SchedulePanel.displayName = 'SchedulePanel';
