import React, { useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Form, Formik } from 'formik';
import DateTimeFrequency, {
	DateTimeFrequencySchema,
} from './Forms/DateTimeFrequency';
import GameSelection, { GameSelectionSchema } from './Forms/GameSelection';
import UserSelection, { UserSelectionSchema } from './Forms/UserSelection';
import InvitePeople, { InvitePeopleSchema } from './Forms/InvitePeople';
import EmailTemplateSelection, {
	EmailTemplateSelectionSchema,
} from './Forms/EmailTemplateSelection';
import LuxonAdapter from '@date-io/luxon';
import { useCurrentAccount } from '../../contexts';
import {
	useAsyncFunction,
	useBackendFunction,
	useCurrentUser,
} from '../../hooks';
import { env } from '../../environment';
import { Confirmation } from './Forms/Confirmation';
import durationOptions from '../../data/ScheduleDuration';
import buildRecurrenceOptions from '../../data/RecurrenceOptions';
import { DateTime } from 'luxon';
import { registerError } from '../../errors';
import { SessionName, SessionNameSchema } from './Forms/sessionName';
import { isError, isPending, isSuccess } from '../../store-tools';
import imgStartActivity from '../../assets/images/scheduling/StartActivity.svg';
import nameSession from '../../assets/images/scheduling/NameSession.svg';
import imgDateTimeFrequency from '../../assets/images/scheduling/DateTimeFrequency.svg';
import imgUserSelection from '../../assets/images/scheduling/UserSelection.svg';

const luxon = new LuxonAdapter();

const useStyles = makeStyles(
	(/** @type {import('@material-ui/core').Theme} */ theme) => ({
		paper: {
			borderRadius: '1rem',
		},
		content: {
			display: 'grid',

			[theme.breakpoints.up('md')]: {
				gridTemplateAreas: '"illustration form"',
				gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
				gap: theme.spacing(3),
			},
		},
		illustrationContainer: {
			display: 'none',

			[theme.breakpoints.up('md')]: {
				gridArea: 'illustration',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: 395,
				overflow: 'hidden',
				borderRadius: '1rem',
			},
		},
		illustration: {
			width: '100%',
			height: '100%',
		},
		form: {
			width: '100%',

			[theme.breakpoints.up('md')]: {
				gridArea: 'form',
				width: 'auto',
			},
		},
		errorText: {
			color: theme.palette.error.main,
		},
		backBtn: {
			marginRight: theme.spacing(1),
		},
	}),
);

const SchedulerFlow = ({
	isOpen,
	onRequestClose,
	games,
	game,
	onScheduleSuccess,
}) => {
	const classes = useStyles();
	const currentUser = useCurrentUser();
	const { currentAccount, getAccountMembers, createOrUpdateGameSchedule } =
		useCurrentAccount();
	const [selectedGame, setSelectedGame] = useState('');

	const isMd = useMediaQuery(
		(/** @type {import('@material-ui/core').Theme} */ theme) =>
			theme.breakpoints.up('md'),
	);

	// if game is already known, no need to show `Game selection step`
	const [step, setStep] = useState(game?.id ? 1 : 0);

	const [emailTemplatePreview, setEmailTemplatePreview] = useState(null);

	const [submittingSchedule, setSubmittingSchedule] = useState(false);

	const [error, setError] = useState('');

	const [createGameRequest, createGame] = useBackendFunction(
		'platform-createGame',
		{
			callOnChange: () => {
				if (!selectedGame || !currentAccount?.accountId) return;

				return {
					gameType: selectedGame,
					accountId: currentAccount.accountId,
				};
			},
		},
	);
	const createGameResult =
		isSuccess(createGameRequest) && createGameRequest.data;

	const [fetchMembersRequest] = useAsyncFunction(
		/**
		 * @param {{ accountId: string }} params
		 */
		async (params) => {
			const { data } = await getAccountMembers(params.accountId);

			/**
			 * @type {Array<{ id: string, name?: string, photoURL?: string | null, email: string }>}
			 */
			const members = data
				// no need to show current user in the invite user list as a member
				.filter((member) => member.email !== currentUser.email)
				.map((member) => ({
					id: member.email,
					displayName: member.displayName,
					photoURL: member.photoURL,
					email: member.email, // passing this for ease of searching
				}));

			return members;
		},
		{
			callOnChange: () => {
				if (!currentAccount?.accountId) return;
				return {
					accountId: currentAccount.accountId,
				};
			},
		},
	);
	const members =
		(isSuccess(fetchMembersRequest) && fetchMembersRequest.data) || [];

	const gameURL =
		createGameResult &&
		selectedGame &&
		`${env()[`${selectedGame}Url`]}/game/${createGameResult.accountID}/${
			createGameResult.url
		}`;

	const StepConfig = {
		/* Game Selection */
		0: {
			heading: 'Pick a starting activity',
			Validation: GameSelectionSchema,
			Form: <GameSelection games={games} />,
			Illustration: (
				<img
					className={classes.illustration}
					src={imgStartActivity}
					alt=""
				/>
			),
		},
		1: {
			heading: 'Name your session',
			Validation: SessionNameSchema,
			Form: (
				<Box mt={4}>
					<SessionName
						placeholder="Team Pow-Wow"
						helperText="This will appear in your calender, the invites sent and in the sessions page."
					/>
				</Box>
			),
			Illustration: (
				<img
					className={classes.illustration}
					src={nameSession}
					alt=""
				/>
			),
		},
		/* Game timings configuration */
		2: {
			heading: 'Set your schedule',
			Validation: DateTimeFrequencySchema,
			Form: <DateTimeFrequency durationOptions={durationOptions} />,
			Illustration: (
				<img
					className={classes.illustration}
					src={imgDateTimeFrequency}
					alt=""
				/>
			),
		},
		/* Adding members */
		3: {
			heading: 'Select players from team',
			Validation: UserSelectionSchema,
			Form: <UserSelection users={members} />,
			Illustration: (
				<img
					className={classes.illustration}
					src={imgUserSelection}
					alt=""
				/>
			),
			skip: members?.length === 0,
		},
		/* Inviting people */
		4: {
			heading: 'Invite people by email or link',
			Validation: InvitePeopleSchema,
			Form: <InvitePeople gameURL={gameURL} />,
			Illustration: (
				<img
					className={classes.illustration}
					src={imgUserSelection}
					alt=""
				/>
			),
		},
		/* Invite template selection */
		5: {
			heading: 'Choose an invite',
			Validation: EmailTemplateSelectionSchema,
			Form: (
				<EmailTemplateSelection
					game={game}
					games={games}
					name="emailTemplate"
					onTemplateChange={(template) =>
						setEmailTemplatePreview(template)
					}
				/>
			),
			Illustration: (
				<div className={classes.illustration}>
					{emailTemplatePreview}
				</div>
			),
		},
		6: {
			heading: 'Confirm your schedule',
			Form: (
				<>
					{/*
							A hacky solution to keep email template illustration styles intact
							after stepping through it. Otherwise, we can't show the email template on confirmation screen
							The styles get reset.
						*/}
					<Box style={{ display: 'none' }}>
						<EmailTemplateSelection
							game={game}
							games={games}
							name="emailTemplate"
							onTemplateChange={(template) =>
								setEmailTemplatePreview(template)
							}
						/>
					</Box>

					<Confirmation gameURL={gameURL} allMembers={members} />
				</>
			),
			Illustration: (
				<div className={classes.illustration}>
					{emailTemplatePreview}
				</div>
			),
		},
	};

	const totalSteps = Object.keys(StepConfig).length;
	const isLastStep = step === totalSteps - 1;
	const canNavigateBack = game?.id ? step > 1 : step > 0;

	const recurrenceOptions = buildRecurrenceOptions();

	async function continueOrSubmit(values) {
		if (step === 1) {
			setSelectedGame(game?.id ?? values.games[0]);
			setStep((step) => step + 1);
			return;
		}
		if (step === 2 && isError(createGameRequest)) {
			createGame();
			return;
		}

		if (isLastStep) {
			// making a copy as we'll need to sanitize the output
			// and that will mess up the form state if done directly on incoming data
			let payload = {
				...values,
			};

			const duration = durationOptions.find(
				(d) => d.value === values.duration,
			);
			payload.duration = duration.data;

			payload.players = payload.players.map((player) => {
				const data = members.find((member) => member.id === player);

				return {
					displayName: data.displayName,
					email: data.id,
					photoURL: data.photoURL,
				};
			});

			// formatting local date to UTC
			const { startDate, startTime } = values;

			// create a combined date time
			const time = DateTime.local(
				startDate.year,
				startDate.month,
				startDate.day,
				startTime.hour,
				startTime.minute,
				0,
			);

			const gameId = game?.id ?? payload.games[0];

			const gameRef = gameURL.split('/').pop();

			payload = {
				...payload,
				baseUrl: env()[`${gameId}Url`], // ideally this would be passed down from parent
				gameRef,
				gameURL,
				accountId: currentAccount.accountId, // account in which invite and game to be created
				time: time.toUTC().toString(), // send UTC string to server
			};

			try {
				setSubmittingSchedule(true);
				await createOrUpdateGameSchedule(payload);

				// close the modal after success
				onRequestClose && onRequestClose();

				// callback to parent when scheduling was successful
				onScheduleSuccess && onScheduleSuccess();
			} catch (error) {
				registerError(error);
				setError('Error submitting schedule, retry again later');
			} finally {
				setSubmittingSchedule(false);
			}
		} else {
			// if the step should be skipped we can jump over it
			const jump = StepConfig[step + 1].skip ? 2 : 1;

			setStep(step + jump);
		}
	}

	const formikRef = React.useRef();

	React.useEffect(() => {
		/**
		 * @type {import('formik').FormikProps<{}>}
		 */
		const formik = formikRef.current;
		if (formik) {
			formik.validateForm();
		}
	}, [step]);

	const navigateToPrevStep = () => {
		if (!canNavigateBack) return;

		// we need to jump 2 steps if the previous step was skipped in the forward direction
		const jumpCount = StepConfig[step - 1].skip ? 2 : 1;
		setStep(step - jumpCount);
	};

	const buttonText = () => {
		const submitButtonText = (
			<>
				<span>Confirm</span>
				<ArrowForwardIcon fontSize={'inherit'} />
				<span>Send</span>
			</>
		);
		return step < totalSteps - 1 ? 'Continue' : submitButtonText;
	};

	return (
		<Dialog
			fullWidth
			maxWidth={isMd ? 'md' : 'sm'}
			open={isOpen}
			onClose={onRequestClose}
			aria-labelledby="scheduler"
			PaperProps={{
				className: classes.paper,
			}}
		>
			<Formik
				innerRef={formikRef}
				initialValues={{
					team: '',
					games: game ? [game.id] : [],
					startDate: luxon.date(),
					startTime: luxon.date(),
					players: [],
					invitees: [],
					emailTemplate: 'basic',
					duration: durationOptions[1].value,
					isRepeating: false,
					schedule: recurrenceOptions[0].value,
				}}
				onSubmit={continueOrSubmit}
				validationSchema={StepConfig[step].Validation}
				validateOnMount={true}
			>
				{(form) => (
					<Form>
						<DialogTitle disableTypography>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								gridGap={2}
								width="100%"
							>
								<Typography variant="h5">
									{StepConfig[step].heading}
								</Typography>
								<IconButton
									onClick={onRequestClose}
									aria-label="close"
									size="small"
								>
									<CloseIcon />
								</IconButton>
							</Box>
						</DialogTitle>

						<DialogContent>
							<Box className={classes.content}>
								<Box className={classes.illustrationContainer}>
									{StepConfig[step].Illustration}
								</Box>
								<Box className={classes.form}>
									{StepConfig[step].Form}
								</Box>
							</Box>
							{error && (
								<span className={classes.errorText}>
									{error}
								</span>
							)}
						</DialogContent>

						<DialogActions>
							{canNavigateBack && (
								<Button
									onClick={navigateToPrevStep}
									aria-label="back"
									variant="outlined"
									color="primary"
									className={classes.backBtn}
								>
									Back
								</Button>
							)}
							<Button
								loading={
									isPending(createGameRequest) ||
									submittingSchedule
								}
								disabled={!form.isValid}
								type="submit"
								variant="contained"
								color="primary"
							>
								{buttonText()}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default SchedulerFlow;
