import React from 'react';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core';
import { oldRemoteSocialTheme } from './oldRsTheme';
import { Config, AppConfig } from '../contexts/config';
import { rsTheme } from './remoteSocialTheme';

type Props = {
	/**
	 * @deprecated only supposed to be used in a single place temporarily in Header.js
	 */
	configureTheme?: (appConfig?: AppConfig) => (theme: Theme) => Theme;
	oldTheme?: boolean;
};

/**
 * This component serves two purposes
 *
 * - Provide a way for us to use shared components in apps
 *   that could possibly have overridden theme at the root.
 *   These shared components must use the Base Remote Social Theme
 *   which is basic grayscale neutral color theme regardless of what
 *   overridden theme looks like.
 *
 * - Provide a way for us to migrate from old RS theme
 *   that overrides too many things at the root to a safer
 *   RS theme which has tighter rules and only allows override
 *   of color properties and properties that do not affect
 *   dimensions/layout of the core components in material-ui.
 */
export const CommonThemeProvider: React.ComponentType<Props> = ({
	configureTheme,
	oldTheme,
	children,
}) => {
	const appConfig = React.useContext(Config);

	const shouldUseNewRemoteSocialTheme =
		typeof oldTheme === 'undefined' ? !appConfig?.design : !oldTheme;

	const appliedTheme = React.useMemo(() => {
		// we are overriding the root app theme completely by either new Base Theme or Old Base Theme
		const rootTheme = shouldUseNewRemoteSocialTheme
			? rsTheme
			: oldRemoteSocialTheme;
		return createTheme(
			configureTheme ? configureTheme(appConfig)(rootTheme) : rootTheme,
		);
	}, [shouldUseNewRemoteSocialTheme, configureTheme, appConfig]);

	return <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>;
};
