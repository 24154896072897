import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PlayerStatus } from '@contracts/platform';

const gifs = {
	loser: [
		'https://media.giphy.com/media/LRxdnngiG8PPW/giphy.gif',
		'https://media.giphy.com/media/klx7v6W8AssZCJxO9v/giphy.gif',
		'https://media.giphy.com/media/WS3FLDbYaPQGY/giphy.gif',
		'https://media.giphy.com/media/VJBS3mdO8vB04wyjgL/giphy.gif',
		'https://media.giphy.com/media/EU5ox7Mve7FS0/giphy.gif',
		'https://media.giphy.com/media/10h8CdMQUWoZ8Y/giphy.gif',
		'https://media.giphy.com/media/cr9vIO7NsP5cY/giphy.gif',
		'https://media.giphy.com/media/H7kDjHfuqukEZoWhut/giphy.gif',
		'https://media.giphy.com/media/2KhpwqOReXDLq/giphy.gif',
		'https://media.giphy.com/media/cmyQPn3LABZmqP8q8m/giphy.gif',
		'https://media.giphy.com/media/spfi6nabVuq5y/giphy.gif',
		'https://media.giphy.com/media/3cVrr8HGrMGVoAGXdd/giphy.gif',
		'https://media.giphy.com/media/9Vb9gWFgb9a4zUXDSW/giphy.gif',
		'https://media.giphy.com/media/mcH0upG1TeEak/giphy.gif',
		'https://media.giphy.com/media/hvYQenYyHYbRCc2JOG/giphy.gif',
		'https://media.giphy.com/media/ZD1wdrHDYKEYdylH8q/giphy.gif',
	],
	winner: [
		'https://media.giphy.com/media/LFiOdYoOlEKac/giphy.gif',
		'https://media.giphy.com/media/xNBcChLQt7s9a/giphy.gif',
		'https://media.giphy.com/media/eoxomXXVL2S0E/giphy.gif',
		'https://media.giphy.com/media/26FfiUnvdLKFCgxQA/giphy.gif',
		'https://media.giphy.com/media/XCsKZAmb26jD2WQZdl/giphy.gif',
		'https://media.giphy.com/media/3BlN2bxcw0l5S/giphy.gif',
		'https://media.giphy.com/media/JOREdnYQ4DM52/giphy.gif',
		'https://media.giphy.com/media/4QFAH0qZ0LQnIwVYKT/giphy.gif',
	],
};

const useStyles = makeStyles(() => ({
	image: {
		display: 'block',
		maxWidth: '100%',
		height: 'auto',
		margin: 'auto',
	},
}));

export default React.memo(function ({ status, className }) {
	const classes = useStyles();
	const images = status === PlayerStatus.winner ? gifs.winner : gifs.loser;
	const src = images[Math.floor(Math.random() * images.length)];

	return (
		<img
			className={clsx(classes.image, className)}
			src={src}
			role="presentation"
			alt=""
		/>
	);
});
