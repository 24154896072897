import { LocationDescriptor } from 'history';
import { isTruthy } from '../utils/isTruthy';

export function fullPathFromLocation(location: LocationDescriptor): string {
	if (typeof location === 'string') {
		return location;
	}
	return [location.pathname, location.search, location.hash]
		.filter(isTruthy)
		.join('');
}
