import React from 'react';
import { timestampToDate, formatDate } from '../utils/date';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	time: {
		// font size depends on what we wrap this dude with
		fontSize: `1em`,
		color: grey[700],
	},
}));

export const Time = ({ timestamp, className }) => {
	const classes = useStyles();
	const date = timestampToDate(timestamp);
	return (
		<time
			className={clsx(classes.time, className)}
			title={date.toLocaleString()}
			dateTime={date.toISOString()}
		>
			{formatDate(date)}
		</time>
	);
};
