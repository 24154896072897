import React, { useCallback } from 'react';
import { useLocalStorage } from '../hooks/useSessionStorage';

const Context = React.createContext(null);

export const AudioControlProvider = ({
	useStorage = useLocalStorage,
	defaultMuted = false,
	children,
}) => {
	const [isMuted, setMuted] = useStorage('audio', defaultMuted);

	const toggleMute = useCallback(() => {
		setMuted((state) => !state);
	}, [setMuted]);

	return (
		<Context.Provider value={{ isMuted, toggleMute }}>
			{children}
		</Context.Provider>
	);
};

export default Context;
