import { useLocation } from 'react-router';

type QueryParams = {
	accountId?: string;
	continuePath?: string;
	inviteId?: string;
	gameId?: string;
};

export function useQueryParams(): QueryParams {
	const search = new URLSearchParams(useLocation().search);
	return {
		accountId: search.get('accountId') ?? undefined,
		continuePath: search.get('continuePath') ?? undefined,
		inviteId: search.get('inviteId') ?? undefined,
		gameId: search.get('gameId') ?? undefined,
	};
}

/** @deprecated use useQueryParams */
export function useQuery() {
	return new URLSearchParams(useLocation().search);
}
