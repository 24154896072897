import React from 'react';
import { TextField, TextFieldProps, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { copyToClipboard } from '@common/utils/copyToClipboard';
import { LoadingButton, registerError } from '@common';

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		display: 'flex',
	},
	dense: {
		alignItems: 'center',
		marginTop: 8,
		marginBottom: 4,
	},
	root: {
		flex: 1,
		borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
	},
	notchedOutline: {
		borderRight: 0,
	},
	button: {
		fontSize: 12,
		lineHeight: 1,
		borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
		whiteSpace: 'nowrap',

		'&:hover': {
			color: theme.palette.primary.contrastText,
		},
	},
	denseButton: {
		height: 40,
	},
}));

type Props = {
	text?: string;
	className?: string;
} & TextFieldProps;

export default function CopyText(props: Props) {
	const { text, className = '', ...rest } = props;
	const classes = useStyles();
	const timeout = React.useRef<NodeJS.Timeout>();
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [isTooltipVisible, setTooltipVisibility] = React.useState(false);

	const handleCopyLink = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		if (!text) {
			return;
		}
		e.preventDefault();

		try {
			await copyToClipboard(text);
			setTooltipVisibility(true);

			if (timeout.current) {
				clearTimeout(timeout.current);
			}

			timeout.current = setTimeout(() => {
				setTooltipVisibility(false);
			}, 2000);
		} catch (error) {
			inputRef.current?.focus();
			inputRef.current?.select();
			registerError(error);
		}
	};

	React.useEffect(() => {
		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, []);

	return (
		<div
			className={clsx(classes.wrapper, className, {
				[classes.dense]: rest.margin === 'dense',
			})}
		>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				placement="top"
				open={isTooltipVisible}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title="Copied!"
				arrow
			>
				<TextField
					inputRef={inputRef}
					onFocus={() => inputRef.current?.select()}
					InputProps={{
						classes: {
							root: classes.root,
							notchedOutline: classes.notchedOutline,
						},
						readOnly: true,
					}}
					value={text}
					fullWidth
					variant={'outlined'}
					{...(rest as any)}
				/>
			</Tooltip>
			<LoadingButton
				className={clsx(classes.button, {
					[classes.dense]: rest.margin === 'dense',
					[classes.denseButton]: rest.margin === 'dense',
				})}
				color="primary"
				variant="contained"
				onClick={handleCopyLink}
				loading={!text}
				disableElevation
			>
				Copy Link
			</LoadingButton>
		</div>
	);
}
