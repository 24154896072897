import React, { useState } from 'react';
import { GameProvider, AudioControlProvider } from '../contexts';

const useDisabledStorage = (_key, initial) => useState(initial);

const GameProviders = ({ alwaysStartMuted, children }) => {
	return (
		<GameProvider>
			<AudioControlProvider
				useStorage={alwaysStartMuted ? useDisabledStorage : undefined}
				defaultMuted={alwaysStartMuted}
			>
				{children}
			</AudioControlProvider>
		</GameProvider>
	);
};

export default GameProviders;
