import React from 'react';
import { Box, IconButton, makeStyles, Radio } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		border: '1px solid #D9D9D9',
		borderRadius: 5,
	},
	row: {
		padding: '0.5rem',
		borderBottom: '1px solid #eee',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: 'rgba(64, 123, 255, 0.13)',
		},
	},
	selected: {
		backgroundColor: 'rgba(64, 123, 255, 0.13)',
	},
}));

const DataList = ({
	data,
	renderRow,
	className,
	defaultSelection,
	selectionMode = 'multiple',
	value,
	onChange,
}) => {
	const classes = useStyles();

	const onUpdate = (id) => {
		let newValue = [...(value || [])];

		if (newValue.includes(id)) {
			newValue.splice(value.indexOf(id), 1);
		} else {
			switch (selectionMode) {
				case 'single':
					newValue = [id];
					break;
				case 'multiple':
				default:
					newValue.push(id);
					break;
			}
		}

		onChange(newValue);
	};

	const _clearValue = () => {
		onChange([]);
	};

	return (
		<div className={`${className} ${classes.wrapper}`}>
			{/* If we want to show a default selection row, we can pass in a component optionally */}
			{defaultSelection && (
				<Box
					display="flex"
					alignItems="center"
					className={classes.row}
					onClick={_clearValue}
				>
					{defaultSelection()}

					<div style={{ marginLeft: 'auto' }}>
						{selectionMode === 'single' ? (
							<Radio
								checked={!value?.length}
								onChange={(event) =>
									event.target.value === ''
										? _clearValue()
										: null
								}
							/>
						) : (
							<IconButton
								onClick={value?.length ? _clearValue : null}
							>
								{value?.length ? <CloseIcon /> : <CheckIcon />}
							</IconButton>
						)}
					</div>
				</Box>
			)}

			{/* The component is agnostic to the data passed and uses renderProp pattern to populate the rows. */}
			{(data || []).map((datum) => (
				<Box
					key={datum.id}
					display="flex"
					alignItems="center"
					className={`${classes.row} ${
						value?.includes(datum.id) ? classes.selected : null
					}`}
					onClick={() => onUpdate(datum.id)}
				>
					{renderRow(datum)}

					<div style={{ marginLeft: 'auto' }}>
						{selectionMode === 'single' ? (
							<Radio checked={value?.includes(datum.id)} />
						) : (
							<IconButton>
								{value?.includes(datum.id) ? (
									<CheckIcon />
								) : (
									<AddIcon />
								)}
							</IconButton>
						)}
					</div>
				</Box>
			))}
		</div>
	);
};

export default DataList;
