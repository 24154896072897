import React from 'react';
import {
	Avatar,
	createStyles,
	IconButton,
	makeStyles,
	SvgIcon,
	Typography,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import { AccountResponse } from '@contracts/platform/accounts';
import { ReactComponent as ProGoldBadge } from '../assets/images/pro-gold.svg';
import { useConfigFlag } from '../hooks';

const useStyles = makeStyles(() =>
	createStyles({
		accountAvatar: { position: 'relative' },
		avatarImg: {
			display: 'flex',
			alignItems: 'center',
			fontWeight: 'normal',
		},
		avatarText: {
			fontWeight: 'normal',
		},
		proBadge: {
			position: 'absolute',
			top: '-2px',
			right: '-6px',
			width: '18px',
			height: '18px',
		},
	}),
);

type Props = {
	account: Pick<AccountResponse, 'name' | 'photoURL' | 'tier'>;
	size?: string;
	click?: () => void;
};

const nameAsInitials = (accountName: string) => {
	return accountName
		.split(' ')
		.slice(0, 2)
		.map((word) => word[0])
		.map((letter) => letter?.toLocaleUpperCase());
};

export const AccountAvatar: React.ComponentType<Props> = ({
	account,
	size,
	click,
}) => {
	const styles = useStyles();
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const showProBadge = billingEnabled && account.tier === 'pro';

	const avatar = (
		<Avatar
			variant="circular"
			src={account?.photoURL || undefined}
			className={styles.avatarImg}
			style={{ ...(size && { width: size, height: size }) }}
			aria-label={account.name}
		>
			{!account.photoURL && (
				<Typography variant="h5" className={styles.avatarText}>
					{account.name ? (
						nameAsInitials(account.name)
					) : (
						<PeopleIcon />
					)}
				</Typography>
			)}
		</Avatar>
	);

	const button = (
		<IconButton
			aria-label={'team avatar'}
			aria-controls="menu-account"
			aria-haspopup="true"
			onClick={click}
			color="inherit"
		>
			{avatar}
		</IconButton>
	);

	return (
		<div className={styles.accountAvatar}>
			{click ? button : avatar}
			{showProBadge && (
				<SvgIcon className={styles.proBadge} component={ProGoldBadge} />
			)}
		</div>
	);
};
