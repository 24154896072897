export const getRoundId = (gameId) => (state) =>
	Object.keys(state.firebase.data?.games?.[gameId]?.roundData || {})?.[0];

export const getBingoCard = (gameId, userId) => (state) => {
	const id = getRoundId(gameId)(state);

	return (
		state.firebase.data?.games?.[gameId]?.roundCards?.[userId]?.[id] || []
	);
};

export const getPlayersData = (gameId) => (state) => {
	const id = getRoundId(gameId)(state);
	const playersData = state.firebase.data?.games?.[gameId]?.playersData || {};
	const playerStatuses =
		state.firebase.data?.games?.[gameId]?.roundData?.[id]?.status || {};

	return Object.keys(playersData).reduce(
		(result, id) => ({
			...result,
			[id]: {
				...playersData[id],
				status: playerStatuses[id],
			},
		}),
		{},
	);
};

export const getDrawnNumbers = (gameId) => (state) => {
	const id = getRoundId(gameId)(state);
	const roundData = state.firebase.data?.games?.[gameId]?.roundData;
	const drawnNumbers = Object.values(roundData?.[id]?.drawnNumbers || {});

	if (!drawnNumbers.length) {
		return [];
	}

	return drawnNumbers;
};

export const getLastDrawnNumber = (gameId) => (state) => {
	const drawnNumbers = getDrawnNumbers(gameId)(state);

	if (!drawnNumbers.length) {
		return null;
	}

	return drawnNumbers[drawnNumbers.length - 1];
};

export const getRoundConfig = (gameId) => (state) => {
	const id = getRoundId(gameId)(state);

	return state.firebase.data?.games?.[gameId]?.roundData?.[id]?.config;
};

export const getRoundPlayers = (gameId) => (state) => {
	const id = getRoundId(gameId)(state);

	return state.firebase.data?.games?.[gameId]?.roundData?.[id]?.players || [];
};
