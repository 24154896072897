import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { copyToClipboard } from '../utils/copyToClipboard';
import { useSnackBarMessage } from '../hooks/useSnackBarMessage';
import { ErrorType, userFriendlyErrorMessage } from '../errors';

type Props = {
	error?: ErrorType | string;
	extraErrorInfo?: string;
} & Omit<TypographyProps<'p'>, 'children'>;

export const ErrorMessage: React.ComponentType<Props> = ({
	className,
	error,
	extraErrorInfo,
	children,
	...rest
}) => {
	const { showMessage, snackbar } = useSnackBarMessage();

	const copyToClipboardOnShiftClick: React.MouseEventHandler<HTMLDivElement> =
		React.useCallback(
			(ev) => {
				if (!error || !ev.shiftKey) {
					return;
				}
				const text =
					error instanceof Error
						? error.stack || String(error)
						: String(error);

				const full = [
					process.env['REACT_APP_VERSION'],
					text,
					extraErrorInfo,
				]
					.filter(Boolean)
					.join('\n\n');

				copyToClipboard(full).then(
					() => showMessage('Copied to clipboard'),
					(err) => console.error(err),
				);
			},
			[error, showMessage, extraErrorInfo],
		);

	if (!error && !children) {
		return null;
	}

	const errorMessage =
		typeof error === 'string'
			? error
			: (error && userFriendlyErrorMessage(error)) ||
			  `We are very sorry, an unexpected error has occurred`;

	return (
		<Typography
			component="p"
			variant="subtitle1"
			color="error"
			className={className}
			{...rest}
		>
			{children ? (
				children
			) : (
				<span onClick={copyToClipboardOnShiftClick}>
					{errorMessage}
				</span>
			)}
			{snackbar}
		</Typography>
	);
};
