export function ensureExists<T>(
	value: T | null | undefined,
	message?: string,
): NonNullable<T> {
	if (value == null) {
		throw new TypeError(
			message ?? `value should be defined, but got "${String(value)}"`,
		);
	}
	return value as NonNullable<T>;
}

export function ensure(
	condition: unknown,
	message?: string,
): asserts condition {
	if (!condition) {
		throw new TypeError(
			message ??
				`Condition should be met, but got "${String(condition)}"`,
		);
	}
}
