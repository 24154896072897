import React, { useState } from 'react';
import { makeStyles, Input } from '@material-ui/core';
import clsx from 'clsx';
import { useMountedRef } from '../hooks';
import Button from './Button';
import PencilIcon from './PencilIcon';

const useEditIconStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
		margin: theme.spacing(0, 0, 0, 0.5),
		minWidth: 'auto',
		width: 'auto',
	},
	svg: {
		width: 16,
		height: 16,
	},
}));

export const EditIconButton = ({ onClick }) => {
	const classes = useEditIconStyles();
	return (
		<Button
			fullWidth={false}
			className={classes.button}
			onClick={onClick}
			aria-label="Edit	"
		>
			<PencilIcon className={classes.svg} />
		</Button>
	);
};

const useEditableFieldStyles = makeStyles((theme) => ({
	editableFieldValue: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'nowrap',
	},
	editableFieldInput: {
		width: '100%',
	},
}));

export const EditableField = ({
	children,
	inputClassName,
	label,
	value,
	onChange,
	onSubmit,
}) => {
	const [editing, setEditing] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const classes = useEditableFieldStyles();
	const mounted = useMountedRef();

	if (editing) {
		return (
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					try {
						setSubmitting(true);
						if (await onSubmit()) {
							mounted.current && setEditing(false);
						}
					} finally {
						mounted.current && setSubmitting(false);
					}
				}}
			>
				<Input
					className={clsx(classes.editableFieldInput, inputClassName)}
					inputProps={{ 'aria-label': label }}
					autoFocus
					disabled={submitting}
					value={value}
					onChange={onChange}
					onBlur={() => !submitting && setEditing(false)}
				/>
			</form>
		);
	}

	return typeof children === 'function' ? (
		children({
			startEditing() {
				setEditing(true);
			},
		})
	) : (
		<div className={classes.editableFieldValue}>
			{children}
			<EditIconButton onClick={(e) => setEditing(true)} />
		</div>
	);
};
