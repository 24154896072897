import React from 'react';
import { Box } from '@material-ui/core';
import uploadBtnBg from '../../assets/images/add-image.png';

export default class PlaceHolder extends React.Component {
	render() {
		const { children, ...rest } = this.props;

		return (
			<Box
				{...rest}
				style={{
					height: '4.5rem',
					width: '4.5rem',
					background: '#C4C4C4 center center no-repeat',
					borderRadius: '50%',
					backgroundImage: `url(${uploadBtnBg})`,
					cursor: 'pointer',
					overflow: 'hidden',
					display: 'inline-flex',
				}}
			>
				<Box
					style={{
						height: '100%',
						width: '100%',
					}}
				>
					{children}
				</Box>
			</Box>
		);
	}
}
