import type firebase from 'firebase';
import { isDevBuild } from '../environment';

type Firebase = typeof firebase;

// These values can be changed through Firebase Console
// https://console.firebase.google.com/project/remotesocial-io/config
export const remoteConfigDefaults = {
	/**
	 * Replace standard Firestore host with our custom load balancer
	 * to see if it addresses issues for our corporate customers
	 */
	proxyFirestoreThroughLoadBalancer: true,
	/**
	 * Disables parallax background in Trivia
	 */
	disableParallaxBackground: false,
	/**
	 * Space separated list of users: user ids, or email domains that are allowed
	 * to login to the system on STAGING, when empty - anyone is allowed to login
	 */
	loginWhitelist: 'remotesocial.io',
	/**
	 * Toggles billing features
	 */
	billingEnabled: false,

	/**
	 * Toggles member profile features
	 */
	memberProfilesEnabled: false,

	/**
	 * If environment is using the latest Ziago API's
	 * */
	ziagoLatestApi: false,
};

export type FlagKey = keyof typeof remoteConfigDefaults;

/**
 * List of flags, changes to which require a page refresh to take effect;
 */
export const pageRefreshFlagKeys: Array<FlagKey> = [
	'proxyFirestoreThroughLoadBalancer',
	'loginWhitelist',
];

const remoteConfigOverrides: Partial<
	Record<FlagKey, boolean | string | number>
> = {
	billingEnabled: true,
	memberProfilesEnabled: true,
	ziagoLatestApi: true,
};

/**
 * Get remote config value, these are not meant to be feature flags;
 *
 * Primary use case for config values is to be able to change behaviour of the app without
 * having to redeploy;
 *
 * Primary use case for feature flags is to run experiments - they are temporary in their nature;
 *
 * This function could be used as feature flags value source temporarily while we implement them properly through Amplitude
 * and have a proper 'experiment' framework with per-experiment tagging for every data-analytics event.
 *
 * `@common/hooks/useConfigFlag` hook can be used safely in components to retrieve values
 */
export function getConfigValue(
	firebase: Firebase,
	key: FlagKey,
): firebase.remoteConfig.Value {
	if (isDevBuild()) {
		if (key in remoteConfigOverrides) {
			return {
				asBoolean: () => Boolean(remoteConfigOverrides[key]),
				asNumber: () => Number(remoteConfigOverrides[key]),
				asString: () => String(remoteConfigOverrides[key]),
				getSource: () => 'static' as const,
			};
		}
	}
	const value = firebase.remoteConfig().getValue(key);
	return value;
}
