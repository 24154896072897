import { Joi, objectSchemaOf } from '../shared';

export type ActivityParams = {
	/**
	 * Specific to onboarding activity is a list of hirees
	 */
	onboardingHires?: string[];
};

export const activityParamsSchema = objectSchemaOf<ActivityParams>({
	onboardingHires: Joi.array().items(Joi.string()).min(1).optional(),
});
