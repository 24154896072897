import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function CrownIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M2.15 19.151v1.789a.403.403 0 00.536.375 29.985 29.985 0 019.095-1.268c3.751 0 7.128.548 9.544 1.43a.402.402 0 00.536-.376v-1.95c-2.539-.957-6.119-1.501-9.855-1.501-3.737 0-7.313.544-9.856 1.501zm14.204-8.562l-4.127-8.655-.221-.477-.225.477-4.529 9.507L0 5.1l1.992 12.381c2.701-.942 6.224-1.452 10.006-1.452s7.301.51 10.002 1.452L24 5.1l-7.237 6.341-.409-.852z" />
		</SvgIcon>
	);
}
