import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { DataListField } from '../../FormFields';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	userAvatar: {
		height: 34,
		width: 34,
		borderRadius: 5,
		marginRight: 16,
	},
	userName: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	selector: {
		height: 220,
		overflowY: 'auto',
		overflowX: 'hidden',
		scrollbarWidth: 'thin',
	},
	searchBox: {
		width: '100%',
		marginTop: 5,
		marginBottom: 8,
	},
}));

const UserSelectionSchema = Yup.object().shape({
	players: Yup.array().min(0).of(Yup.string()).required(),
});

/**
 * @param {{
 *   users: {
 * 	   email?: string;
 * 	   displayName?: string;
 *     photoURL?: string | null;
 *   }[];
 * }} params
 */
const UserSelection = ({ users }) => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');

	const filteredUsers = useMemo(() => {
		const emailUsers = users.filter((user) => user && user.email);
		if (!searchTerm) {
			return emailUsers;
		}

		const sanitizedSearchTerm = searchTerm.toLowerCase();

		return emailUsers.filter(
			(user) =>
				user.displayName?.toLowerCase().includes(sanitizedSearchTerm) ||
				user.email.toLowerCase().includes(sanitizedSearchTerm),
		);
	}, [users, searchTerm]);

	const _renderRow = (user) => {
		return (
			<>
				<img
					src={user?.photoURL || undefined}
					className={classes.userAvatar}
					alt=""
				/>
				<span className={classes.userName}>{user.displayName}</span>
			</>
		);
	};

	return (
		<div>
			<TextField
				size="small"
				className={classes.searchBox}
				variant="outlined"
				placeholder="Search name or email"
				value={searchTerm}
				onChange={(event) => setSearchTerm(event.target.value)}
			/>

			<DataListField
				name="players"
				data={filteredUsers}
				renderRow={_renderRow}
				className={classes.selector}
			/>
		</div>
	);
};

export { UserSelectionSchema };

export default UserSelection;
