import React from 'react';
import {
	createStyles,
	ListItemIcon,
	makeStyles,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { UserAvatar } from '../../userAvatar';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ContinueState, routes } from '../../../routes';
import { Link } from '../../../components/link';
import { LocationDescriptorObject } from 'history';
import { appName, env } from '../../../environment';
import { buildCrossDomainUrl } from '../../../routes/useCrossDomainUrl';
import { useCurrentAccount } from '../../../contexts';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { hubRoutes } from '../../../routes/hubRoutes';

const useStyles = makeStyles((theme) =>
	createStyles({
		info: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(2),
			paddingBottom: theme.spacing(1),
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			gap: '6px',
		},
		primaryText: {
			maxWidth: '20ch',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			fontSize: '20px',
			lineHeight: 1,
		},
		secondaryText: {
			maxWidth: '34ch',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			color: theme.palette.text.secondary,
		},
		menuItem: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			height: theme.spacing(7),
			color: 'inherit',
		},
		menuItemIcon: {
			justifyContent: 'center',
			width: '40px',
			marginRight: theme.spacing(1),
		},
		menuItemContent: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			gap: theme.spacing(0.5),
			height: '100%',
			width: '100%',
		},
		newWindowIcon: {
			width: theme.spacing(2),
			height: theme.spacing(2),
			margin: `0 ${theme.spacing(1)}`,
			padding: 0,
			borderRadius: '50%',
			fontSize: '1rem',
			color: theme.palette.grey[500],
		},
	}),
);

type Props = {
	handleClose: () => void;
	currentUser: { displayName?: string; email?: string };
};

type State = {
	redirectRequired: boolean;
	goToLink: (route: LocationDescriptorObject<ContinueState>) => void;
};

const useState = ({ handleClose }: Pick<Props, 'handleClose'>) => {
	const history = useHistory();
	const { currentAccountId } = useCurrentAccount();

	const redirectRequired = appName() !== 'hub';

	const goToLink = React.useCallback(
		(route) => {
			handleClose();

			if (!redirectRequired) {
				history.push(route);
			} else {
				const hubUserLoginSettingsUrl = buildCrossDomainUrl({
					boundToOrigin: env().hubUrl,
					location: route,
					accountId: currentAccountId,
				});
				window.open(
					hubUserLoginSettingsUrl.toString(),
					'_blank',
					'noopener noreferrer',
				);
			}
		},
		[handleClose, currentAccountId, redirectRequired, history],
	);

	const result = React.useMemo<State>(() => {
		return {
			redirectRequired,
			goToLink,
		};
	}, [redirectRequired, goToLink]);

	return result;
};

export const UserLinks: React.ComponentType<Props> = ({
	currentUser,
	handleClose,
}) => {
	const styles = useStyles();
	const { goToLink, redirectRequired } = useState({ handleClose });
	const location = useLocation();

	return (
		<>
			<div className={styles.info}>
				<ListItemIcon className={styles.menuItemIcon}>
					<UserAvatar
						withName={false}
						orientation="horizontal"
						user={currentUser}
						size="42px"
					/>
				</ListItemIcon>
				<div className={styles.content}>
					<Typography variant="h4" className={styles.primaryText}>
						{currentUser.displayName}
					</Typography>
					<Typography
						variant="caption"
						className={styles.secondaryText}
					>
						{currentUser.email}
					</Typography>
				</div>
			</div>
			<MenuItem
				onClick={() =>
					goToLink(hubRoutes.user.loginSettings({ location }))
				}
				className={styles.menuItem}
				key="profile"
			>
				<ListItemIcon className={styles.menuItemIcon}>
					<LockIcon fontSize="small" />
				</ListItemIcon>
				<Typography variant="body1" className={styles.menuItemContent}>
					Login Settings
				</Typography>
				{redirectRequired && (
					<OpenInNewIcon
						fontSize="small"
						color="secondary"
						className={styles.newWindowIcon}
					/>
				)}
			</MenuItem>
			<MenuItem
				onClick={handleClose}
				className={styles.menuItem}
				key="logout"
			>
				<Link
					className={styles.menuItem}
					to={routes.logout({
						location,
					})}
					underline="none"
				>
					<ListItemIcon className={styles.menuItemIcon}>
						<ExitToAppIcon fontSize="small" />
					</ListItemIcon>
					<Typography
						variant="body1"
						className={styles.menuItemContent}
					>
						Logout
					</Typography>
				</Link>
			</MenuItem>
		</>
	);
};
