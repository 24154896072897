import React, { useContext, useEffect, useState } from 'react';
import {
	CopyText,
	useCurrentUser,
	useGame,
	GamePlayers,
	getGameHost,
	getGameState,
	AnimatedBackgroundContext,
	DocumentHead,
	useCurrentAccount,
} from '@remote-social/common';
import { useParams, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useFirebaseConnect, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Layout from '../layout/Layout';
import Button from '../../components/Button';
import { getPlayersData } from '../../store/selectors';
import { envHost } from '@remote-social/common/src/utils/envLink';
import { GameState } from '@contracts/platform';
import { registerError } from '@remote-social/common/src/errors';

const useStyles = makeStyles((theme) => ({
	copyText: {
		marginBottom: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(4),
	},
	switcher: {
		marginTop: theme.spacing(4),
		marginLeft: theme.spacing(4),
	},
}));

export default function Create() {
	const { account, game } = useParams();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();
	const classes = useStyles();
	const [isStartingRound, setIsStartingRound] = useState(false);
	const [gameConfig, setGameConfig] = useState({
		roundSpeed: 6000,
		winCondition: 'random',
	});
	const user = useCurrentUser();
	const firebase = useFirebase();
	const gameId = account && game ? `${account}-${game}` : null;
	const host = useSelector(getGameHost(gameId));
	const playersData = useSelector(getPlayersData(gameId));
	const gameState = useSelector(getGameState(gameId));
	const isGameInitialised = Boolean(host);
	const { resetBackgroundSpeed } = useContext(AnimatedBackgroundContext);
	const hasGameStarted = gameState === GameState.game;
	const isHost = host === user.uid;
	const hasPlayers = Boolean(
		Object.values(playersData).filter(({ connected }) => connected).length >
			0,
	);
	const gamePath = gameId && `/game/${account}/${game}`;

	useGame(gameId);

	useFirebaseConnect(
		gameId && [
			{
				path: `/games/${gameId}/playersData`,
			},
			{
				path: `/games/${gameId}/host`,
			},
			{
				path: `/games/${gameId}/state`,
			},
			{
				path: `/games/${gameId}/roundData`,
				queryParams: ['limitToLast=1'],
			},
		],
	);

	useEffect(() => {
		if (account && account !== currentAccountId) {
			setCurrentAccount(account);
		}
	}, [account, currentAccountId, setCurrentAccount]);

	useEffect(() => {
		resetBackgroundSpeed();
	}, [resetBackgroundSpeed]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setGameConfig((state) => ({
			...state,
			[name]: value,
		}));
	};

	const handleStartRound = async () => {
		const startGame = firebase.functions().httpsCallable('bingo-startGame');

		setIsStartingRound(true);

		try {
			await startGame({
				gameId,
				gameConfig,
			});
		} catch (e) {
			registerError(e);
			setIsStartingRound(false);
		}
	};

	if (!gameId) {
		return <Redirect to="/" />;
	}

	if (isGameInitialised && (!isHost || hasGameStarted)) {
		return <Redirect to={gamePath} />;
	}

	return (
		<Layout maxWidth="md">
			<DocumentHead title="Create Game" />
			<Grid container spacing={4}>
				<Grid item xs={12} sm={7}>
					<Typography variant="h2" align="center" gutterBottom>
						Let's get this party started!
					</Typography>
					<Typography
						variant="h5"
						align="center"
						color="primary"
						gutterBottom
					>
						Host Tools
					</Typography>
					<Typography variant="h5" align="center" gutterBottom>
						Share this link with your friends.
					</Typography>
					<CopyText
						className={classes.copyText}
						text={gamePath && `${envHost('bingo')}${gamePath}`}
					/>
					<Typography variant="h5" align="center" gutterBottom>
						Start a video call and share your screen.
					</Typography>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<TextField
								id="round-speed"
								name="roundSpeed"
								label="Round Speed"
								value={gameConfig.roundSpeed}
								onChange={handleChange}
								SelectProps={{
									native: true,
								}}
								variant="outlined"
								size="small"
								disabled={hasGameStarted}
								select
								fullWidth
							>
								<option value="9000">Slow</option>
								<option value="6000">Medium</option>
								<option value="3100">Fast</option>
								<option value="2200">Manic</option>
							</TextField>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								id="win-condition"
								name="winCondition"
								label="Win Condition"
								value={gameConfig.winCondition}
								onChange={handleChange}
								SelectProps={{
									native: true,
								}}
								variant="outlined"
								size="small"
								disabled={hasGameStarted}
								select
								fullWidth
							>
								<option value="random">Random</option>
								<option value="row">Row</option>
								<option value="column">Column</option>
								<option value="diagonal">Diagonal</option>
							</TextField>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					direction="column"
					justifyContent="space-between"
					xs={12}
					sm={5}
				>
					<GamePlayers players={playersData} />
					<Button
						className={classes.button}
						onClick={handleStartRound}
						loading={isStartingRound}
						disabled={!hasPlayers}
					>
						Start Bingo, everyone's here
					</Button>
				</Grid>
			</Grid>
		</Layout>
	);
}
