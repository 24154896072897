import { firstValueFrom } from 'rxjs';
import { defer, iif, Observable, throwError, timer } from 'rxjs';
import { concatMap, pairwise, retryWhen, startWith, tap } from 'rxjs/operators';
import { ensureExists } from './preconditions';

function getDelay(backoffDelay: number, maxInterval: number) {
	return Math.min(backoffDelay, maxInterval);
}

function exponentialBackoffDelay(iteration: number, initialInterval: number) {
	return Math.pow(2, iteration) * initialInterval;
}

export type RetryConfig = {
	initialInterval: number;
	maxRetries?: number;
	maxInterval?: number;
	resetOnSuccess?: boolean;
	shouldRetry?: (error: any, previousError: any) => boolean;
	backoffDelay?: (iteration: number, initialInterval: number) => number;
	logAttempt?: (info: {
		error: any;
		previousError: any;
		attempts: number;
		wouldRetry: boolean;
	}) => void;
};

export function retryWithBackoff(
	config: number | RetryConfig,
): <T>(source: Observable<T>) => Observable<T> {
	const {
		initialInterval,
		maxRetries = Infinity,
		maxInterval = Infinity,
		shouldRetry = () => true,
		resetOnSuccess = false,
		backoffDelay = exponentialBackoffDelay,
		logAttempt = undefined,
	} = typeof config === 'number' ? { initialInterval: config } : config;
	return <T>(source: Observable<T>) =>
		defer(() => {
			let index = 0;
			return source.pipe(
				retryWhen<T>((errors) =>
					errors.pipe(
						// combine an error with its previous value
						startWith(undefined),
						pairwise(),
						//
						concatMap(([previousError, err]) => {
							const attempts = ++index;
							const error = ensureExists(err);
							return iif(
								() => {
									const wouldRetry =
										attempts < maxRetries &&
										shouldRetry(error, previousError);
									try {
										logAttempt?.({
											error,
											previousError,
											attempts,
											wouldRetry,
										});
									} catch (err) {
										/* do not break logic because logging failed */
									}
									return wouldRetry;
								},
								timer(
									getDelay(
										backoffDelay(attempts, initialInterval),
										maxInterval,
									),
								),
								throwError(() => error),
							);
						}),
					),
				),
				tap(() => {
					if (resetOnSuccess) {
						index = 0;
					}
				}),
			);
		});
}

type Fn<T> = () => Promise<T>;

export const makeAsyncFunctionRetry = <T>(
	config: RetryConfig,
	fn: Fn<T>,
): Fn<T> => {
	return () => {
		return firstValueFrom(defer(() => fn()).pipe(retryWithBackoff(config)));
	};
};
