import React from 'react';
import { DocumentHead, ErrorMessage, LoadingButton } from '../../components';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { useAsyncFunction, useConfigFlag, useCurrentUser } from '../../hooks';
import { useCurrentAccount } from '../../contexts';
import { isPending, isSuccess } from '../../store-tools';
import { routes } from '../../routes';
import { useStyles } from './OnboardingStyle';
import { animated, useSpring } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import DropZoneField from '../../components/ImageDropField';
import { Formik } from 'formik';
import Placeholder from '../../components/ImageCrop/Placeholder';
import { continuePathFromLocation } from '../../routes/continuePathFromLocation';

function determineContinuePath({
	isCreateFirstAccountRoute,
	billingEnabled,
	location,
}) {
	if (billingEnabled) {
		if (isCreateFirstAccountRoute) {
			// upsell if we our continue path doesn't already lead to paywall
			const shouldUpsell = location.state?.shouldUpsell ?? true;
			return shouldUpsell
				? routes.subscribe({
						intent: 'upsell',
						preserveContinuePath: true,
						location,
				  })
				: continuePathFromLocation(location);
		} else {
			// unconditionally go to subscribe and then destination
			return routes.subscribe({
				intent: 'upsell',
				preserveContinuePath: true,
				location,
			});
		}
	} else {
		return continuePathFromLocation(location) || routes.home({ location });
	}
}

export default function Create({ isCreateFirstAccountRoute }) {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const user = useCurrentUser();
	const {
		createAccount,
		createFirstAccount,
		needsAccountCreated,
		loadingAccounts,
	} = useCurrentAccount();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const [accountName, setAccountName] = React.useState(
		`${user.displayName}'s Team`,
	);
	const [photoURL, setPhotoURL] = React.useState(null);

	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();

	const handleChange = React.useCallback(
		(event) => {
			setAccountName(event.target.value);
		},
		[setAccountName],
	);

	const [createRequest, create] = useAsyncFunction(async () => {
		if (isCreateFirstAccountRoute) {
			// would only be used if we are on /create-first-account
			// route
			await createFirstAccount({
				name: accountName,
				photoURL,
			});
		} else {
			await createAccount({
				name: accountName,
				photoURL,
			});
		}
	});

	const createdSuccessfully = isSuccess(createRequest);

	const continuePath = React.useMemo(
		() =>
			determineContinuePath({
				billingEnabled,
				location,
				isCreateFirstAccountRoute,
			}),
		[billingEnabled, location, isCreateFirstAccountRoute],
	);

	React.useEffect(() => {
		if (
			createdSuccessfully ||
			// this condition checks if we possibly already created the first
			// account in another tab which was previously left opened
			(isCreateFirstAccountRoute && needsAccountCreated === false)
		) {
			history.replace(continuePath);
		}
	}, [
		continuePath,
		createdSuccessfully,
		history,
		isCreateFirstAccountRoute,
		needsAccountCreated,
	]);

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Create Team" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="World Waving"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/worldWavingTest.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1}></Grid>
			<Grid item xs={12} md={5} className={classes.content}>
				<animated.div style={contentSpring}>
					<Grid direction="column" container spacing={2}>
						<Grid item xs={12} align="center">
							<Typography
								align="center"
								className={classes.createTitle}
							>
								Let's create your Remote Social Team
							</Typography>
							<Typography
								variant="subtitle2"
								align="center"
								className={classes.accountSubTitle}
							>
								Give your Team a name and avatar
							</Typography>
						</Grid>
						<Formik
							className={classes.createForm}
							initialValues={{
								image: '',
							}}
						>
							{() => (
								<>
									<Grid
										justifyContent="center"
										alignItems="center"
										item
										xs={12}
										container
										direction="column"
									>
										<DropZoneField
											name="image"
											placeholder={Placeholder}
											onImageUploaded={(e) =>
												setPhotoURL(e)
											}
											uploader={
												'platform-uploadCircleAvatar'
											}
										/>
									</Grid>
									<Grid
										container
										direction="column"
										spacing={2}
									>
										<Grid item xs={12} align="center">
											<TextField
												margin="normal"
												required
												inputProps={{
													min: 0,
													style: {
														textAlign: 'center',
													},
												}}
												fullWidth
												type="text"
												value={accountName}
												id="accountName"
												name="accountName"
												autoFocus
												onChange={handleChange}
											/>
											<ErrorMessage
												error={createRequest.error}
											/>
										</Grid>
									</Grid>
									<Typography
										variant="subtitle2"
										align="center"
										className={classes.accountNameSubTitle}
									>
										(Don't worry, you can change this later)
									</Typography>
									<Grid item xs={12}>
										<LoadingButton
											variant="contained"
											size="large"
											color="primary"
											align="center"
											fullWidth
											className={classes.doneBtn}
											onClick={create}
											loading={
												isPending(createRequest) ||
												loadingAccounts
											}
										>
											Create
										</LoadingButton>
									</Grid>
								</>
							)}
						</Formik>
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
