import React, { useCallback, useMemo } from 'react';
import {
	Box,
	makeStyles,
	TextField,
	Grid,
	Chip as DefaultChip,
	TextFieldProps,
	ChipProps,
	GridProps,
} from '@material-ui/core';
import { escapeRegExp } from '../utils';

const useStyle = makeStyles((theme) => ({
	wrapper: {
		paddingTop: theme.spacing(1),
	},
}));

const DEFAULT_DELIMITERS = [' ', ',', '\n'];

export type ChipTextFieldChangeEventHandler = (
	opts:
		| {
				operation: 'change';
				delimiterRegExp: RegExp;
				// user pressed Enter key or used one of delimiters
				isSubmit: boolean;
				// current uncontrolled value
				currentValue: string;
				// the rest are just defaults flowing from currentValue
				newChips?: string[];
				inputValue: string;
				nextInputValue: string;
				chipsValue: string[];
				nextChipsValue: string[];
		  }
		| {
				operation: 'delete';
				deletedChip: string;
				prevChipsValue: string[];
				nextChipsValue: string[];
		  },
) => void;

type Props = Omit<TextFieldProps, 'value' | 'onChange'> & {
	Chip: React.ComponentType<Omit<ChipProps, 'onDelete' | 'label'>>;
	ChipsContainer: React.ComponentType<GridProps>;
	delimiters?: string[];
	chipsValue: string[];
	inputValue: string;
	onChange: ChipTextFieldChangeEventHandler;
};

const usePreventSubmit = (
	propHandler?: React.KeyboardEventHandler<HTMLInputElement>,
): React.KeyboardEventHandler<HTMLInputElement> =>
	useCallback(
		(e) => {
			propHandler && propHandler(e);

			const element = e.target as HTMLInputElement;
			if (!element || !element.value) {
				// currentTarget is null
				return;
			}

			if (
				e.key === 'Enter' &&
				element.value &&
				element.value.length > 0
			) {
				// if the text field is not empty - means user is still in the process of typing
				e.preventDefault();
			}
		},
		[propHandler],
	);

const ChipTextFieldComponent: React.ComponentType<Props> = ({
	delimiters: delimitersProp = DEFAULT_DELIMITERS,
	chipsValue = [],
	inputValue = '',
	onChange,
	onPaste: onPasteProp,
	onKeyUp: onKeyUpProp,
	onKeyDown: onKeyDownProp,
	onKeyPress: onKeyPressProp,
	Chip = DefaultChip,
	ChipsContainer = Grid,
	...rest
}) => {
	const classes = useStyle();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delimiters = useMemo(() => delimitersProp, [delimitersProp.join('')]);

	const delimiterRegExp = useMemo(
		() =>
			new RegExp(
				`(${delimiters
					.map((delimiter) => escapeRegExp(delimiter))
					.join('|')})+`,
				'g',
			),
		[delimiters],
	);

	const processTextToChips = useCallback(
		(currentValue: string, isSubmit: boolean = false) => {
			if (!onChange) {
				return;
			}
			// here we might have made a little far-fetching assumption
			// that we never would require duplicate chips (which is probably 99% of the cases)
			// also we never want empty string as our chips and we do not want chips padded by
			// white space
			const newChipsCandidate = isSubmit
				? currentValue
						.split(delimiterRegExp)
						.map((text) => text.trim())
						.filter(Boolean)
						.filter((chip) => !delimiters.includes(chip))
				: [];
			const newChips = [...new Set(newChipsCandidate)].filter(
				(chip) => !chipsValue.includes(chip),
			);

			const nextChipsValue =
				newChips.length > 0 ? [...newChips, ...chipsValue] : chipsValue;

			const nextInputValue =
				nextChipsValue === chipsValue ? currentValue : '';

			onChange({
				operation: 'change',
				currentValue,
				chipsValue,
				nextChipsValue,
				inputValue,
				nextInputValue,
				delimiterRegExp,
				isSubmit,
				...(newChips.length > 0 && {
					newChips,
				}),
			});
		},
		[delimiters, delimiterRegExp, chipsValue, onChange, inputValue],
	);

	const onPaste: React.ClipboardEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			onPasteProp && onPasteProp(e);

			/*
			 * prevent default to prevent onChange from kicking in
			 * and filling up the TextField with pasted text which we want to clear later
			 * */
			e.preventDefault && e.preventDefault();

			const text = e.clipboardData.getData('text/plain');

			processTextToChips(text, true);
		},
		[processTextToChips, onPasteProp],
	);

	const onKeyDown = usePreventSubmit(onKeyDownProp);
	const onKeyPress = usePreventSubmit(onKeyDownProp);

	const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			onKeyUpProp && onKeyUpProp(e);

			if (!DEFAULT_DELIMITERS.includes(e.key) && e.key !== 'Enter') {
				return;
			}

			e.preventDefault && e.preventDefault();

			const element = e.target as HTMLInputElement;
			if (!element || !element.value) {
				// currentTarget is null
				return;
			}

			processTextToChips(
				element.value,
				e.key === 'Enter' ||
					delimiters.some((delimiter) => e.key.includes(delimiter)),
			);
		},
		[onKeyUpProp, processTextToChips, delimiters],
	);

	const onDelete = useCallback(
		(index) => {
			if (!onChange) {
				return;
			}
			const nextChipsValue = [...chipsValue];
			const deleted = nextChipsValue.splice(index, 1);
			const deletedChip = deleted[0];
			deletedChip &&
				onChange({
					operation: 'delete',
					deletedChip,
					prevChipsValue: chipsValue,
					nextChipsValue,
				});
		},
		[chipsValue, onChange],
	);

	const onInput: React.ChangeEventHandler<HTMLInputElement> =
		React.useCallback(
			(e) => {
				processTextToChips(e.target.value);
			},
			[processTextToChips],
		);

	const renderChips = useMemo(() => {
		// chips component can change from props
		const Component = Chip;
		return chipsValue.map((chip, index) => (
			<Grid item key={chip}>
				<Component label={chip} onDelete={() => onDelete(index)} />
			</Grid>
		));
	}, [chipsValue, onDelete, Chip]);

	return (
		<Box>
			<TextField
				{...rest}
				type="text"
				value={inputValue}
				onChange={onInput}
				onPaste={onPaste}
				onKeyUp={onKeyUp}
				onKeyDown={onKeyDown}
				onKeyPress={onKeyPress}
			/>

			<ChipsContainer
				container
				spacing={1}
				alignContent="flex-start"
				className={classes.wrapper}
			>
				{renderChips}
			</ChipsContainer>
		</Box>
	);
};
ChipTextFieldComponent.displayName = 'ChipTextField';
ChipTextFieldComponent.defaultProps = {
	delimiters: DEFAULT_DELIMITERS,
};

export const ChipTextField =
	ChipTextFieldComponent as React.ComponentType<Props> & {
		defaultProps: {
			delimiters: string[];
		};
	};
