import React from 'react';
import {
	Avatar,
	makeStyles,
	Typography,
	createStyles,
} from '@material-ui/core';
import type { SlackUser } from '@contracts/slack';

const useStyles = makeStyles((theme) =>
	createStyles({
		realName: {
			marginLeft: theme.spacing(2),
		},
		separator: {
			marginLeft: theme.spacing(1),
		},
		displayName: {
			marginLeft: theme.spacing(1),
		},
	}),
);

export const SlackUserOption: React.ComponentType<{ user: SlackUser }> = ({
	user,
}) => {
	const styles = useStyles();
	const boldName = (user.realName || user.displayName) ?? '';
	const additionalName = (user.displayName || user.realName) ?? '';
	return (
		<>
			<Avatar variant="rounded" src={user.avatarUrl} alt={boldName} />
			<Typography className={styles.realName}>
				<strong>{boldName}</strong>
			</Typography>
			{additionalName && (
				<>
					<Typography className={styles.separator}>●</Typography>
					<Typography className={styles.displayName}>
						{additionalName}
					</Typography>
				</>
			)}
		</>
	);
};
