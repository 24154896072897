import React from 'react';
import * as Yup from 'yup';
import { emailSchema } from '@remote-social/common/src/utils/validation';
import { CopyText } from '@remote-social/common';
import { ChipFormField } from '@remote-social/common/src/components/FormFields';

import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	Box,
	Chip,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles(
	(/** @type {import('@material-ui/core').Theme} */ theme) => ({
		container: {
			marginTop: theme.spacing(1),
		},
		wrapper: {
			maxHeight: 90,
			overflowY: 'auto',
			overflowX: 'hidden',
			scrollbarWidth: 'thin',
		},
		orText: {
			position: 'absolute',
			left: '50%',
			transform: 'translateY(-50%) translateX(-50%)',
			background: theme.palette.background.paper,
			padding: theme.spacing(0, 1, 0, 1),
		},
	}),
);

const InvitePeopleSchema = Yup.object().shape({
	invitees: Yup.array().of(Yup.string()),
});

const EmailChip = (props) => {
	return (
		<Chip
			{...props}
			avatar={<Avatar>{props?.label?.[0].toUpperCase()}</Avatar>}
			size="small"
		/>
	);
};

const ChipsContainer = ({ children, className, ...rest }) => {
	const classes = useStyles();

	return (
		<Grid {...rest} className={clsx(className, classes.wrapper)}>
			{children}
		</Grid>
	);
};

const OrDivider = () => {
	const classes = useStyles();
	return (
		<Box position="relative" my={2}>
			<Divider />
			<Box className={classes.orText}>
				<Typography variant="textSecondary">Or</Typography>
			</Box>
		</Box>
	);
};

/**
 * @param {{ gameURL?: string }} params
 */
const InvitePeople = ({ gameURL }) => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			{gameURL && (
				<>
					<Typography variant="caption">
						Share this link to invite
					</Typography>
					<CopyText text={gameURL} margin="dense" />
					<OrDivider />
				</>
			)}

			<Typography variant="caption">Add emails to invite</Typography>
			<ChipFormField
				margin="dense"
				fullWidth
				variant="outlined"
				name="invitees"
				placeholder="name@email.com"
				chipValidationSchema={emailSchema}
				Chip={EmailChip}
				ChipsContainer={ChipsContainer}
				helperText="Use spaces to separate multiple emails"
			/>
		</Box>
	);
};

export { InvitePeopleSchema };

export default InvitePeople;
