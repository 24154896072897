import {
	Container,
	createStyles,
	makeStyles,
	Paper,
	Theme,
	useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			height: 'auto',

			padding: theme.spacing(0),
			borderRadius: theme.spacing(3),

			[theme.breakpoints.down('xs')]: {
				borderRadius: 0,
			},
		},
	}),
);

export type ScreenContainerProps = {
	/**
	 * Class name for container that wraps children of the screen container
	 *
	 * @note Screen container itself is not designed to be layout-customizable
	 * but we can control padding/margins and display of the container that
	 * holds children
	 */
	containerClassName?: string;
	variant?: 'single-column' | 'two-column';
};

/**
 * Ensures central positioning and consistent padding.
 * - On desktop paper is elevated to produce shadows and round border.
 * - On extra small screens no need to elevate.
 */
export const ScreenContainer: React.ComponentType<ScreenContainerProps> = ({
	children,
	containerClassName: className,
	variant,
}) => {
	const styles = useStyles();
	const isExtraSmall = useMediaQuery<Theme>((theme) =>
		theme.breakpoints.down('xs'),
	);
	const maxWidth = variant === 'two-column' ? 'md' : 'xs';
	return (
		<Container {...(!isExtraSmall && { maxWidth })} fixed disableGutters>
			<Paper elevation={isExtraSmall ? 0 : 3} className={styles.paper}>
				<div className={className}>{children}</div>
			</Paper>
		</Container>
	);
};
