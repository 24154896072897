import { Joi } from './joi';
import type {
	SchemaOf,
	TypeOfSchema,
	ObjectSchemaWithUpdateShape,
	AnyObjectSchema,
} from './joi';
import type { ValueTypesOf } from './valueTypesOf';
import type { UnionToIntersection } from 'utility-types';

export type MakeOptional<T, Keys extends keyof T> = Pick<
	T,
	Exclude<keyof T, Keys>
> &
	{
		[Optional in Keys]?: T[Optional];
	};

export type MakeOptionalAllExcept<
	T,
	Keys extends keyof T,
	All extends keyof T = keyof T,
> = Pick<T, Keys> &
	{
		[Optional in Exclude<All, Keys>]?: T[Optional];
	};

export function makeOptional<T>(map: SchemaOf<T>): SchemaOf<Partial<T>> {
	return Object.entries(map).reduce(
		(acc, [key, schema]) => ({
			...acc,
			[key]: (schema as Joi.AnySchema).optional(),
		}),
		{} as SchemaOf<Partial<T>>,
	);
}

export function omitSchemaProps<T, Keys extends keyof T>(
	schemaMap: SchemaOf<T>,
	keys: Array<Keys>,
) {
	const omitKeys = keys as Array<string>;
	return Object.entries<Joi.AnySchema>(schemaMap).reduce(
		(acc, [key, schema]) => ({
			...acc,
			...(!omitKeys.includes(key) && {
				[key]: schema,
			}),
		}),
		{} as SchemaOf<Omit<T, Keys>>,
	);
}

export function pickSchemaProps<T, Keys extends keyof T>(
	schemaMap: SchemaOf<T>,
	keys: Array<Keys>,
) {
	const pickKeys = keys as Array<string>;
	return Object.entries<Joi.AnySchema>(schemaMap).reduce(
		(acc, [key, schema]) => ({
			...acc,
			...(pickKeys.includes(key) && {
				[key]: schema,
			}),
		}),
		{} as SchemaOf<Pick<T, Keys>>,
	);
}

export type UpdateShape<
	S,
	PrimaryKeys extends keyof S,
	OmitKeys = void,
> = OmitKeys extends keyof S
	? MakeOptionalAllExcept<S, PrimaryKeys, Exclude<keyof S, OmitKeys>>
	: MakeOptionalAllExcept<S, PrimaryKeys>;

export function makeUpdateSchema<
	S,
	Opts extends { primaryKeys: Array<keyof S>; omit?: Array<keyof S> },
>(map: SchemaOf<S>, opts: Opts) {
	const primaryKeys: Array<keyof S> = opts.primaryKeys;
	const omitKeys: Array<keyof S> = opts.omit
		? opts.primaryKeys.concat(opts.omit)
		: opts.primaryKeys;
	const primary = primaryKeys.reduce(
		(acc, key) => ({
			...acc,
			[key]: map[key],
		}),
		{},
	);
	const optional = (Object.keys(map) as Array<keyof S>).reduce(
		(acc, key) => ({
			...acc,
			...(!omitKeys.includes(key) && {
				[key]: map[key].optional(),
			}),
		}),
		{},
	);
	return Object.assign({}, primary, optional) as SchemaOf<
		UpdateShape<
			S,
			ValueTypesOf<Opts['primaryKeys']>,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			Opts['omit'] extends readonly any[]
				? ValueTypesOf<Opts['omit']>
				: void
		>
	>;
}

export function schemaMapOfSchema<T>(schema: Joi.ObjectSchema<T>): SchemaOf<T> {
	return Object.fromEntries(
		Object.keys(schema.describe().keys).map((key) => [
			key,
			schema.extract(key),
		]),
	) as SchemaOf<T>;
}

export function keysOfSchema<S extends AnyObjectSchema>(schema: S): string[] {
	if ('type' in schema && schema.type === 'object' && Joi.isSchema(schema)) {
		return Object.keys(schema.describe().keys);
	}
	return Object.keys(schema);
}

export function combinedKeysOfSchemas(opts: {
	schemas: Array<AnyObjectSchema | string>;
	excludingKeys?: Array<AnyObjectSchema | string>;
}): string[] {
	const { schemas } = opts;
	const excluding = opts.excludingKeys
		? new Set(
				combinedKeysOfSchemas({
					schemas: opts.excludingKeys,
				}),
		  )
		: new Set<string>();
	return [
		...schemas.reduce<Set<string>>((result, schema) => {
			if (typeof schema === 'string') {
				if (!excluding.has(schema)) {
					result.add(schema);
				}
				return result;
			}
			const keys = keysOfSchema(schema);
			keys.forEach((key) => {
				if (!excluding.has(key)) {
					result.add(key);
				}
			});
			return result;
		}, new Set()),
	];
}

export function objectSchemaWithUpdateShape<
	Schema extends AnyObjectSchema,
	Opts extends {
		keys?: string[];
		primaryKeys: Array<keyof TypeOfSchema<Schema>>;
		immutablesKeys?: Array<keyof TypeOfSchema<Schema>>;
		configureUpdateSchema?: (
			schema: Joi.ObjectSchema<TypeOfSchema<Schema>>,
		) => Joi.ObjectSchema<TypeOfSchema<Schema>>;
	},
>(schemaOrSchemaMap: Schema, opts: Opts) {
	const primaryKeys = opts.primaryKeys as string[];
	const omit = (opts.immutablesKeys || []) as string[];
	const keys = [...new Set(opts.keys ?? keysOfSchema(schemaOrSchemaMap))];
	const optional = keys.reduce<string[]>((acc, key) => {
		if (primaryKeys.includes(key) || omit.includes(key)) {
			return acc;
		}
		acc.push(key);
		return acc;
	}, []);
	const configure = opts.configureUpdateSchema ?? ((schema) => schema);
	return (
		Joi.compile(schemaOrSchemaMap) as Joi.ObjectSchema<TypeOfSchema<Schema>>
	).alter({
		update: (schema) =>
			configure(
				schema
					.fork(optional, (schema) => schema.optional())
					.fork(omit, (schema) => schema.optional().strip())
					.fork(primaryKeys, (schema) => schema.required()),
			),
	}) as ObjectSchemaWithUpdateShape<
		TypeOfSchema<Schema>,
		UpdateShape<
			TypeOfSchema<Schema>,
			ValueTypesOf<Opts['primaryKeys']>,
			Opts['immutablesKeys'] extends readonly unknown[]
				? ValueTypesOf<Opts['immutablesKeys']>
				: void
		>
	>;
}

type ConcatObjectSchema<
	A extends AnyObjectSchema,
	B,
> = B extends AnyObjectSchema
	? A extends ObjectSchemaWithUpdateShape<infer A_Shape, infer A_UpdateShape>
		? B extends ObjectSchemaWithUpdateShape<
				infer B_Shape,
				infer B_UpdateShape
		  >
			? ObjectSchemaWithUpdateShape<
					A_Shape & B_Shape,
					A_UpdateShape & B_UpdateShape
			  >
			: ObjectSchemaWithUpdateShape<
					A_Shape & TypeOfSchema<B>,
					A_UpdateShape & TypeOfSchema<B>
			  >
		: B extends ObjectSchemaWithUpdateShape<
				infer B_Shape,
				infer B_UpdateShape
		  >
		? ObjectSchemaWithUpdateShape<
				TypeOfSchema<A> & B_Shape,
				TypeOfSchema<A> & B_UpdateShape
		  >
		: Joi.ObjectSchema<TypeOfSchema<A> & TypeOfSchema<B>>
	: never;

export function concatSchemaMaps<
	Schema extends AnyObjectSchema,
	Arr extends Array<SchemaOf<unknown>>,
>(
	schemaOrSchemaMap: Schema,
	...shemaMaps: Arr
): ConcatObjectSchema<Schema, UnionToIntersection<ValueTypesOf<Arr>>> {
	return (
		Joi.compile(schemaOrSchemaMap) as Joi.ObjectSchema<TypeOfSchema<Schema>>
	).append(
		shemaMaps.reduce((combined, map) => ({ ...combined, ...map }), {}),
	) as unknown as ConcatObjectSchema<
		Schema,
		UnionToIntersection<ValueTypesOf<Arr>>
	>;
}
