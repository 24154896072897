import React from 'react';
import { isDevBuild } from '../environment';
import { AsyncRequestTuple, useAsyncRequest } from './useAsyncRequest';
import type {
	AsyncReturnType,
	BivariantSingleParameterAsyncFn,
} from '../utils/types';
import type { RequestState } from '../store-tools/requestState';

// params is not an array of all parameters
// as this simplifies callOnChange to only return an object instead of array;
type SingleParameterAsyncFn = BivariantSingleParameterAsyncFn;

type Param<Fn extends SingleParameterAsyncFn> = Parameters<Fn>[0];
type Return<Fn extends SingleParameterAsyncFn> = AsyncReturnType<Fn>;

type ResultWithParams<
	Fn extends SingleParameterAsyncFn,
	I = undefined,
> = AsyncRequestTuple<
	RequestState<Return<Fn>, I>,
	(...params: Parameters<Fn>) => void
>;

type ResultNoParams<
	Fn extends SingleParameterAsyncFn,
	I = undefined,
> = AsyncRequestTuple<RequestState<Return<Fn>, I>, () => void>;

type Falsy = '' | false | undefined | null;

type CallOnChangeFn<P> = P extends Falsy
	? () => Exclude<P, Falsy> | Falsy
	: () => P | Falsy;

type InitialValue<Fn extends SingleParameterAsyncFn, I> = I extends never[]
	? Return<Fn> extends unknown[]
		? Return<Fn>
		: I
	: I;

// simplify dependencies check for callOnChange by just serializing parameters to a string
const jsonStringifyHash = <Fn extends SingleParameterAsyncFn>(
	params: Param<Fn> | undefined,
) => JSON.stringify(params);

type UseAsyncFunction = {
	<Fn extends SingleParameterAsyncFn, I>(
		call: Fn,
		opts?: {
			callOnChange?: undefined;
			initialValue?: I;
			hashParams?: typeof jsonStringifyHash;
		},
	): ResultWithParams<Fn, InitialValue<Fn, I>>;
	<Fn extends SingleParameterAsyncFn, I>(
		call: Fn,
		opts: {
			callOnChange: CallOnChangeFn<Param<Fn>>;
			initialValue?: I;
			hashParams?: typeof jsonStringifyHash;
		},
	): ResultNoParams<Fn, InitialValue<Fn, I>>;
	<Fn extends SingleParameterAsyncFn, I>(
		call: Fn,
		opts?: {
			callOnChange?: CallOnChangeFn<Param<Fn>>;
			initialValue?: I;
			hashParams?: typeof jsonStringifyHash;
		},
	): ResultWithParams<Fn, InitialValue<Fn, I>>;
};

export const useAsyncFunction: UseAsyncFunction = <
	Fn extends SingleParameterAsyncFn,
	I = undefined,
>(
	call: Fn,
	opts?: {
		callOnChange?: () => Param<Fn> | undefined;
		initialValue?: I;
		hashParams?: typeof jsonStringifyHash;
	},
): ResultWithParams<Fn, I> => {
	const {
		callOnChange,
		initialValue,
		hashParams = jsonStringifyHash,
	} = opts ?? {};

	const [request, initiateCall] = useAsyncRequest<unknown, {}, I>(
		initialValue,
	);

	// when callOnChange is passed down we want it to be
	// the single source of parameters for initiate callback
	// returned by the hook. see ResultWithParams vs ResultNoParams

	const paramsText = callOnChange && hashParams(callOnChange());

	if (isDevBuild()) {
		// detect cases where we push large amount of data to the backend
		// in those cases we should not be using JSON.stringify during render
		if (
			(paramsText?.length ?? 0) > 300 &&
			hashParams === jsonStringifyHash
		) {
			console.warn(
				new Error(
					'This hook is meant to be used with lightweight parameters only, specify custom memoization function to improve performance',
				),
			);
		}
	}

	// we can memoize fn and/or callOnChange - or not - doesn't matter
	// we will always use the latest values passed to the hook to drive the call
	const optsRef = React.useRef({
		call,
		callOnChange,
	});
	optsRef.current = {
		call,
		callOnChange,
	};

	const initiate = React.useCallback(
		(...args: Parameters<Fn>) => {
			const { call, callOnChange } = optsRef.current;
			if (callOnChange) {
				const argsFromCallback = callOnChange();
				if (!argsFromCallback) {
					// when call on change returns undefined - we skip the call
					return;
				}
				initiateCall<Fn>({
					call,
					args: [argsFromCallback] as Parameters<Fn>,
				});
			} else {
				initiateCall<Fn>({
					call,
					args,
				});
			}
		},
		[initiateCall, optsRef],
	);

	React.useEffect(() => {
		const { callOnChange } = optsRef.current;
		if (!callOnChange) {
			return;
		}
		const params = callOnChange();
		if (!params) {
			return;
		}
		initiate(...([params] as Parameters<Fn>));
	}, [initiate, optsRef, paramsText]);

	const result = React.useMemo(
		() =>
			Object.assign([request, initiate], request, {
				initiate,
				request,
			}),
		[request, initiate],
	);

	return result as unknown as ResultWithParams<Fn, I>;
};
