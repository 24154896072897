import React from 'react';
import { Button, Snackbar, Theme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorMessage, ErrorType } from '@common';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
	alertContent: {
		...theme.typography.body1,
		color: 'inherit',
	},
}));

type Props = {
	error?: ErrorType | string;
	extraErrorInfo?: string;
};

export const ErrorSnackbar: React.ComponentType<Props> = ({
	error,
	extraErrorInfo,
}) => {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useEffect(() => {
		if (error) {
			setOpen(!!error);
		}
	}, [error]);

	if (!error) {
		return null;
	}

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			action={
				<Button color="inherit" size="small">
					<CloseIcon />
				</Button>
			}
		>
			<Alert onClose={handleClose} severity="error" variant="filled">
				<AlertTitle>Error</AlertTitle>
				<ErrorMessage
					error={error}
					extraErrorInfo={extraErrorInfo}
					className={styles.alertContent}
				/>
			</Alert>
		</Snackbar>
	);
};
