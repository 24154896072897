// types for cases where we want to be generic but only
// allow types that are easily supported by Firestore
// e.g. arrays of non-primitives have to be converted to maps in Firestore

import { Joi } from './joi';

export type StorablePrimitive = string | number | boolean;

export type StorablePrimitiveOrArray =
	| StorablePrimitive
	| Array<StorablePrimitive>;

export type StorableValue =
	| StorablePrimitiveOrArray
	| {
			[key in string]: StorableValue;
	  };

export type StorableMap = {
	[key in string]: StorableValue | undefined;
};

export const storablePrimitiveSchema = Joi.alternatives(
	Joi.string(),
	Joi.number(),
	Joi.boolean(),
);

export const storablePrimitiveOrArraySchema = Joi.alternatives(
	storablePrimitiveSchema,
	Joi.array().items(storablePrimitiveSchema),
);

export const storableValueSchema = Joi.alternatives(
	storablePrimitiveOrArraySchema,
	Joi.object().pattern(Joi.string(), Joi.link('#root').required()),
).id('root');

export const storableMapSchema = Joi.object<StorableMap>().pattern(
	Joi.string(),
	storableValueSchema,
);
