import React from 'react';
import * as Yup from 'yup';
import { InputField } from '../../FormFields';

export const SessionNameSchema = Yup.object().shape({
	team: Yup.string().trim().required().min(2),
});

type Props = {
	placeholder: string;
	helperText: string;
};

export const SessionName: React.ComponentType<Props> = ({
	placeholder,
	helperText,
}) => (
	<InputField
		fullWidth
		name="team"
		type="text"
		placeholder={placeholder}
		helperText={helperText}
	/>
);
