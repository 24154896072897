import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { PlanCard } from './planCard';
import { Plan } from './plan';
import { plans } from './plans.data';

const useStyles = makeStyles((theme) =>
	createStyles({
		plans: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			gap: theme.spacing(2),

			[theme.breakpoints.up('sm')]: {
				flexDirection: 'row',
			},
		},
	}),
);

export const Plans: React.ComponentType = () => {
	const styles = useStyles();

	const freePlan = plans[0] as Plan;
	const proPlan = plans[1] as Plan;

	return (
		<div className={styles.plans}>
			<PlanCard key={freePlan.planId} plan={freePlan} />
			<PlanCard key={proPlan.planId} plan={proPlan} />
		</div>
	);
};
