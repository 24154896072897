import React from 'react';
import { Helmet } from 'react-helmet';
import { useContext } from 'react';
import { Config } from '../contexts';

type Props = {
	title: string;
};

export const DocumentHead: React.ComponentType<Props> = ({
	title,
	children,
}) => {
	const config = useContext(Config);
	return (
		<Helmet>
			<title>{[title, config?.title].join(' - ')}</title>
			{children}
		</Helmet>
	);
};
