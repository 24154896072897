import React from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export function useSnackBarMessage() {
	const [message, setMessage] = React.useState('');

	const close = React.useCallback(() => {
		setMessage('');
	}, [setMessage]);

	const snackbar = (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={!!message}
			autoHideDuration={5000}
			onClose={close}
			message={message}
			action={
				<IconButton
					size="small"
					aria-label="close"
					color="inherit"
					onClick={close}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		/>
	);

	return { showMessage: setMessage, snackbar };
}
