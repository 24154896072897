import Joi from 'joi';

declare module 'joi' {
	type CustomValidationResult<T> =
		| {
				error: ValidationError;
				errors?: ValidationError;
				warning?: ValidationError;
				value: unknown;
		  }
		| {
				error: undefined;
				errors?: ValidationError;
				warning?: ValidationError;
				value: T;
		  };

	type DescriptionWithKeys = Joi.Description & {
		keys?: {
			[key: string]: DescriptionWithKeys;
		};
	};

	type ObjectDescriptionWithKeys = Joi.Description & {
		keys: {
			[key: string]: DescriptionWithKeys;
		};
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	interface ObjectSchema<TSchema = any> {
		/**
		 * Validates a value using the schema and options.
		 */
		validate(
			value: unknown,
			options?: ValidationOptions,
		): CustomValidationResult<TSchema>;

		/**
		 * Validates a value using the schema and options.
		 */
		validateAsync(
			value: unknown,
			options?: AsyncValidationOptions,
		): Promise<TSchema>;

		describe(): ObjectDescriptionWithKeys;
	}
}

export interface ObjectSchemaWithUpdateShape<
	Shape = unknown,
	UpdateShape = Shape,
> extends Joi.ObjectSchema<Shape> {
	tailor(key: 'update'): Joi.ObjectSchema<UpdateShape>;
	_updateShape?: UpdateShape;
}

export type UpdateShapeOfSchema<S> = S extends ObjectSchemaWithUpdateShape
	? NonNullable<S['_updateShape']>
	: TypeOfSchema<S>;

export type SchemaOf<T> = Record<keyof T, Joi.AnySchema>;

export type TypeOfSchema<S> = S extends Joi.ObjectSchema<infer O>
	? O
	: S extends SchemaOf<infer Doc>
	? Doc
	: never;

export type AnyObjectSchema =
	| SchemaOf<unknown>
	| Joi.ObjectSchema<unknown>
	| ObjectSchemaWithUpdateShape<unknown, unknown>;

export function objectSchemaOf<T>(map: SchemaOf<T>): Joi.ObjectSchema<T> {
	return Joi.object<T>(map);
}

// this function doesn't do much but just outlines our intent
export function transformSchemaMap<T, R>(
	map: SchemaOf<T>,
	fn: (map: SchemaOf<T>) => SchemaOf<R>,
): SchemaOf<R> {
	return fn(map);
}

export { Joi };
