import React, { useState } from 'react';
import {
	Dialog,
	Button,
	DialogTitle,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { useCurrentUser } from '@common';
import {
	SentimentDissatisfied,
	SentimentVerySatisfied,
	SentimentSatisfied,
	SentimentVeryDissatisfied,
} from '@material-ui/icons';
import { green, red, orange, yellow } from '@material-ui/core/colors';
import { registerError } from '@common';

const SENTIMENTS = {
	VERY_HAPPY: 'VERY_HAPPY',
	HAPPY: 'HAPPY',
	UNHAPPY: 'UNHAPPY',
	VERY_UNHAPPY: 'VERY_UNHAPPY',
};

const sentimentIconDescriptors = [
	{
		value: SENTIMENTS.VERY_UNHAPPY,
		Icon: SentimentVeryDissatisfied,
		fill: red[600],
	},
	{
		value: SENTIMENTS.UNHAPPY,
		Icon: SentimentDissatisfied,
		fill: orange[600],
	},
	{
		value: SENTIMENTS.HAPPY,
		Icon: SentimentSatisfied,
		fill: yellow[800],
	},
	{
		value: SENTIMENTS.VERY_HAPPY,
		Icon: SentimentVerySatisfied,
		fill: green[800],
	},
];

const useStyles = makeStyles((theme) => ({
	sentimentOptions: {
		listStyleType: 'none',
		display: 'flex',
		padding: theme.spacing(0, 4),
		justifyContent: 'space-between',
		alignItems: 'center',
		'& > li > button': {
			minWidth: 0,
		},
	},
}));

const isToday = (timestamp) => {
	if (!timestamp) {
		return false;
	}
	const now = new Date();
	const d = new Date(timestamp.seconds * 1000);
	return (
		now.getFullYear() === d.getFullYear() &&
		now.getMonth() === d.getMonth() &&
		now.getDate() === d.getDate()
	);
};

const useSentiment = () => {
	const user = useCurrentUser();
	const firebase = useFirebase();
	const [responded, setResponded] = useState(false);
	return {
		responded: responded || isToday(user.lastSentiment),
		loaded: user.isLoaded,
		setSentiment(value, skipped) {
			firebase
				.functions()
				.httpsCallable('platform-setSentiment')({
					value: skipped ? null : value,
					timezoneOffset: new Date().getTimezoneOffset(),
				})
				.catch((e) => registerError(e));
			setResponded(true); // optimistic
		},
	};
};

const SentimentPicker = ({ onSentimentClicked, onSkipped }) => {
	const classes = useStyles();
	return (
		<ul className={classes.sentimentOptions}>
			{sentimentIconDescriptors.map(({ Icon, value, fill }) => (
				<li key={value}>
					<Button onClick={() => onSentimentClicked(value)}>
						<Icon
							fill={fill}
							style={{ color: fill, width: 32, height: 32 }}
						/>
					</Button>
				</li>
			))}
			<li>
				<Button onClick={onSkipped}>Skip</Button>
			</li>
		</ul>
	);
};

export const SentimentDialog = () => {
	const { responded, loaded, setSentiment } = useSentiment();

	if (responded || !loaded) {
		return null;
	}
	return (
		<Dialog open={true} disableBackdropClick disableEscapeKeyDown>
			<DialogTitle disableTypography>
				<Typography variant="h5">How are you feeling today?</Typography>
			</DialogTitle>
			<SentimentPicker
				onSentimentClicked={setSentiment}
				onSkipped={() => setSentiment(null, true)}
			/>
		</Dialog>
	);
};
