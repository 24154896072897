import React, { MutableRefObject } from 'react';
import {
	Button,
	ClickAwayListener,
	Dialog,
	Grid,
	Hidden,
	IconButton,
	MenuList,
	Paper,
	Popper,
	Theme,
	useMediaQuery,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { UserLinks } from './userLinks';
import { FrillAnnouncements, UserAvatar } from '@common/components';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
	return createStyles({
		dialogue: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'left',
			minWidth: `35ch`,
		},
		menu: {
			width: `35ch`,
		},
	});
});

type Props = {
	currentUser: { displayName?: string; email?: string; photoURL?: string };
};

type State = {
	menuAnchorRef: MutableRefObject<HTMLButtonElement | null>;
	menuOpen: 'closed' | 'dialogue' | 'menu';
	handleOpen: () => void;
	handleClose: () => void;
};

const useState = (): State => {
	const menuAnchorRef = React.useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = React.useState<
		'closed' | 'dialogue' | 'menu'
	>('closed');

	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	const handleOpen = React.useCallback(() => {
		if (isSm) {
			setMenuOpen('menu');
		} else {
			setMenuOpen('dialogue');
		}
	}, [setMenuOpen, isSm]);

	const handleClose = React.useCallback(() => {
		setMenuOpen('closed');
	}, [setMenuOpen]);

	const result = React.useMemo<State>(() => {
		return {
			menuAnchorRef,
			menuOpen,
			handleOpen,
			handleClose,
		};
	}, [handleClose, handleOpen, menuOpen]);

	return result;
};

export const UserMenu: React.ComponentType<Props> = ({ currentUser }) => {
	const styles = useStyles();
	const { menuOpen, menuAnchorRef, handleOpen, handleClose } = useState();

	return (
		<>
			<Hidden xsDown>
				<FrillAnnouncements />
			</Hidden>
			<Button
				ref={menuAnchorRef}
				onClick={handleOpen}
				aria-controls={
					menuOpen === 'dialogue' ? 'user-dialogue' : 'user-menu'
				}
				aria-haspopup="true"
			>
				<UserAvatar
					withName={false}
					orientation="horizontal"
					user={currentUser}
				/>
			</Button>
			{menuOpen === 'dialogue' && (
				<Dialog
					fullScreen
					open={menuOpen === 'dialogue'}
					onClose={handleClose}
				>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
					>
						<IconButton onClick={handleClose}>
							<ClearIcon fontSize="small" />
						</IconButton>
					</Grid>
					<MenuList
						autoFocus
						variant="menu"
						className={styles.dialogue}
						id="user-dialogue"
					>
						<UserLinks
							key="mobile"
							currentUser={currentUser}
							handleClose={handleClose}
						/>
					</MenuList>
				</Dialog>
			)}
			{menuOpen === 'menu' && (
				<ClickAwayListener onClickAway={handleClose}>
					<Popper
						anchorEl={menuAnchorRef.current}
						placement={'bottom-end'}
						open={menuOpen === 'menu'}
						keepMounted
						role="menu"
						className={styles.menu}
						transition
						disablePortal
					>
						<Paper>
							<MenuList autoFocus id="user-menu">
								<UserLinks
									key="desktop"
									currentUser={currentUser}
									handleClose={handleClose}
								/>
							</MenuList>
						</Paper>
					</Popper>
				</ClickAwayListener>
			)}
		</>
	);
};
