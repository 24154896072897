/*
  Let people into this page only if they are authenticated AS A HOST

  This means:
    1. Auth/Profile has (handled by Loader)
    	a. successfully loaded
    	b. has updated with a createdAt value (handled by background trigger function)
    2. The user is currently logged in: !isEmpty(profile)
*/

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCurrentUser } from '../hooks';
import { Loading } from '../components';

export default function AuthenticatedHostRoute({
	component: Component,
	...rest
}) {
	const user = useCurrentUser();

	if (!user.isLoaded) {
		return <Loading showRandomLabels={true} />;
	}

	const hasVerifiedEmail =
		user.isAuthenticated && Boolean(user.email) && user.emailVerified;
	const needsVerification =
		user.isAuthenticated && !hasVerifiedEmail && !!user.email;
	const isAnonymousOrNoEmail =
		user.isAuthenticated && (user.isAnonymous || !user.email);

	return (
		<Route
			{...rest}
			render={(props) =>
				hasVerifiedEmail ? (
					<Component {...props} {...rest} />
				) : needsVerification ? (
					<Redirect to="/verify-email" />
				) : (
					<Redirect
						to={{
							pathname: isAnonymousOrNoEmail
								? '/register/upgrade'
								: '/onboard',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}
