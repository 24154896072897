import React from 'react';
import {
	LoadingButton,
	DocumentHead,
	RemoteSocialLogo,
	LinkButton,
	ErrorMessage,
	Loading,
} from '../../components';
import { useSpring, animated } from 'react-spring';
import { contentAnimation, gifAnimation } from './animation';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden, Typography, makeStyles } from '@material-ui/core';
import { useCurrentAccount } from '../../contexts';
import { useAuth, useQueryParams } from '../../hooks';
import { useAsyncFunction } from '../../hooks/useAsyncFunction';
import { isError, isPending, isSuccess } from '../../store-tools';
import { UnreachableError } from '../../errors';
import { routes } from '../routes';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	videoContainer: {
		position: 'relative',
		width: '100%',
		minHeight: '550px',
		background: '#F5F6F8',
		borderRadius: '1.5rem',
		overflow: 'hidden',
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	brand: {
		height: '3rem',
		marginTop: theme.spacing(3),
	},
	title: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
	subtitle: {
		marginBottom: theme.spacing(5),
		marginTop: theme.spacing(6),
	},
	mainSection: {
		height: '100%',
		display: 'grid',

		placeItems: 'center',
		alignContent: 'space-between',
		gridTemplateRows: 'auto 1fr repeat(2, auto)',

		[theme.breakpoints.down('xs')]: {
			placeItems: 'start',
			alignContent: 'start',
			gridTemplateRows: 'auto',
		},

		gap: theme.spacing(2),

		padding: theme.spacing(1),
	},
	noWrap: {
		whiteSpace: 'no-wrap',
	},
}));

const NoWrap = ({ children }) => {
	const styles = useStyles();
	return <span className={styles.noWrap}>{children}</span>;
};

const PageLoadingButton = (props) => (
	<LoadingButton variant="contained" disableElevation fullWidth {...props} />
);

const PageLinkButton = (props) => (
	<LinkButton variant="contained" disableElevation fullWidth {...props} />
);

const GenericContent = ({ title, subTitle = '', children }) => {
	const classes = useStyles();
	return (
		<>
			<div>
				<Typography
					variant="h4"
					align="center"
					className={classes.title}
				>
					{title}
				</Typography>
				{subTitle && (
					<Typography
						variant="h5"
						align="center"
						className={classes.subtitle}
					>
						{subTitle}
					</Typography>
				)}
			</div>
			{children}
		</>
	);
};

const JoinContent = ({ title, inviteId, continuePath }) => {
	const history = useHistory();
	const location = useLocation();
	const { joinAccount } = useCurrentAccount();
	const [joinAccountRequest, initiateJoinAccount] = useAsyncFunction((data) =>
		joinAccount(data),
	);

	React.useEffect(() => {
		if (isSuccess(joinAccountRequest)) {
			history.replace(continuePath || routes.home({ location }));
		}
	}, [history, joinAccountRequest, location, continuePath]);

	return (
		<GenericContent title={title}>
			{isError(joinAccountRequest) && (
				<ErrorMessage error={joinAccountRequest.error} />
			)}
			<PageLoadingButton
				loading={isPending(joinAccountRequest)}
				onClick={() => initiateJoinAccount({ inviteId })}
			>
				Join team
			</PageLoadingButton>
		</GenericContent>
	);
};

const SwitchContent = ({ title, data }) => {
	const history = useHistory();
	const location = useLocation();
	const { logout } = useAuth();

	const [logoutAndRedirectRequest, logoutAndRedirect] = useAsyncFunction(
		(
			/**
			 * @type {import('history').LocationDescriptor}
			 */
			destination,
		) =>
			logout().then(
				() => history.replace(destination),
				(error) => console.error(error),
			),
	);

	return (
		<GenericContent
			title={title}
			subTitle={`You are not logged in with ${data.inviteEmail}`}
		>
			{isError(logoutAndRedirectRequest) && (
				<ErrorMessage error={logoutAndRedirectRequest.error} />
			)}
			<PageLoadingButton
				loading={isPending(logoutAndRedirectRequest)}
				onClick={() =>
					logoutAndRedirect(
						routes.login({
							preserveContinuePath: true,
							location,
						}),
					)
				}
			>
				Switch user
			</PageLoadingButton>
			<PageLoadingButton
				loading={isPending(logoutAndRedirectRequest)}
				onClick={() =>
					logoutAndRedirect(
						routes.register({
							preserveContinuePath: true,
							location,
						}),
					)
				}
			>
				Sign up
			</PageLoadingButton>
		</GenericContent>
	);
};

const LoginContent = ({ title, data }) => {
	const location = useLocation();
	return (
		<GenericContent
			title={title}
			subTitle={
				data.inviteEmail
					? `Please login or signup with ${data.inviteEmail} to continue`
					: `Please login or signup to continue`
			}
		>
			<PageLinkButton
				to={routes.login({
					preserveContinuePath: true,
					location,
				})}
			>
				Login
			</PageLinkButton>
			<PageLinkButton
				to={routes.register({
					preserveContinuePath: true,
					location,
				})}
			>
				Sign up
			</PageLinkButton>
		</GenericContent>
	);
};

const ExpiredContent = ({ title }) => {
	const location = useLocation();
	return (
		<GenericContent
			title={title}
			subTitle="This invite link has expired, please contact your team owner to request a new link."
		>
			<PageLinkButton to={routes.home({ location })}>
				Return to Hub
			</PageLinkButton>
		</GenericContent>
	);
};

const ContinueContent = ({ title, continuePath }) => {
	return (
		<GenericContent
			title={title}
			subTitle="To access this team, press Continue."
		>
			<PageLinkButton to={continuePath}>Continue</PageLinkButton>
		</GenericContent>
	);
};

const ReturnToHubContent = ({ title }) => {
	const location = useLocation();
	return (
		<GenericContent
			title={title}
			subTitle="This invite link has already been used. To access this team, return to the Hub."
		>
			<PageLinkButton to={routes.home({ location })}>
				Return to Hub
			</PageLinkButton>
		</GenericContent>
	);
};

const HandleInviteContentSwitcher = ({ inviteId, continuePath, data }) => {
	const title = (
		<>
			<NoWrap>{data.senderName ?? 'Anonymous'}</NoWrap> has invited you to{' '}
			<NoWrap>{data.accountName ?? 'their team'}</NoWrap>
		</>
	);

	switch (data.nextAction) {
		case 'join-account':
			return (
				<JoinContent
					title={title}
					inviteId={inviteId}
					continuePath={continuePath}
				/>
			);
		case 'switch-user':
			return <SwitchContent title={title} data={data} />;
		case 'login':
			return <LoginContent title={title} data={data} />;
		case 'cancelled-invite':
			return <ExpiredContent title={title} />;
		case 'already-member':
			return continuePath ? (
				<ContinueContent title={title} continuePath={continuePath} />
			) : (
				<ReturnToHubContent title={title} />
			);
		default:
			throw new UnreachableError(data.nextAction);
	}
};

export function HandleInvite() {
	const classes = useStyles();
	const location = useLocation();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const history = useHistory();
	const { handleInvitedPeople } = useAuth();
	const { inviteId, continuePath } = useQueryParams();

	React.useEffect(() => {
		if (!inviteId) {
			console.error('Invite details not found!');
			history.replace(routes.login({ location }));
		}
	}, [history, inviteId, location]);

	const [pageLoadRequest] = useAsyncFunction(handleInvitedPeople, {
		callOnChange: () => {
			if (!inviteId) {
				return;
			}
			return {
				inviteId,
			};
		},
	});

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Invitation" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hello"
							src="https://cdn.remotesocial.io/hub/img/Hello.gif"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1} />
			<Grid item xs={12} md={5}>
				<animated.div
					style={contentSpring}
					className={classes.mainSection}
				>
					<RemoteSocialLogo className={classes.brand} />
					{isPending(pageLoadRequest) && <Loading showRandomLabels />}
					{isError(pageLoadRequest) &&
						(pageLoadRequest.error.code === 'not-found' ? (
							<>
								<ErrorMessage
									error={'This invite link is not valid'}
								/>
								<PageLinkButton to={routes.home({ location })}>
									Return to Hub
								</PageLinkButton>
							</>
						) : (
							<ErrorMessage error={pageLoadRequest.error} />
						))}
					{isSuccess(pageLoadRequest) && (
						<HandleInviteContentSwitcher
							inviteId={inviteId}
							continuePath={continuePath}
							data={pageLoadRequest.data}
						/>
					)}
				</animated.div>
			</Grid>
		</Grid>
	);
}
