import { Joi } from '../joi';

const ensureValidZone = (zone: string) => {
	new Intl.DateTimeFormat('en-US', { timeZone: zone }).format();
};

export const timeZoneSchema = Joi.string().custom((value: string) => {
	ensureValidZone(value);
	return value;
}, 'Time Zone');
