/*
  Let people into this page only if they are NOT authenticated

  This means:
    1. Auth/Profile has sucessfully loaded (handled by Loader)
    2. The user is not currently logged in: isEmpty(profile)
*/

import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../hooks';
import { Loading } from '../components';
import { routes } from './routes';
import { continuePathFromLocation } from './continuePathFromLocation';

function UnAuthenticatedRoute({ component: Component, blocked, ...rest }) {
	const location = useLocation();
	const continuePath =
		continuePathFromLocation(location) || routes.home({ location });
	const user = useCurrentUser();

	if (!user.isLoaded) {
		return <Loading showRandomLabels={true} />;
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				!user.isAuthenticated || !blocked ? (
					<Component {...props} {...rest} continue={continuePath} />
				) : (
					<Redirect to={continuePath} />
				)
			}
		/>
	);
}

export default UnAuthenticatedRoute;
