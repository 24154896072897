import React from 'react';
import { useCurrentUser, useLocalStorage } from '../hooks';
import { useFirebase } from 'react-redux-firebase';
import orderBy from 'lodash/orderBy';
import { registerError } from '../errors';
import { useAsyncFunction } from '../hooks/useAsyncFunction';
import { makeUseDocumentPath } from '../store-tools/makeUseDocumentPath';
import { ensureExists } from '@common';
import { useHistory, useLocation } from 'react-router-dom';

export const AccountsContext = React.createContext();

const getAccounts = async ({ firebase }) => {
	const uid = firebase.auth().currentUser?.uid;

	const { data } = await firebase
		.functions()
		.httpsCallable('platform-getAccounts')({
		role: 'member',
	});

	/**
	 * @type {import('@contracts/platform').GetAccountsResponse}
	 */
	const accounts = data;

	const sorted = orderBy(
		accounts,
		[(account) => account.owner === uid, 'name'],
		['desc', 'desc'],
	);
	return sorted;
};

export const makeAccountsApi = ({
	currentAccountId,
	firebase,
	setCurrentAccountId,
	reloadAccounts,
}) => {
	const createAccount = async (accountData) => {
		const { data } = await firebase
			.functions()
			.httpsCallable('platform-createAccount')(accountData);
		/**
		 * @type {import('@contracts/platform').CreateAccountResponse}
		 */
		const account = data;
		reloadAccounts();
		// switch to this new account
		setCurrentAccountId(account.accountId);
		return account;
	};

	return {
		setCurrentAccount: async (accountId) => {
			setCurrentAccountId(accountId);
		},
		createAccount,
		createFirstAccount: async (accountData) => {
			// create an error beforehand to capture original stack trace
			const error = new Error('Duplicate account creation attempt');
			// ensure we do not create duplicate accounts
			const accounts = await getAccounts({ firebase });
			if (accounts.length === 0) {
				await createAccount(accountData);
			} else {
				// register in Sentry to catch other cases when duplicate accounts are
				// created
				registerError(error);
			}
		},
		deleteAccount: async (accountId) => {
			await firebase.functions().httpsCallable('platform-deleteAccount')({
				accountID: accountId,
			});
			reloadAccounts();
		},
		/**
		 * @deprecated use leaveAccount API via contracts/platform/accounts
		 * @param accountId {string}
		 */
		leaveAccount: async (accountId) => {
			await firebase.functions().httpsCallable('platform-leaveAccount')({
				accountId,
			});
			reloadAccounts();
		},
		getAccountMembers: async (accountId = currentAccountId, role) => {
			return await firebase
				.functions()
				.httpsCallable('platform-getAccountMembers')({
				accountID: accountId,
				role,
			});
		},
		addAccountMember: async (accountId, uid, role = 'member') => {
			await firebase
				.functions()
				.httpsCallable('platform-addAccountMember')({
				accountID: accountId,
				uid,
				role,
			});
		},
		deleteAccountMember: async (uid) => {
			await firebase
				.functions()
				.httpsCallable('platform-deleteAccountMember')({
				accountID: currentAccountId,
				uid,
			});
		},
		getAccountMembersWithRolesAndInvites: async (accountId) => {
			return await firebase
				.functions()
				.httpsCallable('platform-getAccountMembersWithRolesAndInvites')(
				{
					accountId,
				},
			);
		},

		resendInviteEmail: async (inviteId) => {
			return await firebase
				.functions()
				.httpsCallable('accountInvites-resendInviteEmail')({
				accountId: currentAccountId,
				inviteId,
			});
		},

		cancelInvite: async (inviteId) => {
			return await firebase
				.functions()
				.httpsCallable('accountInvites-cancelInvite')({
				inviteId,
				accountId: currentAccountId,
			});
		},

		updateMemberRole: async (uid, role) => {
			await firebase
				.functions()
				.httpsCallable('platform-updateMemberRole')({
				accountId: currentAccountId,
				uid,
				role,
			});
		},
		createOrUpdateGameSchedule: async (data) => {
			await firebase
				.functions()
				.httpsCallable('platform-createOrUpdateGameSchedule')(data);
		},
		joinAccount: async (data) => {
			const {
				data: { accountId },
			} = await firebase
				.functions()
				.httpsCallable('accountInvites-joinAccount')(data);
			reloadAccounts();
			setCurrentAccountId(accountId);
		},
		createCustomTriviaCategory: async (categoryData) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-createCustomTriviaCategory')({
				...(categoryData || {}),
				accountID: currentAccountId,
			});
		},
		updateCustomTriviaCategory: async (data) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-updateCustomTriviaCategory')({
				...data,
				accountID: currentAccountId,
			});
		},
		fetchCustomTriviaCategories: async () => {
			return await firebase
				.functions()
				.httpsCallable('trivia-getCustomTriviaCategories')({
				accountID: currentAccountId,
			});
		},
		fetchCustomCategory: async (categoryID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-getCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
			});
		},
		addCustomCategoryQuestion: async (categoryID, data = {}) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-addCategoryQuestion')({
				accountID: currentAccountId,
				categoryID,
				...data,
			});
		},
		deleteCustomCategoryQuestion: async (questionID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-deleteCategoryQuestion')({
				accountID: currentAccountId,
				questionID,
			});
		},
		deleteCustomTriviaCategory: async (categoryID) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-deleteCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
			});
		},
		publishCustomTriviaCategory: async (categoryID, status) => {
			return await firebase
				.functions()
				.httpsCallable('trivia-publishCustomTriviaCategory')({
				accountID: currentAccountId,
				categoryID,
				status,
			});
		},
	};
};

const chooseDefaultAccountId = ({ owned, accounts }) => {
	if (owned.length > 0) {
		return owned[0].accountId;
	} else if (accounts.length > 0) {
		return accounts[0].accountId;
	} else {
		throw new Error('No accounts');
	}
};

let accountsLoadedOnce = false;

const useDocumentPath = makeUseDocumentPath();

export const AccountsProvider = ({ children }) => {
	const user = useCurrentUser();
	const location = useLocation();
	const history = useHistory();

	const firebase = useFirebase();
	const [localStorageAccountId, setCurrentAccountId] =
		useLocalStorage('currentAccountId');

	const [needToRefreshAccounts, setNeedToRefreshAccounts] = React.useState(0);

	const [accountsRequest, refresh] = useAsyncFunction(
		(/** @type {{ uid: string }} */ _arg) => getAccounts({ firebase }),
		{
			callOnChange: () => {
				if (!user.uid) {
					return;
				}
				return { uid: user.uid };
			},
			initialValue: [],
		},
	);
	const loading = ['initial', 'pending'].includes(accountsRequest.status);
	const error = accountsRequest.status === 'error' && accountsRequest.error;

	const currentAccountId =
		accountsRequest.data.length > 0 &&
		accountsRequest.data.find(
			(account) => account.accountId === localStorageAccountId,
		)
			? localStorageAccountId
			: undefined;

	const getAccountRequest = useDocumentPath(
		!loading &&
			!error &&
			user.isLoaded &&
			currentAccountId && {
				path: ['accounts', currentAccountId],
			},
	);

	const accounts = React.useMemo(
		() =>
			accountsRequest.data.map((account) =>
				account.accountId === currentAccountId && getAccountRequest.data
					? {
							...account,
							tier: getAccountRequest.data.tier,
							subscription: getAccountRequest.data.subscription,
					  }
					: account,
			),
		[accountsRequest.data, currentAccountId, getAccountRequest.data],
	);

	React.useEffect(() => {
		if (loading) {
			return;
		}
		const search = new URLSearchParams(location.search);

		if (!search.has('accountId')) {
			return;
		}

		const accountIdFromSearchParam = ensureExists(search.get('accountId'));

		const existingAccount = Boolean(
			accounts?.find(
				(account) => account.accountId === accountIdFromSearchParam,
			),
		);

		if (
			existingAccount &&
			localStorageAccountId !== accountIdFromSearchParam
		) {
			setCurrentAccountId(accountIdFromSearchParam);
		}
	}, [
		localStorageAccountId,
		history,
		location.search,
		setCurrentAccountId,
		accounts,
		loading,
	]);

	React.useEffect(() => {
		if (needToRefreshAccounts > 0) {
			refresh();
		}
	}, [refresh, needToRefreshAccounts]);

	React.useEffect(() => {
		if (
			!loading &&
			!error &&
			user?.accounts?.length !== undefined &&
			accounts?.length !== user?.accounts?.length
		) {
			setNeedToRefreshAccounts((value) => value + 1);
		}
	}, [accounts?.length, user?.accounts?.length, loading, error]);

	const methods = makeAccountsApi({
		firebase,
		currentAccountId,
		setCurrentAccountId,
		reloadAccounts: refresh,
	});

	let owned,
		current,
		accountId = currentAccountId;

	if (!loading && accounts.length > 0) {
		owned = accounts.filter((a) => a.owner === user.uid);
		/*
		  handle scenario when accountId is set to account that doesn't exist anymore
		*/
		current =
			accountId &&
			accounts.find((account) => account.accountId === accountId);
		if (!accountId || !current) {
			accountId = chooseDefaultAccountId({ owned, accounts });
			current = accounts.find(
				(account) => account.accountId === accountId,
			);
		}
	}

	React.useEffect(() => {
		if (!loading && currentAccountId !== accountId) {
			setCurrentAccountId(accountId);
		}
	}, [currentAccountId, setCurrentAccountId, accountId, loading]);

	React.useEffect(() => {
		if (!loading && user.isLoaded && !accountsLoadedOnce) {
			// both user and accounts have finished loading
			window.dataLayer.push({
				event: 'ANALYTICS/ACCOUNTS_LOADED_FIRST_TIME',
			});
			accountsLoadedOnce = true;
		}
	}, [loading, user.isLoaded]);

	React.useEffect(() => {
		const dataLayer = window.dataLayer || [];
		// send user and accountId to Google Analytics for Hotjar reporting.
		if (
			!loading &&
			current &&
			!dataLayer.find((data) => data.accountId === accountId)
		) {
			dataLayer.push({
				event: 'ANALYTICS/CURRENT_ACCOUNT_LOADED',
				accountId,
				accountRole: current.role,
			});
		}
	}, [loading, current, accountId]);

	const value =
		!user.isLoaded || !user.isAuthenticated
			? {
					...methods,
					refresh,
					currentAccount: undefined,
					currentAccountId,
					accounts: [],
					owned: [],
					error: user.error,
					isLoaded: false,
					loadingAccounts: true,
			  }
			: {
					...methods,
					refresh,
					currentAccountId,
					currentAccount: current,
					accounts: accounts || [],
					owned: owned || [],
					error,
					isLoaded: Boolean(!loading && current),
					/**
					 * TODO: This is to be split into:
					 * - useAccounts() - state related to user accounts -> Account[]
					 * - useCurrentAccount() - state related to current user account -> Account
					 * - useNeedsAccountCreated() - logic to determine if new account needs to be created
					 * - useAccountsApi() - API's only, minimum state
					 *
					 * And refactored to use useBackend or useAsyncFunction
					 *
					 * But for now:
					 * */
					loadingAccounts: loading,
					...(!loading &&
						!error && {
							needsAccountCreated: accounts.length === 0,
						}),
			  };

	return (
		<AccountsContext.Provider value={value}>
			{children}
		</AccountsContext.Provider>
	);
};
