import {
	Location,
	LocationDescriptor,
	LocationDescriptorObject,
	parsePath,
} from 'history';
import { fullPathFromLocation } from './fullPathFromLocation';
import { ContinueState, LaxContinueState } from './types';

export function continuePathFromLocation(
	location: Location<unknown> | Location<LaxContinueState>,
): LocationDescriptor<ContinueState> | undefined {
	if (!location.state) {
		return;
	}

	const state = location.state as LaxContinueState;

	if ('from' in state && state.from) {
		return fullPathFromLocation(state.from);
	}

	if (!state.continuePath) {
		return undefined;
	}

	const result = {
		// there is no way parsePath can initialize state, state is not serializable
		...(parsePath(state.continuePath) as Omit<
			LocationDescriptorObject<LaxContinueState>,
			'state'
		>),
		// so we can override state if needed
		...(state?.returnPath && {
			state: {
				// preserve returnPath for when we bumped off a route twice
				returnPath: state.returnPath,
			},
		}),
	};

	return result;
}
