import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { CssBaseline, ThemeProvider, createTheme } from '@material-ui/core';
import { Router } from 'react-router-dom';
import { initFirebase } from '../firebase/init';
import { appTheme } from '../styles';
import { CurrentUserProvider, ConfigProvider } from '../contexts';
import { createFirestoreInstance } from 'redux-firestore';
import { oldRemoteSocialTheme } from '../styles/oldRsTheme';
import { ErrorBoundary } from './errorBoundary';
import { AccountsProvider } from '../contexts/Account';
import { history } from './history';

const rffConfig = {};

const BaseProvidersInternals = ({
	store,
	appConfig,
	theme /* allow for overriding the base theme temporarily on per-app basis */,
	children,
}) => {
	const appliedTheme = useMemo(
		() =>
			theme ||
			(appConfig &&
				createTheme(appTheme(appConfig)(oldRemoteSocialTheme))),
		[appConfig, theme],
	);

	const [firebase, setFirebase] = React.useState();
	const [error, setError] = React.useState();

	React.useEffect(() => {
		initFirebase()
			.then((firebase) => {
				setFirebase(firebase);
			})
			.catch((err) => {
				setError(err);
			});
	}, [setError, setFirebase]);

	React.useEffect(() => {
		// propagate up to error boundary, this is unlikely to throw,
		// but if this throws - it's a critical error
		if (error) {
			throw error;
		}
	}, [error]);

	return (
		<Provider store={store}>
			{firebase && (
				<ReactReduxFirebaseProvider
					firebase={firebase}
					config={rffConfig}
					dispatch={store.dispatch}
					createFirestoreInstance={createFirestoreInstance}
				>
					<Router history={history}>
						<CurrentUserProvider>
							<AccountsProvider>
								<ConfigProvider config={appConfig}>
									<ThemeProvider theme={appliedTheme}>
										<CssBaseline />
										{children}
									</ThemeProvider>
								</ConfigProvider>
							</AccountsProvider>
						</CurrentUserProvider>
					</Router>
				</ReactReduxFirebaseProvider>
			)}
		</Provider>
	);
};

export const BaseProviders = (props) => {
	return (
		<ErrorBoundary>
			<BaseProvidersInternals {...props} />
		</ErrorBoundary>
	);
};
