import React from 'react';
import { ListItemIcon, MenuItem, Select, Typography } from '@material-ui/core';
import { AccountResponse } from '@contracts/platform/accounts/account';
import { AccountAvatar } from './accountAvatar';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
	return createStyles({
		menuButton: {
			display: 'flex',
			alignItems: 'center',
			marginRight: theme.spacing(2),
			textTransform: 'none',
		},
		nameWrapper: { display: 'flex', alignItems: 'center' },
		photo: { minWidth: theme.spacing(5.5) },
		name: {
			display: 'inline-block',
			marginLeft: theme.spacing(1),
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: '10ch',
			whiteSpace: 'nowrap',

			[theme.breakpoints.up('md')]: {
				maxWidth: '20ch',
			},
		},
		menuItemContent: { width: '100%' },
	});
});

type Props = {
	selectedAccountId: string;
	accounts: AccountResponse[];
	onChange: ({
		account,
		isNew,
	}: {
		account: AccountResponse;
		isNew: boolean;
	}) => void;
};

export const AccountSwitcher = (props: Props) => {
	const styles = useStyles();
	const { selectedAccountId, accounts, onChange } = props;

	const handleSelect = React.useCallback(
		(e) => {
			const value = e.target.value;
			if (value !== '__none') {
				const account = accounts.find(
					(account) => account.accountId === value,
				);
				if (account) {
					onChange({
						account,
						isNew: false,
					});
				}
			}
		},
		[accounts, onChange],
	);

	if (!accounts || accounts.length === 0) {
		return <></>;
	}

	return (
		<Select
			fullWidth
			variant="outlined"
			value={selectedAccountId || '__none'}
			onChange={handleSelect}
			SelectDisplayProps={{ style: { display: 'flex' } }}
		>
			<MenuItem value="__none" className={styles.menuButton}>
				Select a team...
			</MenuItem>
			{accounts.map((account) => (
				<MenuItem
					key={account.accountId}
					value={account.accountId}
					className={styles.menuButton}
				>
					<ListItemIcon className={styles.photo}>
						<AccountAvatar account={account} />
					</ListItemIcon>

					<div className={styles.nameWrapper}>
						<Typography variant="body1" className={styles.name}>
							{account.name}
						</Typography>
					</div>
				</MenuItem>
			))}
		</Select>
	);
};
