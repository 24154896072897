import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as MuiLink, LinkProps } from '@material-ui/core';
import type { ContinueState } from '../routes/types';

type Props = LinkProps<ReactRouterLink<ContinueState>>;

export const Link: React.ComponentType<Props> = (props) => (
	<MuiLink
		{...props}
		component={ReactRouterLink as ReactRouterLink<ContinueState>}
	/>
);
