import { buildContinueLocation } from './buildContinueLocation';
import {
	ContinueOpts,
	NoContinuePathOpts,
	RouteInformation,
	ContinueState,
} from './types';
import { LocationDescriptorObject } from 'history';

function buildRouteBasic<Path extends string, Opts, State>(
	path: Path,
	builder: (opts: Opts) => LocationDescriptorObject<State>,
): RouteInformation<Path, Opts, State> {
	return Object.assign(builder, { path });
}

export function buildRoute<
	Path extends string,
	Opts extends {},
	State extends {},
>(path: Path, builder?: (opts: Opts) => LocationDescriptorObject<State>) {
	return buildRouteBasic<
		Path,
		NoContinuePathOpts & Opts,
		ContinueState & State
	>(path, (opts: NoContinuePathOpts & Opts) => {
		const continueLocation = buildContinueLocation({
			destinationPath: path,
			opts,
		});
		const builtLocation = builder ? builder(opts) : undefined;
		return {
			pathname: builtLocation?.pathname ?? continueLocation.pathname,
			state: Object.assign(
				{},
				builtLocation?.state,
				continueLocation.state,
			),
			...(builtLocation?.hash && {
				hash: builtLocation.hash,
			}),
			...(builtLocation?.search && {
				search: builtLocation.search,
			}),
		};
	});
}

export function buildJumpRoute<Path extends string, Opts, State>(
	path: Path,
	builder?: (opts: Opts) => LocationDescriptorObject<State>,
) {
	return buildRouteBasic<Path, ContinueOpts & Opts, ContinueState & State>(
		path,
		(opts: ContinueOpts & Opts) => {
			const continueLocation = buildContinueLocation({
				destinationPath: path,
				opts,
			});
			const builtLocation = builder ? builder(opts) : undefined;
			return {
				pathname: builtLocation?.pathname ?? continueLocation.pathname,
				state: Object.assign(
					{},
					builtLocation?.state,
					continueLocation.state,
				),
				...(builtLocation?.hash && {
					hash: builtLocation.hash,
				}),
				...(builtLocation?.search && {
					search: builtLocation.search,
				}),
			};
		},
	);
}

export function buildSearchParams(params: Record<string, string>) {
	return `?${new URLSearchParams(params).toString()}`;
}

export function createRoutes<R>(routes: R): Readonly<R> {
	return Object.freeze(routes);
}
