import { Button, ButtonProps } from '@material-ui/core';
import { LoadingButtonIndicator } from './loadingButtonIndicator';

type Props = ButtonProps & {
	loading: boolean;
};

export const LoadingButton: React.ComponentType<Props> = ({
	loading,
	children,
	...rest
}) => (
	<Button {...rest} disabled={rest.disabled || loading}>
		<LoadingButtonIndicator loading={loading}>
			{children}
		</LoadingButtonIndicator>
	</Button>
);
