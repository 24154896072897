import { AccountRole } from '@contracts/auth';
import { useCurrentAccount } from '../contexts';

type Props = {
	roles: AccountRole[];
	accountId?: string;
};
type State = {
	status: 'pending' | 'not-allowed' | 'allowed';
};
export const useRolesCheck = ({ roles, accountId }: Props): State => {
	const { isLoaded, needsAccountCreated, currentAccount, accounts } =
		useCurrentAccount();

	if (!isLoaded) {
		return { status: 'pending' };
	}

	// === true because it can be undefined
	if (needsAccountCreated === true) {
		// it's not responsibility of this component to redirect to /create-account
		// it's handled as a common concern in a hook
		return { status: 'not-allowed' };
	}

	const account = accountId
		? accounts?.find((account) => account.accountId === accountId)
		: currentAccount;

	if (!account) {
		return { status: 'not-allowed' };
	}

	const { role } = account;

	if (!roles.includes(role)) {
		return { status: 'not-allowed' };
	}

	return { status: 'allowed' };
};
