import type { ValueTypesOf } from './valueTypesOf';

export const mapOf = <Arr extends readonly string[]>(
	arr: Arr,
): Record<ValueTypesOf<Arr>, ValueTypesOf<Arr>> => {
	return arr.reduce(
		(acc, key) => ({ ...acc, [key]: key }),
		{} as Record<ValueTypesOf<Arr>, ValueTypesOf<Arr>>,
	);
};
