import React from 'react';
import {
	ListChildComponentProps,
	VariableSizeList,
	VariableSizeListProps,
	areEqual,
} from 'react-window';
import Measure, { ContentRect } from 'react-measure';
import mergeRefs from 'react-merge-refs';

type ChildProps<T> = Omit<ListChildComponentProps<T>, 'style'> & {
	isFirstMeasurement: boolean;
	measuredHeight?: number;
};

type Props<T> = Omit<VariableSizeListProps<T>, 'itemSize' | 'children'> & {
	children: React.ComponentType<ChildProps<T> & React.RefAttributes<any>>;
};

/**
 * Implementation:
 * Just in time measure of visible elements.
 * Measuring done using react-measure.
 *
 * - Great for initially empty lists
 * - Ok in other cases - elements would appear to be jumping (layout shifts)
 *   the first time they are measured if the estimated size is not equal to the
 *   size we got from measurement
 * - Supports elements that can change size dynamically (expand/collapse)
 *
 * Disadvantages:
 * - Leads to reflows and measuring/remeasuring churn until scrolling to
 *   the bottom element is complete for non-empty lists; e.g. making a call
 *   to reset
 */
function createList<T>() {
	// this is a factory function to make it easier to type generics
	// based on the T parameter

	const component = React.forwardRef<VariableSizeList<T>, Props<T>>(
		({ children, ...rest }, ref) => {
			const listRef = React.useRef<VariableSizeList<T>>(null);
			const sizeCache = React.useMemo(
				() => new Map<number, number>(),
				[],
			);

			const estimatedItemSize = rest.estimatedItemSize ?? 50;
			const setHeight = React.useCallback(
				(index: number, height: number) => {
					const previous = sizeCache.get(index) ?? estimatedItemSize;
					sizeCache.set(index, height);
					if (previous === height) {
						// prevent unnecessary reset
						return;
					}
					listRef.current?.resetAfterIndex(index);
				},
				[sizeCache, estimatedItemSize],
			);

			const ChildRow = children;

			const AutoMeasureRow = React.useMemo(() => {
				//
				const AutoMeasureRow: React.ComponentType<
					ListChildComponentProps<T>
				> = React.memo((props) => {
					const index = props.index;
					const [isFirstMeasurement, setIsFirstMeasurement] =
						React.useState(sizeCache.get(index) === undefined);
					const calculationsRef = React.useRef(0);
					const onResize = React.useCallback(
						(contentRect: ContentRect) => {
							calculationsRef.current += 1;
							// first calculation is the result of onMount - we ignore that
							if (
								calculationsRef.current > 1 &&
								contentRect.bounds
							) {
								setHeight(index, contentRect.bounds.height);
								setIsFirstMeasurement(false);
							}
						},
						[index],
					);
					const measuredHeight = sizeCache.get(index);
					return (
						<Measure onResize={onResize} bounds>
							{({ measureRef }) => {
								const { style, ...rest } = props;
								return (
									<div style={style}>
										<ChildRow
											{...rest}
											ref={measureRef}
											isFirstMeasurement={
												isFirstMeasurement
											}
											measuredHeight={measuredHeight}
										/>
									</div>
								);
							}}
						</Measure>
					);
				}, areEqual);

				return AutoMeasureRow;
			}, [ChildRow, setHeight, sizeCache]);

			const memoizedRef = React.useMemo(
				() => mergeRefs([ref, listRef]),
				[ref, listRef],
			);

			const itemSize = React.useCallback(
				(index: number) => sizeCache.get(index) ?? estimatedItemSize,
				[sizeCache, estimatedItemSize],
			);

			return (
				<VariableSizeList<T>
					{...rest}
					itemSize={itemSize}
					ref={memoizedRef}
				>
					{AutoMeasureRow}
				</VariableSizeList>
			);
		},
	);
	component.displayName = 'JustInTimeAutoMeasureList';
	return component;
}

export type JustInTimeAutoMeasureRow<T> = React.ComponentType<
	ChildProps<T> & React.RefAttributes<HTMLElement>
>;

export const createAutoMeasuredRow = <T, E = HTMLDivElement>(
	fn: React.ForwardRefRenderFunction<E, ChildProps<T>>,
) => {
	return React.memo(React.forwardRef(fn), areEqual);
};

export const JustInTimeAutoMeasureList = createList() as <T>(
	props: Props<T> & React.RefAttributes<VariableSizeList<T>>,
) => JSX.Element;
