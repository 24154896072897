import React, { useMemo } from 'react';
import { SocialLogin } from './socialLogin';
import { Button, DocumentHead, Link, RemoteSocialLogo } from '../../components';
import { useAuth, useMountedRef, useQuery } from '../../hooks';
import { Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { animated, useSpring } from 'react-spring';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './OnboardingStyle';
import { contentAnimation, gifAnimation } from './animation';
import { ErrorMessage } from '../../components/errorMessage';
import { isEmail } from '../../utils/validation';
import { registerError } from '../../errors';
import { continuePathFromLocation } from '../continuePathFromLocation';
import { routes } from '../routes';

export default function Register() {
	const classes = useStyles();
	const auth = useAuth();
	const location = useLocation();
	const contentSpring = useSpring(contentAnimation);
	const gifSpring = useSpring(gifAnimation);
	const history = useHistory();

	const [credentials, setCredentials] = React.useState({
		email: '',
		password: '',
	});
	const [loading, setLoading] = React.useState(false);
	const [authError, setAuthError] = React.useState(null);
	const nextUrl =
		continuePathFromLocation(location) || routes.home({ location });

	const query = useQuery();

	const utmCampaign = query.get('utm_campaign');
	const utmSource = query.get('utm_source');
	const utmMedium = query.get('utm_medium');

	/**
	 * Pull out information from landing page
	 * @type {import('../../../../contracts/src/platform').UserMarketing}
	 * */
	const userAcquisitionData = useMemo(() => {
		return {
			utmCampaign: utmCampaign ?? undefined,
			utmSource: utmSource ?? undefined,
			utmMedium: utmMedium ?? undefined,
		};
	}, [utmMedium, utmSource, utmCampaign]);

	const handleChange = (event) => {
		setCredentials({
			...credentials,
			[event.target.id]: event.target.value,
		});
	};

	// TODO: it's better we use useAsyncFunction here
	// and avoid triggering side-effects from async code
	const mounted = useMountedRef();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!isEmail(credentials.email)) {
			setAuthError('Email is not valid');
			return;
		} else {
			setAuthError(null);
		}

		setLoading(true);
		try {
			await auth.createUserWithEmailAndPassword({
				email: credentials.email,
				password: credentials.password,
				userAcquisitionData,
				continuePath: nextUrl,
			});
			mounted.current && history.push(nextUrl);
		} catch (error) {
			registerError(error);
			mounted.current && setAuthError(error);
		} finally {
			mounted.current && setLoading(false);
		}
	};

	return (
		<Grid container spacing={2}>
			<DocumentHead title="Sign Up" />
			<Hidden smDown>
				<Grid
					className={classes.videoContainer}
					item
					md={5}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<animated.div style={gifSpring}>
						<img
							className={classes.video}
							alt="Hello"
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/Hello.gif"
						/>
						<img
							className={classes.loginVector}
							alt=""
							src="https://storage.googleapis.com/remotesocial-io-asset-storage/hub/img/loginVector.png"
						/>
					</animated.div>
				</Grid>
			</Hidden>
			<Grid item xs={12} md={1} />
			<Grid item xs={12} md={5}>
				<animated.div style={contentSpring}>
					<Grid
						direction="column"
						container
						style={{ padding: '2rem' }}
					>
						<RemoteSocialLogo className={classes.brand} />
						<Grid item xs={12} align="center">
							<Typography
								component="h3"
								variant="h4"
								align="center"
								className={classes.loginTitle}
							>
								Sign up with
							</Typography>
						</Grid>
						<SocialLogin
							nextUrl={nextUrl}
							userAcquisitionData={userAcquisitionData}
						/>
						<form
							className={classes.form}
							noValidate
							onSubmit={handleSubmit}
						>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									type="email"
									id="email"
									label="Email address"
									name="email"
									autoFocus
									onChange={handleChange}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									type="password"
									id="password"
									label="Password"
									name="password"
									onChange={handleChange}
								/>
								<ErrorMessage error={authError} />
							</Grid>
							<Grid item xs={12}>
								<Button
									click={handleSubmit}
									text={
										loading
											? 'Signing you up...'
											: 'Sign up with email'
									}
									loading={loading}
									className={classes.registerBtn}
									align="center"
								/>
							</Grid>
						</form>
						<Grid item container direction="row">
							<Grid
								item
								align="center"
								xs={12}
								className={classes.loginHere}
							>
								Already have an account?{' '}
								<Link
									to={{
										pathname: '/login',
										state: { from: nextUrl },
									}}
								>
									Log in here
								</Link>
								.
							</Grid>
						</Grid>
						<Grid
							className={classes.terms}
							alignItems="center"
							container
							direction="column"
							spacing={0}
						>
							<Grid>By registering you agree to our </Grid>
							<Grid>
								<a
									className={classes.legalLinks}
									href="https://www.remotesocial.io/legal/terms-conditions"
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms of Use
								</a>{' '}
								and{' '}
								<a
									className={classes.legalLinks}
									href="https://www.remotesocial.io/legal/privacy"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
								.
							</Grid>
						</Grid>
					</Grid>
				</animated.div>
			</Grid>
		</Grid>
	);
}
