import { Redirect } from 'react-router-dom';
import {
	AuthenticatedHostRoute,
	AuthenticatedRoute,
	UnAuthenticatedRoute,
} from '../../routes';
import Auth from './Auth';
import Logout from './Logout';
import { SelectAccount } from './selectAccount';

function getAuthRoutes() {
	return [
		<Redirect
			key="/register/host"
			exact
			path="/register/host"
			to="/register"
		/>,
		<AuthenticatedRoute
			key="/logout"
			path="/logout"
			variant="Logout"
			component={Logout}
			redirectToPath="/login"
			/*
			 * When we redirect to /login (see above) we do not
			 * want to carry over /logout as continuePath
			 */
			redirectToPathAndIgnoreContinueUrl={true}
			containerVariant="single-column"
		/>,
		<AuthenticatedRoute
			key="/select-account"
			path="/select-account/:gameType"
			component={Auth}
			variant="SelectAccountLegacy"
			redirectToPath="/"
			containerVariant="single-column"
		/>,
		<AuthenticatedRoute
			key="/select-team"
			path="/select-team"
			component={Auth}
			variant="SelectAccount"
			redirectToPath="/"
			containerVariant="single-column"
		/>,
		<AuthenticatedHostRoute
			key="/create-first-account"
			path="/create-first-account"
			component={Auth}
			variant="Create"
			redirectToPath="/verify-email"
			isCreateFirstAccountRoute={true}
		/>,
		<AuthenticatedHostRoute
			key="/create-account"
			path="/create-account"
			component={Auth}
			variant="Create"
			redirectToPath="/verify-email"
			isCreateFirstAccountRoute={false}
		/>,
		<AuthenticatedRoute
			key="/verify-email"
			path="/verify-email"
			component={Auth}
			variant="VerifyEmail"
			redirectToPath="/"
		/>,
		<UnAuthenticatedRoute
			key="/onboard"
			path="/onboard"
			component={Auth}
			variant="Onboard"
			blocked={true}
		/>,
		<UnAuthenticatedRoute
			key="/handle-invite"
			path="/handle-invite"
			component={Auth}
			variant="HandleInvite"
			blocked={false}
		/>,
		<UnAuthenticatedRoute
			key="/login"
			path="/login"
			component={Auth}
			variant="Login"
			blocked={true}
		/>,
		<UnAuthenticatedRoute
			key="/reset-password"
			path="/reset-password"
			component={Auth}
			variant="ResetPassword"
			containerVariant="single-column"
		/>,
		<AuthenticatedRoute
			key="/register/upgrade"
			path="/register/upgrade"
			component={Auth}
			variant="RegisterUpgrade"
			redirectToPath="/onboard"
			containerVariant="single-column"
		/>,
		<UnAuthenticatedRoute
			key="/register"
			path="/register"
			component={Auth}
			variant="Register"
			blocked={true}
		/>,
		<UnAuthenticatedRoute
			key="/handle-link"
			path="/handle-link"
			component={Auth}
			variant="HandleLink"
			containerVariant="single-column"
		/>,
	];
}

export { Auth, SelectAccount, getAuthRoutes };
