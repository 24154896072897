import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useAsyncFunction } from './useAsyncFunction';
import { AsyncRequestTuple } from './useAsyncRequest';
import type { MergedApi } from '@contracts/api';
import type { RequestState } from '../store-tools/requestState';
import type {
	AsyncReturnType,
	BivariantSingleParameterAsyncFn,
} from '../utils/types';

type Param<K extends keyof MergedApi> = Parameters<MergedApi[K]>[0];
type Return<K extends keyof MergedApi> = AsyncReturnType<MergedApi[K]>;

type SingleParameterAsyncFn = BivariantSingleParameterAsyncFn;

type UnknownFunctionResult<I> = AsyncRequestTuple<
	RequestState<unknown, I>,
	(...params: unknown[]) => void
>;

type ResultWithParams<
	K extends keyof MergedApi,
	I = undefined,
> = AsyncRequestTuple<
	RequestState<Return<K>, I>,
	(...params: Parameters<MergedApi[K]>) => void
>;

type ResultNoParams<
	K extends keyof MergedApi,
	I = undefined,
> = AsyncRequestTuple<RequestState<Return<K>, I>, () => void>;

type UseCallable = {
	<K extends keyof MergedApi>(functionName: K): MergedApi[K];
	(functionName: string): SingleParameterAsyncFn;
};

export const useCallable: UseCallable = (functionName: string) => {
	const firebase = useFirebase();

	const fn = React.useMemo(() => {
		const fn = firebase.functions().httpsCallable(functionName);
		return (
			params: typeof functionName extends keyof MergedApi
				? Param<typeof functionName>
				: unknown,
		) => fn(params).then((data) => data.data);
	}, [firebase, functionName]);

	return fn;
};

type Falsy = '' | false | undefined | null;

type CallOnChangeFn<P> = P extends Falsy
	? () => Exclude<P, Falsy> | Falsy
	: () => P | Falsy;

type InitialValue<Fk extends keyof MergedApi, I> = I extends never[]
	? Return<Fk> extends unknown[]
		? Return<Fk>
		: I
	: I;

type UseBackendFunction = {
	<Fk extends keyof MergedApi, I>(
		functionName: Fk,
		opts?: {
			callOnChange?: undefined;
			initialValue?: I;
		},
	): ResultWithParams<Fk, InitialValue<Fk, I>>;
	<Fk extends keyof MergedApi, I>(
		functionName: Fk,
		opts: {
			callOnChange: CallOnChangeFn<Param<Fk>>;
			initialValue?: I;
		},
	): ResultNoParams<Fk, InitialValue<Fk, I>>;
	<Fk extends keyof MergedApi, I>(
		functionName: Fk,
		opts?: {
			callOnChange?: CallOnChangeFn<Param<Fk>>;
			initialValue?: I;
		},
	): ResultWithParams<Fk, InitialValue<Fk, I>>;
	<I = undefined>(
		functionName: string,
		opts?: {
			callOnChange?: () => unknown | undefined;
			initialValue?: I;
		},
	): UnknownFunctionResult<I>;
};

export const useBackendFunction: UseBackendFunction = <I>(
	functionName: string,
	opts?: {
		callOnChange?: (() => unknown) | undefined;
		initialValue?: I;
	},
): UnknownFunctionResult<I> => {
	const fn = useCallable(functionName);
	return useAsyncFunction<SingleParameterAsyncFn, I>(fn, opts);
};
