import React from 'react';
import clsx from 'clsx';
import {
	Avatar,
	createStyles,
	IconButton,
	makeStyles,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		avatar: {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
		},
		avatarName: {
			fontSize: 'inherit',
			color: 'inherit',
			fontWeight: 'inherit',
			maxWidth: 65,

			[theme.breakpoints.up('sm')]: {
				maxWidth: 150,
			},

			[theme.breakpoints.up('md')]: {
				maxWidth: 300,
			},
		},
		avatarImg: {
			order: -1,
		},
		avatarWrapper: {
			position: 'relative',
			zIndex: 1,
		},
		horizontal: {
			flexDirection: 'row',
		},
		vertical: {
			flexDirection: 'column',
			'& $avatarName': {
				maxWidth: '100%',
				order: 1,
			},
		},
	}),
);

type Props = {
	// TODO: Review this component, was converted from JS
	className?: string;
	user: {
		displayName?: string;
		photoURL?: string;
	};
	withName?: Boolean;
	size?: string;
	click?: () => void;
	orientation?: 'horizontal' | 'vertical';
};

export const UserAvatar = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			user,
			withName = true,
			size,
			click,
			orientation,
			className,
			children,
		},
		ref,
	) => {
		const classes = useStyles();

		const avatar = (
			<Avatar
				variant="rounded"
				src={user.photoURL}
				className={classes.avatarImg}
				style={{ ...(size && { width: size, height: size }) }}
				aria-label={user.displayName}
			/>
		);

		const button = (
			<IconButton
				aria-label="user avatar"
				aria-controls="menu-profile"
				aria-haspopup="true"
				onClick={click}
				color="inherit"
			>
				{avatar}
			</IconButton>
		);

		return (
			<div
				className={clsx(
					classes.avatar,
					classes[orientation || 'vertical'],
					className,
				)}
				ref={ref}
			>
				{withName && (
					<Typography className={classes.avatarName} noWrap>
						{user.displayName}
					</Typography>
				)}
				<div className={classes.avatarWrapper}>
					{click ? button : avatar}
					{children}
				</div>
			</div>
		);
	},
);
