import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { FluidImgContainer } from './fluidImgContainer';

type Props = {};

const useStyles = makeStyles((theme) =>
	createStyles({
		background: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: '#F5F6F8',
			borderRadius: theme.spacing(3),
			overflow: 'hidden',
		},
	}),
);

export const ImageBackground: React.ComponentType<Props> = ({ children }) => {
	const classes = useStyles();
	return (
		<div className={classes.background}>
			<FluidImgContainer>{children}</FluidImgContainer>
		</div>
	);
};
