import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Typography, makeStyles } from '@material-ui/core';

import { useMountedRef } from '../hooks';
import { Link as UILink } from '@material-ui/core';
import { ErrorMessage } from '@common';

import imgSlackMark from '../assets/images/slack-round.svg';
import imgRemyAvatar from '../assets/images/remy-avatar.png';

const useSlackURL = ({ accountID: accountId, redirectURL }) => {
	const [url, setURL] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const firebase = useFirebase();
	const mounted = useMountedRef();
	useEffect(() => {
		if (!url && !loading && !error) {
			setLoading(true);
			const fn = firebase
				.functions()
				.httpsCallable('platform-generateSlackOAuthURL');
			fn({
				accountID: accountId,
				redirectURI: new URL(
					redirectURL,
					window.location.origin,
				).toString(),
			})
				.then(
					({ data: url }) => mounted.current && setURL(url),
					(e) => mounted.current && setError(e),
				)
				.then(() => mounted.current && setLoading(false));
		}
	}, [loading, url]);
	return { url, loading, error };
};

const SLACK_AVATAR_SIZE = 112;
const imageOversize = 42; // there's a lot of whitespace in teh image I pulled from the Slack media kit...

const useStyles = makeStyles((theme) => ({
	slackMark: {
		margin: theme.spacing(8, 'auto'),
		width: SLACK_AVATAR_SIZE,
		height: SLACK_AVATAR_SIZE,
		borderRadius: SLACK_AVATAR_SIZE / 2,
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	avatarRow: {
		display: 'flex',
		padding: theme.spacing(1, 0),
		'& > *': {
			marginRight: theme.spacing(2),
		},
		marginBottom: theme.spacing(5),
	},
	link: {
		color: theme.palette.text.primary,
		fontWeight: 'bold',
	},
}));

export const ConnectToSlack = ({
	CTAButton = UILink,
	accountID,
	redirectURL,
}) => {
	const classes = useStyles();
	const { url: slackURL, error } = useSlackURL({
		accountID,
		redirectURL,
	});
	if (error && error.details?.code !== 'role-required') {
		return <ErrorMessage error={error} />;
	}
	return (
		<>
			<div className={classes.slackMark}>
				<img
					width={SLACK_AVATAR_SIZE + imageOversize}
					height={SLACK_AVATAR_SIZE + imageOversize}
					src={imgSlackMark}
					alt="Slack Avatar"
				/>
			</div>
			<div className={classes.avatarRow}>
				<img src={imgRemyAvatar} alt="Remy Avatar" />
				<Typography variant="body2">
					We’ll add Remy (our amazing bot) to your preferred Slack
					channel to manage the Physical Challenge announcements for
					you.
				</Typography>
			</div>
			{error ? (
				<Typography variant="body2">
					Only the owner of this account can link to Slack. Please ask
					them to set up the first challenge, or use{' '}
					<UILink
						className={classes.link}
						href="https://hub.remotesocial.io#default-account"
					>
						your own account
					</UILink>
					.
				</Typography>
			) : (
				<CTAButton href={slackURL} disabled={!slackURL}>
					Connect with Slack
				</CTAButton>
			)}
		</>
	);
};
