import * as Sentry from '@sentry/react';
import { ensureError } from './types';
import { isDevBuild } from '../environment';
import { registerErrorOnce } from './registerErrorOnce';
import type {
	CaptureContext as SentryCaptureContext,
	ScopeContext as SentryScopeContext,
	Scope,
} from '@sentry/types';

type ScopeContext = Omit<SentryScopeContext, 'level'> & {
	// simplify type imports so we don't have to always import Severity
	// from Sentry in order to set the level, below list matches the Sentry
	// constants
	level:
		| 'fatal'
		| 'error'
		| 'warning'
		| 'log'
		| 'info'
		| 'debug'
		| 'critical';
};

type CaptureContext = Scope | Partial<ScopeContext> | ((scope: Scope) => Scope);

/**
 * Register an error if you want to track its stats in Sentry
 * but you are going to swallow it. E.g.
 *
 * try {
 *   return await doThings();
 * } catch(err) {
 *   registerError(err); // <- do this unless you want to totally ignore this error
 *   return fallbackValue;
 * }
 *
 * @param error Caught error
 */
export function registerError(error: any, captureContext?: CaptureContext) {
	registerErrorOnce(error, () => {
		if (isDevBuild()) {
			console.error(error);
			return;
		}
		Sentry.captureException(error, (scope) => {
			const err = ensureError(error);
			if (err.code) {
				scope.setTag('errorCode', err.code);
			}
			return scope.update(captureContext as SentryCaptureContext);
		});
	});
}
