import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { actionTypes as rrfActionTypes } from 'react-redux-firebase';
import { constants as rfConstants } from 'redux-firestore';
import { AnyAction, Reducer } from './action';

const staticMiddlewares = [
	...getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [
				// just ignore every redux-firebase and react-redux-firebase action type
				...Object.keys(rfConstants.actionTypes).map(
					(type) => `${rfConstants.actionsPrefix}/${type}`,
				),
				...Object.keys(rrfActionTypes).map(
					(type) => `@@reactReduxFirebase/${type}`,
				),
			],
			ignoredPaths: ['firebase', 'firestore'],
		},
		thunk: false,
	}),
];

export const createStore = <S = unknown, A extends AnyAction = AnyAction>(
	reducer: Reducer<S, A>,
) => {
	const middleware = [...staticMiddlewares];
	const store = configureStore<S, A, typeof middleware>({
		reducer,
		middleware,
	});
	return store;
};
