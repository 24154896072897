import type firebase from 'firebase';
import { registerError } from '../errors/registerError';
import { isDevBuild, env } from '../environment';
import { setUser } from '../errors/setUser';
import { createApiCall } from './apiCall';
import { withAutoRetry } from './autoRetry';
import { onceAsync } from '../utils/once';
import { withLocalChaosMonkey } from './chaosMonkey';
import {
	getConfigValue,
	pageRefreshFlagKeys,
	remoteConfigDefaults,
} from './remoteConfig';

export type Firebase = typeof firebase;

export const initFirebase = onceAsync(async () => {
	// keep using pre-initialized firebase that is coming from __firebase/init.js (see public/index.html)
	const firebase = window.firebase as any as Firebase;
	if (isDevBuild() && firebase.apps.length > 0) {
		console.warn(
			`🚨 Any changes to Firebase initialization code during development requires page refresh and wont be effective otherwise
	this is limitation of Firebase which can be initialized just once.`,
		);
		return firebase.apps[0];
	}
	const config = env();

	firebase.initializeApp(config.firebaseConfig);

	const auth = firebase.auth();
	const firestore = firebase.firestore();
	const database = firebase.database();

	await initializeRemoteConfig(firebase);

	if (
		config.name !== 'local' &&
		getConfigValue(
			firebase,
			'proxyFirestoreThroughLoadBalancer',
		).asBoolean()
	) {
		firestore.settings({
			host: config.firebaseConfig.firestoreHostPath,
			merge: true,
		});
	}

	auth.onAuthStateChanged((user) => {
		setUser(
			user && {
				// do not send more than required
				uid: user.uid,
			},
		);
	});

	const functionsBaseUrl =
		config.name === 'local'
			? `http://${window.location.hostname}:5001/remotesocial-dev/us-central1`
			: config.firebaseConfig.functionsBaseUrl;

	const functions = monkeyPatchFunctionsWithCustomApiCall(
		firebase,
		functionsBaseUrl,
	);

	if (isDevBuild() && config.name === 'local') {
		console.warn('🚨  Using local emulators');

		const hostname = window.location.hostname;

		auth.useEmulator(`http://${hostname}:9099`);
		functions.useEmulator(hostname, 5001);
		database.useEmulator(hostname, 9000);
		firestore.settings({
			host: `${hostname}:8080`,
			ssl: false,
		});
	}

	auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

	if (
		!isDevBuild() &&
		config.name !== 'local' &&
		config.firebaseConfig.measurementId
	) {
		console.log('Using analytics', config.firebaseConfig.measurementId);
		firebase.analytics();
	}

	return firebase;
});

function valueOfFlagsThatTriggerPageReload(firebase: Firebase) {
	return pageRefreshFlagKeys
		.map((key) => `${key}: ${getConfigValue(firebase, key).asString()}`)
		.join(', ');
}

async function initializeRemoteConfig(firebase: Firebase) {
	try {
		firebase.remoteConfig().defaultConfig = { ...remoteConfigDefaults };

		// use preloaded config
		await firebase.remoteConfig().ensureInitialized();

		const before = valueOfFlagsThatTriggerPageReload(firebase);

		firebase.remoteConfig().settings = {
			fetchTimeoutMillis: 60_000,
			// force-refresh on page load, without this - it won't refresh
			// until 12 hours passed
			minimumFetchIntervalMillis: 1,
		};

		// NOTE: this is intentionally not awaited - we load the config asynchronously
		firebase
			.remoteConfig()
			.fetchAndActivate()
			.then((isNewConfigActivated) => {
				// fetch and activate would have triggered load of new config flags
				// but we already might have used some of the previous values - so
				// we should check if maybe we need to reload the page to make sure
				// all of them are used during initialization of the page
				const after = valueOfFlagsThatTriggerPageReload(firebase);
				// only if new config values are different
				if (isNewConfigActivated && before !== after) {
					console.log(
						'Detected changes to flags that require page refresh: ',
						after,
					);
					// NOTE: Reload the page to ensure the new flag values are effective
					// this should not lead to us loosing history.state
					// and this might look like a bad UX, but won't expected
					// to happen too often - only for special keys and only when they
					// change;
					window.location.reload();
				}
			})
			.catch((err) => {
				registerError(err);
			});
	} catch (err) {
		registerError(err);
	} finally {
		firebase.remoteConfig().settings = {
			fetchTimeoutMillis: 60_000,
			// reset back to 12 hours
			minimumFetchIntervalMillis: 12 * 60 * 60_000,
		};
	}
}

function createFunctions(
	firebase: Firebase,
	functionsBaseUrl?: string,
): firebase.functions.Functions {
	const fns = firebase.functions() as firebase.functions.Functions & {
		_url: (name: string) => string;
	};
	fns.httpsCallable = (name, options) => {
		const apiCall = withAutoRetry(
			withLocalChaosMonkey(
				createApiCall(firebase, {
					baseUrl: functionsBaseUrl,
				}),
			),
		);
		return (data) => {
			return apiCall(name, data, options);
		};
	};
	return fns;
}

function monkeyPatchFunctionsWithCustomApiCall(
	firebase: Firebase,
	functionsBaseUrl?: string,
) {
	const functions = createFunctions(firebase, functionsBaseUrl);

	// Now prevent from new instance of functions created
	const previousFunctions = firebase.functions.bind(firebase);
	const newFunctions: typeof previousFunctions = ((app) => {
		if (!app) {
			// typical usage of firebase functions in our app
			return functions;
		}
		// for future untypical usage assume default behavior Firebase
		// provides
		return previousFunctions(app);
	}) as typeof previousFunctions;
	firebase.functions = newFunctions.bind(firebase);

	return functions;
}
