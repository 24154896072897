const durationOptions = [
	{
		value: 'm15',
		label: 'For 15 minutes',
		data: { minutes: 15 },
	},
	{
		value: 'm30',
		label: 'For 30 minutes',
		data: { minutes: 30 },
	},
	{
		value: 'm45',
		label: 'For 45 minutes',
		data: { minutes: 45 },
	},
	{
		value: 'h1',
		label: 'For 1 hour',
		data: { hours: 1 },
	},
];

export default durationOptions;
