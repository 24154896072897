import React, { useCallback } from 'react';
import { useField } from 'formik';
import DataList from '../scheduling/DataList';

const DataListField = ({ name, ...rest }) => {
	const [, meta, helpers] = useField(name);
	const { value } = meta;

	const handleChange = useCallback(
		(value) => {
			helpers.setValue(value || [], true);
		},
		[helpers],
	);

	return <DataList {...rest} value={value} onChange={handleChange} />;
};

export default DataListField;
