import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';

type Props = {
	loading: boolean;
};

const useStyles = makeStyles((_theme) =>
	createStyles({
		fillParentContainer: {
			// regardless of what parent's display prop is
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			// center the icon within
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export const LoadingButtonIndicator: React.ComponentType<Props> = (props) => {
	const styles = useStyles();
	return (
		<>
			{props.children}
			{props.loading && (
				<div className={styles.fillParentContainer}>
					<CircularProgress size={'1.5rem'} />
				</div>
			)}
		</>
	);
};
