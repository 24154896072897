import { LocationDescriptor } from 'history';
import { fullPathFromLocation } from './fullPathFromLocation';
import { continuePathFromLocation } from './continuePathFromLocation';
import { isLocation } from './isLocation';

export function isLogoutContinueParameter(
	fromOrPathname: LocationDescriptor | undefined,
): boolean {
	if (!fromOrPathname) {
		return false;
	}
	const fullPath = fullPathFromLocation(fromOrPathname);
	if (fullPath.startsWith('/logout')) {
		return true;
	} else if (isLocation(fromOrPathname)) {
		const continuePath = continuePathFromLocation(fromOrPathname);
		return continuePath
			? fullPathFromLocation(continuePath).startsWith('/logout')
			: false;
	}
	return false;
}
