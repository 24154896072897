import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { TextField } from '@material-ui/core';

const TextInput = ({ value, onChange, debounce, ...rest }) => {
	const [text, setText] = useState(value);
	const [debouncedValue] = useDebounce(text, 400);

	const handleChange = (evt) => {
		if (debounce) {
			setText(evt.target.value);
		}
	};

	const onChangeText = (evt) => {
		onChange(evt.target.value || '');
	};

	useEffect(() => {
		if (debounce) {
			onChange(debouncedValue || '');
		}
	}, [debouncedValue, debounce]);

	useEffect(() => {
		setText(value);
	}, [value]);

	return (
		<TextField
			{...rest}
			value={debounce ? text : value}
			onChange={debounce ? handleChange : onChangeText}
		/>
	);
};

export default TextInput;
