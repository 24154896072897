import { isDevBuild } from './isDevBuild';
import { EnvName } from './types';

const envNameByHostname = new Map<string, EnvName>([
	['localhost', 'local'],
	['127.0.0.1', 'local'],
]);

const localIp = process.env['REACT_APP_LOCAL_IP'];

if (isDevBuild() && localIp) {
	envNameByHostname.set(localIp, 'local');
}

const stagingRegExp = /[^.].remotesocial.app/;
const prodRegExp = /[^.].remotesocial.io/;

export const envNameFromHostname = (hostname: string): EnvName => {
	const envName = envNameByHostname.get(hostname);
	if (envName) {
		return envName;
	}
	if (stagingRegExp.test(hostname)) {
		return 'staging';
	}
	if (prodRegExp.test(hostname)) {
		return 'prod';
	}
	throw new Error('Cannot determine environment by hostname');
};
