import {
	CollectionArgs,
	CollectionArgsResultType,
	CollectionMap,
} from '@contracts/shared';
import { useFirestoreConnect } from 'react-redux-firebase';
import { ErrorType, registerError } from '../errors';
import { makeUseSelector } from './makeUseSelector';
import { FirebaseReduxState } from './firebase';
import { RequestState } from './requestState';
import React, { useMemo, useRef } from 'react';
import { useTimeoutState } from './useTimeoutState';

// allow falsy input to skip subscribing to Firestore while
// there is not enough parameters
type Falsy = false | '' | 0 | undefined | null;

type State<
	Schema extends CollectionMap,
	Args extends CollectionArgs<Schema>,
	I,
> = RequestState<CollectionArgsResultType<Schema, Args>, I>;

export const makeUseDocumentPath = <Schema extends CollectionMap>() => {
	const useDocumentPath = <
		Args extends CollectionArgs<Schema>,
		I = undefined,
	>(
		opts?:
			| {
					path: Args;
			  }
			| Falsy,
		config?: {
			default?: I;
			waitToExist?: boolean;
			timeout?: {
				milliseconds: number;
				with?: I;
				onTimeout?: () => void;
			};
		},
	): State<Schema, Args, I> => {
		const useSelector = makeUseSelector<FirebaseReduxState<any>>();

		const query = opts && pathToQuery<Schema, Args>(opts.path);

		// path to the data in redux store
		const path = (query && query.storeAs) || '';

		useFirestoreConnect(query || []);

		const error: ErrorType | undefined = useSelector(
			(state) =>
				(
					state.firestore.errors.byQuery as any as Record<
						string,
						ErrorType
					>
				)[path],
		);
		// will resolve to false even when data is undefined
		const requesting: boolean = useSelector(
			(state) => state.firestore.status.requesting[path],
		);
		// will resolve to null when document is not found
		const data: {} | null | undefined = useSelector(
			(state) => state.firestore.data[path] as any,
		);
		const loading =
			requesting || (config?.waitToExist ? !data : data === undefined);

		const isPathSpecified = Boolean(opts);

		const { timeoutError, timeoutValue } = useTimeoutState(
			config?.timeout && {
				disabledWhen: !isPathSpecified || Boolean(error) || !loading,
				milliseconds: config.timeout.milliseconds,
				timeoutWith: config.timeout.with,
				onTimeout: config.timeout.onTimeout,
			},
		);

		// memoize default value just once the
		// first time it initializes into something
		// other that falsy, ignore changes to default value
		const defaultVal = config?.default;
		const onceRef = useRef<I | undefined>(defaultVal);
		React.useEffect(() => {
			if (!onceRef.current && defaultVal) {
				onceRef.current = defaultVal;
			}
		}, [defaultVal]);

		const result = useMemo(() => {
			if (!isPathSpecified) {
				return {
					status: 'initial' as const,
					...(onceRef.current && {
						data: onceRef.current,
					}),
				};
			}

			if (error) {
				return {
					status: 'error' as const,
					error,
					...(onceRef.current && {
						data: onceRef.current,
					}),
				};
			}

			if (loading) {
				if (timeoutError) {
					return {
						status: 'error' as const,
						error: timeoutError,
						...(onceRef.current && {
							data: onceRef.current,
						}),
					};
				}
				if (timeoutValue) {
					return {
						status: 'error' as const,
						data: timeoutValue ?? onceRef.current,
					};
				}
				return {
					status: 'pending' as const,
					...(onceRef.current && {
						data: onceRef.current,
					}),
				};
			}

			return {
				status: 'success' as const,
				data: data ?? onceRef.current,
			};
		}, [isPathSpecified, error, loading, data, timeoutError, timeoutValue]);

		React.useEffect(() => {
			if (error) {
				registerError(error);
			}
		}, [error]);

		return result as State<Schema, Args, I>;
	};

	return useDocumentPath;
};

function pathToQuery<
	Schema extends CollectionMap,
	Args extends CollectionArgs<Schema>,
>(path: Args) {
	const storeAs = path.join('/');
	return {
		collection: path[0],
		...(path.length > 1 && {
			doc: path[1],
		}),
		...(path.length > 2 && {
			subcollections: path.slice(2).reduce((arr, collection, index) => {
				if (index % 2 === 1) {
					// skip document
					return arr;
				}
				const doc = path[2 + index + 1];
				return arr.concat([
					{
						collection,
						...(doc && {
							doc,
						}),
					},
				]);
			}, [] as Array<{ collection: string; doc?: string }>),
		}),
		storeAs: storeAs,
	};
}
