/** CurrentUser2
 * This context monitors the firebase auth and firestore
 * to return a user object with the user profile
 * when a user has successfully authenticated
 **/

import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { registerError } from '../errors';
import { useDocumentPath } from '../hooks/useDocumentPath';
// TODO: Cleanup - there is a little bit of a mess of a cycle here
// until we refactor the entire auth
import {
	useCrossDomainSync,
	useSynchronizeUserProfile,
} from '../hooks/useAuth';
import { useAsyncFunction, useCallable } from '../hooks';

/**
 * @typedef {{
 *   uid: string,
 *   displayName?: string,
 *   photoURL?: string,
 *   isAnonymous: boolean,
 *   email?: string,
 *   emailVerified: boolean,
 *   accounts: string[],
 * }} User
 */

/**
 * @typedef {{ refreshCurrentUser: () => void }} RefreshHack
 */

/**
 * @typedef {{ isLoaded: true; isAuthenticated: boolean; profile: boolean; } & User} LoadedUser
 */

/**
 * @typedef {{
 *     isLoaded: false;
 *     isAuthenticated: false;
 * 		 profile: boolean;
 *   } & Partial<Omit<User, 'isAuthenticated'>>
 * } LoadingUser
 */

/**
 * @typedef {RefreshHack & (LoadedUser | LoadingUser)} UseCurrentUserState
 */

/**
 * Temporary helper while we didn't migrate to TypeScript
 *
 * @type {React.Context<UseCurrentUserState>}
 */
const CurrentUserContext = React.createContext();

const firebaseUserToPlainState = (
	/** @type {import('@firebase/auth-types').User | null} */ firebaseUser,
) =>
	firebaseUser
		? {
				uid: firebaseUser.uid,
				displayName: firebaseUser.displayName,
				photoURL: firebaseUser.photoURL,
				email: firebaseUser.email,
				emailVerified: firebaseUser.emailVerified ?? false,
				isAnonymous: firebaseUser.isAnonymous,
				isLoaded: true,
				isEmpty: false,
		  }
		: {
				isLoaded: true,
				isEmpty: true,
		  };

export const CurrentUserProvider = ({ children }) => {
	const firebase = useFirebase();
	// this is mutable variable we should not rely on everywhere
	// we should instead listen to changes using onAuthStateChanged
	// which we do below and set to the state variable
	/**
	 * @type {ReturnType<typeof firebaseUserToPlainState>}
	 **/
	// @ts-expect-error
	const currentUserInitial = null;
	const [currentUser, setCurrentUser] = React.useState(currentUserInitial);
	// this is a hack that we should use when onAuthStateChanged below doesn't
	// trigger
	const [triggerCurrentUserRefresh, setTriggerCurrentUserRefresh] =
		React.useState(0);
	const refreshCurrentUser = React.useCallback(() => {
		setTriggerCurrentUserRefresh((index) => index + 1);
	}, [setTriggerCurrentUserRefresh]);
	React.useEffect(
		() =>
			firebase.auth().onAuthStateChanged(
				(user) => {
					setCurrentUser(firebaseUserToPlainState(user));
				},
				(err) => {
					registerError(err);
				},
			),
		[firebase],
	);
	React.useEffect(() => {
		if (triggerCurrentUserRefresh !== 0) {
			setCurrentUser(
				firebaseUserToPlainState(firebase.auth().currentUser),
			);
		}
	}, [firebase, triggerCurrentUserRefresh]);

	const crossDomainSync = useCrossDomainSync();
	const [initialSync, setInitialSync] = React.useState(false);

	const profileRequest = useDocumentPath(
		currentUser?.uid && {
			path: ['users', currentUser.uid],
		},
		{
			timeout: {
				milliseconds: 2000,
				onTimeout: () => {
					registerError(
						new Error('Loading profile has timed out after 2s'),
						{
							level: 'warning',
						},
					);
				},
			},
		},
	);

	const syncFromAuth = useCallable('platform-syncFromAuth');

	// ensure user document is finished creating before we continue
	const [syncRequest] = useAsyncFunction(
		async ({ shouldSynchronize }) => {
			if (!shouldSynchronize) {
				return;
			}
			await syncFromAuth();
		},
		{
			callOnChange: () => {
				// wait while profile is loaded
				if (['initial', 'pending'].includes(profileRequest.status)) {
					return;
				}
				const shouldSynchronize =
					(profileRequest.status === 'error' &&
						profileRequest.error.code === 'deadline-exceeded') ||
					(profileRequest.status === 'success' &&
						!profileRequest.data);

				return {
					shouldSynchronize,
				};
			},
		},
	);

	const result = React.useMemo(() => {
		if (!currentUser || !currentUser.isLoaded) {
			crossDomainSync.triggerLoginSync().catch((error) => {
				registerError(error);
			});
			return {
				uid: undefined,
				profile: false,
				isLoaded: false,
				isAuthenticated: false,
				refreshCurrentUser,
			};
		}

		if (currentUser && currentUser.isLoaded) {
			if (currentUser.isEmpty) {
				if (crossDomainSync.syncState !== 'login') {
					crossDomainSync
						.triggerLoginSync()
						.then(() => {
							setInitialSync(true);
						})
						.catch((error) => {
							registerError(error);
						});
				}

				return {
					uid: undefined,
					profile: false,
					isLoaded:
						initialSync &&
						!['pending'].includes(profileRequest.status),
					isAuthenticated: false,
					refreshCurrentUser,
				};
			}

			if (['initial', 'pending'].includes(profileRequest.status)) {
				return {
					uid: currentUser && currentUser.uid,
					profile: false,
					isLoaded: false,
					isAuthenticated: true,
					refreshCurrentUser,
				};
			}
		}

		if (crossDomainSync.syncState !== 'logout') {
			crossDomainSync.triggerLogoutSync();
		}

		return {
			displayName: currentUser.displayName,
			photoURL: currentUser.photoURL,
			...profileRequest.data,
			uid: currentUser.uid,
			profile: !!profileRequest.data,
			email: currentUser.email,
			// trust Firebase for emailVerified field first, but fallback to our field
			// to not break the experience for users who were verified by a custom DB update hack
			emailVerified:
				currentUser.emailVerified || profileRequest.data?.emailVerified,
			isLoaded: !['initial', 'pending'].includes(syncRequest.status),
			isAuthenticated: true,
			refreshCurrentUser,
		};
	}, [
		currentUser,
		profileRequest,
		syncRequest,
		initialSync,
		refreshCurrentUser,
	]);

	const profile = profileRequest.data;

	React.useEffect(() => {
		const dataLayer = window.dataLayer || [];
		if (
			currentUser &&
			currentUser.isLoaded &&
			profile &&
			!dataLayer.find((data) => data.uid === currentUser.uid)
		) {
			const userCreatedAt = profile.createdAt.toDate().toISOString();
			const userUpdatedAt = profile.updatedAt.toDate().toISOString();

			dataLayer.push({
				event: 'ANALYTICS/USER_LOADED',
				uid: currentUser.uid,
				userCreatedAt,
				userUpdatedAt,
			});
		}
	}, [currentUser, profile]);

	useSynchronizeUserProfile({
		isAuthenticated: result.isAuthenticated,
		userDoc: profile,
	});

	return (
		<CurrentUserContext.Provider value={result}>
			{children}
		</CurrentUserContext.Provider>
	);
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
