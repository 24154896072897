const onceFlag = Symbol('errorRegistered');

export function registerErrorOnce(error: any, register: () => void) {
	if (!error) {
		return;
	}
	if (error[onceFlag]) {
		return;
	}
	register();
	error[onceFlag] = true;
}
