import { apps, AppNames } from './types';

export const appName = (): AppNames => {
	const app = process.env['REACT_APP_NAME'] || 'hub';
	if (!app || !apps.includes(app)) {
		throw new Error(
			'Cannot determine app name, please run your app with REACT_APP_NAME env var set to the app name',
		);
	}
	return app as AppNames;
};
