import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import CrownIcon from './CrownIcon';
import { UserAvatar } from './userAvatar';
import { PlayerStatus } from '@contracts/platform';

import imgLoser from '../assets/images/loser.gif';

const useStyles = makeStyles((theme) => ({
	avatar: {
		fontSize: 11,
		maxWidth: 50,
	},
	players: {
		flex: 1,
		fontWeight: 500,
	},
	player: {
		opacity: 0.25,
		position: 'relative',
	},
	connected: {
		opacity: 1,
	},
	status: {
		fontSize: 30,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[`&.${PlayerStatus.winner}`]: {
			color: yellow[500],
			marginTop: theme.spacing(-6),
			marginRight: theme.spacing(-6),
			transform: 'rotate(45deg)',
		},
		'& > img': {
			maxWidth: '150%',
		},
	},
}));

/**
 * @param numPlayers {number}
 * @return {string}
 * */
const PlayerCount = ({ numPlayers }) => {
	if (numPlayers === 0) {
		return 'No Players';
	}

	return `${numPlayers} Player${numPlayers > 1 ? 's' : ''}`;
};

export default function GamePlayers({ players, className }) {
	const classes = useStyles();
	let numPlayers = 0;

	if (!Object.keys(players).length) {
		return null;
	}

	Object.keys(players).forEach((player) => {
		if (players[player].connected) numPlayers++;
	});

	return (
		<div className={className}>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h5" gutterBottom>
					Your crew
				</Typography>

				<Typography variant="h5" gutterBottom>
					<PlayerCount numPlayers={numPlayers} />
				</Typography>
			</Box>
			<Grid className={classes.players} container spacing={4}>
				{players &&
					Object.keys(players).map((id) => {
						const player = players[id];

						return (
							<Grid
								key={id}
								className={clsx(
									classes.player,
									player.connected && classes.connected,
								)}
								item
							>
								<UserAvatar
									withName={true}
									className={classes.avatar}
									user={player}
									size={50}
								>
									<div
										className={clsx(
											classes.status,
											player.status,
										)}
									>
										{player.status ===
											PlayerStatus.winner && (
											<CrownIcon
												color="inherit"
												fontSize="large"
											/>
										)}
										{(player.status ===
											PlayerStatus.loser ||
											player.status ===
												PlayerStatus.loser_disqualified) && (
											<img
												src={imgLoser}
												role="presentation"
												alt=""
											/>
										)}
									</div>
								</UserAvatar>
							</Grid>
						);
					})}
			</Grid>
		</div>
	);
}
