export const envNames = ['local', 'staging', 'prod'];

export type EnvName = 'local' | 'staging' | 'prod';

export const apps = [
	'hub',
	'bingo',
	'trivia',
	'physical',
	'connect',
	'checkin',
];

export type AppNames =
	| 'hub'
	| 'bingo'
	| 'trivia'
	| 'physical'
	| 'connect'
	| 'poker'
	| 'checkin';
