function fallbackCopyTextToClipboard(text: string) {
	return new Promise<void>((res, rej) => {
		const textArea = document.createElement('textarea');
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.position = 'fixed';

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			if (!document.execCommand('copy')) {
				throw new Error('Copying command returned falsy result');
			}
			res();
		} catch (err) {
			rej(err);
		} finally {
			document.body.removeChild(textArea);
		}
	});
}

export async function copyToClipboard(text: string) {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	await navigator.clipboard.writeText(text);
}
