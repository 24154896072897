import { ChipTextFieldChangeEventHandler } from '@remote-social/common';
import { catchError, UnreachableError } from '../errors';
import * as Yup from 'yup';
import { escapeRegExp } from '../utils';

const handleChangeNoValidation =
	(opts: {
		setInput: (input: string) => void;
		setChips: (chips: string[]) => void;
	}): ChipTextFieldChangeEventHandler =>
	(change) => {
		const { setInput, setChips } = opts;
		switch (change.operation) {
			case 'change': {
				setInput(change.nextInputValue);
				if (change.newChips) {
					setChips(change.nextChipsValue);
				}
				break;
			}
			case 'delete': {
				setChips(change.nextChipsValue);
				break;
			}
			default: {
				throw new UnreachableError(change);
			}
		}
	};

const validateChip = (validationSchema: Yup.StringSchema, chip: string) =>
	catchError(() => validationSchema.validateSync(chip));

const validateChips = (validationSchema: Yup.StringSchema, chips: string[]) => {
	let error: string | undefined;
	const validChips = [];
	const invalidChips = [];
	const transformed = [];
	for (const chip of chips) {
		// TODO: Consider another validation library because of the below
		// 🤦‍♂️ Yup devs think that throwing exceptions is a valid way to pass around data
		const result = validateChip(validationSchema, chip);
		if (result.error?.message && !error) {
			error = result.error.message;
		}
		if (result.error) {
			invalidChips.push(chip);
		} else if (result.data) {
			validChips.push(result.data);
			if (result.data !== chip) {
				// some validation schemas transform text
				transformed.push(chip);
			}
		}
	}
	return {
		validChips,
		transformed,
		invalidChips,
		error,
	};
};

const removeChipsFromCurrentValue = (opts: {
	currentValue: string;
	chipsValue: string[];
	delimiterRegExp: RegExp;
}) => {
	const { currentValue, chipsValue, delimiterRegExp } = opts;
	const existingChipsRegexp =
		chipsValue.length > 0 &&
		new RegExp(chipsValue.map((chip) => escapeRegExp(chip)).join('|'), 'g');
	const result = existingChipsRegexp
		? currentValue
				.replace(existingChipsRegexp, ' ')
				.replace(delimiterRegExp, ' ')
				.trim()
		: currentValue.replace(delimiterRegExp, ' ').trim();
	return result;
};

const handleChangeWithSchema =
	(opts: {
		validationSchema: Yup.StringSchema;
		setInput: (input: string) => void;
		setChips: (chips: string[]) => void;
		setError: (error: string) => void;
	}): ChipTextFieldChangeEventHandler =>
	(change) => {
		const { validationSchema, setError, setInput, setChips } = opts;
		switch (change.operation) {
			case 'change': {
				const { isSubmit, newChips, currentValue } = change;

				if (!isSubmit) {
					// allow users to enter any text
					setInput(currentValue);
					return;
				}

				const result = validateChips(validationSchema, newChips || []);

				const { validChips, transformed, error } = result;

				const chipsValue =
					validChips.length === 0
						? change.chipsValue
						: [...new Set([...validChips, ...change.chipsValue])];

				if (error) {
					setInput(
						removeChipsFromCurrentValue({
							...change,
							chipsValue: [
								// ensure we remove both value before transform and after transform
								...new Set([
									...validChips,
									...transformed,
									...change.chipsValue,
								]),
							],
						}),
					);
					setError(error);
				} else {
					setInput('');
					setError('');
				}

				if (validChips.length > 0) {
					setChips(chipsValue);
				}
				break;
			}
			case 'delete': {
				setChips(change.nextChipsValue);
				break;
			}
			default: {
				throw new UnreachableError(change);
			}
		}
	};

// Controlled validation of chip text input
export const useValidatedChipTextFieldState = (opts: {
	validationSchema?: Yup.StringSchema;
	setInput: (input: string) => void;
	setChips: (chips: string[]) => void;
	setError: (error: string) => void;
	delimiters?: string[];
}) => {
	const { validationSchema, setInput, setChips, setError } = opts;

	const onChange = !validationSchema
		? handleChangeNoValidation({
				setInput,
				setChips,
		  })
		: handleChangeWithSchema({
				validationSchema,
				setInput,
				setChips,
				setError,
		  });

	return {
		onChange,
	};
};
