import React from 'react';

// material-ui
import { Button as UIButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	wrapper: {
		textAlign: 'center',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: '-12px',
		marginLeft: '-12px',
	},
	submit: {},
}));

/** @deprecated use instead:
 * @material-ui/core/Button.tsx
 * linkButton.tsx
 * loadingButton.tsx
 * */
export default function Button({
	fullWidth = true,
	component,
	className,
	submit = true,
	loading,
	text,
	children,
	click,
	onClick,
	variant,
	disabled,
	href,
	to,
	type,
	nextUrl = '/',
	size,
	...props
}) {
	const classes = useStyles();
	const width = fullWidth ? '100%' : 'inherit';
	const submitButton = type && type !== 'submit' ? false : true;
	return (
		<div className={classes.wrapper} style={{ width }}>
			<UIButton
				{...props}
				type={type || 'submit'}
				style={{ width }}
				variant={variant || 'contained'}
				size={size || 'large'}
				disableElevation
				className={clsx(submitButton && classes.submit, className)}
				onClick={click || onClick}
				disabled={loading || disabled}
				component={
					component || (to ? RouterLink : href ? 'a' : undefined)
				}
				to={{
					pathname: to,
					state: {
						from: nextUrl,
					},
				}}
				href={href}
			>
				{text}
				{children}
				{loading && (
					<CircularProgress
						size={24}
						className={classes.buttonProgress}
					/>
				)}
			</UIButton>
		</div>
	);
}
