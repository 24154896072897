import { Joi } from './joi';
import type {
	SchemaOf,
	TypeOfSchema,
	ObjectSchemaWithUpdateShape,
	AnyObjectSchema,
	UpdateShapeOfSchema,
} from './joi';
import type { ValueTypesOfMap } from './valueTypesOf';
import { combinedKeysOfSchemas } from './schemaTransformation';
import { ensure } from './preconditions';

export type DiscriminatedUnionOpts<
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
> = {
	discriminator: Key;
	discriminatorValuesSchema: Joi.StringSchema;
	valueToSchema: Map;
};

/**
 * Helper factory function to create a generic DiscriminatedUnionOpts, doesn't
 * do much other than highlighting intent and assigning correct types to values
 */
export function discriminatedUnionOpts<
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(opts: DiscriminatedUnionOpts<Map, Key>) {
	return opts;
}

type AppendUnionSchema<A extends AnyObjectSchema, U> = U extends AnyObjectSchema
	? A extends ObjectSchemaWithUpdateShape<infer A_Shape, infer A_UpdateShape>
		? U extends ObjectSchemaWithUpdateShape<
				infer U_Shape,
				infer U_UpdateShape
		  >
			? ObjectSchemaWithUpdateShape<
					A_Shape & U_Shape,
					A_UpdateShape | (A_UpdateShape & U_UpdateShape)
			  >
			: ObjectSchemaWithUpdateShape<
					A_Shape & TypeOfSchema<U>,
					A_UpdateShape | (A_UpdateShape & TypeOfSchema<U>)
			  >
		: U extends ObjectSchemaWithUpdateShape<
				infer U_Shape,
				infer U_UpdateShape
		  >
		? ObjectSchemaWithUpdateShape<
				TypeOfSchema<A> & U_Shape,
				TypeOfSchema<A> | (TypeOfSchema<A> & U_UpdateShape)
		  >
		: Joi.ObjectSchema<TypeOfSchema<A> & TypeOfSchema<U>>
	: never;

function addDiscriminatorErrorInfo(
	discriminator: string,
	discriminatorValue: string | undefined,
	schema: Joi.AnySchema,
) {
	return schema.error((errors) => {
		const errorsWithExtraInfo = errors as Array<
			Joi.ErrorReport & {
				local: { label: string; value: unknown };
				prefs: Joi.ValidationOptions;
			}
		>;
		ensure(errorsWithExtraInfo[0], 'Should have at least one error');
		const { messages, code, local, state, prefs } = errorsWithExtraInfo[0];
		const codes = ['object.unknown', 'object.min', 'any.unknown'];
		const value =
			discriminatorValue != null
				? `"${discriminatorValue}"`
				: String(discriminatorValue);
		return schema.$_createError(code, local.value, local, state, prefs, {
			messages: Object.fromEntries(
				codes.map((code) => [
					code,
					`${String(messages[code])} given "${String(
						discriminator,
					)}" is ${value}`,
				]),
			),
		}) as Error;
	});
}

export function appendDiscriminatedUnionSchema<
	Schema extends AnyObjectSchema,
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(
	schemaOrSchemaMap: Schema,
	opts: DiscriminatedUnionOpts<Map, Key>,
): AppendUnionSchema<Schema, ValueTypesOfMap<Map>> {
	const { discriminator, discriminatorValuesSchema, valueToSchema } = opts;
	const schema = Joi.compile(schemaOrSchemaMap) as Joi.ObjectSchema<{}>;
	return schema
		.append({
			[discriminator]: discriminatorValuesSchema.required().alter({
				update: (schema) => schema.optional(),
			}),
		})
		.when(`.${String(discriminator)}`, [
			...Object.entries<AnyObjectSchema>(valueToSchema).map(
				([value, schema]) => ({
					is: value,
					then: addDiscriminatorErrorInfo(
						String(discriminator),
						value,
						Joi.compile(schema),
					),
				}),
			),
			{
				is: Joi.any(),
				then: addDiscriminatorErrorInfo(
					String(discriminator),
					undefined,
					Joi.object().keys(
						// forbid all keys from the union to be used
						// when timing is undefined
						Object.fromEntries(
							combinedKeysOfSchemas({
								schemas: Object.values(valueToSchema),
								excludingKeys: [schema, discriminator],
							}).map((key) => [key, Joi.forbidden()]),
						),
					),
				),
			},
		]) as unknown as AppendUnionSchema<Schema, ValueTypesOfMap<Map>>;
}

function validateDiscriminatorValue<
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(
	value: unknown,
	opts: DiscriminatedUnionOpts<Map, Key>,
	validateShape: 'regular-shape' | 'update-shape',
): keyof Map | undefined {
	const discriminatorValidation = Joi.object({
		[opts.discriminator]:
			validateShape === 'regular-shape'
				? opts.discriminatorValuesSchema
				: opts.discriminatorValuesSchema.tailor('update'),
	}).validate(
		{
			[opts.discriminator]: value,
		},
		{
			errors: {
				stack: true,
			},
		},
	);
	if (discriminatorValidation.error) {
		throw discriminatorValidation.error;
	}
	const discriminatorValue = discriminatorValidation.value as {
		[P in Key]: keyof Map | undefined;
	};
	return discriminatorValue[opts.discriminator];
}

/**
 * Given a chunk of data that is combined with properties associated with
 * discriminated union - split properties into two groups - properties associated
 * with the `union` and `other` properties.
 *
 * When discriminator is undefined this function returns the entire data structure as `other`
 */
export const splitPartialDiscriminatedUnionValue = <
	T extends
		| TypeOfSchema<ValueTypesOfMap<Map>>
		| Partial<UpdateShapeOfSchema<ValueTypesOfMap<Map>>>,
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(
	fromValue: T,
	opts: DiscriminatedUnionOpts<Map, Key>,
): {
	union?: UpdateShapeOfSchema<ValueTypesOfMap<Map>>;
	other?: Pick<
		T,
		Exclude<keyof T, keyof UpdateShapeOfSchema<ValueTypesOfMap<Map>>>
	>;
} => {
	const discriminatorValue = validateDiscriminatorValue(
		fromValue[opts.discriminator as unknown as keyof T],
		opts,
		'update-shape',
	);

	if (!discriminatorValue) {
		return {
			union: undefined,
			other: fromValue,
		};
	}

	const schema = opts.valueToSchema[discriminatorValue];
	if (!schema) {
		throw new Error(
			'Invalid discriminator value: ' + String(discriminatorValue),
		);
	}

	const result = (
		Joi.compile(schema).tailor('update') as Joi.ObjectSchema<
			UpdateShapeOfSchema<ValueTypesOfMap<Map>>
		>
	).validate(fromValue, {
		stripUnknown: true,
		errors: {
			stack: true,
		},
	});
	if (result.error) {
		throw result.error;
	}

	return {
		union: result.value,
		other: Object.fromEntries(
			Object.entries(fromValue as {}).filter(
				([key]) => !(key in result.value),
			),
		) as unknown as Pick<
			T,
			Exclude<keyof T, keyof UpdateShapeOfSchema<ValueTypesOfMap<Map>>>
		>,
	};
};

export const splitDiscriminatedUnionValue = <
	T extends TypeOfSchema<ValueTypesOfMap<Map>>,
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(
	fromValue: T,
	opts: DiscriminatedUnionOpts<Map, Key>,
): {
	union?: TypeOfSchema<ValueTypesOfMap<Map>>;
	other?: Pick<T, Exclude<keyof T, keyof TypeOfSchema<ValueTypesOfMap<Map>>>>;
} => {
	const discriminatorValue = validateDiscriminatorValue(
		fromValue[opts.discriminator],
		opts,
		'regular-shape',
	);

	if (!discriminatorValue) {
		return {
			union: undefined,
			other: fromValue,
		};
	}

	const schema = opts.valueToSchema[discriminatorValue];
	if (!schema) {
		throw new Error(
			'Invalid discriminator value: ' + String(discriminatorValue),
		);
	}

	const result = (
		Joi.compile(schema) as Joi.ObjectSchema<
			TypeOfSchema<ValueTypesOfMap<Map>>
		>
	).validate(fromValue, {
		stripUnknown: true,
		errors: {
			stack: true,
		},
	});
	if (result.error) {
		throw result.error;
	}

	return {
		union: result.value,
		other: Object.fromEntries(
			Object.entries(fromValue as {}).filter(
				([key]) => !(key in result.value),
			),
		) as unknown as Pick<
			T,
			Exclude<keyof T, keyof TypeOfSchema<ValueTypesOfMap<Map>>>
		>,
	};
};

export function combineUpdatesWithDiscriminatedUnionValue<
	T extends object & TypeOfSchema<ValueTypesOfMap<Map>>,
	U extends object & Partial<UpdateShapeOfSchema<ValueTypesOfMap<Map>>>,
	Map extends Record<string, Joi.ObjectSchema<unknown> | SchemaOf<unknown>>,
	Key extends keyof TypeOfSchema<ValueTypesOfMap<Map>>,
>(
	existingValue: T,
	update: U | undefined,
	opts: DiscriminatedUnionOpts<Map, Key>,
): T {
	if (!update) {
		return existingValue;
	}
	const existingDiscriminatorValue = validateDiscriminatorValue(
		existingValue[opts.discriminator],
		opts,
		'regular-shape',
	);
	const updatedDiscriminatorValue = validateDiscriminatorValue(
		update[opts.discriminator as keyof U],
		opts,
		'update-shape',
	);

	if (
		existingDiscriminatorValue === updatedDiscriminatorValue ||
		!updatedDiscriminatorValue
	) {
		// when discriminator is not updated - we assume none of the union props are updated
		return {
			...existingValue,
			...update,
		};
	} else {
		const schema = opts.valueToSchema[updatedDiscriminatorValue];
		if (!schema) {
			throw new Error(
				'Invalid discriminator value: ' +
					String(updatedDiscriminatorValue),
			);
		}

		const { other: existingOtherProps } = splitDiscriminatedUnionValue(
			existingValue,
			opts,
		);
		const { union: updateToUnion, other: updateToOtherProps } =
			splitPartialDiscriminatedUnionValue(update, opts);

		// when the value of discriminator changes - we have to provide all the mandatory properties

		const validatedUpdate = (
			Joi.compile(schema) as Joi.ObjectSchema<
				TypeOfSchema<ValueTypesOfMap<Map>>
			>
		).validate(updateToUnion, {
			errors: {
				stack: true,
			},
		});
		if (validatedUpdate.error) {
			throw validatedUpdate.error;
		}

		return {
			...existingOtherProps,
			...updateToOtherProps,
			...(validatedUpdate.value as object),
		} as T;
	}
}
