import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { AnimatedBackgroundContext } from '@common/contexts';
import clsx from 'clsx';
import { debounce } from '../utils';
import { ResizeObserver } from '../utils/resizeObserver';

const useStyles = makeStyles(() => ({
	root: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	blur: {
		filter: 'blur(5px)',
	},
	blurBG: {
		filter: 'blur(50px)',
	},
}));

// use these colors for the shapes in the absence of any other setting
const DEFAULT_COLORS = [
	(opacity) => `hsla(338, 50%, 47%, ${opacity})`,
	(opacity) => `hsla(36, 68%, 62%,  ${opacity})`,
	(opacity) => `hsla(185, 26%, 49%, ${opacity})`,
	(opacity) => `hsla(271, 35%, 47%, ${opacity})`,
];

// use these shapes in the absence of any other
const shapes = [
	// quarter ring
	'M17.245 54.231l.027-.001c16.101-.728 29.745 11.733 30.473 27.834.007.147.005.29.009.437l36.66-1.659c-.005-.146-.002-.29-.008-.436-1.645-36.348-32.444-64.48-68.793-62.837l-.027.003 1.659 36.66z', // arc
	// circle
	'M54.7 36.997c7.294 2.083 10.877 8.774 8.803 16.435-1.996 7.355-10.388 12.27-17.469 10.227-7.005-2.018-11.6-10.182-9.683-17.047 1.993-7.14 8.343-13.35 18.35-9.615', // ball
	// pill shape
	'M92.934 50.578c-2.74 7.577-8.577 11.437-16.09 12.95-15.296 3.076-30.677 5.747-45.94 8.982-15.37 3.259-27.583-9.435-24.025-22.81 2.101-7.902 8.034-11.315 14.483-12.792 17.328-3.973 34.789-7.943 52.42-9.82 12.8-1.363 22.957 9.544 19.152 23.49', // pill
	// half moon
	'M84.87 60.972c-10.576 17.645-26.548 24.51-46.548 23.326-4.348-.706-8.492-2.063-12.507-3.848-4.031-2.987-8.2-5.813-12.058-9.006-4.37-3.617-4.458-7.128.2-11.103 15.339-13.09 30.45-26.447 45.637-39.714 3.238-2.827 6.435-5.702 9.636-8.572 4.26-3.82 9.1-3.189 12.074 1.599 6.393 10.287 9.515 21.335 7.724 33.516-.675 4.816-2.373 9.32-4.159 13.802', // taco
	// triple stripe
	'M65.675 29.506c.46-2.577.937-5.154 4.815-3.95 3.495 1.084 6.084 2.436 5.3 6.712-2.196 11.957-4.403 23.914-6.565 35.878-1.078 5.967-2.075 11.946-3.086 17.929-.474 2.82-.708 5.987-4.683 5.72-4.035-.27-5.885-2.923-5.468-6.73.724-6.665 1.595-13.327 2.786-19.92 2.152-11.905 4.584-23.762 6.901-35.64v.001zM34.33 80.601c-.963 2.805-1.267 6.51-5.678 5.473-3.954-.934-4.972-4.263-4.46-7.685 1.343-9.005 3.035-17.96 4.636-26.928 1.585-8.863 3.86-17.668 4.573-26.597.357-4.484 2.907-4.63 5.243-4.43 2.873.247 5.98 1.444 5.229 5.763-1.719 9.87-3.222 19.78-4.943 29.649-1.438 8.27-3.057 16.503-4.6 24.755zM53.773 8.259c6.09-.507 9.123 1.653 7.963 8.338-2.784 16.079-5.342 32.192-7.994 48.29-.04.235-.05.484-.147.69-1.517 3.252-.176 9.368-6.072 8.458-6.003-.921-5.23-6.305-4.463-10.755 2.784-16.147 5.699-32.268 8.564-48.401.071-2.414.653-4.668 2.149-6.62z', // Lines
];

let state0 = 1;
let state1 = 2;
const xorshift128plus = () => {
	let s1 = state0;
	let s0 = state1;
	state0 = s0;
	s1 ^= s1 << 23;
	s1 ^= s1 >> 17;
	s1 ^= s0;
	s1 ^= s0 >> 26;
	state1 = s1;
	return state0 + state1;
};
Array.from(Array.from({ length: 47 })).forEach(xorshift128plus);

const random = () => Math.random(); //(xorshift128plus()/MAX_RAND);

function getRandomFloat(min, max) {
	return random() * (max - min) + min;
}

function getRandomInt(min, max) {
	return Math.floor(getRandomFloat(min, max));
}

/**
 * Notes: x & y are +/- canvasSize/2, even though canvas coordinates are between 0 and canvas size.
 *  This helps when thinking about angles, but needs correcting before we draw.
 */
class Shape {
	constructor(
		canvas,
		context,
		{
			foreground,
			opacity,
			sizeMultiplier,
			respawnArea,
			isPageLoad,
			colors,
		},
	) {
		this.canvas = canvas;
		this.context = context;
		this.foreground = foreground;
		this.opacity = opacity;
		this.path = new Path2D(shapes[Math.floor(random() * shapes.length)]);
		this.sizeMultiplier = sizeMultiplier || 1;
		this.respawnArea = respawnArea;
		this.colors = colors;

		this.reset(!isPageLoad);
	}

	get gutter() {
		return 100 * this.z; // how many pixels off screen the shape will need to travel before being recycled;
	}

	get isOutsideBounds() {
		return this._isOutsideBounds;
	}

	_setIsOutsideBounds() {
		const halfW = this.canvas.width / 2;
		const halfH = this.canvas.height / 2;
		const gutter = this.gutter;

		this._isOutsideBounds =
			this.x > halfW + gutter ||
			this.x < -(halfW + gutter) ||
			this.y > halfH + gutter ||
			this.y < -(halfH + gutter);
	}

	reset(recycled = false) {
		// z is required to calculate x and y
		this.z =
			this.sizeMultiplier *
			(this.foreground
				? getRandomFloat(0.25, 0.75)
				: getRandomFloat(0.75, 1.75));

		let x, y, direction;
		const halfW = this.canvas.width / 2;
		const halfH = this.canvas.height / 2;
		do {
			if (recycled) {
				switch (this.respawnArea) {
					case 'center':
						// middle 10th of the screen
						x = getRandomInt(-halfW / 10, halfW / 10);
						y = getRandomInt(-halfH / 10, halfH / 10);
						direction = x > 0 ? 1 : -1; // move outward
						break;
					case 'outside':
						const gutter = this.gutter;
						x =
							getRandomInt(halfW, halfW + gutter) *
							(random() > 0.5 ? 1 : -1);
						y =
							getRandomInt(halfH, halfH + gutter) *
							(random() > 0.5 ? 1 : -1);
						direction = x > 0 ? -1 : 1; // move inward
						break;
					default:
						throw new Error(
							'Invalid respawnArea ' + this.respawnArea,
						);
				}
			} else {
				// start at a random spot
				x = getRandomInt(-halfW, halfW);
				y = getRandomInt(-halfH, halfH);
				direction =
					this.respawnArea === 'center'
						? x > 0
							? 1
							: -1 // move outward
						: random() > 0.5
						? 1
						: -1; // move inward or outward randomly.
			}
		} while (!x); // if x is zero, slope is Infinite and y is infinite, which ends up being NaN and gets stuck in the corner.

		this.x = x;
		this.y = y;
		this.direction = direction;
		this.b =
			this.respawnArea === 'center' ? 0 : getRandomInt(-halfH, halfH);
		this.slope = this.y / this.x;
		this.speed = getRandomInt(2, 6);
		this.rotate = random();
		this.rotateDirection = random() < 0.5 ? 1 : -1;
		this.color = this.colors[Math.floor(random() * this.colors.length)](
			this.opacity,
		);
		this._isOutsideBounds = false;
	}

	update(multiplier) {
		const speed = this.speed * multiplier;

		// move faster sideways
		const increment =
			Math.abs(this.slope) < 0.01 // avoid division by zero and ludicrous speed.
				? speed * 100
				: Math.min(speed, Math.abs(speed / this.slope));

		this.z = Math.min(
			this.z + 0.0005 * speed,
			this.sizeMultiplier * (this.foreground ? 2 : 3),
		);
		this.x += this.direction * increment;
		this.y = this.slope * this.x + this.b;
		this.rotate += 0.005 * this.rotateDirection;
		this._setIsOutsideBounds();

		if (this.isOutsideBounds) {
			this.reset(true);
		}
	}

	draw() {
		// We add the center to the x/y because translate relative from origin (which is the center)
		const x = this.x + this.canvas.width / 2;
		const y = this.y + this.canvas.height / 2;

		// All our SVGs use a 100x100 view box to simplify rotation
		const centerX = 50;
		const centerY = 50;

		this.context.save();
		this.context.fillStyle = this.color;

		this.context.translate(x, y);
		this.context.scale(this.z, this.z);
		this.context.rotate(this.rotate);
		this.context.translate(-centerX, -centerY);
		this.context.fill(this.path);
		this.context.restore();
	}
}

const createShape = (
	i,
	blur,
	foreground,
	background,
	canvas,
	context,
	canvasBG,
	contextBG,
	sizeMultiplier,
	respawnArea,
	colors,
	isPageLoad,
) => {
	const fg = i % 2 === 1;
	let opacity = 0.8;
	if (foreground && background) {
		opacity = fg ? 0.7 : 0.4;
		return new Shape(fg ? canvas : canvasBG, fg ? context : contextBG, {
			foreground: fg,
			opacity,
			sizeMultiplier,
			respawnArea,
			colors,
			isPageLoad,
		});
	} else if (foreground) {
		opacity = 0.7;
		return new Shape(canvas, context, {
			opacity,
			foreground,
			sizeMultiplier,
			respawnArea,
			colors,
			isPageLoad,
		});
	} else if (background) {
		opacity = 0.4;
		return new Shape(canvasBG, contextBG, {
			opacity,
			foreground: false,
			sizeMultiplier,
			respawnArea,
			colors,
			isPageLoad,
		});
	}
	return null;
};

export default function AnimatedBackground({
	className,
	shapeCount,
	sizeMultiplier = 1,
	respawnArea = 'center',
	blur = false,
	colors = DEFAULT_COLORS,
	foreground = true,
	background = false,
}) {
	const classes = useStyles();
	const ref = useRef();
	const refBG = useRef();
	const shapes = useRef([]);
	const healthyShapes = useRef(0);
	const { backgroundSpeed } = useContext(AnimatedBackgroundContext);

	if (!shapeCount) {
		shapeCount = 50 + Math.floor(50 * backgroundSpeed);
	}

	shapeCount = Math.max(0, shapeCount);

	useEffect(() => {
		const canvas = ref.current;
		const canvasBG = refBG.current;
		const context = canvas && canvas.getContext('2d');
		const contextBG = canvasBG && canvasBG.getContext('2d');
		const resizeObserver = new ResizeObserver(debounce(resize, 200));
		const parent = foreground
			? canvas.parentElement
			: background
			? canvasBG.parentElement
			: false;

		function resize() {
			if (canvas) {
				canvas.width = parent.clientWidth;
				canvas.height = parent.clientHeight;
			}

			if (canvasBG) {
				canvasBG.width = parent.clientWidth;
				canvasBG.height = parent.clientHeight;
			}
		}

		resize();

		for (let i = 0; i < shapeCount; i++) {
			shapes.current.push(
				createShape(
					i,
					blur,
					foreground,
					background,
					canvas,
					context,
					canvasBG,
					contextBG,
					sizeMultiplier,
					respawnArea,
					colors,
					true,
				),
			);
		}
		healthyShapes.current = shapeCount;

		resizeObserver.observe(parent);

		return () => {
			resizeObserver.unobserve(parent);
		};
	}, []);

	useEffect(() => {
		// adjust to match shapeCount
		const canvas = ref.current;
		const canvasBG = refBG.current;
		const context = canvas && canvas.getContext('2d');
		const contextBG = canvasBG && canvasBG.getContext('2d');
		// save dying shapes to meet the count
		const maxSaveableIndex = Math.min(shapes.current.length, shapeCount);
		for (let i = healthyShapes.current; i < maxSaveableIndex; i++) {
			shapes.current[i].dying = false;
		}
		// create new shapes to meet the count
		for (let i = shapes.current.length; i < shapeCount; i++) {
			shapes.current.push(
				createShape(
					i,
					blur,
					foreground,
					background,
					canvas,
					context,
					canvasBG,
					contextBG,
					sizeMultiplier,
					respawnArea,
					colors,
					false,
				),
			);
		}
		// poison shapes to drop to the count
		for (let i = shapeCount; i < healthyShapes.current; i++) {
			shapes.current[i].dying = true;
		}
		healthyShapes.current = shapeCount;
	}, [shapeCount]);

	useEffect(() => {
		const canvas = ref.current;
		const canvasBG = refBG.current;
		const context = canvas && canvas.getContext('2d');
		const contextBG = canvasBG && canvasBG.getContext('2d');
		let stop = false;
		let fpsInterval = 1000 / 60;
		let then = Date.now();
		let now;
		let elapsed;
		let raf;

		function animate() {
			if (stop) {
				return;
			}

			raf = requestAnimationFrame(animate);

			now = Date.now();
			elapsed = now - then;

			if (elapsed > fpsInterval) {
				then = now - (elapsed % fpsInterval);

				context && context.clearRect(0, 0, canvas.width, canvas.height);
				contextBG &&
					contextBG.clearRect(0, 0, canvasBG.width, canvasBG.height);

				for (let shape of shapes.current) {
					shape.update(backgroundSpeed);
					shape.draw();
				}
				shapes.current = shapes.current.filter(
					(s) => !s.dying || !s.isOutsideBounds,
				);
			}
		}

		animate();

		return () => {
			stop = true;
			cancelAnimationFrame(raf);
		};
	}, [backgroundSpeed]);

	return (
		<>
			{background && (
				<canvas
					ref={refBG}
					className={clsx(
						classes.root,
						blur && classes.blurBG,
						className,
					)}
				/>
			)}
			{foreground && (
				<canvas
					ref={ref}
					className={clsx(
						classes.root,
						blur && classes.blur,
						className,
					)}
				/>
			)}
		</>
	);
}
