import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { isDevBuild } from '@remote-social/common/src/environment';
import {
	getFirebase,
	actionTypes as rrfActionTypes,
} from 'react-redux-firebase';
import { constants as rfConstants } from 'redux-firestore';
import rootReducer from './rootReducer';

// import { verifyAuth } from '../features/auth/authSlice';

const extraArgument = {
	getFirebase,
};

const middleware = [
	...getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [
				// just ignore every redux-firebase and react-redux-firebase action type
				...Object.keys(rfConstants.actionTypes).map(
					(type) => `${rfConstants.actionsPrefix}/${type}`,
				),
				...Object.keys(rrfActionTypes).map(
					(type) => `@@reactReduxFirebase/${type}`,
				),
			],
			ignoredPaths: ['firebase', 'firestore'],
		},
		thunk: {
			extraArgument,
		},
	}),
];

const store = configureStore({
	reducer: rootReducer,
	middleware,
});

if (isDevBuild() && module.hot) {
	module.hot.accept('./rootReducer', () => {
		const newRootReducer = require('./rootReducer').default;
		store.replaceReducer(newRootReducer);
	});
}

export default store;
