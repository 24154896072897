/*
  Let people into this page only if they are authenticated

  This means:
    1. Auth/Profile has (handled by Loader)
    	a. successfully loaded
    	b. has updated with a createdAt value (handled by background trigger function)
    2. The user is currently logged in: !isEmpty(profile)
*/

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCurrentUser } from '../hooks';
import { Loading } from '../components';

/**
 * @type {React.ComponentType.<{
 *   component: React.ComponentType<any>;
 *   redirectToPath: string;
 *   redirectToPathAndIgnoreContinueUrl?: boolean;
 * } & Omit<import('react-router-dom').RouteProps, 'render'>
 *   & Record<string, any>
 * >}
 */
const AuthenticatedRoute = ({
	component: Component,
	redirectToPath,
	redirectToPathAndIgnoreContinueUrl,
	...rest
}) => {
	const user = useCurrentUser();
	if (!user.isLoaded) {
		return <Loading showRandomLabels={true} />;
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				user.isAuthenticated ? (
					<Component {...props} {...rest} />
				) : (
					<Redirect
						to={{
							pathname: redirectToPath || '/onboard',
							...(!redirectToPathAndIgnoreContinueUrl && {
								state: { from: props.location },
							}),
						}}
					/>
				)
			}
		/>
	);
};

export default AuthenticatedRoute;
