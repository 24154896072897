import {
	buildJumpRoute,
	buildRoute,
	buildSearchParams,
	createRoutes,
} from './routeBuilding';
import type { SubscriptionIntent } from '@common/hooks/useBilling';

// Be careful not to create cyclic dependencies with routes.
// The role of this file is just to declare paths and how
// route parameters are built into paths/state to ensure
// we reuse same logic and don't forget continueUrl where needed

export const routes = createRoutes({
	home: buildRoute('/'),
	handleLink: buildRoute('/handle-link'),
	resetPassword: buildRoute('/reset-password'),
	onboard: buildJumpRoute('/onboard'),
	logout: buildJumpRoute('/logout'),
	/** @deprecated use `selectAccount` route */
	selectAccountLegacy: buildRoute(
		`/select-account/:gameType`,
		(opts: { gameType: 'bingo' | 'trivia' | 'physical' }) => ({
			pathname: `/select-account/${opts.gameType}`,
		}),
	),
	selectAccount: buildJumpRoute('/select-team'),
	verifyEmail: buildJumpRoute('/verify-email'),
	createFirstAccount: buildJumpRoute(
		'/create-first-account',
		(opts: { shouldUpsell: boolean }) => ({
			state: {
				shouldUpsell: opts.shouldUpsell,
			},
		}),
	),
	createAccount: buildJumpRoute('/create-account'),
	integrateSlack: buildJumpRoute('/integrate-slack'),
	login: buildJumpRoute('/login'),
	register: buildJumpRoute('/register'),
	upgrade: buildJumpRoute('/register/upgrade'),
	subscribe: buildJumpRoute(
		'/subscribe',
		(opts: { intent?: SubscriptionIntent }) => ({
			...(opts.intent && {
				state: {
					intent: opts.intent,
				},
			}),
		}),
	),
	checkoutSuccess: buildRoute(
		'/checkout-success',
		(params: { continuePath: string; accountId: string }) => {
			return {
				pathname: '/checkout-success',
				search: buildSearchParams({
					accountId: params.accountId,
					continuePath: params.continuePath,
				}),
			};
		},
	),
});
