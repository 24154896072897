export const getGameHost = (gameId) => (state) =>
	state.firebase.data?.games?.[gameId]?.host || '';

export const getGameState = (gameId) => (state) =>
	state.firebase.data?.games?.[gameId]?.state;

export const getPlayersData = (gameId) => (state) => {
	return state.firebase.data?.games?.[gameId]?.playersData || {};
};

export const getConfig = (gameId) => (state) => {
	return state.firebase.data?.games?.[gameId]?.config;
};

export const getPlayers = (gameId) => (state) => {
	return state.firebase.data?.games?.[gameId]?.players || [];
};
