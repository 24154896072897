import { ContinueState, ContinueOpts, LaxContinueState } from './types';
import { fullPathFromLocation } from './fullPathFromLocation';
import { isLocationDescriptorObject } from './isLocation';

export function buildContinueLocation<T>({
	destinationPath,
	opts,
	state: nextState,
}: {
	destinationPath: string;
	opts?: ContinueOpts;
	state?: T;
}): {
	pathname: string;
	state?: T extends unknown
		? ContinueState
		: ContinueState | T | (ContinueState & T);
} {
	if (!opts || !opts.location) {
		return {
			pathname: destinationPath,
			...(nextState && {
				state: nextState,
			}),
		};
	}

	const { location } = opts;

	const state = location.state as LaxContinueState | undefined;

	const currentPath = fullPathFromLocation(location);

	// convert `from` to continuePath for backward compatibility
	if (
		state &&
		'from' in state &&
		(typeof state.from === 'string' ||
			isLocationDescriptorObject(state.from))
	) {
		return {
			pathname: destinationPath,
			state: {
				...nextState,
				continuePath: fullPathFromLocation(state.from),
			},
		};
	}

	if (!opts?.preserveContinuePath) {
		return {
			pathname: destinationPath,
			state: Object.assign({}, nextState, {
				previousPath: currentPath,
				...(state?.returnPath && {
					returnPath: state?.returnPath,
				}),
			}),
		};
	}

	const continuePath = state?.continuePath || currentPath;
	const returnPath =
		continuePath && !state?.continuePath
			? state?.returnPath || state?.previousPath || currentPath
			: state?.returnPath;

	return {
		pathname: destinationPath,
		state: Object.assign({}, nextState, {
			previousPath: currentPath,
			continuePath,
			...(continuePath !== returnPath &&
				returnPath && {
					returnPath,
				}),
		}),
	};
}
