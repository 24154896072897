import React, { MutableRefObject } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
	Button,
	ClickAwayListener,
	Dialog,
	Grid,
	IconButton,
	MenuList,
	Paper,
	Popper,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AccountAvatar } from '@common/components';
import { useCurrentAccount } from '@common/contexts';
import ClearIcon from '@material-ui/icons/Clear';
import { AccountLinks } from './accountLinks';
import { env } from '@common/environment';
import { AccountResponse } from '@contracts/platform';

const useStyles = makeStyles((theme) => {
	return createStyles({
		dialogue: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'left',
			minWidth: '35ch',
		},
		menu: {
			width: '35ch',
		},
		menuButton: {
			textTransform: 'none',

			/** when a user has no account menu options */
			'&:disabled': {
				color: theme.palette.text.primary,
			},
		},
		name: {
			display: 'none',

			[theme.breakpoints.up('sm')]: {
				display: 'inline-block',
				marginLeft: theme.spacing(1),
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '10ch',
				whiteSpace: 'nowrap',
			},

			[theme.breakpoints.up('md')]: {
				maxWidth: '20ch',
			},

			[theme.breakpoints.up('lg')]: {
				maxWidth: '30ch',
			},
		},
	});
});

type State = {
	currentAccount?: AccountResponse;
	menuDisabled: boolean;
	accountSelectorHidden: boolean;
	menuAnchorRef: MutableRefObject<HTMLButtonElement | null>;
	menuOpen: 'closed' | 'dialogue' | 'menu';
	handleOpen: () => void;
	handleClose: () => void;
};

const useState = (): State => {
	const { currentAccount } = useCurrentAccount();
	const menuAnchorRef = React.useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = React.useState<
		'closed' | 'dialogue' | 'menu'
	>('closed');

	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	const accountSwitchLockUrls = [
		`${env().bingoUrl}/create`,
		`${env().bingoUrl}/game`,
		`${env().triviaUrl}/create`,
		`${env().triviaUrl}/game`,
		`${env().checkinUrl}/create-activity`,
		`${env().checkinUrl}/create-scheduled-room`,
		`${env().checkinUrl}/lobby`,
		`${env().checkinUrl}/room`,
		`${env().checkinUrl}/checkin`,
	];
	const currentUrl = window.location.href;
	const accountSelectorHidden = Boolean(
		accountSwitchLockUrls.find((url) => currentUrl.includes(url)),
	);
	const menuDisabled = accountSelectorHidden;

	const handleOpen = React.useCallback(() => {
		if (isSm) {
			setMenuOpen('menu');
		} else {
			setMenuOpen('dialogue');
		}
	}, [setMenuOpen, isSm]);

	const handleClose = React.useCallback(() => {
		setMenuOpen('closed');
	}, [setMenuOpen]);

	const result = React.useMemo<State>(() => {
		return {
			currentAccount,
			accountSelectorHidden,
			menuDisabled,
			menuAnchorRef,
			menuOpen,
			handleOpen,
			handleClose,
		};
	}, [
		currentAccount,
		accountSelectorHidden,
		menuDisabled,
		handleClose,
		handleOpen,
		menuOpen,
		menuAnchorRef,
	]);

	return result;
};

export const AccountMenu: React.ComponentType = () => {
	const styles = useStyles();
	const {
		currentAccount,
		accountSelectorHidden,
		menuDisabled,
		menuAnchorRef,
		menuOpen,
		handleOpen,
		handleClose,
	} = useState();

	if (!currentAccount) {
		return null;
	}

	return (
		<>
			<Button
				ref={menuAnchorRef}
				onClick={handleOpen}
				aria-controls={
					menuOpen === 'dialogue'
						? 'account-dialogue'
						: 'account-menu'
				}
				aria-haspopup="true"
				className={styles.menuButton}
				disabled={menuDisabled}
			>
				<AccountAvatar account={currentAccount} />
				<Typography variant="body1" className={styles.name}>
					{currentAccount.name}
				</Typography>
				{!menuDisabled && <KeyboardArrowDownIcon />}
			</Button>
			{menuOpen === 'dialogue' && (
				<Dialog
					fullScreen
					open={menuOpen === 'dialogue'}
					onClose={handleClose}
				>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
					>
						<IconButton onClick={handleClose}>
							<ClearIcon fontSize="small" />
						</IconButton>
					</Grid>
					<MenuList
						autoFocus
						variant="menu"
						className={styles.dialogue}
						id="user-dialogue"
					>
						<AccountLinks
							key="mobile"
							handleClose={handleClose}
							accountSelectorHidden={accountSelectorHidden}
						/>
					</MenuList>
				</Dialog>
			)}
			{menuOpen === 'menu' && (
				<ClickAwayListener onClickAway={handleClose}>
					<Popper
						anchorEl={menuAnchorRef.current}
						placement={'bottom-start'}
						open={menuOpen === 'menu'}
						keepMounted
						role="menu"
						className={styles.menu}
						transition
						disablePortal
					>
						<Paper>
							<MenuList autoFocus id="account-menu">
								<AccountLinks
									key="desktop"
									handleClose={handleClose}
									accountSelectorHidden={
										accountSelectorHidden
									}
								/>
							</MenuList>
						</Paper>
					</Popper>
				</ClickAwayListener>
			)}
		</>
	);
};
