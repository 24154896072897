import { RequestState } from './requestState';
import { ErrorType, UnreachableError } from '../errors';
import { literalAs } from '../utils/literalAs';
import {
	AnyRequestState,
	ErrorPayload,
	InitialPayload,
	InitialPayloadOf,
	PayloadOf,
	PendingPayload,
	SuccessPayload,
} from './requestState';

export function isInitial<A extends AnyRequestState>(
	state: A,
): state is A & InitialPayload<InitialPayloadOf<A>> {
	return state.status === 'initial';
}

export function isPending<A extends AnyRequestState>(
	state: A,
): state is A & PendingPayload<PayloadOf<A>, InitialPayloadOf<A>> {
	return state.status === 'pending';
}

export function isSuccess<A extends AnyRequestState>(
	state: A,
): state is A & SuccessPayload<PayloadOf<A>> {
	return state.status === 'success';
}

export function isError<A extends AnyRequestState>(
	state: A,
): state is A & ErrorPayload<PayloadOf<A>, InitialPayloadOf<A>> {
	return state.status === 'error';
}

export function requestStateSummary<A extends AnyRequestState[]>(...state: A) {
	return state.reduce(
		(acc, entry) => {
			switch (entry.status) {
				case 'initial':
					acc.initial += 1;
					return acc;
				case 'pending':
					acc.pending += 1;
					return acc;
				case 'error':
					acc.errors += 1;
					if (!acc.firstError) {
						acc.firstError = entry.error;
					}
					return acc;
				case 'success':
					acc.success += 1;
					return acc;
				default:
					throw new UnreachableError(entry);
			}
		},
		{
			initial: 0,
			pending: 0,
			errors: 0,
			success: 0,
			firstError: literalAs<undefined | ErrorType>(undefined),
		},
	);
}

export function combineRequestStatus<A extends AnyRequestState[]>(...state: A) {
	const summary = requestStateSummary(...state);
	if (summary.errors > 0) {
		return { status: 'error' as const, error: summary.firstError };
	}
	if (summary.initial > 0) {
		return { status: 'pending' as const };
	}
	if (summary.pending > 0) {
		return { status: 'pending' as const };
	}
	return { status: 'success' as const };
}

type SuccessRequestStateMap<A extends Record<string, AnyRequestState>, I> = {
	[K in keyof A]: PayloadOf<A[K]>;
} &
	Omit<I, keyof A>;

type InitialRequestStateMap<A extends Record<string, AnyRequestState>, I> = {
	[K in keyof A]: InitialPayloadOf<A[K]>;
} &
	Omit<I, keyof A>;

export function combineRequestState<
	A extends Record<string, AnyRequestState>,
	I,
>(
	stateMap: A,
	initialValues: I | {} = {},
): RequestState<SuccessRequestStateMap<A, I>, InitialRequestStateMap<A, I>> {
	type Result = RequestState<
		SuccessRequestStateMap<A, I>,
		InitialRequestStateMap<A, I>
	>;
	const summary = requestStateSummary(...Object.values(stateMap));
	const data = Object.entries(stateMap).reduce(
		(acc, [key, value]) => ({
			...acc,
			...('data' in value && {
				[key]: value.data,
			}),
		}),
		{
			...initialValues,
		} as InitialRequestStateMap<A, I>,
	);
	if (summary.errors > 0) {
		return {
			status: 'error' as const,
			error: summary.firstError,
			data,
		} as Result;
	}
	if (summary.pending > 0) {
		return {
			status: 'pending' as const,
			data,
		} as Result;
	}
	if (summary.initial > 0) {
		return {
			status: 'initial' as const,
			data,
		} as Result;
	}
	return {
		status: 'success' as const,
		data,
	} as Result;
}
