import React from 'react';
import { useField } from 'formik';
import { DatePicker } from '@material-ui/pickers';

const DatePickerField = (props) => {
	const { errorText, ...rest } = props;
	const [field, meta, helpers] = useField(props);

	const { value } = meta;
	const { setValue } = helpers;

	const handleDateChange = (date) => {
		setValue(date);
	};

	return (
		<DatePicker
			margin="normal"
			format="MM/dd/yyyy"
			{...field}
			{...rest}
			value={value}
			onChange={handleDateChange}
		/>
	);
};

export default DatePickerField;
