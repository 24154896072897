import React from 'react';
import { useField } from 'formik';
import TextInput from './atoms/TextInput';

const InputField = ({ name, ...rest }) => {
	const [field, , helpers] = useField(name);

	const onChange = (value) => {
		helpers.setValue(value || '');
	};

	return <TextInput {...rest} value={field.value} onChange={onChange} />;
};

export default InputField;
