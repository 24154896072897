import { isError, isPending, isSuccess } from '../store-tools';
import { useCurrentAccount } from '../contexts';
import { useBackendFunction } from '../hooks';
import React from 'react';
import { ensureValidUrl } from '../utils/ensureValidUrl';
import { ErrorType } from '../errors';
import { fullPathFromLocation, routes } from '../routes';
import { continuePathFromLocation } from '../routes/continuePathFromLocation';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { returnPathFromLocation } from '../routes/returnPathFromLocation';
import { buildCrossDomainUrl } from '../routes/useCrossDomainUrl';

export type SubscriptionIntent = 'paywall' | 'upsell';

type State = {
	isPending: boolean;
	error: ErrorType | undefined;
	navigateToSubscriptionPortal: () => void;
	navigateToBillingPortal: () => void;
	onCancel: () => void;
	intent: SubscriptionIntent;
};

function determineContinuePath({
	location,
}: {
	location: Location<unknown>;
	intent: SubscriptionIntent;
}) {
	return continuePathFromLocation(location) || routes.home({ location });
}

type LocationState = {
	intent?: SubscriptionIntent;
};

export const useBilling = (opts?: { returnPath?: string }): State => {
	const history = useHistory();
	const location = useLocation<LocationState>();
	const { currentAccountId } = useCurrentAccount();
	const intent = location.state?.intent || 'paywall';

	const origin = window.location.origin;

	const determinedContinuePath = React.useMemo(
		() =>
			determineContinuePath({
				location,
				intent,
			}),
		[location, intent],
	);
	const continuePath = React.useMemo(
		() =>
			typeof determinedContinuePath === 'string'
				? determinedContinuePath
				: fullPathFromLocation(determinedContinuePath),
		[determinedContinuePath],
	);

	const successUrl = React.useMemo(() => {
		if (!currentAccountId) {
			return undefined;
		}

		const fullPath = fullPathFromLocation(
			routes.checkoutSuccess({
				continuePath,
				accountId: currentAccountId,
				location,
			}),
		);
		const url = `${origin}${fullPath}`;

		return ensureValidUrl(url);
	}, [currentAccountId, continuePath, location, origin]);

	const returnUrl = React.useMemo(() => {
		const url = buildCrossDomainUrl({
			boundToOrigin: origin,
			location: opts?.returnPath ? opts.returnPath : continuePath,
			accountId: currentAccountId,
		});

		return ensureValidUrl(url);
	}, [continuePath, currentAccountId, opts?.returnPath, origin]);

	/** create Stripe subscription checkout portal */
	const [createSubscriptionCheckoutRequest, createSubscriptionCheckout] =
		useBackendFunction('billing-createSubscriptionCheckoutSession');
	const createSubscriptionCheckoutSuccess = isSuccess(
		createSubscriptionCheckoutRequest,
	);
	const createSubscriptionCheckoutError = isError(
		createSubscriptionCheckoutRequest,
	)
		? createSubscriptionCheckoutRequest.error
		: undefined;
	const createSubscriptionCheckoutPending = isPending(
		createSubscriptionCheckoutRequest,
	);

	/** create Stripe billing management portal */
	const [createBillingPortalRequest, createBillingPortal] =
		useBackendFunction('billing-createBillingPortal');
	const createBillingPortalSuccess = isSuccess(createBillingPortalRequest);
	const createBillingPortalError = isError(createBillingPortalRequest)
		? createBillingPortalRequest.error
		: undefined;
	const createBillingPortalPending = isPending(createBillingPortalRequest);

	const error = createSubscriptionCheckoutError || createBillingPortalError;

	const navigateToSubscriptionPortal = React.useCallback(() => {
		if (currentAccountId && successUrl) {
			createSubscriptionCheckout({
				accountId: currentAccountId,
				successUrl,
				returnUrl,
			});
		}
	}, [currentAccountId, createSubscriptionCheckout, successUrl, returnUrl]);

	const navigateToBillingPortal = React.useCallback(() => {
		if (currentAccountId && returnUrl) {
			createBillingPortal({
				accountId: currentAccountId,
				returnUrl,
			});
		}
	}, [currentAccountId, createBillingPortal, returnUrl]);

	React.useEffect(() => {
		if (createSubscriptionCheckoutSuccess) {
			window.location.href = ensureValidUrl(
				createSubscriptionCheckoutRequest.data.url,
			);
		}
	}, [createSubscriptionCheckoutSuccess, createSubscriptionCheckoutRequest]);

	React.useEffect(() => {
		if (createBillingPortalSuccess) {
			window.location.href = ensureValidUrl(
				createBillingPortalRequest.data.url,
			);
		}
	}, [createBillingPortalSuccess, createBillingPortalRequest]);

	const onCancel = React.useCallback(() => {
		if (intent === 'upsell') {
			// Continue on free plan
			history.replace(determinedContinuePath);
		} else {
			// Back button
			history.replace(
				returnPathFromLocation(location) || routes.home({ location }),
			);
		}
	}, [determinedContinuePath, history, intent, location]);

	const result = React.useMemo(() => {
		return {
			error,
			isPending:
				createSubscriptionCheckoutPending || createBillingPortalPending,
			navigateToSubscriptionPortal,
			navigateToBillingPortal,
			onCancel,
			intent,
		};
	}, [
		error,
		createSubscriptionCheckoutPending,
		createBillingPortalPending,
		navigateToSubscriptionPortal,
		navigateToBillingPortal,
		onCancel,
		intent,
	]);

	return result;
};
