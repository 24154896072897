import { ConnectToSlackScreen } from '@common/slack/connectToSlackScreen';
import { ensureTruthy, Loading, useCurrentAccount } from '@common';
import { useLocation } from 'react-router';
import { continuePathFromLocation } from '../../../routes/continuePathFromLocation';
import { routes } from '../../../routes/routes';
import { useCrossDomainUrl } from '../../useCrossDomainUrl';

export const IntegrateWithSlack: React.ComponentType = () => {
	const { currentAccount, isLoaded } = useCurrentAccount();
	const location = useLocation<{ from?: string }>();

	// we need a full path
	const redirectUrl = useCrossDomainUrl({
		location:
			continuePathFromLocation(location) || routes.home({ location }),
	});

	if (!isLoaded) {
		return <Loading />;
	}

	const accountId = ensureTruthy(currentAccount?.accountId);

	return (
		<ConnectToSlackScreen accountId={accountId} redirectUrl={redirectUrl} />
	);
};
