import React from 'react';
import clsx from 'clsx';

import { Button } from '@remote-social/common';

import { fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: {
		fontFamily: theme.fontFamily,
		fontWeight: 900,
		fontSize: 26,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		margin: theme.spacing(4, 'auto', 0),
		'&:hover': {
			backgroundColor: fade(theme.palette.primary.main, 0.8),
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 32,
		},
	},
}));

export default function MyButton({ className, children, ...rest }) {
	const classes = useStyles();

	return (
		<Button
			className={clsx(classes.button, className)}
			fullWidth={false}
			{...rest}
		>
			{children}
		</Button>
	);
}
