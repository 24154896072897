import type { SchemaOf, ValueTypesOf } from '../shared';
import { Joi, objectSchemaOf, omitSchemaProps } from '../shared';
import type { ScheduleBase, WeeklyScheduleTiming } from '../schedules';
import {
	scheduleBaseSchemaMap,
	weeklyScheduleTimingSchemaMap,
} from '../schedules';
import type { ActivityParams } from './activityParams';
import { activityParamsSchema } from './activityParams';

export const connectScheduleStatus = ['active', 'paused', 'completed'] as const;

export const connectScheduleStatusSchema = Joi.string().valid(
	...connectScheduleStatus,
);

export type ConnectScheduleStatus = ValueTypesOf<typeof connectScheduleStatus>;

export type ConnectScheduleTiming = Omit<WeeklyScheduleTiming, 'timing'>;

export const connectScheduleTimingSchemaMap = omitSchemaProps(
	weeklyScheduleTimingSchemaMap,
	['timing'],
);

export const connectScheduleTimingSchema = objectSchemaOf(
	connectScheduleTimingSchemaMap,
);

export type ConnectSchedule = ScheduleBase & {
	integrationId: string;
	integrationType: 'slack';
	slackChannelId: string;
	slackChannelName: string;
	accountId: string;
	activityIds: [string, ...string[]];
	activityParams?: ActivityParams;
	status: ConnectScheduleStatus;
	tags?: string[];
} & ConnectScheduleTiming;

export const connectScheduleSchemaMap: SchemaOf<ConnectSchedule> = {
	...scheduleBaseSchemaMap,
	integrationId: Joi.string().required(),
	integrationType: Joi.string().valid('slack').required(),
	slackChannelId: Joi.string().required(),
	slackChannelName: Joi.string().required(),
	accountId: Joi.string().required(),
	activityIds: Joi.array().items(Joi.string().required()).min(1).required(),
	activityParams: activityParamsSchema.optional(),
	status: connectScheduleStatusSchema.required(),
	tags: Joi.array().items(Joi.string()).optional(),
	...connectScheduleTimingSchemaMap,
};
