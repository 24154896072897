import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	brand: {
		height: '3rem',
		fill: '#444444',
		marginTop: theme.spacing(3),
	},
	paper: {
		padding: '1rem',
		borderRadius: '2rem',
		border: `1px solid ${theme.palette.text.hint}`,
		boxShadow: `0 0 1rem ${theme.palette.text.hint}`,
		width: '100%',
		position: 'relative',
		height: '550px',
	},
	container: {
		minHeight: '550px',
		alignItems: 'center',
	},
	loginTitle: {
		fontWeight: '700',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: '#444444',
	},
	loginSubTitle: {
		fontWeight: '600',
	},
	forgotPassword: {
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(2),
	},
	form: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	registerBtn: {
		width: '100%',
		background: '#{h:180, s:0, l:20, a:1}',
		border: '0.3px solid #C4C4C4',
		borderRadius: '5px',
		fontWeight: '700',
		margin: theme.spacing(3, 0, 2),
	},
	signUpHere: {
		marginTop: theme.spacing(1),
	},
	video: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	loginVector: {
		position: 'absolute',
		left: '20%',
		right: '20.42%',
		top: '3%',
		bottom: '10.24%',
		opacity: '0.9',
		transform: 'rotateX(180deg)',
		zIndex: -1,
	},
	videoContainer: {
		position: 'relative',
		width: '100%',
		minHeight: '550px',
		background: '#F5F6F8',
		borderRadius: '1.5rem',
		overflow: 'hidden',
	},
	//register component
	withterms: {
		marginTop: theme.spacing(2),
	},
	legalLinks: {
		color: '#3f51b5',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	loginHere: {
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(2),
	},
	// Onboard components
	onboardSubTitle: {
		fontWeight: '500',
		marginBottom: theme.spacing(5),
		marginTop: theme.spacing(6),
		fontSize: '1.25rem',
		color: '#444444',
	},
	homeLink: {
		color: '#3f51b5',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	loginBtn: {
		margin: theme.spacing(-2, 0, 2),
	},
	signupBtn: {
		width: '100%',
		background: '#444444',
		color: '#FFFFFF',
		fontWeight: '700',
		margin: theme.spacing(4, 0, 3),
	},
	onboardTitle: {
		fontWeight: '700',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		color: '#444444',
	},
	onboardVector: {
		position: 'absolute',
		left: '20%',
		right: '20.42%',
		top: '3%',
		bottom: '10.24%',
		opacity: '0.9',
		zIndex: -1,
	},
	// create component
	createTitle: {
		fontWeight: '700',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		color: '#444444',
	},
	createForm: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(5),
	},
	accountSubTitle: {
		fontWeight: '700',
		marginBottom: theme.spacing(5),
	},
	accountNameSubTitle: {
		fontWeight: '500',
	},
	doneBtn: {
		marginTop: theme.spacing(10),
	},
	content: {
		margin: theme.spacing(2, 0),
		color: '#444444',
	},
	//register-players
	centerAlign: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	invitedText: {
		position: 'absolute',
		margin: 'auto 40px',
		top: '5%',
		textAlign: 'center',
		fontSize: '15px',
	},
	playerTeamName: {
		position: 'absolute',
		top: '90%',
		fontWeight: 'bold',
	},
	//verify
	verifyTitle: {
		fontWeight: '700',
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(1),
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		color: '#444444',
	},
	verifySubtitle: {
		fontWeight: '700',
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(20),
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		color: '#444444',
	},
}));
