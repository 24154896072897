import { ErrorMessage } from './errorMessage';
import { Typography, Link } from '@material-ui/core';
import * as Sentry from '@sentry/react';

export const ErrorBoundary: React.ComponentType = ({ children }) => {
	return (
		<Sentry.ErrorBoundary
			fallback={({ componentStack, error }) => {
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
						}}
					>
						<ErrorMessage
							error={error}
							extraErrorInfo={componentStack ?? undefined}
						/>
						<Typography component="p" variant="subtitle1">
							If this happens again, send us email at{' '}
							<Link href="mailto:support@remotesocial.io">
								support@remotesocial.io
							</Link>
						</Typography>
					</div>
				);
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};
