import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Header as DefaultHeader } from './header';
import { default as DefaultFooter } from './Footer';
import { default as DefaultMainContent } from './MainContent';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 'calc(100vh + 5rem)', // footer always below the fold
		width: '100%',
		backgroundColor: theme.palette.background.default,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		flex: 0,
	},
	main: {
		flex: 1,
		padding: theme.spacing(5, 0),
	},
	footer: {
		flex: 0,
	},
}));

type Props = {
	Header?: React.ComponentType<{ className?: string }>;
	Footer?: React.ComponentType<{ className?: string }>;
	MainContent?: React.ComponentType<{ className?: string }>;
	className?: string;
	headerClassName?: string;
	footerClassName?: string;
	mainContentClassName?: string;
	headerFabs?: React.ReactNode;
};

export const AppLayout = forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<Props>
>(
	(
		{
			Header = DefaultHeader,
			Footer = DefaultFooter,
			MainContent = DefaultMainContent,
			className,
			headerClassName,
			footerClassName,
			mainContentClassName,
			children,
			headerFabs,
		},
		ref,
	) => {
		const classes = useStyles();
		return (
			<div className={clsx(classes.root, className)} ref={ref}>
				<Header
					className={clsx(classes.header, headerClassName)}
					children={headerFabs}
				/>
				<MainContent
					className={clsx(classes.main, mainContentClassName)}
				>
					{children}
				</MainContent>
				<Footer className={clsx(classes.footer, footerClassName)} />
			</div>
		);
	},
);
