export function ensureExists<T>(value: T | null | undefined): NonNullable<T> {
	if (value == null) {
		throw new TypeError(
			`Value should be defined, but got "${String(value)}"`,
		);
	}
	return value as NonNullable<T>;
}

type Falsy = false | null | undefined | 0 | '';

export function ensureTruthy<T>(value: T | Falsy): T {
	if (!value) {
		throw new TypeError(
			`Value should be truthy, but got "${String(value)}"`,
		);
	}
	return value;
}

export function ensure(
	condition: unknown,
	message?: string,
	...args: unknown[]
): asserts condition {
	if (!condition) {
		if (message || args) {
			console.error(message, ...args);
		}
		throw new TypeError(
			message ??
				`Condition should be met, but got "${String(condition)}"`,
		);
	}
}

export function ensureAtLeastOne<T>(array?: T[]): [T, ...T[]] {
	if (!array) {
		throw new TypeError(`Expected an array but got "${String(array)}"`);
	}
	if (array.length === 0) {
		throw new Error(
			'The array was expected to contain at least one element',
		);
	}
	return array as [T, ...T[]];
}
