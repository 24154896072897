import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	useCurrentUser,
	useGame,
	usePrevious,
	AudioControl,
	SentimentDialog,
	GamePlayers,
	getGameState,
	getGameHost,
	DocumentHead,
	useCurrentAccount,
} from '@remote-social/common';
import { Typography, Button, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Reward from 'react-rewards';
import useSound from 'use-sound';
import Layout from '../layout/Layout';
import BingoCard from '../../components/BingoCard';
import PlayerStatusGif from '../../components/PlayerStatusGif';
import {
	getRoundConfig,
	getDrawnNumbers,
	getRoundPlayers,
	getPlayersData,
} from '../../store/selectors';
import { envHost } from '@remote-social/common/src/utils/envLink';
import { GameState, PlayerStatus } from '@contracts/platform';

import sndClapping from '../../assets/sounds/clapping.mp3';
import sndDisappointed from '../../assets/sounds/dissapointed.mp3';
import { registerError } from '@remote-social/common/src/errors';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginBottom: theme.spacing(4),
	},
	winnerHeading: {
		fontWeight: 'bold',
		justifySelf: 'flex-start',
	},
	loserHeading: {
		justifySelf: 'flex-start',
	},
	waitText: {
		marginTop: theme.spacing(2),
	},
	gamePlayers: {
		margin: theme.spacing(2, 0),
	},
	hostButtons: {
		margin: theme.spacing(2, 0, 0),
	},
	muteButton: {
		position: 'absolute',
		top: theme.spacing(-2),
		right: theme.spacing(2),
		background: theme.palette.common.white,
	},
}));

export default function Game() {
	const { account, game } = useParams();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();
	const classes = useStyles();
	const rewardRef = useRef();
	const user = useCurrentUser();
	const firebase = useFirebase();
	const history = useHistory();
	const [isClosingRound, setIsClosingRound] = useState(false);
	const gameId = account && game ? `${account}-${game}` : null;
	const hasJoinedGame = useGame(gameId);
	const host = useSelector(getGameHost(gameId));
	const playersData = useSelector(getPlayersData(gameId));
	const roundConfig = useSelector(getRoundConfig(gameId));
	const roundPlayers = useSelector(getRoundPlayers(gameId));
	const drawnNumbers = useSelector(getDrawnNumbers(gameId));
	const gameState = useSelector(getGameState(gameId));
	const { isMuted, toggleMute } = useContext(AudioControl);
	const [playClapping] = useSound(sndClapping);
	const [playDissapointed] = useSound(sndDisappointed);
	const hasGameStarted = gameState === GameState.game;
	const isHost = host === user.uid;
	const playerData = playersData?.[user.uid];
	const playerStatus = playerData?.status;
	const previousPlayerStatus = usePrevious(playerStatus);

	const hostPath = `/create/${account}/${game}`;

	// If the game has already started then we only want to show the players in the current round otherwise
	// we show all players connected
	const players = hasGameStarted
		? roundPlayers.reduce(
				(result, id) => ({ ...result, [id]: playersData?.[id] }),
				{},
		  )
		: playersData;

	let subscriptions = [];

	if (gameId) {
		subscriptions = [
			...subscriptions,
			{
				path: `/games/${gameId}/host`,
			},
			{
				path: `/games/${gameId}/state`,
			},
		];

		if (hasJoinedGame) {
			subscriptions = [
				...subscriptions,
				{
					path: `/games/${gameId}/playersData`,
				},
				{
					path: `/games/${gameId}/roundData`,
					queryParams: ['limitToLast=1'],
				},
				{
					path: `/games/${gameId}/roundCards/${user.uid}`,
					queryParams: ['limitToLast=1'],
				},
			];
		}
	}

	useFirebaseConnect(subscriptions);

	useEffect(() => {
		if (account && hasJoinedGame && account !== currentAccountId) {
			setCurrentAccount(account);
		}
	}, [account, currentAccountId, hasJoinedGame, setCurrentAccount]);

	/**
	 * Creates the loop for drawing numbers on the host client
	 */
	useEffect(() => {
		if (!roundConfig || !isHost || !hasJoinedGame) {
			return;
		}

		const timeout = setTimeout(async () => {
			const drawNumber = firebase
				.functions()
				.httpsCallable('bingo-drawNumber');

			await drawNumber({ gameId });
		}, roundConfig.roundSpeed);

		return () => {
			clearTimeout(timeout);
		};
	}, [
		isHost,
		hasJoinedGame,
		roundConfig,
		firebase,
		drawnNumbers,
		gameId,
		hostPath,
		hasGameStarted,
	]);

	useEffect(() => {
		if (
			!playerStatus ||
			playerStatus === previousPlayerStatus ||
			!rewardRef.current
		) {
			return;
		}

		window.scrollTo(0, 0);

		if (playerStatus === PlayerStatus.winner) {
			rewardRef.current.rewardMe();

			if (!isMuted) {
				playClapping();
			}
		} else {
			rewardRef.current.punishMe();

			if (!isMuted) {
				playDissapointed();
			}
		}
	}, [
		isMuted,
		playClapping,
		playDissapointed,
		playerStatus,
		previousPlayerStatus,
	]);

	const handleCloseRound = async () => {
		const closeRound = firebase
			.functions()
			.httpsCallable('bingo-closeRound');

		setIsClosingRound(true);

		try {
			await closeRound({ gameId });

			history.push(`/create/${account}/${game}`);
		} catch (e) {
			registerError(e);
			setIsClosingRound(false);
		}
	};

	const gameOver =
		playerStatus === PlayerStatus.winner ||
		playerStatus === PlayerStatus.loser_disqualified ||
		playerStatus === PlayerStatus.loser;
	let content;

	if (gameId && isHost && !hasGameStarted) {
		return <Redirect to={hostPath} />;
	}

	if (hasGameStarted && roundConfig) {
		content = <BingoCard gameId={gameId} />;

		if (playerStatus === PlayerStatus.winner) {
			content = (
				<>
					<Reward
						ref={rewardRef}
						type="confetti"
						config={{ spread: 180 }}
					>
						<Typography
							className={classes.winnerHeading}
							variant="h2"
							color="primary"
							gutterBottom
							align="center"
						>
							Fudge yeah! You win!
						</Typography>
					</Reward>
					<PlayerStatusGif status={playerData.status} />
				</>
			);
		}

		if (
			playerStatus === PlayerStatus.loser_disqualified ||
			playerStatus === PlayerStatus.loser
		) {
			content = (
				<>
					<Reward
						ref={rewardRef}
						type="confetti"
						config={{ spread: 180 }}
					>
						<Typography
							className={classes.loserHeading}
							variant="h2"
							gutterBottom
							align="center"
						>
							Uh oh! Better luck next time.
						</Typography>
					</Reward>
					<PlayerStatusGif status={playerData.status} />
				</>
			);
		}
	} else {
		content = (
			<>
				<Typography variant="h3" gutterBottom align="center">
					Waiting for the host to begin the round...
				</Typography>
				<SentimentDialog />
			</>
		);
	}

	return (
		<Layout maxWidth="sm">
			<DocumentHead title="Play" />
			<Fab
				className={classes.muteButton}
				aria-label="toggle audio"
				size="small"
				onClick={toggleMute}
			>
				{isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
			</Fab>
			<div className={classes.content}>{content}</div>
			<GamePlayers className={classes.gamePlayers} players={players} />
			{isHost ? (
				<Button
					variant="outlined"
					onClick={handleCloseRound}
					loading={isClosingRound}
					fullWidth
					className={classes.hostButtons}
				>
					Close this round
				</Button>
			) : (
				gameOver && (
					<Typography
						variant="h3"
						className={classes.waitText}
						gutterBottom
						align="center"
					>
						Wait for the host to begin another round, or...
					</Typography>
				)
			)}
			{gameOver && (
				<Button
					variant="outlined"
					href={envHost()}
					fullWidth
					className={classes.hostButtons}
				>
					Return to the Hub
				</Button>
			)}
		</Layout>
	);
}
