import React from 'react';
import clsx from 'clsx';
import { animated, useTransition } from 'react-spring';
import { makeStyles } from '@material-ui/core';
import getDrawnNumberText from '../utils/getDrawnNumberText';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		minHeight: 63,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: theme.spacing(5),
		width: '100%',
	},
	wrapper: {
		position: 'absolute',
		fontSize: 20,
		color: theme.palette.primary.main,
		fontWeight: 900,
		textAlign: 'center',
		'& span': {
			display: 'inline-flex',
			width: '1.85em',
			height: '1.85em',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.common.white,
			backgroundColor: theme.palette.primary.main,
			borderRadius: '100%',
			fontSize: '95%',
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 28,
		},
	},
}));

export default function DrawnNumberIndicator({
	number,
	className,
	children,
	...rest
}) {
	const classes = useStyles();
	const transitions = useTransition(number, null, {
		from: { opacity: 0, transform: 'translate3d(0, 100%, 0) scale(0)' },
		enter: { opacity: 1, transform: 'translate3d(0, 0%, 0) scale(1)' },
		leave: { opacity: 0, transform: 'translate3d(0, -100%, 0) scale(0)' },
	});

	return (
		<div className={classes.root}>
			{transitions.map(({ item, props, key }) => (
				<animated.div
					key={key}
					style={props}
					className={clsx(classes.wrapper, className)}
					{...rest}
				>
					{!item ? (
						children
					) : (
						<div
							dangerouslySetInnerHTML={{
								__html: `&nbsp;${getDrawnNumberText(
									item,
								)}&nbsp;`,
							}}
						/>
					)}
				</animated.div>
			))}
		</div>
	);
}
