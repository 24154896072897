import type firebase from 'firebase';

// allows for simpler error checking without bothering too much about
// what kind of instance is this
export type ErrorType = Error & {
	code?:
		| firebase.functions.FunctionsErrorCode
		| (string & {
				_otherErrorTypes?: undefined;
		  });
	details?: {
		code?: string;
	};
};

/**
 * Special error type that should never happen in runtime accepts
 * never as parameter to ensure we do exhaustive switch statements
 */
export class UnreachableError extends Error {
	constructor(unreachable: never) {
		super(`Unreachable error: ${String(unreachable)}`);
		this.name = 'UnreachableError';
	}
}

export function ensureError(error: unknown): ErrorType {
	if (error instanceof Error) {
		return error;
	}
	// convert to error
	return new Error(`${String(error)}`);
}
