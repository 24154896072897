import React from 'react';
import {
	CircularProgress,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
	}),
);

type Props = {
	label?: string;
	showRandomLabels?: boolean;
};

const minTimeout: number = 800;
const maxTimeout: number = 2700;
const messagePool: string[] = [
	'Unleashing the hounds',
	'Reticulating splines',
	'Parsing for errors',
	'Snipping the red wire',
	'Snipping the blue wire',
	'Assembling the avengers',
	'Pre-heating the oven to 180',
	'Starting our engines',
	'Pixelating',
	'Sending in the clowns',
	'Staying woke',
	'Scanning the horizon',
	'Authorizing',
	'Opening the pod bay doors',
	'Starting our engines',
	'Getting ready to rumble',
	'Accelerating to 88mph',
	'Using the force',
	'Capturing the golden snitch',
	'Aligning the stars',
	'Doubling our rainbows',
	'Believing in ourselves',
	'Compiling',
	'Charging our lasers',
	'Reverse engineering',
	'Optimising',
	'Pruning branches',
	'Merging to production',
	'Promoting synergy',
	'Arriving at a consensus',
	'Getting to the chopper',
	'Being a goldfish',
	'Stirring gently',
	'Combining all ingredients',
	'Flushing cache',
	'Appending indexes',
	'Crunching the numbers',
	'Cooking the books',
	'Inviting to team',
	'Refering a friend',
	'Drawing bingo number',
	'Fighting for our right to party',
	'Disassembling',
	'Recombobulating',
	'Phoning home',
	'Bringing home the bacon',
];

function getRandomTimeout(min: number, max: number): number {
	return min + Math.floor(Math.random() * (max - min));
}

function getRandomMessage(usedMessages: Set<string>): string {
	const unusedMessages = messagePool.filter((m) => !usedMessages.has(m));
	return (
		unusedMessages[Math.floor(Math.random() * unusedMessages.length)] || ''
	);
}

const RandomLabels = () => {
	const [message, setMessage] = React.useState('Loading');
	const usedMessages = React.useMemo(() => new Set<string>(), []);

	const nextMessage = React.useCallback(() => {
		if (usedMessages.size >= messagePool.length) {
			usedMessages.clear();
		}
		const message = getRandomMessage(usedMessages);
		usedMessages.add(message);
		setMessage(message);
	}, [setMessage, usedMessages]);

	React.useEffect(() => {
		const randomTimeout = getRandomTimeout(minTimeout, maxTimeout);
		const labelTimer = setTimeout(nextMessage, randomTimeout);
		return () => {
			clearTimeout(labelTimer);
		};
	}, [nextMessage, message]);

	return <>{message}...</>;
};

export const Loading: React.ComponentType<Props> = ({
	label,
	showRandomLabels,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress color="inherit" />
			{showRandomLabels ? (
				<Typography>
					<RandomLabels />
				</Typography>
			) : (
				<Typography>{label || 'Loading'}</Typography>
			)}
		</div>
	);
};
