import React from 'react';
import { useFormikContext, useField } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useCurrentUser } from '../../../hooks';

import imgConfetti from '../../../assets/images/confetti.png';
import imgLogoWhite from '../../../assets/images/Logo-White.png';
import imgAward from '../../../assets/images/award.png';
import imgRooster from '../../../assets/images/rooster.png';

const useStyle = makeStyles(
	(/** @type {import('@material-ui/core').Theme} */ theme) => ({
		wrapper: {
			marginTop: 10,
			borderRadius: 5,
			height: 300,
			overflowY: 'auto',
			overflowX: 'hidden',
			scrollbarWidth: 'thin',
		},

		logo: {
			width: 50,
			marginBottom: 10,
		},

		basicContainer: {
			background: '#4E6AFF',
		},
		newUserContainer: {
			background: '#FF4E87',
		},

		illustrationContainer: {
			position: 'relative',
			height: '100%',
			paddingBottom: 30,
		},
		illustration: {
			height: 200,
			marginBottom: 10,
		},

		invitor: {
			color: 'white',
			fontSize: 16,
			fontWeight: 'bold',
		},
		gameTitle: {
			color: 'white',
			fontSize: 18,
			fontWeight: 'bold',
			marginBottom: 10,
		},
		title: {
			color: 'white',
			textAlign: 'center',
			fontWeight: 'bold',
			textTransform: 'uppercase',
			fontSize: 20,
		},
		thumbnail: {
			position: 'relative',
			height: 150,
			width: 150,
			borderRadius: 5,
			cursor: 'pointer',
			padding: 10,
			border: '4px solid transparent',
			margin: 4,
		},
		activeThumbnail: {
			borderColor: theme.palette.primary.main,
		},
		thumbnailTitle: {
			fontSize: '0.6rem',
			marginTop: 10,
		},
		thumbnailImage: {
			maxWidth: '100%',
			maxHeight: '60%',
		},
		thumbnailConfetti: {
			position: 'absolute',
			top: 10,
			maxWidth: '90%',
		},
	}),
);

const EmailTemplateSelectionSchema = Yup.object().shape({
	emailTemplate: Yup.string().trim(),
});

const EmailTemplateSelection = ({ name, onTemplateChange, game, games }) => {
	const classes = useStyle();

	const user = useCurrentUser();
	const { values } = useFormikContext();
	const [, meta, helpers] = useField(name);

	const { value } = meta;

	React.useEffect(() => {
		if (value) {
			onTemplateChange(EmailTemplates[value].illustration);
		}
	}, []);

	const handleChange = (template) => {
		if (value === template) {
			return;
		}
		helpers.setValue(template, false);

		onTemplateChange(EmailTemplates[template].illustration);
	};

	const currentGame = game?.id
		? game
		: games?.find((game) => game.id === values.games[0]);

	const EmailTemplates = {
		new_user: {
			illustration: (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="flex-end"
					className={clsx(
						classes.newUserContainer,
						classes.illustrationContainer,
					)}
				>
					<img
						src={imgConfetti}
						alt=""
						className={classes.thumbnailConfetti}
					/>

					<img src={imgLogoWhite} className={classes.logo} alt="" />
					<span className={classes.invitor}>
						{user?.displayName.split(' ')[0]} has invited you to:
					</span>
					<span className={classes.gameTitle}>{values?.team}</span>

					<img
						className={classes.illustration}
						src={imgAward}
						alt=""
					/>

					<span className={classes.title} style={{ width: 250 }}>
						who will be this week's champion?
					</span>
				</Box>
			),
			thumbnail: (
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="flex-end"
					alignItems="center"
					className={classes.newUserContainer}
				>
					<img
						src={imgConfetti}
						alt=""
						className={classes.thumbnailConfetti}
					/>
					<img
						src={imgAward}
						alt=""
						className={classes.thumbnailImage}
					/>
					<span
						className={clsx(classes.title, classes.thumbnailTitle)}
					>
						who will be this week's champion
					</span>
				</Box>
			),
		},
		basic: {
			illustration: (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="flex-end"
					className={clsx(
						classes.basicContainer,
						classes.illustrationContainer,
					)}
				>
					<img src={imgLogoWhite} className={classes.logo} alt="" />
					<span className={classes.invitor}>
						{user?.displayName.split(' ')[0]} has invited you to:
					</span>
					<span className={classes.gameTitle}>{values?.team}</span>

					<img
						className={classes.illustration}
						src={imgRooster}
						alt=""
					/>

					<span className={classes.title} style={{ width: 250 }}>
						i challenge you to a game of {currentGame?.name}
					</span>
				</Box>
			),
			thumbnail: (
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="flex-end"
					alignItems="center"
					className={classes.basicContainer}
				>
					<img
						src={imgRooster}
						alt=""
						className={classes.thumbnailImage}
					/>
					<span
						className={clsx(classes.title, classes.thumbnailTitle)}
					>
						i challenge you to a game of {currentGame?.name}
					</span>
				</Box>
			),
		},
	};

	return (
		<div>
			<Box
				className={classes.wrapper}
				display="flex"
				flexWrap="wrap"
				alignItems="flex-start"
			>
				{Object.keys(EmailTemplates).map((template) => (
					<div
						key={template}
						onClick={() => {
							handleChange(template);
						}}
					>
						{React.cloneElement(
							EmailTemplates[template].thumbnail,
							{
								className: clsx(
									classes.thumbnail,
									EmailTemplates[template].thumbnail.props
										.className,
									template === value &&
										classes.activeThumbnail,
								),
							},
						)}
					</div>
				))}
			</Box>
		</div>
	);
};

export { EmailTemplateSelectionSchema };
export default EmailTemplateSelection;
