import type firebase from 'firebase';

type FunctionsErrorCode = firebase.functions.FunctionsErrorCode;

export class FunctionsError extends Error {
	readonly code: firebase.functions.FunctionsErrorCode;
	readonly functionExecutionId?: string;
	readonly traceContext?: string;
	readonly httpStatus?: number;
	readonly details?: any;

	constructor(
		message: string,
		opts: {
			code: firebase.functions.FunctionsErrorCode;
			functionExecutionId?: string;
			traceContext?: string;
			httpStatus?: number;
			details?: any;
		},
	) {
		super(message);
		this.name = 'FunctionsError';
		this.code = opts.code;
		this.functionExecutionId = opts.functionExecutionId;
		this.traceContext = opts.traceContext;
		this.details = opts.details;
		this.httpStatus = opts.httpStatus;
	}
}

// https://github.com/firebase/firebase-js-sdk/blob/cd9ca9b7355020017e3463f02f12f2f74f6b3254/packages/functions/src/service.ts#L46
const errorCodeMap: { [name: string]: FunctionsErrorCode } = {
	OK: 'ok',
	CANCELLED: 'cancelled',
	UNKNOWN: 'unknown',
	INVALID_ARGUMENT: 'invalid-argument',
	DEADLINE_EXCEEDED: 'deadline-exceeded',
	NOT_FOUND: 'not-found',
	ALREADY_EXISTS: 'already-exists',
	PERMISSION_DENIED: 'permission-denied',
	UNAUTHENTICATED: 'unauthenticated',
	RESOURCE_EXHAUSTED: 'resource-exhausted',
	FAILED_PRECONDITION: 'failed-precondition',
	ABORTED: 'aborted',
	OUT_OF_RANGE: 'out-of-range',
	UNIMPLEMENTED: 'unimplemented',
	INTERNAL: 'internal',
	UNAVAILABLE: 'unavailable',
	DATA_LOSS: 'data-loss',
};

export interface HttpResponseBody {
	data?: unknown;
	result?: unknown;
	error?: {
		message?: unknown;
		status?: unknown;
		details?: unknown;
	};
}

function codeForHTTPStatus(status: number): FunctionsErrorCode {
	if (status >= 200 && status < 300) {
		return 'ok';
	}
	switch (status) {
		case 400:
			return 'invalid-argument';
		case 401:
			return 'unauthenticated';
		case 403:
			return 'permission-denied';
		case 404:
			return 'not-found';
		case 409:
			return 'aborted';
		case 429:
			return 'resource-exhausted';
		case 499:
			return 'cancelled';
		case 500:
			return 'internal';
		case 501:
			return 'unimplemented';
		case 503:
			return 'unavailable';
		case 504:
			return 'deadline-exceeded';
		default:
	}
	return 'unknown';
}

export function errorForResponse(
	httpStatus: number,
	responseHeaders: Headers,
	bodyJSON: HttpResponseBody | null,
): { error: Error; data?: undefined } | { error?: undefined; data: unknown } {
	const headersData = {
		// CORS settings might be preventing from reading these headers
		// so server side setup is required
		functionExecutionId:
			responseHeaders.get('function-execution-id') ?? undefined,
		traceContext: responseHeaders.get('x-cloud-trace-context') ?? undefined,
		httpStatus,
	};
	let code = codeForHTTPStatus(httpStatus);
	let description: string = code;
	let details: unknown = undefined;
	try {
		const errorJSON = bodyJSON && bodyJSON.error;
		if (errorJSON) {
			const status = errorJSON.status;
			if (typeof status === 'string') {
				const lookup = errorCodeMap[status];
				if (!lookup) {
					return {
						error: new FunctionsError('internal', {
							code: 'internal',
							...headersData,
						}),
					};
				}
				code = lookup;
				description = status;
			}

			const message = errorJSON.message;
			if (typeof message === 'string') {
				description = message;
			}

			details = errorJSON.details;
		}
	} catch (e) {}

	if (!bodyJSON) {
		throw new FunctionsError('Response is not valid JSON object.', {
			code: 'internal',
			...headersData,
		});
	}

	if (code === 'ok') {
		if (
			typeof bodyJSON.result === 'undefined' &&
			typeof bodyJSON.data === 'undefined'
		) {
			throw new FunctionsError('Response is missing data field.', {
				code: 'internal',
				...headersData,
			});
		}
		return {
			data: bodyJSON.result ?? bodyJSON.data,
		};
	}

	return {
		error: new FunctionsError(description, {
			code,
			details,
			...headersData,
		}),
	};
}
