import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Theme, Typography } from '@material-ui/core';
import {
	AccountSwitcher,
	DocumentHead,
	LinkButton,
	Loading,
	PageError,
	RemoteSocialLogo,
} from '../../components';
import { routes, useCurrentAccount, useSessionStorage } from '@common';
import { AccountResponse } from '@contracts/platform/accounts/account';
import { continuePathFromLocation } from '../continuePathFromLocation';

const useStyles = makeStyles((theme: Theme) => ({
	brand: {
		height: '3rem',
		fill: theme.palette.text.primary,
	},
	heading: {
		marginTop: theme.spacing(2),
	},
}));

type SelectAccountState = {
	accountWasSelected: boolean;
	setAccountWasSelected: (newValue: boolean) => void;
};

export const useSelectAccountState = (): SelectAccountState => {
	const [accountWasSelected, setAccountWasSelected] =
		useSessionStorage('accountWasSelected');

	return {
		accountWasSelected,
		setAccountWasSelected,
	};
};

export const SelectAccount: React.ComponentType = () => {
	const { setAccountWasSelected } = useSelectAccountState();
	const history = useHistory();
	const location =
		useLocation<ReturnType<typeof routes.selectAccount>['state']>();
	const nextUrl =
		continuePathFromLocation(location) || routes.home({ location });
	const {
		loadingAccounts,
		accounts,
		error,
		currentAccount,
		setCurrentAccount,
	} = useCurrentAccount();
	const classes = useStyles();

	const handleChange = React.useCallback(
		({ account }: { account: AccountResponse }) =>
			setCurrentAccount(account.accountId),
		[setCurrentAccount],
	);

	React.useEffect(() => {
		return history.listen(() => setAccountWasSelected(true));
	}, [history, setAccountWasSelected]);

	React.useEffect(() => {
		if (!loadingAccounts && accounts?.length === 1) {
			history.replace(nextUrl);
		}
	}, [accounts, history, loadingAccounts, nextUrl, setAccountWasSelected]);

	if (error) {
		return <PageError error={error} />;
	}

	return (
		<Grid container direction="column" alignItems="center" spacing={2}>
			<DocumentHead title="Select Team" />
			<Grid item>
				<RemoteSocialLogo variant="text" className={classes.brand} />
				<Typography
					component="h5"
					variant="h5"
					align="center"
					className={classes.heading}
				>
					SELECT TEAM
				</Typography>
			</Grid>
			{loadingAccounts ? (
				<Loading />
			) : (
				<>
					<Grid item>
						{currentAccount && accounts && (
							<AccountSwitcher
								selectedAccountId={currentAccount?.accountId}
								accounts={accounts}
								onChange={handleChange}
							/>
						)}
					</Grid>
					<Grid item>
						<LinkButton
							variant="contained"
							color="primary"
							disabled={!currentAccount?.accountId}
							to={nextUrl}
							size="large"
						>
							Select
						</LinkButton>
					</Grid>
				</>
			)}
		</Grid>
	);
};
