import React from 'react';
import { env } from '../environment';

type Opts = {
	title: string;
	baseUrl: string;
	subdomain: string;
	design?: {
		fontFamily: string[];
		background: { h: number; s: number; l: number; a: number };
		main: { h: number; s: number; l: number; a: number };
	};
};

type Props = {
	config: Opts;
};

export type AppConfig = Opts & {
	appUrl: string;
};

export const Config = React.createContext<AppConfig | undefined>(undefined);

export const ConfigProvider: React.ComponentType<Props> = ({
	config,
	children,
}) => {
	// TODO: This looks weird - in which case origin doesn't match the config?
	const appUrl =
		env().name !== 'local'
			? `https://${config.subdomain}.${config.baseUrl}`
			: global.window.location.origin;
	return (
		<Config.Provider
			value={{
				...config,
				appUrl,
			}}
		>
			{children}
		</Config.Provider>
	);
};
