import React from 'react';
import type { makeAccountsApi } from './Account';
import { AccountsContext } from './Account';
import { AccountResponse } from '@contracts/platform';

type MakeAccountsApi = typeof makeAccountsApi;

export type UseCurrentAccountsState = {
	currentAccount?: AccountResponse;
	currentAccountId?: string;
	accounts: Array<AccountResponse>;
	owned: Array<AccountResponse>;
	/** @deprecated (soon to be replaced by `status`) whether current account is loaded */
	isLoaded: boolean;
	/** @deprecated (soon to be replaced by `status`) whether list of accounts is loaded */
	loadingAccounts: boolean;
	error?: Error;
	refresh: () => void;
	needsAccountCreated?: boolean;
} & ReturnType<MakeAccountsApi>;

export const useCurrentAccount = (): UseCurrentAccountsState => {
	return React.useContext<UseCurrentAccountsState>(AccountsContext);
};
