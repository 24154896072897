import { Location, LocationDescriptorObject } from 'history';

export function isLocation(location: unknown): location is Location {
	// even though all properties in `Location` are required - we only need the pathname to be always
	// present to represent initialized location, the rest can be non-present or strings
	return Boolean(isLocationDescriptorObject(location) && location.pathname);
}

export function isLocationDescriptorObject(
	location: unknown,
): location is LocationDescriptorObject {
	const candidate = location as LocationDescriptorObject | undefined | null;
	return (
		typeof candidate === 'object' &&
		candidate !== null &&
		// at least one of these initialized to be a string
		(('pathname' in candidate && typeof candidate.pathname === 'string') ||
			('search' in candidate && typeof candidate.search === 'string') ||
			('hash' in candidate && typeof candidate.hash === 'string') ||
			// or we got state which can be anything
			'state' in candidate)
	);
}
