import { ensureError, ErrorType } from './types';

type Result<T> =
	| { status: 'error'; error: ErrorType; data?: undefined }
	| { status: 'success'; error?: undefined; data: T };

export async function catchErrorAsync<T>(fn: () => T): Promise<Result<T>>;
export async function catchErrorAsync<T>(
	fn: () => Promise<T>,
): Promise<Result<T>> {
	try {
		const data = await Promise.resolve(fn());
		return {
			status: 'success',
			data,
		};
	} catch (error: unknown) {
		return {
			status: 'error',
			error: ensureError(error),
		};
	}
}

export function catchError<T>(fn: () => T): Result<T> {
	try {
		const data = fn();
		return {
			status: 'success',
			data,
		};
	} catch (error: unknown) {
		return {
			status: 'error',
			error: ensureError(error),
		};
	}
}
