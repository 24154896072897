import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, DocumentHead, Link, RemoteSocialLogo } from '../../components';
import { useAuth } from '../../hooks';

// router
// import { useHistory } from 'react-router-dom';
// material-ui
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ErrorMessage } from '../../components/errorMessage';
import { isEmail } from '../../utils/validation';
import { registerError } from '../../errors';
import { continuePathFromLocation } from '../../routes/continuePathFromLocation';
import { routes } from '../../routes/routes';

// material-ui styles
const useStyles = makeStyles((theme) => ({
	brand: {
		height: '3rem',
		fill: theme.palette.common.blackish,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	loginTitle: {
		margin: theme.spacing(2, 0),
	},
	submitBtn: {
		background: '#444444',
		color: '#FFFFFF',
		fontWeight: '700',
		width: '100%',
	},
}));

// login component
export default function ResetPassword() {
	const classes = useStyles();
	const auth = useAuth();
	const [email, setEmail] = useState('');
	const [Loading, setLoading] = useState(false);
	const [AuthError, setAuthError] = useState('');
	const [submitted, setSubmitted] = useState();
	const location = useLocation();
	const continuePath =
		continuePathFromLocation(location) || routes.home({ location });

	// update credentials with current value
	const handleChange = (event) => {
		setEmail(event.target.value);
	};

	// dispatch the credentials to Firebase Auth
	const handleSubmit = async (event) => {
		event.preventDefault(); // block the form submit

		// don't call the API if the email is not valid
		if (!isEmail(email)) {
			setAuthError('Email is not valid');
			return;
		} else {
			// clean the error from an earlier state
			setAuthError('');
		}

		try {
			setLoading(true); // set loading state
			setAuthError(''); // reset error state

			await auth.sendPasswordResetEmail(email, continuePath);
			setSubmitted(true);
		} catch (error) {
			setLoading(false);
			switch (error.code) {
				case 'auth/user-not-found':
					setAuthError(
						"We couldn't find an account with that email address.",
					);
					break;
				default:
					registerError(error);
					setAuthError(
						'Something went wrong.' +
							(error.code ? ` (code: ${error.code})` : ''),
					);
					break;
			}
			console.error(error);
			return error;
		}
	};

	// render the reset Password form
	return (
		<Grid container align="center" justifyContent="center" spacing={2}>
			<Grid item xs={12} sm={10}>
				<DocumentHead title="Reset Password" />
				<Grid item xs={12} align="center">
					<RemoteSocialLogo className={classes.brand} />
					<Typography
						component="h5"
						variant="h5"
						align="center"
						className={classes.loginTitle}
					>
						RESET YOUR PASSWORD
					</Typography>
				</Grid>
				{submitted ? (
					<>
						<Grid item xs={12}>
							Done!
							<br />
							If an account exists with that email address, you'll
							receive a password reset email.
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12}>
							<form
								className={classes.form}
								noValidate
								onSubmit={handleSubmit}
							>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									type="email"
									id="email"
									label="Email address"
									name="email"
									autoFocus
									onChange={handleChange}
								/>
								<ErrorMessage error={AuthError} />
								<Button
									click={handleSubmit}
									text="Reset password"
									loading={Loading}
									className={classes.submitBtn}
								/>
							</form>
						</Grid>
						<Box mt={3} mb={3}>
							<Grid item container direction="row" spacing={2}>
								<Grid item align="center" xs={12}>
									<Link to="/login">
										Remembered your password? Login here
									</Link>
								</Grid>
								<Grid item align="center" xs={12}>
									<Link to="/register">
										Don't have an account? Register here
									</Link>
								</Grid>
							</Grid>
						</Box>
					</>
				)}
			</Grid>
		</Grid>
	);
}
