import React from 'react';
import { Button, Theme, useMediaQuery } from '@material-ui/core';
import { env } from '@common/environment';
import Logo from '@common/components/RemoteSocialLogo';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useCrossDomainUrl } from '../../routes/useCrossDomainUrl';

const useStyles = makeStyles((theme) => {
	return createStyles({
		logo: {
			width: '2.25rem',
			height: '2.25rem',
			fill: 'inherit',

			[theme.breakpoints.up('sm')]: {
				width: 'auto',
			},
		},
	});
});

export const Branding: React.ComponentType = () => {
	const styles = useStyles();
	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const hubUrl = useCrossDomainUrl({
		boundToOrigin: env().hubUrl,
	});

	return (
		<Button href={hubUrl} color="inherit">
			<Logo className={styles.logo} variant={isSm ? 'text' : 'mini'} />
		</Button>
	);
};
