import type { Firebase } from './init';
import { fromFetch } from 'rxjs/fetch';
import { defer, firstValueFrom, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import {
	errorForResponse,
	FunctionsError,
	HttpResponseBody,
} from './functionsError';
import { ensureError } from '../errors/types';

export type ApiCall = ReturnType<typeof createApiCall>;

const DEFAULT_FUNCTION_TIMEOUT = 15000;

/**
 * NOTE:
 * We are not using firebase.functions.httpsCallable anymore because we want to be able to get
 * additional information from the response headers, when possible. This information is crucial
 * for better diagnosing of errors and issues.
 *
 * This is very close to default implementation, except doesn't use appCheck or instanceId tokens
 * Which we can add later when we start using them
 * https://github.com/firebase/firebase-js-sdk/blob/master/packages/functions/src/service.ts#L222
 */

export function createApiCall(
	firebase: Firebase,
	defaultOptions: {
		baseUrl?: string;
		timeout?: number;
	},
) {
	const idToken = () =>
		Promise.resolve(firebase.auth().currentUser?.getIdToken());

	const apiCall = async (
		functionName: string,
		data: unknown,
		options?: {
			baseUrl?: string;
			timeout?: number;
		},
	) => {
		const timeoutMs =
			defaultOptions?.timeout ??
			options?.timeout ??
			DEFAULT_FUNCTION_TIMEOUT;

		const projectId = (firebase.app().options as { projectId?: string })
			.projectId;

		const baseUrl =
			options?.baseUrl ??
			defaultOptions?.baseUrl ??
			`https://us-central1-${projectId}.cloudfunctions.net`;

		const url = `${baseUrl}/${functionName}`;

		const token = await idToken();

		const response = await firstValueFrom(
			fromFetch(url, {
				method: 'POST',
				headers: {
					'x-start-timestamp': String(Date.now()),
					'Content-Type': 'application/json',
					...(token && {
						Authorization: `Bearer ${token}`,
					}),
				},
				body: JSON.stringify({ data: data ?? null }),
				selector: (response) =>
					defer(async () => {
						let json: HttpResponseBody | null = null;
						try {
							json = await response.json();
						} catch (err) {}
						const { error, data } = errorForResponse(
							response.status,
							response.headers,
							json,
						);
						if (error) {
							throw error;
						}
						return { data };
					}),
			}).pipe(
				timeout({
					each: timeoutMs,
					with: () =>
						throwError(
							() =>
								new FunctionsError(
									`Function timed out after ${(
										timeoutMs / 1000
									).toFixed(2)} seconds`,
									{
										code: 'deadline-exceeded',
									},
								),
						),
				}),
				catchError((error) => {
					const err = ensureError(error);
					if (!(err instanceof FunctionsError)) {
						// this happens when the fetch call itself throws
						// so we didn't even get a response object
						// could be CORS issue or anything else - this can
						// be diagnosed and gathered from the Sentry trace
						return throwError(
							() =>
								new FunctionsError(err.message, {
									code: 'internal',
								}),
						);
					}
					return throwError(() => err);
				}),
			),
		);

		return response;
	};

	return apiCall;
}
