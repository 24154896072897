import React from 'react';
import { isInitial, isPending, isError } from '../store-tools';
import { useSlackStore } from './store';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import {
	Avatar,
	CircularProgress,
	IconButton,
	makeStyles,
	TextField,
	createStyles,
} from '@material-ui/core';
import type { SlackUser } from '@contracts/slack';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SlackUserOption } from './slackUserOption';
import {
	determineUserStatus,
	helperTextFromStatus,
	requestFailedMessage,
	SlackUserSelectStatus,
} from './slackUserSelectState';

const useStyles = makeStyles((theme) =>
	createStyles({
		selectedAvatar: {
			width: '24px',
			height: '24px',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
	}),
);

type Props = {
	inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;

	value: string | SlackUser | undefined;
	onChange: (userName: string | SlackUser) => void;

	helperText?: string | Partial<Record<SlackUserSelectStatus, string>>;
	label?: string;
	placeholder?: string;
};

export const SlackUserSelect: React.ComponentType<Props> = React.memo(
	({
		inputRef,
		value,
		onChange: onChangeProp,
		helperText: helperTextProp,
		...rest
	}) => {
		const styles = useStyles();
		const { listUsersRequest, listUsers } = useSlackStore();

		const triggerLoadUsers = React.useCallback(() => {
			listUsers();
		}, [listUsers]);

		React.useEffect(() => {
			if (isInitial(listUsersRequest)) {
				triggerLoadUsers();
			}
		}, [listUsersRequest, triggerLoadUsers]);

		const userStatus = React.useMemo(
			() => determineUserStatus(value, listUsersRequest),
			[value, listUsersRequest],
		);

		const onChange = React.useCallback(
			(_: unknown, user: string | SlackUser | null) => {
				if (typeof user === 'object' && user !== null) {
					onChangeProp(user);
				} else if (typeof user === 'string') {
					onChangeProp(user);
				}
			},
			[onChangeProp],
		);
		const [inputValue, setInputValue] = React.useState(
			typeof value === 'object' ? value.displayName : value,
		);
		const onInputChange = React.useCallback(
			(_: unknown, value: string) => {
				setInputValue(value);
			},
			[setInputValue],
		);

		const onBlur = React.useCallback(() => {
			if (value !== inputValue) {
				const result = determineUserStatus(
					inputValue,
					listUsersRequest,
				);
				if (result.status === 'selected') {
					onChangeProp(result.entry);
				} else if (inputValue) {
					onChangeProp(inputValue);
				}
			}
		}, [inputValue, listUsersRequest, onChangeProp, value]);

		React.useEffect(() => {
			if (userStatus.status === 'selected') {
				onChangeProp(userStatus.entry);
			}
		}, [onChangeProp, userStatus.entry, userStatus.status]);

		const loading = isPending(listUsersRequest);
		const error = isError(listUsersRequest);

		const label = rest.label;
		const placeholder = rest.placeholder;
		const helperText = helperTextFromStatus(
			userStatus.status,
			helperTextProp,
		);

		const renderInput = React.useCallback(
			(params: AutocompleteRenderInputParams) => (
				<TextField
					{...params}
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					label={label}
					placeholder={placeholder}
					helperText={helperText}
					InputProps={{
						...params.InputProps,
						inputRef,
						startAdornment: userStatus.entry?.avatarUrl && (
							<Avatar
								src={userStatus.entry?.avatarUrl}
								variant="rounded"
								className={styles.selectedAvatar}
							/>
						),
						endAdornment: (
							<>
								{params.InputProps.endAdornment}
								{loading && (
									<CircularProgress
										size="1em"
										color="inherit"
									/>
								)}
								{error && (
									<IconButton
										onClick={triggerLoadUsers}
										size="small"
									>
										<RefreshIcon fontSize="small" />
									</IconButton>
								)}
							</>
						),
					}}
				/>
			),
			[
				error,
				helperText,
				inputRef,
				label,
				loading,
				placeholder,
				styles.selectedAvatar,
				triggerLoadUsers,
				userStatus.entry?.avatarUrl,
			],
		);

		return (
			<Autocomplete
				size="small"
				value={userStatus.entry ?? value}
				inputValue={inputValue}
				options={listUsersRequest.data}
				getOptionLabel={getOptionLabel}
				loading={error || loading}
				loadingText={error ? requestFailedMessage : 'Loading...'}
				onChange={onChange}
				onInputChange={onInputChange}
				freeSolo={true}
				renderOption={renderOption}
				renderInput={renderInput}
				openOnFocus={true}
				onBlur={onBlur}
			/>
		);
	},
);

function renderSlackUser(user: SlackUser) {
	return <SlackUserOption user={user} />;
}

const getOptionLabel = (option: string | SlackUser): string =>
	typeof option === 'object'
		? option.realName || option.displayName || option.slackUserId
		: option;

const renderOption = (option: string | SlackUser) =>
	typeof option === 'object' ? renderSlackUser(option) : <>{option}</>;
