import React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { Link, makeStyles } from '@material-ui/core';
import {
	AnimatedBackground,
	AppLayout,
	PageNotFound,
} from '@common/components';
import {
	AuthenticatedHostRoute,
	AuthenticatedRoute,
	getAuthRoutes,
} from '@common/routes';
import { AnimatedBackgroundSpeed } from '@common/contexts';
import Home from './features/home/Home';
import Create from './features/create/Create';
import Game from './features/game/Game';
import BingoLogo from './components/BingoLogo';

const useStyles = makeStyles((theme) => ({
	bingoHeader: {
		textAlign: 'center',
		position: 'relative',
	},
	logoWrapper: {
		display: 'inline-block',
	},
	logo: {
		width: 200,
		fill: '#fff',
		color: theme.palette.primary.main,
		height: 'auto',
		margin: theme.spacing(2, 'auto', 6),
		zIndex: 2,
		[theme.breakpoints.up('sm')]: {
			width: 300,
		},
	},
	content: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: 'calc(100vh + 200px)', // footer always below the fold
		background: theme.app.gradient,
		overflow: 'hidden',
		zIndex: 1,
	},
	bg: {
		background: theme.app.gradient,
	},
}));

export default function App() {
	const classes = useStyles();

	const colors = [
		(opacity) => `hsla(338, 50%, 47%, ${opacity})`,
		(opacity) => `hsla(36, 68%, 62%,  ${opacity})`,
		(opacity) => `hsla(185, 26%, 49%, ${opacity})`,
		(opacity) => `hsla(271, 35%, 47%, ${opacity})`,
		(opacity) => `hsla(195, 86%, 64%, ${opacity})`,
	];

	return (
		<AnimatedBackgroundSpeed>
			<AppLayout className={classes.content}>
				<AnimatedBackground
					blur={false}
					colors={colors}
					className={classes.bg}
					shapeCount={40}
					speed={100}
					sizeMultiplier={2}
					respawnArea="center"
					foreground={true}
					background={true}
				/>
				<div className={classes.bingoHeader}>
					<Link
						className={classes.logoWrapper}
						component={RouterLink}
						to="/"
					>
						<BingoLogo className={classes.logo} />
					</Link>
				</div>
				<Switch>
					{/* Home Route */}
					<Route exact path="/" component={Home} />

					{/* Auth Routes */}
					{getAuthRoutes()}

					{/* Game Routes */}
					<AuthenticatedHostRoute
						exact
						path="/create/:account/:game"
						component={Create}
					/>
					<AuthenticatedRoute
						path="/game/:account/:game"
						component={Game}
						redirectToPath="/register"
					/>
					{/* Default Route (404) */}
					<Route component={PageNotFound} />
				</Switch>
			</AppLayout>
		</AnimatedBackgroundSpeed>
	);
}
