import React from 'react';
import { ErrorType, registerError } from '../errors';
import { TimeoutError } from '../errors/timeoutError';

type Opts<T> = {
	milliseconds: number;
	timeoutWith?: T;
	disabledWhen: boolean;
	errorMessage?: () => string;
	onTimeout?: () => void;
};

export function useTimeoutState<T>(opts?: Opts<T>) {
	const [timeoutError, setTimeoutError] = React.useState<
		ErrorType | undefined
	>(undefined);
	const [timeoutValue, setTimeoutValue] = React.useState<T | null>(null);

	const disabledWhen = !opts || opts.disabledWhen;
	const timeoutMillis = opts?.milliseconds;
	const timeoutWith = opts?.timeoutWith;
	const errorMessage = React.useMemo(
		() =>
			opts?.errorMessage ??
			(() => `Timeout occurred at ${Number(timeoutMillis) / 1000}s`),
		[timeoutMillis, opts?.errorMessage],
	);

	React.useEffect(() => {
		if (disabledWhen) {
			return;
		}

		const timerId = setTimeout(() => {
			if (timeoutWith) {
				setTimeoutValue(timeoutWith);
			} else {
				setTimeoutError(new TimeoutError(errorMessage()));
			}
		}, timeoutMillis);

		return () => {
			// can be triggered by unmounting or disabling
			// the timeout
			clearTimeout(timerId);
		};
	}, [disabledWhen, errorMessage, timeoutMillis, timeoutWith]);

	const onTimeoutRef = React.useRef(opts?.onTimeout);

	React.useEffect(() => {
		const onTimeout = onTimeoutRef.current;
		if (onTimeout && (timeoutError || timeoutValue !== null)) {
			try {
				onTimeout();
			} catch (err) {
				registerError(err);
			}
		}
	}, [timeoutError, timeoutValue]);

	const state = React.useMemo(() => {
		if (timeoutError) {
			return {
				isTimeout: true as const,
				timeoutError,
			};
		} else if (timeoutValue !== null) {
			return {
				isTimeout: true as const,
				timeoutValue,
			};
		} else {
			return {
				isTimeout: false as const,
			};
		}
	}, [timeoutError, timeoutValue]);

	return state;
}
