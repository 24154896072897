// import the color tools from utils

import {
	getColorShade,
	getContrastTheme,
	applyOpacity,
	makeHSLA,
} from '@common';
import { responsiveFontSizes, createTheme } from '@material-ui/core';

// NOTE: This file is being deprecated

/**
 * @deprecated This is being deprecated
 */
export function oldRsTheme() {
	return {
		name: 'old-theme',
		typography: {
			fontSize: 16,
			fontFamily: ['Greycliff CF', 'sans-serif'],
			h1: {
				fontWeight: 300,
				fontSize: '3.5rem',
			},
			h2: {
				fontWeight: 400,
				fontSize: '2.3rem',
			},
			h3: {
				fontWeight: 400,
				fontSize: '1.9rem',
			},
			h4: {
				fontWeight: 500,
				fontSize: '1.6rem',
			},
			h5: {
				fontWeight: 700,
				fontSize: '1.3rem',
			},
			h6: {
				fontWeight: 700,
				fontSize: '0.95rem',
			},
		},
		shape: {
			borderRadius: 4, // used by CopyText
		},
		overrides: {
			MuiButton: {
				root: {
					borderRadius: '6px',
					fontWeight: 700,
				},
			},
			MuiInputBase: {
				root: {
					fontWeight: 500,
				},
			},
		},
	};
}

const white = { h: 180, s: 100, l: 100, a: 1 }; // white
const blackish = { h: 180, s: 0, l: 20, a: 1 }; // black
const black = { h: 180, s: 0, l: 0, a: 1 }; // black

/**
 * @deprecated This is being deprecated
 */
export const configureHeaderTheme = (appConfig) => (rsTheme) => {
	if (!appConfig || !appConfig.design) {
		return rsTheme;
	}

	const appMain = appConfig.design.main;
	const appBackground = appConfig.design.background;
	const appFont = appConfig.design.fontFamily;
	const headerBackground = appConfig.design.headerBackground || null;
	const headerText = appConfig.design.headerText || null;

	const main = appMain; // get the main color from the config
	const dark = getColorShade(appMain, 'dark'); // make a dark shade of the main color
	const light = getColorShade(appMain, 'light'); // make a light shade of the main color
	const type = getContrastTheme(headerBackground || appBackground); // get the contrast threshold based on the lightest color

	const text = headerText || (type === 'dark' ? main : blackish); // always dark
	const textBackground = white; // always light. Using main seems to never be a good idea (e.g. purple/teal text on black)
	const invert = type === 'dark' ? white : blackish;

	return {
		...rsTheme,
		name: 'old-header-theme',
		typography: {
			...rsTheme.typography,
			fontFamily: appFont || rsTheme.typography.fontFamily,
		},
		palette: {
			primary: {
				dark: makeHSLA(dark),
				main: makeHSLA(main),
				light: makeHSLA(light),
			},
			text: {
				primary: makeHSLA(text),
				secondary: makeHSLA(applyOpacity(text, 0.87)),
				disabled: makeHSLA(applyOpacity(text, 0.54)),
				hint: makeHSLA(applyOpacity(text, 0.38)),
			},
			divider: makeHSLA(applyOpacity(text, 0.12)),
			background: {
				default: makeHSLA(headerBackground || appBackground),
				paper: makeHSLA(white),
			},
		},
		app: {
			...rsTheme.app,
			main: makeHSLA(main),
			dark: makeHSLA(dark),
			light: makeHSLA(light),
			invert: makeHSLA(invert),
			gradient: `linear-gradient(45deg, ${makeHSLA(
				dark,
			)}, 48%, ${makeHSLA(light)})`,
		},
		overrides: {
			...rsTheme.overrides,
			MuiButton: {
				...rsTheme.overrides.MuiButton,
				contained: {
					backgroundColor: makeHSLA(text),
					color: makeHSLA(textBackground),
					'&:hover': {
						color: makeHSLA(textBackground),
						backgroundColor: makeHSLA(applyOpacity(text, 0.67)),
					},
					'&:disabled': {
						color: makeHSLA(textBackground),
						backgroundColor: makeHSLA(applyOpacity(text, 0.5)),
					},
				},
				outlined: {
					borderColor: makeHSLA(text),
					color: makeHSLA(text),
					'&:hover': {
						backgroundColor: makeHSLA(applyOpacity(text, 0.12)),
					},
				},
			},
			PrivateValueLabel: {
				label: {
					color: makeHSLA(invert),
					// TODO - specify the background color as well to ensure contrast?
				},
			},
			MuiFormLabel: {
				root: {
					'&.Mui-focused': {
						color: makeHSLA(text),
						// TODO - specify the background color as well to ensure contrast?
					},
				},
			},
			MuiTypography: {
				...rsTheme.overrides.MuiTypography,
				gutterBottom: {
					marginBottom: 20,
				},
			},
		},
	};
};

export const appTheme = (appConfig) => (rsTheme) => {
	// for backward compatibility between old appConfig that had design property
	// and new appConfig that doesn't have design property:
	if (!appConfig || !appConfig.design) {
		return rsTheme;
	}

	// the primary color - text and icons will be this color
	const appMain = appConfig.design.main;

	// the background of the header / app
	const appBackground = appConfig.design.background;

	const appFont = appConfig.design.fontFamily;

	// the background of paper
	const appPaper = appConfig.design.paper || white;
	const appOverrides = appConfig.design.overrides || {};

	const headerBackground = appConfig.design.headerBackground || null;
	const headerText = appConfig.design.headerText || null;
	const error = appConfig.design.error;

	const main = appMain; // get the main color from the config
	const dark = getColorShade(appMain, 'dark'); // make a dark shade of the main color
	const light = getColorShade(appMain, 'light'); // make a light shade of the main color
	const type = getContrastTheme(appBackground); // get the contrast threshold based on the lightest color
	const paperType = getContrastTheme(appPaper);
	const mainType = getContrastTheme(main);

	const text =
		paperType === 'dark' ? (mainType === 'dark' ? white : main) : blackish;
	const buttonBackground = paperType === 'dark' ? black : white;
	const invert = type === 'dark' ? white : blackish;

	return {
		...rsTheme,
		name: 'old-app-theme',
		typography: {
			...rsTheme.typography,
			fontFamily: appFont || rsTheme.typography.fontFamily,
		},
		palette: {
			primary: {
				dark: makeHSLA(dark),
				main: makeHSLA(main),
				light: makeHSLA(light),
			},
			text: {
				primary: makeHSLA(text),
				secondary: makeHSLA(applyOpacity(text, 0.87)),
				disabled: makeHSLA(applyOpacity(text, 0.54)),
				hint: makeHSLA(applyOpacity(text, 0.38)),
				header: headerText && makeHSLA(headerText),
			},
			divider: makeHSLA(applyOpacity(text, 0.12)),
			background: {
				default: makeHSLA(appBackground),
				paper: makeHSLA(appPaper || white),
				header: headerBackground && makeHSLA(headerBackground),
			},
			error: error && {
				main: makeHSLA(error),
			},
		},
		app: {
			...rsTheme.app,
			main: makeHSLA(main),
			dark: makeHSLA(dark),
			light: makeHSLA(light),
			invert: makeHSLA(invert),
			gradient: `linear-gradient(45deg, ${makeHSLA(
				dark,
			)}, 48%, ${makeHSLA(light)})`,
		},
		overrides: {
			...rsTheme.overrides,
			...appOverrides,
			MuiButton: {
				...rsTheme.overrides.MuiButton,
				contained: {
					backgroundColor: makeHSLA(text),
					color: makeHSLA(buttonBackground),
					'&:hover': {
						color: makeHSLA(applyOpacity(buttonBackground, 0.9)),
						backgroundColor: makeHSLA(applyOpacity(text, 0.67)),
					},
					'&:disabled': {
						color: makeHSLA(applyOpacity(buttonBackground, 0.5)),
						backgroundColor: makeHSLA(applyOpacity(text, 0.12)),
					},
				},
				outlined: {
					borderColor: makeHSLA(text),
					color: makeHSLA(text),
					'&:hover': {
						color: makeHSLA(text),
						backgroundColor: makeHSLA(applyOpacity(text, 0.12)),
					},
					'&:disabled': {
						color: makeHSLA(text),
						backgroundColor: makeHSLA(applyOpacity(text, 0.12)),
					},
				},
				...appOverrides.MuiButton,
			},
			PrivateValueLabel: {
				label: {
					color: makeHSLA(invert),
					// TODO - specify the background color as well to ensure contrast?
				},
			},
			MuiFormLabel: {
				root: {
					'&.Mui-focused': {
						color: makeHSLA(text),
						// TODO - specify the background color as well to ensure contrast?
					},
				},
			},
			MuiTypography: {
				...rsTheme.overrides.MuiTypography,
				gutterBottom: {
					marginBottom: 20,
				},
			},
		},
	};
};

/**
 * @deprecated This theme is being deprecated, new shared components and apps supposed to rely on new theme from "remoteSocialTheme.js"
 */
export const oldRemoteSocialTheme = responsiveFontSizes(
	createTheme(oldRsTheme()),
);
