import React, { useState, useMemo, forwardRef, createContext } from 'react';
import {
	Button,
	Toolbar,
	Menu,
	MenuItem,
	makeStyles,
	Divider,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	toggleButtonOn: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
	},
}));

const FilterContext = createContext();
const FilterBar = ({
	className,
	filter,
	initialFilter,
	onChange,
	children,
}) => {
	const [focusedID, setFocusedID] = useState(null);
	const [ownFilter, setOwnFilter] = useState(initialFilter);
	const _filter = filter || ownFilter;
	const context = useMemo(
		() => ({
			filter: _filter,
			focusedID,
			onFocus: setFocusedID,
			onBlur: () => setFocusedID(null),
			onChange: (filterID, value) => {
				const newFilter = {
					..._filter,
					[filterID]: value,
				};
				if (filter) {
					// use external state
					onChange && onChange(filterID, value, newFilter);
				} else {
					// maintain our own state
					setOwnFilter(newFilter);
				}
			},
		}),
		[filter, _filter, focusedID, onChange],
	);
	return (
		<Toolbar className={className}>
			<FilterContext.Provider value={context}>
				{children}
			</FilterContext.Provider>
		</Toolbar>
	);
};
const filterConsuming = (Component) => {
	function FilterConsuming(props) {
		const id = props.id;
		return (
			<FilterContext.Consumer>
				{({ filter, focusedID, onFocus, onBlur, onChange }) => (
					<Component
						value={filter[id]}
						hasFocus={focusedID === id}
						onFocus={() => onFocus(id)}
						onBlur={onBlur}
						onChange={(value) => onChange(id, value)}
						filter={filter}
						{...props}
					/>
				)}
			</FilterContext.Consumer>
		);
	}
	Object.defineProperty(FilterConsuming, 'name', {
		value: `FilterConsuming(${Component.name})`,
		enumerable: false,
		writable: false,
	});
	return FilterConsuming;
};
const FilterItemLabel = forwardRef(function FilterItemLabel(
	{ children, onClick, ...props },
	ref,
) {
	return (
		<Button {...props} ref={ref} onClick={onClick}>
			{children}
		</Button>
	);
});
const FilterToggleItem = filterConsuming(function SimpleFilterItem({
	value,
	onChange,
	onBlur,
	onFocus,
	children,
}) {
	const classes = useStyles();
	return (
		<FilterItemLabel
			className={clsx(value && classes.toggleButtonOn)}
			onClick={(e) => {
				e.preventDefault();
				onFocus();
				onChange(!value);
				onBlur();
			}}
		>
			{children}
		</FilterItemLabel>
	);
});
const FilterMenuItem = filterConsuming(function FilterMenuItem({
	id,
	label,
	options,
	value,
	hasFocus,
	onFocus,
	onBlur,
	onChange,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const selected = options.find((o) => o.value === value);
	return (
		<>
			<FilterItemLabel
				onClick={(e) => {
					e.preventDefault();
					setAnchorEl(e.currentTarget);
					onFocus();
				}}
				aria-haspopup="true"
				aria-controls={id + '-menu'}
			>
				{label}
				{selected ? (
					<>
						{' : '}
						{selected.label}
					</>
				) : (
					''
				)}
			</FilterItemLabel>
			<Menu
				id={id + '-menu'}
				anchorEl={anchorEl}
				open={hasFocus}
				onClose={onBlur}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						onClick={() => {
							onChange(option.value);
							onBlur();
						}}
					>
						{option.label}
					</MenuItem>
				))}
				{value && <Divider />}
				{value && (
					<MenuItem
						onClick={() => {
							onChange(undefined);
							onBlur();
						}}
					>
						Show all
					</MenuItem>
				)}
			</Menu>
		</>
	);
});

export { FilterBar, FilterMenuItem, FilterToggleItem };
