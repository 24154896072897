import {
	applyOpacity,
	getContrastTheme,
	getGreyShade,
	makeHSLA,
} from '../utils/colors';
import { createTheme, responsiveFontSizes, Theme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createTheme' {
	interface Theme {
		name: string;
		// TODO: Add custom theme options here, if absolutely necessary
	}
	interface ThemeOptions {
		name: string;
	}
}

const black = { h: 225, s: 0, l: 0, a: 1 };
const blackish = { h: 225, s: 0, l: 22, a: 1 };
const white = { h: 255, s: 0, l: 100, a: 1 };
const lightGrey = { h: 255, s: 0, l: 50, a: 1 };

// every app would create their own version of createPalette function
const createCoreThemePalette = (): PaletteOptions => {
	const primary = {
		main: makeHSLA(blackish),
		dark: makeHSLA(getGreyShade(blackish, 'dark')),
		light: makeHSLA(getGreyShade(blackish, 'light')),
		contrastText:
			getContrastTheme(blackish) === 'dark'
				? makeHSLA(white)
				: makeHSLA(blackish),
	};

	const secondary = {
		main: makeHSLA(lightGrey),
		dark: makeHSLA(getGreyShade(lightGrey, 'dark')),
		light: makeHSLA(getGreyShade(lightGrey, 'light')),
		contrastText:
			getContrastTheme(lightGrey) === 'dark'
				? makeHSLA(white)
				: makeHSLA(blackish),
	};

	return {
		common: {
			black: makeHSLA(black),
			white: makeHSLA(white),
		},
		primary: primary,
		secondary: secondary,
		text: {
			primary: primary.main,
			secondary: secondary.main,
			disabled: makeHSLA(applyOpacity(blackish, 0.5)),
			hint: makeHSLA(applyOpacity(blackish, 0.5)),
		},
		background: {
			paper: makeHSLA(white),
			default: makeHSLA(white),
		},
	};
};

const breakpoints = createBreakpoints(
	/* we use default which you can find here https://material-ui.com/customization/default-theme/#default-theme */
	{},
);

export const createRemoteSocialTheme = (opts: {
	name: string;
	createPalette: () => PaletteOptions;
}): Theme => {
	const relativeSpacing = (factor: number) => `${0.5 * factor}rem`;
	const palette = opts.createPalette();
	// if any color overrides exist below - they should use only palette
	return responsiveFontSizes(
		createTheme({
			// for diagnostic, tell users which theme they are using
			name: opts.name,
			palette,
			breakpoints,
			typography: {
				fontSize: 16,
				fontFamily: [
					'Greycliff CF',
					'Helvetica Neue',
					'sans-serif',
				].join(','),
				h1: {
					fontWeight: 400,
					fontSize: '3.25rem',
					lineHeight: 1.192,
				},
				h2: {
					fontWeight: 600,
					fontSize: '2.25rem',
					lineHeight: 1.111,
				},
				h3: {
					fontWeight: 600,
					fontSize: '1.5rem',
					lineHeight: 1.305,
				},
				h4: {
					fontWeight: 600,
					fontSize: '1.25rem',
					lineHeight: 1.305,
				},
				h5: {
					fontWeight: 600,
					fontSize: '1.0625rem',
					lineHeight: 1.305,
				},
				h6: {
					fontWeight: 600,
					fontSize: '1rem',
					lineHeight: 1.25,
				},
				subtitle1: {
					fontWeight: 600,
					fontSize: '1rem',
					lineHeight: 1.25,
				},
				subtitle2: {
					fontWeight: 600,
					fontSize: '0.875rem',
					lineHeight: 1.428,
				},
				body1: {
					fontWeight: 400,
					fontSize: '1rem',
					lineHeight: 1.25,
				},
				body2: {
					fontWeight: 400,
					fontSize: '0.875rem',
					lineHeight: 1.428,
				},
				caption: {
					fontWeight: 400,
					fontSize: '0.75rem',
					lineHeight: 1.425,
				},
				overline: {
					fontWeight: 600,
					fontSize: '0.75rem',
					lineHeight: 1.425,
				},
			},
			shape: {
				// used by CopyText
				borderRadius: 4,
			},
			// This might break existing material-ui components, so might be subject to change later
			spacing: relativeSpacing,
			overrides: {
				MuiCssBaseline: {
					'@global': {
						html: {
							minHeight: '100%',
							fontSize: '1rem',
						},
						body: {
							minHeight: '100%',
							fontSize: '1rem',
							minWidth: '320px',
						},
						'#root': {
							minHeight: '100%',
							position: 'relative',
							display: 'flex',
						},
					},
				},
				MuiButton: {
					root: {
						borderRadius: '6px',
						fontWeight: 600,
					},
				},
				MuiDialogTitle: {
					root: {
						padding: '1rem 1rem 0.5rem',
					},
				},
				MuiDialogContent: {
					root: {
						padding: '0.5rem 1rem',
					},
				},
				MuiDialogActions: {
					root: {
						padding: '0.5rem 1rem 1rem',
					},
				},
			},
		}),
		{
			factor: 2.857,
		},
	);
};

export const rsTheme = createRemoteSocialTheme({
	name: 'core-theme',
	createPalette: createCoreThemePalette,
});
