import React from 'react';
import { useField } from 'formik';
import { TimeSelect } from '@remote-social/common';
import { DateTime } from 'luxon';

type TimeSelectProps = Omit<
	React.ComponentProps<typeof TimeSelect>,
	'value' | 'onChange'
>;

type Props = {
	name: string;
} & TimeSelectProps;

export const TimeSelectField: React.ComponentType<Props> = (props) => {
	const [field, meta, helpers] = useField(props.name);

	const { value } = meta;
	const { setValue } = helpers;

	const handleTimeChange = (date: DateTime) => {
		setValue(date);
	};

	return (
		<TimeSelect
			{...props}
			{...field}
			value={value}
			onChange={handleTimeChange}
		/>
	);
};
