export function filterUndefined<T>(instance: T): T {
	return Object.entries(instance).reduce(
		(acc, [key, value]: [string, unknown]) => {
			return {
				...acc,
				...(value !== undefined && {
					[key]: value,
				}),
			};
		},
		{} as T,
	);
}
