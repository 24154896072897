import React, { useRef, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSessionStorage, AudioControl } from '@remote-social/common';
import { useSpring, animated } from 'react-spring';
import useSound from 'use-sound';

import imgStamp from '../assets/images/stamp.png';
import sndStamp from '../assets/sounds/stamp2.mp3';

const useStyles = makeStyles((theme) => ({
	'@keyframes stamp': {
		'0%': {
			transform: 'scale(2.5)',
			opacity: 0,
		},
		'100%': {
			transform: 'scale(1)',
			opacity: 0.25,
		},
	},
	root: {
		position: 'relative',
		display: 'inline-block',
	},
	stamp: {
		width: 60,
		height: 60,
		position: 'absolute',
		pointerEvents: 'none',
		marginLeft: -20,
		marginTop: -20,
		'&::before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundImage: `url(${imgStamp})`,
			backgroundSize: 'contain',
			animation: '$stamp 150ms ease-in forwards',
		},
		[theme.breakpoints.up('sm')]: {
			marginLeft: -40,
			marginTop: -40,
			width: 80,
			height: 80,
		},
	},
}));

export default function Stampable({ className, storageKey, children }) {
	const classes = useStyles();
	const ref = useRef();
	const [stamps, setStamps] = useSessionStorage(storageKey || 'stamps', []);
	const [impacted, setImpacted] = useState(false);
	const props = useSpring({
		to: impacted
			? [{ transform: 'scale(0.98)' }, { transform: 'scale(1)' }]
			: {},
		from: { transform: 'scale(1)' },
		config: { duration: 50 },
		delay: 100,
		onRest(ds) {
			setImpacted(false);
		},
	});

	const { isMuted } = useContext(AudioControl);
	const [playStamp] = useSound(sndStamp);

	function handleClick(event) {
		const bounds = ref.current.getBoundingClientRect();
		const x = event.clientX - bounds.left;
		const y = event.clientY - bounds.top;

		if (!isMuted) {
			playStamp();
		}

		setImpacted(true);

		setStamps((state) => [
			...state,
			{
				x: (x / bounds.width) * 100,
				y: (y / bounds.height) * 100,
				rotation: Math.floor(Math.random() * 360),
			},
		]);
	}

	return (
		<animated.div
			ref={ref}
			style={props}
			className={clsx(classes.root, className)}
			onClick={handleClick}
		>
			{children}
			{stamps.map((stamp, index) => (
				<div
					key={index}
					className={classes.stamp}
					style={{
						transform: `rotate(${stamp.rotation}deg)`,
						left: stamp.x + '%',
						top: stamp.y + '%',
					}}
				/>
			))}
		</animated.div>
	);
}
