import React from 'react';
import {
	createStyles,
	List,
	ListItem,
	ListItemIcon,
	makeStyles,
	Paper,
	SvgIcon,
	Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Plan } from './plan';
import { ReactComponent as ProGoldBadge } from '../../../assets/images/pro-gold.svg';

const useStyles = makeStyles((theme) =>
	createStyles({
		planCard: {
			position: 'relative',
			width: '100%',
			padding: theme.spacing(2),
		},
		top: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			borderBottom: `1px solid ${theme.palette.grey[200]}`,
		},
		checked: {
			position: 'absolute',
			top: theme.spacing(2),
			right: theme.spacing(2),
			width: '2rem',
			height: '2rem',

			'& path': {
				fill: theme.palette.primary.main,
			},
		},
		title: { fontWeight: 'bold', textAlign: 'center' },
		pricing: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'center',
		},
		price: {
			fontWeight: 'bold',
			padding: 0,
			margin: 0,
		},
		currency: { padding: 0, margin: 0 },
		priceNote: {
			textAlign: 'center',
		},
		bottom: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			paddingTop: theme.spacing(1),
		},
		featuresHeading: {
			padding: `${theme.spacing(1)} 0`,
		},
		featureItem: {
			padding: 0,
		},
		featureIcon: {
			minWidth: '36px',
		},
	}),
);

type Props = {
	plan: Plan;
	active?: boolean;
	showFeatureDescription?: boolean;
};

export const PlanCard: React.ComponentType<Props> = ({
	plan,
	active = false,
}) => {
	const styles = useStyles();

	return (
		<Paper className={styles.planCard}>
			<div className={styles.top}>
				{active && (
					<CheckCircleOutlineIcon className={styles.checked} />
				)}
				<Typography variant="h2" className={styles.title}>
					{plan.title}
					{plan.icon === 'pro' && (
						<SvgIcon component={ProGoldBadge} />
					)}
				</Typography>
				<div className={styles.pricing}>
					<Typography variant="h1" className={styles.price}>
						{plan.price}
					</Typography>
					<Typography variant="body1" className={styles.currency}>
						{plan.currency}
					</Typography>
				</div>
				<Typography variant="body2" className={styles.priceNote}>
					{plan.priceNote}
				</Typography>
			</div>
			<div className={styles.bottom}>
				<List
					subheader={
						<Typography
							variant="h6"
							className={styles.featuresHeading}
						>
							What's included:
						</Typography>
					}
				>
					{plan.features.map((feature) => (
						<ListItem
							className={styles.featureItem}
							key={feature.planFeatureId}
						>
							<ListItemIcon className={styles.featureIcon}>
								<CheckIcon />
							</ListItemIcon>
							<Typography variant="subtitle2">
								{feature.intro}
							</Typography>
						</ListItem>
					))}
				</List>
			</div>
		</Paper>
	);
};
