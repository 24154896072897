import React from 'react';
import { registerError } from '../errors';

const getValue = (storageProp, key, value) => {
	try {
		const item = window[storageProp].getItem(key);
		return item ? JSON.parse(item) : value;
	} catch (error) {
		registerError(error);
		return value;
	}
};

const makeUseStorage = (storageProp) =>
	function (key, initialValue) {
		const storedValueRef = React.useRef(
			getValue(storageProp, key, initialValue),
		);

		const [storedValue, setStoredValue] = React.useState(
			storedValueRef.current,
		);

		const setValue = React.useCallback(
			(value) => {
				try {
					const valueToStore =
						value instanceof Function
							? value(storedValueRef.current)
							: value;

					storedValueRef.current = valueToStore;
					setStoredValue(valueToStore);

					window[storageProp].setItem(
						key,
						JSON.stringify(valueToStore),
					);
				} catch (error) {
					registerError(error);
				}
			},
			[key, setStoredValue],
		);

		return [storedValue, setValue];
	};

export const useSessionStorage = makeUseStorage('sessionStorage');
export const useLocalStorage = makeUseStorage('localStorage');
