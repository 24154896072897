import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { isDevBuild } from '../environment';

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: '100%',
			height: 'auto',
			paddingTop: (props: { width: number; height: number }) =>
				`calc(${(props.height / props.width).toFixed(3)} * 100%)`,
			position: 'relative',
			[`& > img, & > .MuiAvatar-root, & > .MuiSkeleton-root`]: {
				height: '100%',
				width: '100%',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
			},
		},
	}),
);

// These will be inferred from the children if not specified
type Props =
	| {
			width?: number;
			height?: number;
			aspectRatio?: undefined;
	  }
	| {
			/**
			 * Ratio: width / height
			 */
			aspectRatio: number;
	  };

const isImageElement = (
	value: React.ReactNode,
): value is React.ReactElement<React.ImgHTMLAttributes<HTMLImageElement>> => {
	return React.isValidElement(value) && value.type === 'img';
};

const inferDimensions = (node: React.ReactNode, props: Props) => {
	if (typeof props.aspectRatio === 'number') {
		// ratio = width / height
		// width = ratio * height
		// height = width / ratio
		return {
			width: 100,
			height: 100 / props.aspectRatio,
		};
	}
	if (
		('width' in props || 'height' in props) &&
		props.width &&
		props.height
	) {
		return {
			width: props.width,
			height: props.height,
		};
	}
	if (!isImageElement(node)) {
		return undefined;
	}
	const width = Number(node.props.width);
	const height = Number(node.props.height);
	if (!Number.isFinite(width) || !Number.isFinite(height)) {
		return undefined;
	}
	return {
		width,
		height,
	};
};

/**
 * Ensures image contained within has fluid width/height to grow
 * and shrink as the container of the image grows and shrink and conform
 * to aspect ratio calculated from props solving layout shift issues
 * in cases when images are being loaded by the browser
 *
 * NOTE: This seemed to mess up MUIGrid based layouts so not making
 * this an Img component before we test this in different situations
 */
export const FluidImgContainer: React.ComponentType<Props> = (props) => {
	const dimensions = inferDimensions(props.children, props);
	const styles = useStyles(
		dimensions || {
			width: 1,
			height: 1,
		},
	);
	if (!dimensions) {
		if (isDevBuild() && isImageElement(props.children)) {
			throw new Error(
				'💥  Please set width and height on the image within FluidImgContainer or ImageBackground components',
			);
		}
		return <>{props.children}</>;
	}
	return <div className={styles.container}>{props.children}</div>;
};
