import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DocumentHead } from './documentHead';

const useStyles = makeStyles(() => ({
	center: {
		display: 'flex',
		placeContent: 'center',
		alignSelf: 'center',
		flex: 1,
		height: '100%',
	},
}));

export default function PageNotFound() {
	const styles = useStyles();
	return (
		<div className={styles.center}>
			<DocumentHead title="Page not found" />
			<div>
				<h1>Page not found</h1>
				<a href="/">Home</a>
			</div>
		</div>
	);
}
