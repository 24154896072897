import * as Yup from 'yup';

/*
 * Email regex as on firebase security recommendation
 * check https://firebase.google.com/docs/reference/security/database/regex for more info
 * */
const emailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$', 'i');

export const emailSchema = Yup.string()
	.matches(emailRegex, 'Must be a valid email address')
	.transform((value) =>
		typeof value === 'string' ? value.toLowerCase() : value,
	);

export const isEmail = (str) => {
	if (!str) {
		return false;
	}
	return emailRegex.test(str);
};
