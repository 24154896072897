import { firstValueFrom, timer } from 'rxjs';
import { isDevBuild } from '../environment/isDevBuild';
import { once } from '../utils/once';
import { ApiCall } from './apiCall';

const CHAOS_CHANCE = 0.0;
const DELAY = 0;
const delayPerFunctions = new Map<string, number>([
	// ['platform-createGame', 5000]
	// ['platform-getAccounts', 5000],
]);
const failFunctions = new Map<string, Error>([
	// ['checkin-create', new Error('Failed')],
	// ['checkin-join', new Error('Failed')],
	// ['checkin-start', new Error('Failed')],
	// ['checkin-selectCard', new Error('Failed')],
	// ['checkin-finishPresentingTurn', new Error('Failed')],
	// ['checkin-hostNextPresenter', new Error('Failed')],
	// ['checkin-hostPreviousPresenter', new Error('Failed')],
	// ['platform-getSlackIntegrations', new Error('Failed')],
	// ['platform-generateSlackOAuthURL', new Error('Failed')],
	// ['platform-getAccounts', new Error('Failed')],
	// ['platform-createGame', new Error('Failed')],
	// ['platform-listChannels', new Error('Failed')],
]);

export const isChaosMonkeyEnabled = once(() => {
	const chaosEnabled = isDevBuild();
	if (chaosEnabled) {
		const effects = [
			CHAOS_CHANCE > 0 &&
				`by imitating failure of ${CHAOS_CHANCE.toFixed(
					2,
				)} of your requests`,
			DELAY > 0 &&
				`by delaying responses of requests by ${(DELAY / 1000).toFixed(
					2,
				)} seconds`,
			delayPerFunctions.size > 0 &&
				`by delaying following functions: ${[
					...delayPerFunctions.keys(),
				].join(', ')}`,
			failFunctions.size > 0 &&
				`by failing following functions: ${[
					...failFunctions.keys(),
				].join(', ')}`,
		].filter(Boolean);

		console.warn(
			`🙈 Local chaos monkey is going to help you debug your app:\n`,
			effects.length > 0
				? effects.map((effect) => `  --> ${effect}`).join('\n')
				: '  --> You can set it up by modifying constants in "chaosMonkey.ts"',
		);
	}
	return chaosEnabled;
});

export const withLocalChaosMonkey = (apiCall: ApiCall): ApiCall => {
	if (!isChaosMonkeyEnabled()) {
		return apiCall;
	}
	let numberOfCalls = 0;
	const apiCallWithMonkeyInIt: ApiCall = (functionName: string, ...rest) => {
		const stack = new Error().stack;
		numberOfCalls += 1;
		return Promise.resolve()
			.then(async () => {
				if (failFunctions.size > 0) {
					const error = failFunctions.get(functionName);
					if (error) {
						throw error;
					}
				}
			})
			.then(() => {
				const roll = Math.random();
				if (roll < CHAOS_CHANCE) {
					console.warn(
						`🙈 Failing function with name ${functionName}`,
						{
							roll,
							chaosChance: CHAOS_CHANCE,
							numberOfCalls,
						},
					);
					const error = new Error('INTERNAL');
					error.stack = stack;
					throw error;
				}
			})
			.then(() => apiCall(functionName, ...rest))
			.then(async (result) => {
				const delay = delayPerFunctions.get(functionName) ?? DELAY;
				if (delay > 0) {
					await firstValueFrom(timer(delay));
				}
				return result;
			});
	};
	return apiCallWithMonkeyInIt;
};
