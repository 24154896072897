import React, {
	useCallback,
	useState,
	forwardRef,
	useImperativeHandle,
	useMemo,
} from 'react';

import { useDropzone } from 'react-dropzone';
import ImageCrop from './ImageCrop';
import { Dialog, makeStyles } from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { useField } from 'formik';
import { Loading } from '@common';
import { registerError } from '@common';

const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'relative',
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		border: 'none',
		borderRadius: 4,
	},
}));

const DropZoneField = forwardRef((props, ref) => {
	const [field, meta, helpers] = useField(props.name);
	const { onImageUploaded, description, uploader } = props;

	const classes = useStyles();
	const firebase = useFirebase();

	const [droppedFile, setDroppedFile] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [uploading, setUploading] = useState(false);

	const handleChange = useCallback(
		(result) => {
			onImageUploaded && onImageUploaded(result);
			helpers.setValue(result);
		},
		[helpers, onImageUploaded],
	);

	const onCropCancelled = () => {
		setDroppedFile(null);
		setShowCropper(false);
	};

	const onDrop = useCallback(
		async (acceptedFiles) => {
			if (field && acceptedFiles && acceptedFiles[0]) {
				// get the webkit URL of the blob
				let imageDataUrl = await readFile(acceptedFiles[0]);

				// save in local state to be used later
				setDroppedFile(imageDataUrl);

				// open the cropper Modal
				setShowCropper(true);

				// now we wait for the user to finish the crop process
			}
		},
		[field],
	);

	const uploadFileToCloud = useCallback(
		async (file) => {
			// convert file to base64 string
			const base64File = await new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});

			// send the string to cloud function
			const uploadResponse = await firebase
				.functions()
				.httpsCallable(uploader)({
				file: base64File,
			});

			// return the public URL of the image
			return uploadResponse.data;
		},
		[uploader, firebase],
	);

	const onImageCropped = useCallback(
		async (image) => {
			setShowCropper(false);

			// once image cropper sends us back the image
			try {
				setUploading(true);
				let response = await uploadFileToCloud(image);

				// we have to do this stupid delay
				// for the image to be made accessible through our CDN globally
				// otherwise get 404 in image
				console.log('response', response);
				await new Promise((resolve) => {
					setTimeout(() => {
						resolve();
					}, 2000);
				});

				if (response) {
					handleChange(response);
				}
			} catch (error) {
				// there was an error. we should show some kind of toast
				registerError(error);
			} finally {
				setUploading(false);
			}
		},
		[handleChange, uploadFileToCloud],
	);

	const Wrapper = props.placeholder || 'div';

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		accept: 'image/jpeg, image/png',
	});

	useImperativeHandle(ref, () => ({
		openFile() {
			open();
		},
	}));

	const renderImage = useMemo(() => {
		return (
			<img
				className={classes.img}
				src={`${meta.value}`}
				alt={description || 'Upload Image'}
			/>
		);
	}, [meta.value, classes.img, description]);

	return (
		<>
			<Wrapper
				{...getRootProps()}
				{...props.placeholderProps}
				onClick={props.onClick || getRootProps().onClick}
				className={classes.wrapper}
			>
				<input {...getInputProps()} />
				{meta?.value ? renderImage : null}
				{uploading && <Loading label="Uploading" />}
			</Wrapper>
			<Dialog
				open={showCropper}
				onClose={onCropCancelled}
				maxWidth={false}
				PaperProps={{
					style: {
						padding: 0,
						width: 496,
					},
				}}
			>
				<ImageCrop
					{...props.cropperProps}
					image={droppedFile}
					onImageCropped={onImageCropped}
					onCropCancelled={onCropCancelled}
				/>
			</Dialog>
		</>
	);
});

function readFile(file) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

export default DropZoneField;
