import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	main: {
		position: 'relative',
		// paddingTop: theme.spacing(6),
		// paddingBottom: theme.spacing(6)
	},
}));

export default function MainContent({ className, children }) {
	const classes = useStyles();
	return <main className={clsx(classes.main, className)}>{children}</main>;
}
