import { fullPathFromLocation } from './fullPathFromLocation';
import { useCurrentAccount } from '../contexts/useCurrentAccount';
import { LocationDescriptor } from 'history';

export function buildCrossDomainUrl(params: {
	boundToOrigin?: string;
	location?: LocationDescriptor;
	accountId: string | undefined;
}) {
	const origin = window.location.origin;
	const url = new URL(
		`${params.boundToOrigin || origin}${
			params.location ? fullPathFromLocation(params.location) : '/'
		}`,
	);
	if (params.boundToOrigin !== origin) {
		if (!url.searchParams.has('accountId') && params.accountId) {
			url.searchParams.set('accountId', params.accountId);
		}
	}
	return url.toString();
}

export function useCrossDomainUrl(params: {
	boundToOrigin?: string;
	location?: LocationDescriptor;
}) {
	const { currentAccountId } = useCurrentAccount();
	return buildCrossDomainUrl({
		...params,
		accountId: currentAccountId,
	});
}
