import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { AccountAvatar, Link } from '../../../components';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentAccount } from '../../../contexts';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import GamesIcon from '@material-ui/icons/Games';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { hubRoutes } from '../../../routes/hubRoutes';
import { appName, env } from '../../../environment';
import { AccountResponse } from '@contracts/platform/accounts/account';
import { useRolesCheck } from '@common/hooks/useRolesCheck';
import { ContinueState, useConfigFlag } from '@common';
import { LocationDescriptorObject } from 'history';
import { buildCrossDomainUrl } from '@common/routes/useCrossDomainUrl';

const useStyles = makeStyles((theme) => {
	return createStyles({
		currentAccountName: {
			maxWidth: '20ch',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			fontSize: '20px',
			lineHeight: 1,
		},
		currentAccountDetails: {
			maxWidth: '20ch',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			color: theme.palette.text.secondary,
		},
		menuItem: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			height: theme.spacing(4),
			color: 'inherit',
		},
		teamMenuItem: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			height: theme.spacing(7),
			color: 'inherit',
		},
		menuItemIcon: {
			justifyContent: 'center',
			width: '40px',
			marginRight: theme.spacing(1),
		},
		menuItemContent: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			gap: theme.spacing(0.5),
			height: '100%',
			width: '100%',
		},
		name: {
			maxWidth: '22ch',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		icon: {
			width: theme.spacing(5),
			height: theme.spacing(5),
			borderRadius: '50%',
			fontSize: '1rem',
			color: theme.palette.text.secondary,
		},
		newWindowIcon: {
			width: theme.spacing(2),
			height: theme.spacing(2),
			margin: `0 ${theme.spacing(1)}`,
			padding: 0,
			borderRadius: '50%',
			fontSize: '1rem',
			color: theme.palette.grey[500],
		},
		subheading: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			textTransform: 'uppercase',
			color: theme.palette.text.secondary,
		},
		divider: {
			minHeight: 'auto',
			marginBottom: theme.spacing(1.5),
		},
	});
});

type Props = {
	handleClose: () => void;
	accountSelectorHidden: boolean;
};

type State = {
	currentAccount?: AccountResponse;
	alternateAccounts: AccountResponse[];
	selectAccount: (accountId: string) => void;
	redirectRequired: boolean;
	goToLink: (route: LocationDescriptorObject<ContinueState>) => void;
	isOwnerOrAdmin: boolean;
	isOwner: boolean;
	billingEnabled: boolean;
};

const useState = ({ handleClose }: Props) => {
	const history = useHistory();
	const location = useLocation();
	const { currentAccount, currentAccountId, setCurrentAccount, accounts } =
		useCurrentAccount();
	const billingEnabled = useConfigFlag('billingEnabled').asBoolean();
	const ownerAdminRequest = useRolesCheck({
		roles: ['owner', 'admin'],
	});
	const ownerRequest = useRolesCheck({
		roles: ['owner'],
	});
	const isOwnerOrAdmin = ownerAdminRequest.status === 'allowed';
	const isOwner = ownerRequest.status === 'allowed';
	const redirectRequired = appName() !== 'hub';

	const selectAccount = React.useCallback(
		(accountId: string) => {
			handleClose();
			setCurrentAccount(accountId);
			history.push(hubRoutes.home({ location }));
		},
		[handleClose, history, location, setCurrentAccount],
	);

	const goToLink = React.useCallback(
		(route) => {
			handleClose();

			if (!currentAccountId) {
				return;
			}

			if (!redirectRequired) {
				history.push(route);
			} else {
				const hubSettingsUrl = buildCrossDomainUrl({
					boundToOrigin: env().hubUrl,
					location: route,
					accountId: currentAccountId,
				});
				window.open(
					hubSettingsUrl.toString(),
					'_blank',
					'noopener noreferrer',
				);
			}
		},
		[handleClose, currentAccountId, redirectRequired, history],
	);

	const result = React.useMemo<State>(() => {
		const alternateAccounts = currentAccount?.accountId
			? accounts.filter(
					(account) => account.accountId !== currentAccount.accountId,
			  )
			: [];

		return {
			currentAccount,
			alternateAccounts,
			selectAccount,
			redirectRequired,
			isOwnerOrAdmin,
			isOwner,
			goToLink,
			billingEnabled,
		};
	}, [
		currentAccount,
		accounts,
		selectAccount,
		redirectRequired,
		isOwnerOrAdmin,
		isOwner,
		goToLink,
		billingEnabled,
	]);

	return result;
};

export const AccountLinks: React.ComponentType<Props> = (props) => {
	const styles = useStyles();
	const location = useLocation();
	const { handleClose, accountSelectorHidden } = props;
	const {
		currentAccount,
		alternateAccounts,
		selectAccount,
		redirectRequired,
		goToLink,
		isOwnerOrAdmin,
		isOwner,
		billingEnabled,
	} = useState(props);

	if (!currentAccount) {
		return <></>;
	}

	return (
		<>
			<MenuItem
				key={currentAccount.accountId}
				className={styles.teamMenuItem}
				onClick={() => goToLink(hubRoutes.home({ location }))}
			>
				<ListItemIcon className={styles.menuItemIcon}>
					<AccountAvatar account={currentAccount} />
				</ListItemIcon>

				<div className={styles.menuItemContent}>
					<Typography
						variant="h4"
						className={styles.currentAccountName}
					>
						{currentAccount.name}
					</Typography>
					{currentAccount.role === 'owner' && (
						<Typography
							variant="caption"
							className={styles.currentAccountDetails}
						>
							(Owner)
						</Typography>
					)}
				</div>
			</MenuItem>
			<MenuItem
				onClick={() =>
					goToLink(hubRoutes.settings.people({ location }))
				}
				className={styles.menuItem}
				key="People"
			>
				<ListItemIcon className={styles.menuItemIcon}>
					<PeopleIcon fontSize="small" />
				</ListItemIcon>
				<Typography variant="body1" className={styles.menuItemContent}>
					People
				</Typography>
				{redirectRequired && (
					<OpenInNewIcon
						fontSize="small"
						color="secondary"
						className={styles.newWindowIcon}
					/>
				)}
			</MenuItem>
			{isOwnerOrAdmin && (
				<MenuItem
					onClick={() =>
						goToLink(hubRoutes.settings.activity.root({ location }))
					}
					className={styles.menuItem}
					key="Activity Settings"
				>
					<ListItemIcon className={styles.menuItemIcon}>
						<GamesIcon fontSize="small" />
					</ListItemIcon>
					<Typography
						variant="body1"
						className={styles.menuItemContent}
					>
						Activity Settings
					</Typography>
					{redirectRequired && (
						<OpenInNewIcon
							fontSize="small"
							color="secondary"
							className={styles.newWindowIcon}
						/>
					)}
				</MenuItem>
			)}
			{isOwnerOrAdmin && (
				<MenuItem
					onClick={() =>
						goToLink(hubRoutes.settings.account({ location }))
					}
					className={styles.menuItem}
					key="Team Settings"
				>
					<ListItemIcon className={styles.menuItemIcon}>
						<SettingsIcon fontSize="small" />
					</ListItemIcon>
					<Typography
						variant="body1"
						className={styles.menuItemContent}
					>
						Team Settings
					</Typography>
					{redirectRequired && (
						<OpenInNewIcon
							fontSize="small"
							color="secondary"
							className={styles.newWindowIcon}
						/>
					)}
				</MenuItem>
			)}
			{billingEnabled && isOwner && (
				<MenuItem
					onClick={() =>
						goToLink(
							hubRoutes.settings.billing({
								location,
								preserveContinuePath: true,
							}),
						)
					}
					className={styles.menuItem}
					key="Billing & Subscription"
				>
					<ListItemIcon className={styles.menuItemIcon}>
						<PaymentIcon fontSize="small" />
					</ListItemIcon>
					<Typography
						variant="body1"
						className={styles.menuItemContent}
					>
						Billing & Subscription
					</Typography>
					{redirectRequired && (
						<OpenInNewIcon
							fontSize="small"
							color="secondary"
							className={styles.newWindowIcon}
						/>
					)}
				</MenuItem>
			)}
			{!accountSelectorHidden && (
				<>
					<MenuItem className={styles.divider} divider disabled />
					<Typography
						variant="subtitle2"
						className={styles.subheading}
					>
						Change team
					</Typography>
					{alternateAccounts.map((account) => (
						<MenuItem
							key={account.accountId}
							selected={
								account.accountId === currentAccount.accountId
							}
							className={styles.teamMenuItem}
							onClick={() => selectAccount(account.accountId)}
						>
							<ListItemIcon className={styles.menuItemIcon}>
								<AccountAvatar account={account} />
							</ListItemIcon>

							<div className={styles.menuItemContent}>
								<Typography
									variant="body1"
									className={styles.name}
								>
									{account.name}
								</Typography>
								{account.role === 'owner' && (
									<Typography variant="caption">
										(Owner)
									</Typography>
								)}
							</div>
						</MenuItem>
					))}
					<MenuItem onClick={handleClose}>
						<Link
							className={styles.teamMenuItem}
							to={hubRoutes.createAccount({
								preserveContinuePath: true,
								location,
							})}
							underline="none"
						>
							<ListItemIcon className={styles.menuItemIcon}>
								<AddIcon className={styles.icon} />
							</ListItemIcon>
							<Typography variant="body1">
								Create New Team
							</Typography>
						</Link>
					</MenuItem>
				</>
			)}
		</>
	);
};
