import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isInitial, isSuccess } from '../../store-tools';
import { useAuth } from '../../hooks';
import { useAsyncFunction } from '../../hooks/useAsyncFunction';
import { continuePathFromLocation } from '../continuePathFromLocation';
import { routes } from '../routes';

export default function Logout() {
	const auth = useAuth();
	const history = useHistory();
	/**
	 * @type {import('history').Location<{ from?: string; }>}
	 */
	const location = useLocation();
	const nextUrl =
		continuePathFromLocation(location) || routes.home({ location });

	const [logoutRequest, logout] = useAsyncFunction(() => auth.logout());
	useEffect(() => {
		if (isInitial(logoutRequest)) {
			logout();
		}
		if (isSuccess(logoutRequest)) {
			history.push(nextUrl);
		}
	}, [history, logoutRequest, logout, nextUrl]);

	return <React.Fragment></React.Fragment>;
}
