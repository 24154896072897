import React from 'react';
import { useFormikContext } from 'formik';
import { Avatar, Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import durationOptions from '../../../data/ScheduleDuration';
import { AvatarGroup } from '@material-ui/lab';
import buildRecurrenceOptions from '../../../data/RecurrenceOptions';
import { useCurrentUser } from '../../../hooks';
import { CopyText } from '@remote-social/common';

/**
 * @param {{
 *   allMembers: {
 * 	   id?: string;
 * 	   displayName?: string;
 *	   photoURL?: string | null;
 *   }[];
 *   gameURL?: string
 * }} params
 */
export const Confirmation = ({ allMembers, gameURL }) => {
	const user = useCurrentUser();
	const { values: formValues } = useFormikContext();

	const team = formValues.team;
	const time = DateTime.fromISO(formValues.startTime).toFormat('h:mm a');
	const date = DateTime.fromISO(formValues.startDate).toFormat('cccc, D');
	const duration = durationOptions.find(
		(option) => option.value === formValues.duration,
	)?.label;
	const isRepeating = formValues.isRepeating;
	const recurrence = React.useMemo(() => {
		const recurrenceOptions = buildRecurrenceOptions(formValues.startDate);

		return recurrenceOptions.find(
			(option) => option.value === formValues.schedule,
		)?.label;
	}, [formValues.startDate, formValues.schedule]);
	const invitees = formValues.invitees;
	const members = allMembers.filter((member) =>
		formValues.players.includes(member.id),
	);

	return (
		<Box>
			<Box mb={1}>
				<Typography variant="subtitle1">Title</Typography>
				<Typography variant="body1">{team}</Typography>
			</Box>

			<Box mb={1}>
				<Typography variant="subtitle1">Time & Date</Typography>
				<Typography variant="body1">
					{time}, {date}
				</Typography>
			</Box>

			<Box mb={1}>
				<Typography variant="subtitle1">Duration</Typography>
				<Typography variant="body1">{duration}</Typography>
			</Box>

			{isRepeating && (
				<Box mb={1}>
					<Typography variant="subtitle1">Repeating</Typography>
					<Typography variant="body1">{recurrence}</Typography>
				</Box>
			)}

			{(members.length !== 0 || invitees.length !== 0) && (
				<Box mt={3} mb={1}>
					<Typography variant="subtitle1">Invited</Typography>
					<AvatarGroup max={members.length + 1}>
						<Avatar
							key={user.uid}
							src={user.photoURL}
							alt={user.displayName}
							title={user.displayName}
						/>
						{members.map((member) => (
							<Avatar
								key={member.id}
								src={member.photoURL || undefined}
								alt={member.displayName}
								title={member.displayName}
							/>
						))}
						{invitees?.map((invitee) => (
							<Avatar key={invitee} />
						))}
					</AvatarGroup>
				</Box>
			)}

			{gameURL && (
				<Box mt={1}>
					<Typography variant="caption">
						Share this link to invite
					</Typography>
					<CopyText text={gameURL} margin="dense" />
				</Box>
			)}
		</Box>
	);
};
