import {
	buildJumpRoute,
	buildRoute,
	buildSearchParams,
	createRoutes,
} from './routeBuilding';
import { routes } from './routes';

export const hubRoutes = createRoutes({
	...routes,
	handleInvite: buildJumpRoute(
		'/handle-invite',
		(opts: { inviteId: string; continuePath?: string }) => ({
			pathname: `/handle-invite`,
			search: buildSearchParams({
				inviteId: opts.inviteId,
				...(opts.continuePath && {
					continuePath: opts.continuePath,
				}),
			}),
		}),
	),
	slack: buildRoute('/slack'),
	sessions: buildRoute('/sessions'),
	accounts: {
		createMemberProfile: buildRoute(
			'/teams/:accountId/create-member-profile' as const,
			({
				accountId,
				responseId,
				formId,
			}: {
				accountId: string;
				responseId?: string;
				formId?: string;
			}) => ({
				pathname: `/teams/${accountId}/create-member-profile`,
				search: buildSearchParams({
					...(responseId && {
						responseId,
					}),
					...(formId && {
						formId,
					}),
				}),
			}),
		),
		memberProfile: buildRoute(
			'/teams/:accountId/members/:uid' as const,
			({ accountId, uid }: { uid: string; accountId: string }) => {
				return {
					pathname: `/teams/${accountId}/members/${uid}`,
				};
			},
		),
	},
	settings: {
		root: buildRoute('/settings'),
		activity: {
			root: buildRoute('/settings/activity'),
			trivia: buildRoute(
				'/settings/activity/trivia/:categoryID' as const,
				({ categoryId }: { categoryId: string }) => {
					return {
						pathname: `/settings/activity/trivia/${categoryId}`,
					};
				},
			),
		},
		account: buildRoute('/settings/team'),
		billing: buildJumpRoute('/settings/billing'),
		people: buildRoute('/settings/people'),
	},
	games: {
		joinZiagoGame: buildJumpRoute('/joinGame/:gameType'),
	},
	user: {
		loginSettings: buildRoute('/user/login-settings'),
	},
});
