/* 
	###################
	# CONFIG SETTINGS #
	###################
*/
// Application configuration settings
const appConfig = {
	title: 'Bingo', // customise the app title
	baseUrl: 'remotesocial.io',
	subdomain: 'bingo', // customise the subdomain
	design: {
		background: {
			h: 180,
			s: 100,
			l: 100,
			a: 1,
		},
		main: {
			h: 281,
			s: 49,
			l: 54,
			a: 1,
		},
	},
};

export { appConfig };
