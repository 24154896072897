import { DateTime } from 'luxon';

const REPEAT_INTERVAL = {
	WEEKLY: 'WEEKLY',
};

const DAYS_OF_WEEK = {
	Sunday: 'SUN',
	Monday: 'MON',
	Tuesday: 'TUE',
	Wednesday: 'WED',
	Thursday: 'THU',
	Friday: 'FRI',
	Saturday: 'SAT',
};

const ISO_weekday = {
	MON: 1,
	TUE: 2,
	WED: 3,
	THU: 4,
	FRI: 5,
	SAT: 6,
	SUN: 7,
};

const byISOWeekDay = (a, b) => ISO_weekday[a] - ISO_weekday[b];

function WeeklyRecurrenceBuilder(schedule) {
	this.schedule = schedule;
	this.repeatInterval = REPEAT_INTERVAL.WEEKLY;
}

WeeklyRecurrenceBuilder.prototype.on = function (daysOfWeek) {
	if (typeof daysOfWeek === 'string') {
		return this.on([daysOfWeek]);
	}
	validateDaysOfWeek(daysOfWeek);
	this.daysOfWeek = daysOfWeek.slice().sort(byISOWeekDay);
	return this.timesOfDay ? this.schedule : this;
};

WeeklyRecurrenceBuilder.prototype.at = function (timesOfDay) {
	if (typeof timesOfDay === 'string') {
		return this.at([timesOfDay]);
	}
	validateTimesOfDay(timesOfDay);
	this.timesOfDay = timesOfDay.slice().sort();
	return this.daysOfWeek ? this.schedule : this;
};

WeeklyRecurrenceBuilder.prototype.until = function (date) {
	validateISODate(date);
	this.endDate = date;
	return this.daysOfWeek && this.timesOfDay ? this.schedule : this;
};
WeeklyRecurrenceBuilder.prototype.for = function (number, unit) {
	validateRecurrenceLimit(number, unit);
	this.limit = { number, unit };
	return this.daysOfWeek && this.timesOfDay ? this.schedule : this;
};

WeeklyRecurrenceBuilder.prototype.build = function () {
	if (!this.daysOfWeek) {
		throw new Error('daysOfWeek was not set.');
	}
	if (!this.timesOfDay) {
		throw new Error('timesOfDay was not set.');
	}
	const props = {
		repeat: this.repeatInterval,
		daysOfWeek: this.daysOfWeek,
		timesOfDay: this.timesOfDay,
	};
	if (this.endDate) {
		props.until = this.endDate;
	}
	if (this.limit) {
		if (this.limit.unit !== 'INTERVAL') {
			throw new Error('TODO');
		}
		props.until = DateTime.fromMillis(Date.now(), {
			zone: this.schedule.timezone,
		})
			.plus({
				days: 7 * this.limit.number,
			})
			.set({
				hour: 0,
				minute: 0,
				second: 0,
				millisecond: 0,
			})
			.toISODate();
	}
	return props;
};

function ScheduleBuilder(tz) {
	this.timezone = tz;
	this.recurrences = [];
}
ScheduleBuilder.prototype.recurs = function (repeatInterval) {
	validateRepeatInterval(repeatInterval);
	const r = new WeeklyRecurrenceBuilder(this);
	this.recurrences.push(r);
	return r;
};
ScheduleBuilder.prototype.inTimezone = function (tz) {
	validateTimezone(tz);
	this.timezone = tz;
	return this;
};
ScheduleBuilder.prototype.build = function () {
	return {
		tz: this.timezone,
		recurrences: this.recurrences.map((r) => r.build()),
	};
};

const getNextEventForRecurrence = (recurrence, timezone, sinceMs) => {
	if (recurrence.repeat !== REPEAT_INTERVAL.WEEKLY) {
		throw new Error('TODO');
	}
	const sinceZoned = DateTime.fromMillis(sinceMs, { zone: timezone });
	const sinceDayOfWeek = sinceZoned.weekday; // Mon = 1, Sun = 7
	const sinceTimeOfDay = sinceZoned.toLocaleString(DateTime.TIME_24_SIMPLE);

	const scheduledWeekdays = recurrence.daysOfWeek.map((d) => ISO_weekday[d]);
	let nextScheduledWeekday =
		scheduledWeekdays.find((d) => d >= sinceDayOfWeek) ||
		scheduledWeekdays[0];

	if (nextScheduledWeekday === sinceDayOfWeek) {
		// it might happen today
		const nextScheduledTimeToday = recurrence.timesOfDay.find(
			(t) => t > sinceTimeOfDay,
		);
		if (nextScheduledTimeToday) {
			const timeParts = nextScheduledTimeToday.split(':');
			const hour = timeParts[0],
				minute = timeParts[1];
			return sinceZoned.set({
				hour: hour,
				minute: minute,
				second: 0,
				millisecond: 0,
			});
		}
		// move to the next scheduled day
		nextScheduledWeekday =
			scheduledWeekdays.find((d) => d > sinceDayOfWeek) ||
			scheduledWeekdays[0];
	}

	// first time on another day
	const timeParts = recurrence.timesOfDay[0].split(':');
	const hour = timeParts[0],
		minute = timeParts[1];

	const nextOccurrence = sinceZoned
		.plus({
			days:
				nextScheduledWeekday -
				sinceDayOfWeek +
				// add a week if we'd have been moving backwards or staying still
				(sinceDayOfWeek >= nextScheduledWeekday ? 7 : 0),
		})
		.set({
			hour: hour,
			minute: minute,
			second: 0,
			millisecond: 0,
		});

	if (
		recurrence.until &&
		DateTime.fromISO(recurrence.until, { zone: timezone }) < nextOccurrence
	) {
		return null;
	}

	return nextOccurrence;
};

function getNextEvent(schedule, sinceMs = Date.now()) {
	const nextEventOptions = schedule.recurrences
		.map((r) => getNextEventForRecurrence(r, schedule.tz, sinceMs))
		.filter(Boolean);
	if (nextEventOptions.length === 0) {
		return null;
	}
	return DateTime.min.apply(DateTime, nextEventOptions).toMillis();
}

function validateRecurrenceLimit(number, unit) {
	if (unit !== 'INTERVAL') {
		throw new Error('Invalid recurrence limit unit: ' + unit);
	}
}

function validateISODate(dateStr) {
	if (!/\d{4}-\d{2}-\d{2}/.test(dateStr)) {
		throw new Error('Invalid ISO date: ' + dateStr);
	}
}

function validateRepeatInterval(repeat) {
	if (repeat !== REPEAT_INTERVAL.WEEKLY) {
		throw new Error('Invalid repeat interval: ' + repeat);
	}
}

function validateDaysOfWeek(daysOfWeek) {
	if (!Array.isArray(daysOfWeek)) {
		throw new Error('daysOfWeek must be a string or array.');
	}
	const invalid = daysOfWeek.filter(
		(d) => !Object.values(DAYS_OF_WEEK).includes(d),
	);
	if (invalid.length) {
		throw new Error('Invalid days of week: ' + invalid);
	}
	if (daysOfWeek.length === 0) {
		throw new Error('No daysOfWek set.');
	}
}

function isValidTimeOfDay(time) {
	// 00:00 through 23:59
	return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

function validateTimesOfDay(timesOfDay) {
	if (!Array.isArray(timesOfDay)) {
		throw new Error('timesOfDay must be a string or array.');
	}
	const invalid = timesOfDay.filter((t) => !isValidTimeOfDay(t));
	if (invalid.length) {
		throw new Error('Invalid time of day: ' + invalid);
	}
	if (timesOfDay.length === 0) {
		throw new Error('No timesOfDay set.');
	}
}

function validateRecurrence(recurrence) {
	validateRepeatInterval(recurrence.repeat);
	validateDaysOfWeek(recurrence.daysOfWeek);
	validateTimesOfDay(recurrence.timesOfDay);
	recurrence.until && validateISODate(recurrence.until);
}

function validateTimezone(tz) {
	const dt = DateTime.utc().setZone(tz);
	if (!dt.isValid) {
		throw new Error('Invalid timezone: ' + tz);
	}
}

function validateSchedule(schedule) {
	validateTimezone(schedule.tz);
	if (!Array.isArray(schedule.recurrences)) {
		throw new Error('recurrences must be an array.');
	}
	schedule.recurrences.forEach(validateRecurrence);
}

const scheduleBuilder = (tz) => {
	validateTimezone(tz);
	return new ScheduleBuilder(tz);
};

const timesMatch = (epochMs, zone, timeStr) => {
	const givenTimeStr = DateTime.fromMillis(epochMs, { zone }).toISOTime();
	return givenTimeStr.startsWith(timeStr); // e.g. "13:15:55.123+11:00".startsWith("13:15")
};

const isFirstOfDay = (epochMs, schedule) => {
	const firstTime = schedule.recurrences
		.map((r) => r.timesOfDay[0])
		.sort()[0];
	return timesMatch(epochMs, schedule.tz, firstTime);
};

const isLastOfDay = (epochMs, schedule) => {
	const lastTime = schedule.recurrences
		.map((r) => r.timesOfDay[r.timesOfDay.length - 1])
		.sort()
		.pop();
	return timesMatch(epochMs, schedule.tz, lastTime);
};

export {
	getNextEvent,
	validateSchedule,
	scheduleBuilder,
	isFirstOfDay,
	isLastOfDay,
	REPEAT_INTERVAL,
	DAYS_OF_WEEK,
	ISO_weekday,
};
