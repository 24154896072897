const numberText = {
	millenial: {
		1: 'Just begun, Number <span>1</span>',
		2: 'One little duck, Number <span>2</span>',
		3: 'Number <span>3</span>, Cup of tea',
		4: 'Knock at the door, Number <span>4</span>',
		5: 'Man alive, Number <span>5</span>',
		6: 'Number <span>6</span>, Half a dozen',
		7: 'Lucky <span>7</span>',
		8: 'Tinder date, Number <span>8</span>',
		9: 'Feeling fine, Number <span>9</span>',
		10: 'Number <span>10</span> Downing Street',
		11: 'Legs <span>11</span>',
		12: 'One dozen, Number <span>12</span>',
		13: 'Unlucky for some, <span>13</span>',
		14: 'Valentine’s Day, Number <span>14</span>',
		15: 'Young and keen, Number <span>15</span>',
		16: 'Sweet <span>16</span>',
		17: '<span>17</span>, Selfie queen',
		18: 'Coming of age, Number <span>18</span>',
		19: 'Goodbye teens, Number <span>19</span>',
		20: 'One score, Number <span>20</span>',
		21: 'Key to the door, <span>21</span>',
		22: 'Two little ducks, <span>22</span>',
		23: 'Low key, <span>23</span>',
		24: 'Two dozen, <span>24</span>',
		25: 'Duck and dive, <span>25</span>',
		26: 'Pick and mix, <span>26</span>',
		27: 'Stairway to heaven, <span>27</span>',
		28: 'In a state, <span>28</span>',
		29: 'Rise and shine, <span>29</span>',
		30: 'Thirsty <span>30</span>',
		31: 'Get up and run, <span>31</span>',
		32: 'Buckle my shoe, <span>32</span>',
		33: 'All the threes, <span>33</span>',
		34: 'Ask for more, <span>34</span>',
		35: 'Jump and jive, <span>35</span>',
		36: 'Three dozen, <span>36</span>',
		37: "More than eleven, it's <span>37</span>",
		38: 'Over halfway, <span>38</span>',
		39: '<span>39</span> steps',
		40: 'Life begins at <span>40</span>',
		41: 'Time for fun, <span>41</span>',
		42: 'Winnie the Pooh, <span>42</span>',
		43: 'Invest in us please 😉, <span>43</span>',
		44: 'Use the fours Luke, <span>44</span>',
		45: 'Big beehive, <span>45</span>',
		46: 'Up to tricks, <span>46</span>',
		47: 'Four and seven, <span>47</span>',
		48: 'Four dozen, <span>48</span>',
		49: '<span>49</span>, Drinking wine',
		50: 'Half a century, <span>50</span>',
		51: 'Tweak of the thumb, <span>51</span>',
		52: 'Almost through, <span>52</span>',
		53: 'Give it to me, <span>53</span>',
		54: 'Clean the floor, <span>54</span>',
		55: 'Snakes alive, <span>55</span>',
		56: "It's Lit, <span>56</span>",
		57: 'Bacon, like Kevin, <span>57</span>',
		58: '<span>58</span>, Make them wait',
		59: 'Perfect crime, <span>59</span>',
		60: 'Five dozen, number <span>60</span>',
		61: 'Baker’s bun, <span>61</span>',
		62: 'Tickety-boo, <span>62</span>',
		63: 'Tickle me, <span>63</span>',
		64: 'Red raw, <span>64</span>',
		65: 'Retirement, <span>65</span>',
		66: 'Route <span>66</span>',
		67: 'Stairway to heaven, <span>67</span>',
		68: 'Saving Grace, <span>68</span>',
		69: 'A favourite of mine, <span>69</span>',
		70: 'Three score and ten, <span>70</span>',
		71: '<span>71</span>, Bang on the drum',
		72: 'Six dozen, number <span>72</span>',
		73: 'Yeet, <span>73</span>',
		74: 'Hit the floor, <span>74</span>',
		75: 'Strive and strive, <span>75</span>',
	},
	antler: {
		1: '<span>1</span> Billion Dollars',
		2: 'Thomas, <span>2</span> Hours sleep a night',
		3: "Nathalie, didn't talk til <span>3</span>",
		4: 'Knock at the door, Number <span>4</span>',
		5: 'Jump and jive, Number <span>5</span>',
		6: '<span>6</span>, sandwiches eaten by Bede during pitches',
		7: 'Lucky <span>7</span>',
		8: 'Tinder date, Number <span>8</span>',
		9: 'Feeling fine, Number <span>9</span>',
		10: 'Number <span>10</span> Downing Street',
		11: 'Legs <span>11</span>',
		12: '<span>12</span> Weeks being LEGENDS!',
		13: 'Unlucky for some, <span>13</span>',
		14: 'Valentine’s Day, Number <span>14</span>',
		15: 'Atleast <span>15</span> teams invested',
		16: 'Sweet <span>16</span>',
		17: '<span>17</span> years old figure skating champion',
		18: 'Coming of age, Number <span>18</span>',
		19: '<span>19</span>, Selfie queen - Nivaaz',
		20: 'Investing aplenty, Number <span>20</span>',
		21: 'Key to the door, <span>21</span>',
		22: 'Two little ducks, <span>22</span>',
		23: 'Low key, <span>23</span>',
		24: 'Two dozen, <span>24</span>',
		25: '<span>25</span> Kilos gained to escape military service',
		26: 'Pick and mix, <span>26</span>',
		27: 'Stairway to heaven, <span>27</span>',
		28: 'In a state, <span>28</span>',
		29: 'Rise and shine, <span>29</span>',
		30: 'Thirsty <span>30</span>',
		31: 'Get up and run, <span>31</span>',
		32: 'Buckle my shoe, <span>32</span>',
		33: 'All the threes, <span>33</span>',
		34: 'Ask for more, <span>34</span>',
		35: '<span>35</span> ',
		36: 'Three dozen, <span>36</span>',
		37: "More than eleven, it's <span>37</span>",
		38: 'Over halfway, <span>38</span>',
		39: '<span>39</span> steps',
		40: 'Life begins at <span>40</span>',
		41: 'Time for fun, <span>41</span>',
		42: 'Winnie the Pooh, <span>42</span>',
		43: 'Invest in us please 😉, <span>43</span>',
		44: 'Use the fours Luke, <span>44</span>',
		45: 'Big beehive, <span>45</span>',
		46: 'Up to poker tricks <span>46</span> (Markus)',
		47: 'Four and seven, <span>47</span>',
		48: 'Four dozen, <span>48</span>',
		49: '<span>49</span>, Drinking wine',
		50: "I've watched Step Brothers <span>50</span> Times - Edward",
		51: 'Tweak of the thumb, <span>51</span>',
		52: 'Almost through, <span>52</span>',
		53: 'Give it to me, <span>53</span>',
		54: 'Malcolm on the floor, <span>54</span> Wydborowa',
		55: 'Snakes alive, <span>55</span>',
		56: "It's Lit, <span>56</span>",
		57: 'Bacon, like Kevin, <span>57</span>',
		58: '<span>58</span>, Make them wait',
		59: 'Luddy time, <span>59</span>',
		60: 'Five dozen, number <span>60</span>',
		61: 'Baker’s bun, <span>61</span>',
		62: 'Clean up your poo, <span>62</span>',
		63: 'Tickle me, <span>63</span>',
		64: 'Invest more, <span>64</span>',
		65: 'Retirement, <span>65</span>',
		66: 'Route <span>66</span>',
		67: 'Stairway to heaven, <span>67</span>',
		68: 'Saving Grace, <span>68</span>',
		69: 'Dinner for two, <span>69</span>',
		70: '<span>70</span> Feedback Form reminders',
		71: '<span>71</span>, Bang on the drum',
		72: 'Six dozen, number <span>72</span>',
		73: 'Yeet, <span>73</span>',
		74: 'Hit the floor, <span>74</span>',
		75: 'Strive and strive, <span>75</span>',
	},
	squarepeg: {
		1: 'Remote Social, Number <span>1</span>',
		2: '<span>2</span> Grand Finals last weekend',
		3: "<span>3</span> IPO's",
		4: '<span>4</span> Square Peg offices',
		5: '<span>5</span> Phenomenal Airwallex founders',
		6: '<span>6</span> new investors this year',
		7: 'Lucky <span>7</span>',
		8: 'Tinder date, Number <span>8</span>',
		9: '<span>9</span> exits!',
		10: "<span>10</span>, Ed's age in months",
		11: '<span>11</span> Rothschild, Tel Aviv',
		12: '20<span>12</span>, launch year',
		13: 'Unlucky for some, <span>13</span>',
		14: 'Valentine’s Day, Number <span>14</span>',
		15: 'Young and keen, Number <span>15</span>',
		16: "StashAway's Series C, $<span>16</span>M",
		17: '<span>17</span>, Selfie queen',
		18: 'Coming of age, Number <span>18</span>',
		19: '<span>19</span> York St, Melbourne',
		20: '20<span>20</span>, best year to launch',
		21: '<span>21</span> members of Square Peg',
		22: 'Two little ducks, <span>22</span>',
		23: 'Low key, <span>23</span>',
		24: 'Two dozen, <span>24</span>',
		25: 'Duck and dive, <span>25</span>',
		26: 'Pick and mix, <span>26</span>',
		27: 'Stairway to heaven, <span>27</span>',
		28: 'In a state, <span>28</span>',
		29: 'Raise and shine, <span>29</span>',
		30: '$<span>30</span>M Neuron Mobility Series C',
		31: 'Get up and run, <span>31</span>',
		32: 'Buckle my shoe, <span>32</span>',
		33: '<span>33</span> current portfolio companies',
		34: 'Ask for more <span>34</span>',
		35: 'Jump and jive, <span>35</span>',
		36: 'Three dozen, <span>36</span>',
		37: "More than eleven, it's <span>37</span>",
		38: 'Over halfway, <span>38</span>',
		39: '<span>39</span> total investments',
		40: 'Life begins at <span>40</span>',
		41: 'Time for fun, <span>41</span>',
		42: 'Winnie the Pooh, <span>42</span>',
		43: 'Invest in us please 😉, <span>43</span>',
		44: 'Square Peg HQ, <span>44</span> Foveaux',
		45: 'Big beehive, <span>45</span>',
		46: 'Up to tricks, <span>46</span>',
		47: 'Four and seven, <span>47</span>',
		48: 'Four dozen, <span>48</span>',
		49: '<span>49</span>, Drinking wine',
		50: 'Half a century, <span>50</span>',
		51: 'Tweak of the thumb, <span>51</span>',
		52: 'Almost through, <span>52</span>',
		53: 'Give it to me, <span>53</span>',
		54: 'Clean the floor, <span>54</span>',
		55: 'Snakes alive, <span>55</span>',
		56: "It's Lit, <span>56</span>",
		57: 'Bacon, like Kevin, <span>57</span>',
		58: '<span>58</span>, Make them wait',
		59: 'Perfect crime, <span>59</span>',
		60: 'Five dozen, number <span>60</span>',
		61: 'Baker’s bun, <span>61</span>',
		62: 'Tickety-boo, <span>62</span>',
		63: 'Tickle me, <span>63</span>',
		64: 'Red raw, <span>64</span>',
		65: 'Retirement, <span>65</span>',
		66: 'Route <span>66</span>',
		67: 'Stairway to heaven, <span>67</span>',
		68: 'Saving Grace, <span>68</span>',
		69: 'A favourite of mine, <span>69</span>',
		70: 'Three score and ten, <span>70</span>',
		71: '<span>71</span>, Bang on the drum',
		72: 'Six dozen, number <span>72</span>',
		73: 'Yeet, <span>73</span>',
		74: 'Hit the floor, <span>74</span>',
		75: '$<span>75</span>M Remote Social ARR in 2024*',
	},
	original: {
		1: 'Remote Social, Number <span>1</span>',
		2: 'One little duck, Number <span>2</span>',
		3: 'Number <span>3</span>, Cup of tea',
		4: 'Knock at the door, Number <span>4</span>',
		5: 'Man alive, Number <span>5</span>',
		6: 'Number <span>6</span>, Half a dozen',
		7: 'Lucky <span>7</span>',
		8: 'One fat lady, <span>8</span>',
		9: 'Feeling fine, Number <span>9</span>',
		10: 'Number <span>10</span> Downing Street',
		11: 'Legs <span>11</span>',
		12: 'One dozen, Number <span>12</span>',
		13: 'Unlucky for some, <span>13</span>',
		14: 'Valentine’s Day, Number <span>14</span>',
		15: 'Young and keen, Number <span>15</span>',
		16: 'Sweet <span>16</span>',
		17: '<span>17</span>, Dancing queen',
		18: 'Coming of age, Number <span>18</span>',
		19: 'Goodbye teens, Number <span>19</span>',
		20: 'One score, Number <span>20</span>',
		21: 'Key to the door, <span>21</span>',
		22: 'Two little ducks, <span>22</span>',
		23: 'Thee and me, <span>23</span>',
		24: 'Two dozen, <span>24</span>',
		25: 'Duck and dive, <span>25</span>',
		26: 'Pick and mix, <span>26</span>',
		27: 'Stairway to heaven, <span>27</span>',
		28: 'In a state, <span>28</span>',
		29: 'Rise and shine, <span>29</span>',
		30: 'Dirty Gertie, number <span>30</span>',
		31: 'Get up and run, <span>31</span>',
		32: 'Buckle my shoe, <span>32</span>',
		33: 'All the threes, <span>33</span>',
		34: 'Ask for more, <span>34</span>',
		35: 'Jump and jive, <span>35</span>',
		36: 'Three dozen, <span>36</span>',
		37: "More than eleven, it's <span>37</span>",
		38: 'Over halfway, <span>38</span>',
		39: '<span>39</span> steps',
		40: 'Life begins at <span>40</span>',
		41: 'Time for fun, <span>41</span>',
		42: 'Winnie the Pooh, <span>42</span>',
		43: 'Nice to see, <span>43</span>',
		44: 'Droopy Drawers, <span>44</span>',
		45: 'Big beehive, <span>45</span>',
		46: 'Up to tricks, <span>46</span>',
		47: 'Four and seven, <span>47</span>',
		48: 'Four dozen, <span>48</span>',
		49: '<span>49</span>, Drinking wine',
		50: 'Half a century, <span>50</span>',
		51: 'Tweak of the thumb, <span>51</span>',
		52: 'Almost through, <span>52</span>',
		53: 'Give it to me, <span>53</span>',
		54: 'Clean the floor, <span>54</span>',
		55: 'Snakes alive, <span>55</span>',
		56: 'Loving it, <span>56</span>',
		57: 'Ham and Devon, <span>57</span>',
		58: '<span>58</span>, Make them wait',
		59: 'Perfect crime, <span>59</span>',
		60: 'Five dozen, number <span>60</span>',
		61: 'Baker’s bun, <span>61</span>',
		62: 'Tickety-boo, <span>62</span>',
		63: 'Tickle me, <span>63</span>',
		64: 'Red raw, <span>64</span>',
		65: 'Retirement, <span>65</span>',
		66: 'Route <span>66</span>',
		67: 'Made in heaven, <span>67</span>',
		68: 'Saving Grace, <span>68</span>',
		69: 'Any way you look, <span>69</span>',
		70: 'Three score and ten, <span>70</span>',
		71: '<span>71</span>, Bang on the drum',
		72: 'Six dozen, number <span>72</span>',
		73: 'Queen Bee, <span>73</span>',
		74: 'Hit the floor, <span>74</span>',
		75: 'Strive and strive, <span>75</span>',
	},
};

export default function getDrawnNumberText(number, group = 'original') {
	return numberText[group][number] || `<span>${number}</span>`;
}
