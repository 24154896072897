import { makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import MailIcon from '@material-ui/icons/Mail';
import { ReactComponent as ProBadge } from '../../assets/images/pro.svg';
import React from 'react';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator,
} from '@material-ui/lab';
import { DateTime } from 'luxon';
import Antler from '../../assets/images/testimonials/antler.png';
import Adobe from '../../assets/images/partners/adobe.png';
import Atlassian from '../../assets/images/partners/atlassian.png';
import LogMeIn from '../../assets/images/partners/logmein.png';
import { UserAvatar } from '../../components/userAvatar';

const useStyles = makeStyles((theme: Theme) => ({
	information: {
		background: '#F5F6F8',
		borderRadius: '1.5rem',
		overflow: 'hidden',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
	},
	proBadgeTimelineDot: {
		marginTop: theme.spacing(1),
		padding: 0,
		boxShadow: 'none',
		background: 'none',
	},
	proBadge: {
		width: '2.5rem',
		height: '2.5rem',
	},
	testimonial: { padding: theme.spacing(2) },
	testimonialTop: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	testimonialBottom: {
		marginTop: theme.spacing(2),
	},
	partners: {
		padding: theme.spacing(2),
	},
	partnerLogos: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
}));

const daysUntil = ({
	from = DateTime.now(),
	days,
}: {
	from?: DateTime;
	days: number;
}) => {
	return from.plus({ days }).toFormat('dd MMMM yyyy');
};

export const UpsellInfo: React.ComponentType = () => {
	const styles = useStyles();
	const reminderDate = daysUntil({ days: 27 });
	const planStartDate = daysUntil({ days: 30 });

	return (
		<div className={styles.information}>
			<Timeline>
				<TimelineItem className={styles.timelineItem}>
					<TimelineSeparator>
						<TimelineDot color="primary">
							<EventIcon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Typography variant="h6" component="h1">
							Today
						</Typography>
						<Typography variant="body2">
							Start your free trial for 30 days (cancel anytime)
						</Typography>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem className={styles.timelineItem}>
					<TimelineSeparator>
						<TimelineDot color="primary">
							<MailIcon />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Typography variant="h6" component="h1">
							{reminderDate}
						</Typography>
						<Typography variant="body2">
							We'll remind you before your trial ends
						</Typography>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem className={styles.timelineItem}>
					<TimelineSeparator>
						<TimelineDot className={styles.proBadgeTimelineDot}>
							<SvgIcon
								component={ProBadge}
								className={styles.proBadge}
							/>
						</TimelineDot>
					</TimelineSeparator>
					<TimelineContent>
						<Typography variant="h6" component="h1">
							{planStartDate}
						</Typography>
						<Typography variant="body2">
							Your subscription plan starts, unless you cancel
							during the trial
						</Typography>
					</TimelineContent>
				</TimelineItem>
			</Timeline>
			<div className={styles.testimonial}>
				<div className={styles.testimonialTop}>
					<UserAvatar user={{ photoURL: Antler }} size="3rem" />
					<div>
						<Typography variant="body1">
							<strong>Natasha Badrov</strong>
						</Typography>
						<Typography variant="body2">
							<strong>Antler</strong>
						</Typography>
					</div>
				</div>
				<div className={styles.testimonialBottom}>
					<Typography variant="body2" gutterBottom>
						&quot;Everyone in my team is feeling more connected.
					</Typography>
					<Typography variant="body2">
						Remote Social has given our team and founders the right
						tools to build on their relationships and improve
						engagement overall.&quot;
					</Typography>
				</div>
			</div>
			<div className={styles.partners}>
				<Typography variant="caption">
					<strong>Loved by all types of teams everywhere</strong>
				</Typography>
				<div className={styles.partnerLogos}>
					<img src={Adobe} alt="Adobe" width="77" height="23" />
					<img
						src={Atlassian}
						alt="Atlassian"
						width="96"
						height="16"
					/>
					<img src={LogMeIn} alt="LogMeIn" width="73" height="25" />
				</div>
			</div>
		</div>
	);
};
