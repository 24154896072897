import { ErrorType } from '../errors';
import React from 'react';
import { ErrorMessage } from '../components';

export const AuthErrorMessage = ({
	error,
}: {
	error?: ErrorType | null;
}): React.ReactElement => {
	if (!error) {
		return <></>;
	}

	switch (error.code) {
		case 'auth/popup-closed-by-user':
		case 'auth/user-cancelled':
			return <ErrorMessage error={error}>Login cancelled.</ErrorMessage>;

		case 'auth/credential-already-in-use': // another user is linked to this provider+account
		case 'auth/account-exists-with-different-credential': // the email address for the provider+account is linked to another user
		case 'auth/email-already-in-use': // same as above
			// TODO: actually merge the anonymous user data with the existing user data.
			// But for now, they lose the anonymous data and log in as the existing user.
			// const { email, credential } = error;

			const badCred = error.code === 'auth/credential-already-in-use';
			return (
				<ErrorMessage error={error}>
					{badCred
						? 'That account is already linked to another user.'
						: 'The email address on that account is already linked to another user. Please try again, possibly with a different provider e.g., Google, Facebook.'}
				</ErrorMessage>
			);

		default:
			return <ErrorMessage error={error} />;
	}
};
