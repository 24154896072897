import React from 'react';

import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		position: 'relative',
	},
	paper: {
		padding: '2rem',
		borderRadius: '2.5rem',
		border: `1px solid ${theme.palette.text.hint}`,
		boxShadow: `0 0 1rem ${theme.palette.text.hint}`,
		backgroundColor: theme.palette.common.white,
		width: '100%',
		position: 'relative',
		zIndex: 1,
	},
	contentWrapper: {
		width: '100%',
		position: 'relative',
		zIndex: 1,
		padding: theme.spacing(0, 4),
	},
	footer: {
		marginTop: 'auto',
		padding: theme.spacing(4),
		display: 'flex',
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'center',
		zIndex: 1,
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-start',
		},
	},
	footerLogo: {
		width: 50,
		height: 'auto',
		[theme.breakpoints.up('sm')]: {
			width: 200,
		},
	},
}));

export default function Layout({ children, maxWidth, heading, ...rest }) {
	const classes = useStyles();

	return (
		<Container
			maxWidth={maxWidth}
			fixed
			className={classes.container}
			{...rest}
		>
			<Paper className={classes.paper} elevation={0}>
				{children}
			</Paper>
		</Container>
	);
}
