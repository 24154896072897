import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Link } from 'react-router-dom';

// props for a button component that is going
// to have additional properties that Link component has
type BaseProps<S> = ButtonProps<Link<S>>;

// we do not want our users to override component for LinkButton
type FilteredButtonProps<S> = Omit<BaseProps<S>, 'component'>;

type Props<S> = FilteredButtonProps<S>;

export const LinkButton = <S,>({
	to,
	replace,
	innerRef,
	...rest
}: React.PropsWithChildren<Props<S>>) => (
	<Button
		{...rest}
		to={to}
		replace={replace}
		innerRef={innerRef}
		component={Link as Link<S>}
	/>
);
