import React from 'react';
import { useCurrentUser } from '../../hooks';
import { UserMenu } from './user/userMenu';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import { CommonThemeProvider } from '../../styles/commonThemeProvider';
import { AccountMenu } from './account/accountMenu';
import { Branding } from './branding';
import { useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import { LinkButton } from '@common';

export const headerBackgroundColor = (theme: Theme) => {
	return theme.palette.common.white;
};

const useStyles = makeStyles((theme) => {
	return createStyles({
		header: {
			color: theme.palette.text.primary,
			fill: theme.palette.text.primary,
			background: headerBackgroundColor(theme),
		},
		toolbar: {
			display: 'grid',
			gridTemplateAreas: `
				'accounts branding user'
			`,
			gridAutoFlow: 'column',
			gridAutoColumns: 'minmax(0, 1fr) 1fr minmax(0, 1fr)',
			justifyContent: 'space-between',
		},
		accounts: {
			gridArea: 'accounts',
		},
		branding: {
			gridArea: 'branding',
			justifySelf: 'center',
		},
		user: {
			gridArea: 'user',
			justifySelf: 'end',
		},
	});
});

type State = {
	isUserLoaded: boolean;
	isAuthenticated: boolean;
	currentUser?: { displayName?: string; email?: string };
};

const useState = () => {
	const {
		isLoaded: isUserLoaded,
		isAuthenticated,
		displayName,
		email,
		photoURL,
	} = useCurrentUser();

	const result = React.useMemo<State>(() => {
		return {
			isUserLoaded,
			isAuthenticated,
			currentUser: { displayName, email, photoURL },
		};
	}, [displayName, email, isAuthenticated, isUserLoaded, photoURL]);

	return result;
};

type Props = {
	className?: string;
};

const HeaderInternal: React.ComponentType<Props> = ({ className }) => {
	const styles = useStyles();
	const location = useLocation();
	const { isUserLoaded, isAuthenticated, currentUser } = useState();

	return (
		<AppBar className={clsx(styles.header, className)} position="static">
			<Toolbar className={styles.toolbar}>
				<div className={styles.accounts}>
					<AccountMenu />
				</div>
				<div className={styles.branding}>
					<Branding />
				</div>
				<div className={styles.user}>
					{isUserLoaded && (
						<>
							{isAuthenticated && currentUser ? (
								<UserMenu currentUser={currentUser} />
							) : (
								<LinkButton
									variant="text"
									to={routes.login({
										location,
									})}
								>
									Log in
								</LinkButton>
							)}
						</>
					)}
				</div>
			</Toolbar>
		</AppBar>
	);
};

export const Header: React.ComponentType<Props> = (props) => (
	<CommonThemeProvider>
		<HeaderInternal {...props} />
	</CommonThemeProvider>
);
