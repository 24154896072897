import type {
	AnyObjectSchema,
	SchemaOf,
	UpdateShapeOfSchema,
	ValueTypesOf,
} from '../shared';
import { combineUpdatesWithDiscriminatedUnionValue } from '../shared';
import { discriminatedUnionOpts } from '../shared';
import { appendDiscriminatedUnionSchema } from '../shared';
import { concatSchemaMaps } from '../shared';
import { objectSchemaWithUpdateShape } from '../shared';
import { Joi, timeZoneSchema } from '../shared';
import type { StorableMap } from '../shared/dataTypes';
import { storableMapSchema } from '../shared/dataTypes';
import type { TimeOfDay, TimesOfDayType } from './timeOfDay';
import { timeOfDaySchema, timesOfDayTypeSchema } from './timeOfDay';

export type AtLeastOneTimeOfDay = [TimeOfDay, ...TimeOfDay[]];

export const daysOfWeek = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
] as const;

export type DayOfWeek = ValueTypesOf<typeof daysOfWeek>;

export type AtLeastOneDayOfWeek = [DayOfWeek, ...DayOfWeek[]];

export type ScheduleJobParams = StorableMap;

export const jobTypes = [
	'connect-activity',
	'connect-send-survey-link',
] as const;

export type ScheduleJobType = ValueTypesOf<typeof jobTypes>;

export type OneOffScheduleTiming = {
	timing: 'one-off';
	executeDate: string;
};

export type WeeklyScheduleTiming = {
	timing: 'weekly-recurrence';
	startDate?: string;
	endDate?: string;
	daysOfWeek: AtLeastOneDayOfWeek;
	timesOfDay: AtLeastOneTimeOfDay;
	timesOfDayType?: TimesOfDayType;
	timeZone: string;
};

export const timings = ['one-off', 'weekly-recurrence'] as const;

export const timingSchema = Joi.string().valid(...timings);

// these are attributes of the schedule that related to when
// the schedule will fire off

export type ScheduleTiming = WeeklyScheduleTiming | OneOffScheduleTiming;

export const dayOfWeekSchema = Joi.string().valid(...daysOfWeek);

export const weeklyScheduleTimingSchemaMap: SchemaOf<WeeklyScheduleTiming> = {
	timing: Joi.string().valid('weekly-recurrence').required(),
	startDate: Joi.string().isoDate().optional(),
	endDate: Joi.string().isoDate().optional(),
	daysOfWeek: Joi.array().items(dayOfWeekSchema).min(1).required(),
	timesOfDay: Joi.array().items(timeOfDaySchema).min(1).required(),
	timesOfDayType: timesOfDayTypeSchema.optional(),
	timeZone: timeZoneSchema.required(),
};

export const oneOffScheduleTimingSchemaMap: SchemaOf<OneOffScheduleTiming> = {
	timing: Joi.string().valid('one-off').required(),
	executeDate: Joi.string().isoDate().required(),
};

const weeklyScheduleTimingSchema = objectSchemaWithUpdateShape(
	weeklyScheduleTimingSchemaMap,
	{
		keys: Object.keys(weeklyScheduleTimingSchemaMap),
		primaryKeys: ['timing'],
	},
);

export type WeeklyScheduleTimingUpdateShape = UpdateShapeOfSchema<
	typeof weeklyScheduleTimingSchema
>;

const oneOffScheduleTimingSchema = objectSchemaWithUpdateShape(
	oneOffScheduleTimingSchemaMap,
	{
		keys: Object.keys(oneOffScheduleTimingSchemaMap),
		primaryKeys: ['timing'],
	},
);

export type OneOffScheduleTimingUpdateShape = UpdateShapeOfSchema<
	typeof oneOffScheduleTimingSchema
>;

export type ScheduleTimingUpdateShape =
	| WeeklyScheduleTimingUpdateShape
	| OneOffScheduleTimingUpdateShape;

const scheduleTimingDiscriminatedUnion = discriminatedUnionOpts({
	discriminator: 'timing',
	discriminatorValuesSchema: timingSchema.alter({
		update: (schema) => schema.optional(),
	}),
	valueToSchema: {
		'one-off': oneOffScheduleTimingSchema,
		'weekly-recurrence': weeklyScheduleTimingSchema,
	},
});

export const appendScheduleTimingSchema = <S extends AnyObjectSchema>(
	objectSchema: S,
) =>
	appendDiscriminatedUnionSchema(
		objectSchema,
		scheduleTimingDiscriminatedUnion,
	);

// these are attributes of the schedule that
// are not related to schedule timing (when schedule fires off)

export type ScheduleBase = {
	scheduleId: string;
	isActive: boolean;
};

export type ScheduleInternals = {
	topic: string;
	type: ScheduleJobType;
	params?: ScheduleJobParams;
	// ignored on prod:
	nowDateIso?: string;
};

export type Schedule = ScheduleBase & ScheduleInternals & ScheduleTiming;

export const jobTypeSchema = Joi.string().valid(...jobTypes);

export const scheduleBaseSchemaMap: SchemaOf<ScheduleBase> = {
	scheduleId: Joi.string().required(),
	isActive: Joi.boolean().required(),
};

export const scheduleInternalsSchemaMap: SchemaOf<ScheduleInternals> = {
	topic: Joi.string().required(),
	type: jobTypeSchema.required(),
	params: storableMapSchema.optional(),
	// ignored on prod:
	nowDateIso: Joi.string().isoDate().optional(),
};

export const scheduleSchema = appendScheduleTimingSchema(
	objectSchemaWithUpdateShape(
		concatSchemaMaps(scheduleBaseSchemaMap, scheduleInternalsSchemaMap),
		{
			keys: [
				...Object.keys(scheduleBaseSchemaMap),
				...Object.keys(scheduleInternalsSchemaMap),
			],
			primaryKeys: ['scheduleId'],
			immutablesKeys: ['type'],
		},
	),
);

export type ScheduleUpdateShape = UpdateShapeOfSchema<typeof scheduleSchema>;

export const scheduleUpdateShapeSchema = scheduleSchema.tailor('update');

export const combineScheduleUpdates = <
	T extends Schedule,
	U extends Partial<ScheduleUpdateShape>,
>(
	existingValue: T,
	updates?: U,
) =>
	combineUpdatesWithDiscriminatedUnionValue(
		existingValue,
		updates,
		scheduleTimingDiscriminatedUnion,
	);
