import React from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { DataListField } from '../../FormFields';

const useStyles = makeStyles(() => ({
	gameAvatar: {
		width: 34,
		borderRadius: 5,
		marginRight: 16,
	},
	gameLabel: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	selector: {
		maxHeight: 150,
		overflowY: 'auto',
		overflowX: 'hidden',
		scrollbarWidth: 'thin',
	},
}));

const GameSelectionSchema = Yup.object().shape({
	games: Yup.array().min(1).of(Yup.string()).required(),
});

const GameSelection = ({ games }) => {
	const classes = useStyles();

	const _renderRow = (game) => {
		return (
			<>
				<img
					src={game.avatarUrl}
					className={classes.gameAvatar}
					alt={`${game.name}`}
				/>
				<span className={classes.gameLabel}>{game.name}</span>
			</>
		);
	};

	return (
		<div>
			<p style={{ textAlign: 'center' }}>
				You can add or change your game at anytime within the session
			</p>

			<DataListField
				name="games"
				data={games}
				renderRow={_renderRow}
				className={classes.selector}
				selectionMode="single"
			/>
		</div>
	);
};

export { GameSelectionSchema };
export default GameSelection;
