import React from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
} from '@material-ui/core';
import { DateTime, Info } from 'luxon';
import { once } from '../utils/once';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',

		flexDirection: 'row',
		flexWrap: 'wrap',

		gap: theme.spacing(0, 1),

		[`& > *`]: {
			flex: 1,
		},
	},
	label: {
		flex: 1,
		flexBasis: '100%',
	},
}));

// meridiems are localized "am" and "pm" strings, in case when we use 12-hour format
const meridiems: string[] = Info.meridiems();

const range = (start: number, end: number, step = 1): number[] => {
	const length = Math.floor((end - start) / step) + 1;

	return Array.from({ length }, (_, k) => k * step + start);
};

type TimeFormat = '24-hour' | '12-hour';

const guessTimeFormat = once((): TimeFormat => {
	const time = DateTime.now()
		.toLocaleString(DateTime.TIME_SIMPLE)
		.toLowerCase();
	const meridiem = DateTime.now().toFormat('a').toLowerCase();
	const containsMeridiem = time.includes(meridiem);
	return containsMeridiem ? '12-hour' : '24-hour';
});

type TimeSelectOpts = { timeFormat: TimeFormat; minuteStep: number };

const buildOptions = (opts: TimeSelectOpts) => {
	const { timeFormat, minuteStep } = opts;
	if (timeFormat === '24-hour') {
		return {
			timeFormat,
			minuteStep,
			hours: range(0, 23).map((hour) => ({
				label: hour.toFixed(0).padStart(2, '0'),
				value: hour,
			})),
			minutes: range(0, 59, minuteStep).map((minute) => ({
				label: minute.toFixed(0).padStart(2, '0'),
				value: minute,
			})),
			meridiems: [],
		};
	} else {
		return {
			timeFormat,
			minuteStep,
			hours: range(1, 12).map((hour) => ({
				label: hour.toFixed(0).padStart(2, '0'),
				/* to convert back to 24 hour we will need meridiem anyway */
				value: hour,
			})),
			minutes: range(0, 59, minuteStep).map((minute) => ({
				label: minute.toFixed(0).padStart(2, '0'),
				value: minute,
			})),
			meridiems: meridiems.map((m) => ({
				label: m,
				value: m.toUpperCase(),
			})),
		};
	}
};

const dateTimeToSelectOptions = (
	value: DateTime,
	opts: TimeSelectOpts,
): { hour: number; minute: number; meridiem: string } => {
	const hour =
		opts.timeFormat === '24-hour'
			? value.get('hour')
			: Math.floor(value.hour % 12 !== 0 ? value.hour % 12 : 12);
	const dateMinute = value.get('minute');
	const minute = dateMinute - (dateMinute % opts.minuteStep);
	const meridiem = value.toFormat('a').toUpperCase();
	return {
		hour,
		minute,
		meridiem,
	};
};

const selectOptionsToDateTime = (
	option: { hour: number; minute: number; meridiem: string },
	opts: TimeSelectOpts,
	prototypeDateTime: DateTime = DateTime.now(),
): DateTime => {
	if (opts.timeFormat === '24-hour') {
		const { hour, minute } = option;
		return prototypeDateTime.set({
			hour,
			minute,
		});
	} else {
		const { hour, minute, meridiem } = option;
		return prototypeDateTime.set({
			hour: meridiem === 'AM' ? hour % 12 : (hour % 12) + 12,
			minute,
		});
	}
};

const renderOptions = (
	options: Array<{ label: string; value: number | string }>,
) => {
	return options.map(({ label, value }) => (
		<MenuItem key={value} value={value}>
			{label}
		</MenuItem>
	));
};

type Props = {
	value: DateTime;
	onChange: (value: DateTime) => void;

	timeFormat?: TimeFormat;
	minuteStep?: number;
	label?: string;
	helperText?: string;
};

export const TimeSelect: React.ComponentType<Props> = React.memo(
	({
		timeFormat = guessTimeFormat(),
		minuteStep = 1,
		value,
		onChange,
		label,
		helperText,
	}) => {
		const classes = useStyles();

		const options = React.useMemo(
			() =>
				buildOptions({
					timeFormat,
					minuteStep,
				}),
			[timeFormat, minuteStep],
		);

		const { hour, minute, meridiem } = dateTimeToSelectOptions(
			value,
			options,
		);

		const lastProps = React.useRef({
			hour,
			minute,
			meridiem,
			value,
		});
		lastProps.current = {
			hour,
			minute,
			meridiem,
			value,
		};

		const handleChange = React.useCallback(
			(next: { hour?: number; minute?: number; meridiem?: string }) => {
				const { hour, minute, meridiem, value } = lastProps.current;
				onChange(
					selectOptionsToDateTime(
						{
							hour,
							minute,
							meridiem,
							...next,
						},
						options,
						value,
					),
				);
			},
			[onChange, options],
		);

		const handleHourChange: React.ChangeEventHandler<{
			name?: string;
			value: unknown;
		}> = React.useCallback(
			(event) => {
				const newHour = Number(event.target.value);
				handleChange({
					hour: newHour,
				});
			},
			[handleChange],
		);

		const handleMinuteChange: React.ChangeEventHandler<{
			name?: string;
			value: unknown;
		}> = React.useCallback(
			(event) => {
				const newMinute = Number(event.target.value);
				handleChange({
					minute: newMinute,
				});
			},
			[handleChange],
		);

		const handleMeridiemChange: React.ChangeEventHandler<{
			name?: string;
			value: unknown;
		}> = React.useCallback(
			(event) => {
				const newMeridiem = String(event.target.value);
				handleChange({
					meridiem: newMeridiem,
				});
			},
			[handleChange],
		);

		return (
			<Box className={classes.wrapper}>
				<FormControl variant="outlined" fullWidth margin="dense">
					{label && <InputLabel>{label}</InputLabel>}
					<Select
						value={hour}
						onChange={handleHourChange}
						label={label}
						margin="dense"
					>
						{renderOptions(options.hours)}
					</Select>
				</FormControl>

				<FormControl variant="outlined" fullWidth margin="dense">
					<Select value={minute} onChange={handleMinuteChange}>
						{renderOptions(options.minutes)}
					</Select>
				</FormControl>

				{options.meridiems.length > 0 && (
					<FormControl variant="outlined" fullWidth margin="dense">
						<Select
							value={meridiem}
							onChange={handleMeridiemChange}
						>
							{renderOptions(options.meridiems)}
						</Select>
					</FormControl>
				)}

				{helperText && (
					<FormHelperText className={classes.label}>
						{helperText}
					</FormHelperText>
				)}
			</Box>
		);
	},
);
TimeSelect.displayName = 'TimeSelect';
