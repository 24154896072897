import Payments from '../../assets/images/payments.svg';
import {
	Box,
	Button,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { UserAvatar } from '../../components/userAvatar';
import { User } from '@contracts/platform';
import { isError, isPending, RequestState } from '../../store-tools';
import { useCurrentAccount } from '../../contexts';
import { useAsyncFunction } from '../../hooks';
import { Loading, PageError, ScreenContainer } from '../../components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			display: 'grid',
			gridTemplateAreas: `"information actions"`,
			gridTemplateColumns: 'minmax(0, 35%) minmax(0, 65%)',
			gap: theme.spacing(2),
			minHeight: '500px',
		},
	},
	information: {
		display: 'none',

		[theme.breakpoints.up('md')]: {
			display: 'flex',
			gridArea: 'information',
			alignItems: 'center',
			background: '#F5F6F8',
			padding: theme.spacing(4),
			borderRadius: '1.5rem',
			overflow: 'hidden',
		},
	},
	informationImg: {
		width: '100%',
		height: 'auto',
	},
	actions: {
		gridArea: 'actions',

		display: 'grid',
		gridAutoRows: 'min-content minmax(0, auto) min-content',
		gap: theme.spacing(2),
	},
	owner: {
		width: '6rem',
		padding: theme.spacing(2),
	},
}));

type AccountUser = User & { id: string };

type ValidState = {
	owners: AccountUser[];
};

type State = RequestState<ValidState>;

const useState = (): State => {
	const {
		currentAccount,
		getAccountMembers,
		error: accountError,
	} = useCurrentAccount();

	const [getAccountMembersRequest] = useAsyncFunction<
		(params: { accountId: string }) => Promise<AccountUser[]>,
		AccountUser[]
	>(
		async (params: { accountId: string }) =>
			(await getAccountMembers(params.accountId, 'owner')).data,
		{
			callOnChange: () => {
				if (!currentAccount?.accountId) {
					return;
				}

				return {
					accountId: currentAccount.accountId,
				};
			},
			initialValue: [],
		},
	);
	const pending = isPending(getAccountMembersRequest);
	const error = isError(getAccountMembersRequest || accountError)
		? getAccountMembersRequest.error || accountError
		: undefined;
	const owners = getAccountMembersRequest.data;

	const result = React.useMemo<State>(() => {
		if (!pending) {
			return {
				status: 'success',
				data: {
					owners,
				},
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return { status: 'pending' as const };
		}
	}, [error, owners, pending]);

	return result;
};

export const AccountMemberPaywallContent: React.ComponentType = () => {
	const styles = useStyles();
	const history = useHistory();
	const state = useState();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status !== 'success') {
		return <Loading showRandomLabels />;
	}

	const { owners } = state.data;

	return (
		<ScreenContainer
			variant="two-column"
			containerClassName={styles.container}
		>
			<div className={styles.information}>
				<img
					className={styles.informationImg}
					src={Payments}
					alt="Payments"
				/>
			</div>
			<div className={styles.actions}>
				<Box mb={3}>
					<Typography variant="h3" align="center" gutterBottom>
						Requires Pro account
					</Typography>
					<Typography variant="h4" align="center" gutterBottom>
						Contact owner to upgrade:
					</Typography>
				</Box>
				<Box justifySelf="center">
					{owners.map((owner) => (
						<Paper className={styles.owner} key={owner.id}>
							<UserAvatar user={owner} size="4rem" />
						</Paper>
					))}
				</Box>
				<Button
					fullWidth
					color="primary"
					variant="outlined"
					onClick={history.goBack}
				>
					Back
				</Button>
			</div>
		</ScreenContainer>
	);
};
