import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

type SanitizedMessage = string & {
	_SanitizedMessage: undefined;
};

// This list can grow further
const allowedTags = ['p', 'br', 'span', 'i', 'b', 'em', 'code'];
const allowedClasses = ['noWrap'];

export function sanitizeMessage(html: string): SanitizedMessage {
	return sanitizeHtml(html, {
		allowedTags,
		allowedAttributes: false,
		allowedClasses: {
			...Object.fromEntries(
				allowedTags.map((tag) => [tag, allowedClasses]),
			),
		},
	}) as SanitizedMessage;
}

const useStyles = makeStyles(() =>
	createStyles({
		messageRoot: {
			[`& .noWrap`]: {
				whiteSpace: 'nowrap',
			},
		},
	}),
);

type Props = {
	message: string;
	children?: never;
	deps?: {
		useStyles: typeof useStyles;
	};
};

export const HtmlMessage: React.ComponentType<Props> = React.memo(
	(props) => {
		const styles = props.deps?.useStyles();
		const sanitized = React.useMemo(
			() => sanitizeMessage(props.message),
			[props.message],
		);
		return <span className={styles?.messageRoot}>{parse(sanitized)}</span>;
	},
	(a, b) => a.message === b.message,
);
HtmlMessage.defaultProps = {
	deps: {
		useStyles,
	},
};
