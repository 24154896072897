import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
	useCurrentUser,
	useMountedRef,
	useSessionStorage,
	AudioControl,
	AnimatedBackgroundContext,
} from '@remote-social/common';
import { Button as UIButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import { useFirebase } from 'react-redux-firebase';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';
import Button from './Button';
import Stampable from './Stampable';
import DrawnNumberIndicator from './DrawnNumberIndicator';
import {
	getBingoCard,
	getDrawnNumbers,
	getLastDrawnNumber,
	getRoundConfig,
	getRoundId,
} from '../store/selectors';

import sndBingo2 from '../assets/sounds/bingo2.mp3';

const useStyles = makeStyles((theme) => ({
	cardWrapper: {
		textAlign: 'center',
	},
	card: {
		display: 'inline-grid',
		gridGap: '2px',
		gridTemplateRows: `repeat(5, 50px)`,
		gridTemplateColumns: `repeat(5, 50px)`,
		gridAutoFlow: 'column',
		[theme.breakpoints.up('sm')]: {
			gridGap: '5px',
			gridTemplateRows: `repeat(5, 80px)`,
			gridTemplateColumns: `repeat(5, 80px)`,
		},
	},
	number: {
		textAlign: 'center',
		backgroundColor: theme.palette.grey[200],
		fontWeight: 900,
		padding: 0,
		minWidth: 0,
		fontSize: 16,
		[theme.breakpoints.up('sm')]: {
			fontSize: 20,
		},
	},
	numberText: {
		transition: 'transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1)',
	},
	selected: {
		color: theme.palette.primary.main,
		transform: 'scale(1.5)',
	},
	wildcard: {
		border: '1px solid',
		color: theme.palette.grey[200],
		backgroundColor: 'transparent',
	},
	winCondition: {
		margin: theme.spacing(0, 'auto', 4),
	},
	bar: {
		boxShadow: `inset 0 3px 5px rgba(0, 0, 0, 0.1)`,
		margin: theme.spacing(2, 0),
		backgroundColor: theme.palette.grey[200],
		borderRadius: 4,
		overflow: 'hidden',
		height: 10,
	},
	progress: {
		transition: (props) => `width ${props.roundSpeed}ms linear`,
		boxShadow: `inset 0 -3px 5px rgba(0, 0, 0, 0.1)`,
		backgroundColor: theme.palette.primary.main,
		height: 10,
	},
}));

export default function BingoCard({ gameId }) {
	const user = useCurrentUser();
	const firebase = useFirebase();
	const [isCheckingBingo, setIsCheckingBingo] = useState(false);
	const roundId = useSelector(getRoundId(gameId));
	const roundConfig = useSelector(getRoundConfig(gameId));
	const bingoCard = useSelector(getBingoCard(gameId, user.uid));
	const drawnNumbers = useSelector(getDrawnNumbers(gameId));
	const lastDrawnNumber = useSelector(getLastDrawnNumber(gameId));
	const [selectedNumbers, setSelectedNumbers] = useSessionStorage(
		`selectedNumbers:${roundId}`,
		[],
	);
	const { setBackgroundSpeed } = useContext(AnimatedBackgroundContext);
	const { isMuted } = useContext(AudioControl);
	const classes = useStyles(roundConfig);
	const hasBingoCard = bingoCard.length > 0;
	const mounted = useMountedRef();
	const [playBingo] = useSound(sndBingo2);

	useEffect(() => {
		setBackgroundSpeed(0.2 + drawnNumbers.length * 0.01);
	}, [drawnNumbers.length, setBackgroundSpeed]);

	const handleStampNumber = (value) => {
		if (selectedNumbers.includes(value)) {
			return;
		}

		setSelectedNumbers((state) => [...state, value]);
	};

	const handleBingo = useCallback(async () => {
		if (!isMuted) {
			playBingo();
		}

		const checkBingo = firebase
			.functions()
			.httpsCallable('bingo-checkBingo');

		setIsCheckingBingo(true);

		await checkBingo({ gameId, selectedNumbers });

		if (mounted.current) {
			setIsCheckingBingo(false);
		}
	}, [firebase, gameId, isMuted, mounted, playBingo, selectedNumbers]);

	if (!hasBingoCard) {
		return (
			<>
				<Typography variant="h4" component="p">
					This round is already in progress.
				</Typography>
				<Typography variant="h4" component="p">
					You will be able to join in the next round.
				</Typography>
			</>
		);
	}

	const winConditionText =
		roundConfig.winCondition === 'row'
			? 'Stamp 5 in a horizontal ROW'
			: roundConfig.winCondition === 'column'
			? 'Stamp 5 in a vertical COLUMN'
			: 'Stamp 5 in either DIAGONAL';

	return (
		<div className={classes.cardWrapper}>
			{roundConfig && (
				<>
					<Typography variant="h5">
						{winConditionText}, then click BINGO!
					</Typography>
					<Typography className={classes.winCondition} variant="h6">
						Note: the centre square is already stamped
					</Typography>
					<DrawnNumberIndicator number={lastDrawnNumber}>
						Get ready...
					</DrawnNumberIndicator>
					<div className={classes.bar}>
						<div
							className={classes.progress}
							style={{
								width: `${
									(drawnNumbers.length /
										roundConfig.numberPool) *
									100
								}%`,
							}}
						/>
					</div>
				</>
			)}
			<Stampable storageKey={`stamps:${roundId}`}>
				<div className={classes.card}>
					{bingoCard.map((row) =>
						row.map((cell) => (
							<UIButton
								key={cell.value}
								className={clsx(
									classes.number,
									typeof cell.value !== 'number' &&
										classes.wildcard,
								)}
								value={cell.value}
								disabled={cell.stamped}
								onClick={() => handleStampNumber(cell.value)}
							>
								{cell.value === '*' ? (
									<StarIcon
										color="inherit"
										fontSize="large"
									/>
								) : (
									<span
										className={clsx(
											classes.numberText,
											(selectedNumbers.includes(
												cell.value,
											) ||
												cell.stamped) &&
												classes.selected,
										)}
									>
										{cell.value}
									</span>
								)}
							</UIButton>
						)),
					)}
				</div>
			</Stampable>
			<Button onClick={handleBingo} loading={isCheckingBingo} fullWidth>
				BINGO!
			</Button>
		</div>
	);
}
